import React, { useRef } from "react";
// import axios from "axios";
import { useApp } from "../context/app-context";
import backgroundImgPitch from "../assets/pitch.jpg";
import vfl from "../assets/VFLLogo.png";
// import LoaderFootball from "./loader/LoaderFootball";
import TopBanner from "./TopBanner";

// const navigation = [
//   { name: "Teams", href: "#" },
//   { name: "Matches", href: "#" },
//   { name: "Stats", href: "#" },
//   { name: "Sponsors", href: "#" },
// ];

export default function Topintro() {
  const { targetRef } = useApp();
  // const val = "home";
  // const targetRef = useRef(null);

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-white">
      {/* <Navbar hide={val} /> */}
      {/* <Navbar /> */}
      <div
        className="relative isolate px-6 pt-14 lg:px-8 bg-cover bg-center opacity-90 filter bg:saturate-0 bg:grayscale-0 bg:sepia-0 bg:invert-0 brightness-100 contrast-100 "
        style={{ backgroundImage: `url(${backgroundImgPitch})` }}
      >
        <TopBanner />
        {/* <div className="bg-cover bg-center h-screen" style={{backgroundImage: "url('/background.jpg')"}}> */}
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-3xl pt-16 pb-32 sm:pb-48 lg:pb-56">
          <img src={vfl} alt="" />
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-black sm:text-6xl ">
              Welcome to Vagad Football League Season-4
            </h1>

            <p className="mt-6 text-xl leading-8 text-[#FFFBF5] font-bold  text-shadow ">
              Welcome to VFL season-4 where passion meets competition! Join for
              excitement, camaraderie, thrilling matches.Let's kick off an epic
              season!
            </p>
            {/* <LoaderFootball /> */}
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                // href="#"
                className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 duration-300 ..."
                onClick={scrollToTarget}
              >
                Get started
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
