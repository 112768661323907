import React from "react";

function LeagueInfoBanner() {
  return (
    <>
      <div className="bg-gray-900 py-4 sm:py-16 ">
        <div className="px-4 py-10 sm:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-12 lg:px-8 lg:py-20 bg-white rounded-md md:rounded-none">
          <div className="grid grid-cols-2 row-gap-8 md:grid-cols-4 gap-y-4">
            <div className="text-center md:border-r">
              <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl mb-2">
                108
              </h6>
              <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                Players
              </p>
            </div>
            <div className="text-center md:border-r">
              <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl mb-2">
                34
              </h6>
              <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                Matches
              </p>
            </div>
            <div className="text-center md:border-r">
              <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl mb-2 ">
                3
              </h6>
              <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                Weekly Prizes
              </p>
            </div>
            <div className="text-center">
              <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl mb-2">
                &#8734;
              </h6>
              <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                Excitment
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeagueInfoBanner;
