import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LoaderFootball from "../components/loader/LoaderFootball";

function SignUp() {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signUpsuccesfull, setSignUpsuccesfull] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("UserName:", userName);
    console.log("Email:", email);
    console.log("Password:", password);
    console.log("Phone number :", phoneNumber);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    function validateEmail(email) {
      return emailRegex.test(email);
    }
    console.log(validateEmail(email));
    if (userName?.length == 0) {
      setError("Please enter UserName");
    } else if (userName?.length > 16) {
      setError("UserName should have less than 16 characters");
    } else if (email?.length == 0) {
      setError("Please enter Email");
    } else if (!validateEmail(email)) {
      setError("Please enter valid email");
    }
    // else if (email?.length > 20) {
    //   setError("Email should have less than 20 characters");
    // }
    else if (phoneNumber?.length !== 10) {
      setError("Phone number should have 10 digits");
    } else if (password?.length == 0) {
      setError("Please enter Password");
    } else if (password?.length <= 8) {
      setError("Password length should be greater than 8");
    } else {
      const data = {
        username: userName,
        email: email,
        password: password,
        number: phoneNumber,
      };
      console.log(data);

      const usernames = allUser?.map((user) => user.username);
      const useremails = allUser?.map((user) => user.email);

      const usernameExists = usernames?.includes(userName);
      const useremailsExists = useremails?.includes(email);

      console.log("User name that alreadt exists : ", usernameExists);
      if (usernameExists) {
        setError("UserName already Exists");
      } else if (useremailsExists) {
        setError("User Email already Exists");
      } else {
        postSignUpCred(data);
      }
    }
  };

  const postSignUpCred = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user/signup`,
        data
      );
      console.log("Response:", response.data);
      if (response?.data?.length !== 0) {
        setSignUpsuccesfull(true);
        resetVal();
        setLoading(false);
        navigate("/login");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (signUpsuccesfull) {
      const timer = setTimeout(() => {
        setSignUpsuccesfull(false);
      }, 3000); // Adjust the duration as needed

      return () => clearTimeout(timer);
    }
  }, [signUpsuccesfull]);

  useEffect(() => {
    if (error?.length !== 0) {
      const timer = setTimeout(() => {
        setError();
      }, 3000); // Adjust the duration as needed

      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    allUserProfile();
  }, []);

  const resetVal = () => {
    setUserName("");
    setEmail("");
    setPassword("");
  };

  const allUserProfile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/getusers?limit=1000`
      );
      setAllUser(response.data.users);
      console.log("All users : ", response.data.users);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  return (
    <div className="mx-auto max-w-screen bg-[#192347] px-4 py-24   sm:px-6 lg:px-8 shadow-lg">
      <div className="mx-auto max-w-lg bg-white rounded-md py-5">
        <h1 className="text-center text-2xl font-bold text-indigo-600 sm:text-3xl">
          Get started today
        </h1>

        <p className="mx-auto mt-4 max-w-md text-center text-gray-500 px-2 font-semibold text-lg ">
          Please enter your valid credentials
        </p>
        <div className="mt-2 mx-2 bg-yellow-200 px-4 py-4  mb- rounded-md text-md flex items-center max-w-lg">
          <svg
            viewBox="0 0 24 24"
            className="text-yellow-800 w-9 h-9 sm:w-9 sm:h-9 mr-3"
          >
            <path
              fill="currentColor"
              d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
            ></path>
          </svg>
          <span className="text-yellow-800  font-semibold text-md">
            {" "}
            We will be using these details to contact you for prizes
          </span>
        </div>
        <form
          onSubmit={handleSubmit}
          className="mb-0 space-y-4 rounded-lg p-4  sm:p-6 lg:p-8"
        >
          <p className="text-center text-lg font-medium">
            Sign in to your account
          </p>
          <div>
            <label htmlFor="username" className="sr-only">
              Username
            </label>
            <div className="relative">
              <input
                type="text"
                value={userName}
                onChange={handleUserNameChange}
                className={`w-full rounded-lg border-b-2 border-gray-600 p-4 pe-12 text-sm shadow-sm`}
                placeholder="Enter username"
              />
              <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-4 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div>
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <div className="relative">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className={`w-full rounded-lg border-b-2 border-gray-600  p-4 pe-12 text-sm shadow-sm`}
                placeholder="Enter email"
              />
              <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-4 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div>
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <div className="relative">
              <input
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                className="w-full rounded-lg border-b-2 border-gray-600 p-4 pe-12 text-sm shadow-sm"
                placeholder="Enter password"
              />
              <span
                className="absolute inset-y-0 end-0 grid place-content-center px-4 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {isPasswordVisible ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13.875 18.825A10.012 10.012 0 0112 19c-4.478 0-8.268-2.943-9.542-7A10.013 10.013 0 0112 5c2.027 0 3.913.597 5.475 1.625M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M20.084 20.084a1.517 1.517 0 000-2.134L4.055 4.055a1.517 1.517 0 10-2.134 2.134l16.029 16.029a1.517 1.517 0 002.134 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                )}
              </span>
            </div>
          </div>

          <div>
            <label htmlFor="phoneNumber" className="sr-only">
              Phone
            </label>
            <div className="relative">
              <input
                type="number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className={`w-full rounded-lg border-b-2 border-gray-600 p-4 pe-12 text-sm shadow-sm`}
                placeholder="Enter phone number"
              />
              <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  className="h-4 w-4 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          {error ? (
            <div className="bg-red-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto max-w-lg">
              <svg
                viewBox="0 0 24 24"
                className="text-red-600 w-5 h-5 sm:w-5 sm:h-5 mr-3"
              >
                <path
                  fill="currentColor"
                  d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
                ></path>
              </svg>
              <span className="text-red-800"> {error} </span>
            </div>
          ) : (
            <></>
          )}

          <button
            type="submit"
            className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
          >
            Sign Up
          </button>

          <p className="text-center text-sm text-gray-500">
            Already have an account?{" "}
            <NavLink
              to={`/login`}
              className="underline text-indigo-600 font-medium text-base"
            >
              Log in
            </NavLink>
          </p>
        </form>
        <div
          className={`fixed left-1/2 transform -translate-x-1/2 bottom-0 mb-10 ${
            signUpsuccesfull
              ? "opacity-100 pointer-events-auto transition-opacity duration-500"
              : "opacity-0 pointer-events-none transition-opacity-slow duration-3000"
          }`}
          style={{
            transitionTimingFunction: "ease-in-out",
          }}
        >
          {signUpsuccesfull && (
            <div className="relative">
              <div className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md animate-fade-in relative z-10">
                Succesfully signed up
              </div>
            </div>
          )}
        </div>
        {loading ? (
          <div className="flex flex-col text-center">
            <LoaderFootball height={24} width={24} />
            <div className="text-lg font-bold text-[#185f8d] animate-pulse">
              Signing Up...
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default SignUp;
