import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useCallback, useState } from "react";
import { useApp } from "../../context/app-context";

function Button2({ content, onClick, active, disabled }) {
  return (
    <button
      className={`flex flex-col cursor-pointer items-center justify-center w-[3.6rem] h-12 shadow-sm  transition-colors rounded-lg font-bold text-sm 
      ${active ? "bg-blue-500 text-white" : "text-blue-500 bg-white"}
      ${
        !disabled
          ? "hover:bg-blue-500 hover:text-white"
          : "text-blue-300 bg-white cursor-not-allowed"
      }
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
}

function PublicPagination({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  //   fantasyTeam,
}) {
  const { paginatedWeekPublic, setPaginatedWeekPublic, allUser, setAllUser } =
    useApp();
  const [error, setError] = useState(null);

  //new
  const changeNcall = (pageIndexToMap) => {
    // getMatchDatanew(`${process.env.REACT_APP_SERVER_URL}/api/match?week=${pageIndexToMap}`);
    // //console.log(`index : ${pageIndexToMap}`);
    // const fantasyTeamWeek4 = fantasyTeam?.find(
    //   (team) => team.week === pageIndexToMap
    // );
    // let temp = fantasyTeamWeek4;
    // if (fantasyTeamWeek4 === undefined) {
    //   temp = "0";
    // }
    //console.log(pageIndexToMap);
    allUserProfile(pageIndexToMap);
    //console.log(pageIndexToMap);
    setPaginatedWeekPublic(pageIndexToMap);
    // setweeklyFantasyTeam(temp);
  };
  //   async function getMatchDatanew(url) {
  //     try {
  //       console.log(url);
  //       const response = await axios.get(url);
  //       console.log("GET Match request successful");
  //       console.log(response.data);
  //       setMatchData(response.data);
  //     } catch (error) {
  //       console.error("Error making GET match request:", error);
  //       // Handle errors
  //       setError("Error fetching GET match request. Please try again later.");
  //     }
  //   }

  const allUserProfile = async (week) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/getusers?limit=1000&week=${week}`
      );
      const data = response.data;
      const users = response.data.users;
      // Adding points attribute to each user
      const temp = users.sort((a, b) => {
        const aPoints = WeekPointsFunc(a, week);
        const bPoints = WeekPointsFunc(b, week);
        return bPoints - aPoints;
      });
      setAllUser(temp);

      //   setAllUser(temp);
      //console.log(temp);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const WeekPointsFunc = (client, week) => {
    const teamForWeek = client?.fantasyTeam?.find((team) => team.week === week);

    const points = teamForWeek?.points;

    return points;
  };

  const sortUsers = () => {
    const temp = [...allUser].sort((a, b) => b.points - a.points);
    setAllUser(temp);
  };
  const renderPageLinks = useCallback(() => {
    if (pageCount === 0) return null;
    const visiblePageButtonCount = 3;
    let numberOfButtons =
      pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
    const pageIndices = [pageIndex];
    numberOfButtons--;
    [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
      const pageNumberBefore = pageIndices[0] - 1;
      const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
      if (
        pageNumberBefore >= 0 &&
        (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
      ) {
        pageIndices.unshift(pageNumberBefore);
      } else {
        pageIndices.push(pageNumberAfter);
      }
    });

    return pageIndices?.map((pageIndexToMap) => (
      <li key={pageIndexToMap}>
        <Button2
          content={`Week ${pageIndexToMap + 1}`}
          onClick={() => {
            gotoPage(pageIndexToMap);
            // changeNcall(pageIndexToMap);  orignal
            changeNcall(pageIndexToMap + 1); // work around
          }}
          active={pageIndex === pageIndexToMap}
        />
      </li>
    ));
  }, [pageCount, pageIndex]);
  return (
    <div className="flex justify-center  grid-cols-4 gap-2  p-2 mx-auto mt-5 mb-5">
      <ul className="flex gap-2 bg-gray-200 p-2 rounded-xl">
        <li>
          <Button2
            content={
              <div className="flex ml-1">
                <ChevronLeftIcon className="w-4 h-4" />
                <ChevronLeftIcon className="w-4 h-4 -translate-x-1/2" />
              </div>
            }
            onClick={() => {
              gotoPage(0);
              changeNcall(1);
            }}
            disabled={!canPreviousPage}
          />
        </li>
        {renderPageLinks()}
        <li>
          <Button2
            content={
              <div className="flex ml-1">
                <ChevronRightIcon className="w-4 h-4" />
                <ChevronRightIcon className="w-4 h-4 -translate-x-1/2" />
              </div>
            }
            onClick={() => {
              gotoPage(pageCount - 1);
              changeNcall(pageCount);
            }}
            disabled={!canNextPage}
          />
        </li>
      </ul>
    </div>
  );
}

export default PublicPagination;
