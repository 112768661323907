import React, { useEffect, useState } from "react";
import axios from "axios";
import { useApp } from "../../context/app-context";
//
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
//
const people = [
  {
    id: 1,
    name: "Wade Cooper",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Arlene Mccoy",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Devon Webb",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 4,
    name: "Tom Cook",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 5,
    name: "Tanya Fox",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 6,
    name: "Hellen Schmidt",
    avatar:
      "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 7,
    name: "Caroline Schultz",
    avatar:
      "https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 8,
    name: "Mason Heaney",
    avatar:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 9,
    name: "Claudie Smitham",
    avatar:
      "https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 10,
    name: "Emil Schaefer",
    avatar:
      "https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function MatchUpdates() {
  //League Matches

  //Check if Admin uploaded : (Team and players)
  //   useEffect(() => {}, []);
  // ------------------------------------------------------Team

  // ------------------------------------------------------Player

  //   const [showDetailsTeam1, setShowDetailsTeam1] = useState(false);chow is only one showdetails sufficient

  //   useEffect(() => {
  //     console.log(liveMatch, "live match");
  //     const team1Name = liveMatch?.team1;
  //     getLiveTeam1Data(`${process.env.REACT_APP_SERVER_URL}/api/player?team=${team1Name}&limit=1000`);
  //     const team2Name = liveMatch?.team2;
  //     getLiveTeam2Data(`${process.env.REACT_APP_SERVER_URL}/api/player?team=${team2Name}&limit=1000`);
  //   }, []);

  //   async function getLiveTeam1Data(url) {
  //     try {
  //       const response = await axios.get(url);
  //       console.log("GET Live Team1 Players request successful");
  //       console.log(response.data.players);
  //       setLiveTeam1Data(response.data.players);
  //     } catch (error) {
  //       console.error("Error making GET Live Team1 Players  request:", error);
  //       // Handle errors
  //       throw error; // Re-throw the error to be handled outside the function if needed
  //     }
  //   }
  //   async function getLiveTeam2Data(url) {
  //     try {
  //       const response = await axios.get(url);
  //       console.log("GET Live Team2 Players request successful");
  //       console.log(response.data.players);
  //       setLiveTeam2Data(response.data.players);
  //     } catch (error) {
  //       console.error("Error making GET Live Team2 Players  request:", error);
  //       // Handle errors
  //       throw error; // Re-throw the error to be handled outside the function if needed
  //     }
  //   }

  // //lineup selection
  // const [lineup1, setLineup1] = useState([]);
  // const [lineup2, setLineup2] = useState([]);

  // const handleCheckboxChange = (event, team) => {
  //   const playerId = event.target.value;
  //   const isChecked = event.target.checked;

  //   if (team === "team1") {
  //     if (isChecked) {
  //       setLineup1((prevLineup1) => prevLineup1.filter((id) => id !== ""));

  //       setLineup1([...lineup1, playerId]);
  //     } else {
  //       setLineup1(lineup1.filter((id) => id !== playerId));
  //     }
  //   } else if (team === "team2") {
  //     if (isChecked) {
  //       setLineup2((prevLineup2) => prevLineup2.filter((id) => id !== ""));
  //       setLineup2([...lineup2, playerId]);
  //     } else {
  //       setLineup2(lineup2.filter((id) => id !== playerId));
  //     }
  //   }
  // };
  const { activeTab, setActiveTab, liveMatch, liveLineup } = useApp();
  const [showDetails, setShowDetails] = useState(false);
  const [error, setError] = useState(null);

  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedGoalScorer, setSelectedGoalScorer] = useState(people[0]);
  const [selectedAssistedBy, setSelectedAssistedBy] = useState(people[0]);
  const [selectedYellowCarder, setSelectedYellowCarder] = useState(people[0]);
  const [selectedRedCarder, setSelectedRedCarder] = useState(people[0]);

  const [scoreUpdatedMessage, setScoreUpdatedMessage] = useState(false);
  const [yellowcardUpdatedMessage, setYellowcardUpdatedMessage] =
    useState(false);
  const [redcardUpdatedMessage, setRedcardUpdatedMessage] = useState(false);

  const handleCheckData = () => {
    // Perform any necessary checks
    // If conditions are met, set active tab to a specific value
    setActiveTab("tab1"); // Assuming "tab1" is the ID of the tab you want to navigate to
  };
  const [team1MatchUpdateData, SetTeam1MatchUpdateData] = useState([]);
  const [team2MatchUpdateData, setTeam2MatchUpdateData] = useState([]);
  const [conditionallyRenderedTeam, setconditionallyRenderedTeam] = useState(
    []
  );
  const [scoringTeam, setScoringTeam] = useState();
  const [displayText, setDisplayText] = useState();

  useEffect(() => {
    setSelectedTeam();
    setSelectedRedCarder();
    setSelectedYellowCarder();
    setSelectedGoalScorer();
    setSelectedAssistedBy();
    setMinute("");
    getMatchgettingUpdated();
    console.log(liveMatch);
  }, [liveLineup, liveMatch]);

  async function getMatchgettingUpdated() {
    try {
      const team1Name = liveMatch.team1;
      const team2Name = liveMatch.team2;
      setScoringTeam([
        {
          teamName: team1Name,
          team_logo: liveMatch.team1logo,
          teamIdentity: "team1",
        },
        {
          teamName: team2Name,
          team_logo: liveMatch.team2logo,
          teamIdentity: "team2",
        },
      ]);
      getTeam1Data(
        `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team1Name}&limit=1000`,
        liveMatch
      );
      getTeam2Data(
        `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team2Name}&limit=1000`,
        liveMatch
      );
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }
  async function getTeam1Data(url, propsEachmatchData) {
    try {
      const response = await axios.get(url);
      console.log("GET Team1 Players request successful");
      console.log(response.data.players);
      console.log(propsEachmatchData);
      const filteredTeam1Players = response.data.players.filter((player) =>
        propsEachmatchData?.lineup1.includes(player._id)
      );
      SetTeam1MatchUpdateData(filteredTeam1Players);

      console.log("filtered team1 players", filteredTeam1Players);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }
  async function getTeam2Data(url, propsEachmatchData) {
    try {
      const response = await axios.get(url);
      console.log("GET Team2 Players request successful");
      console.log(response.data.players);
      console.log(propsEachmatchData);

      const filteredTeam2Players = response.data.players.filter((player) =>
        propsEachmatchData?.lineup2.includes(player._id)
      );
      setTeam2MatchUpdateData(filteredTeam2Players);

      console.log("filtered team2 players", filteredTeam2Players);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }

  const handleTeamtoMap = (team) => {
    console.log(team?.teamName);
    const currentTeam = team?.teamName;
    setSelectedTeam(team);
    setScoreUpdatedMessage(false);
    setRedcardUpdatedMessage(false);
    setYellowcardUpdatedMessage(false);
    if (currentTeam === liveMatch?.team1) {
      setSelectedGoalScorer();
      setSelectedAssistedBy();
      setSelectedRedCarder();
      setSelectedYellowCarder();
      setMinute("");
      setconditionallyRenderedTeam(team1MatchUpdateData);
      console.log(team1MatchUpdateData);
    } else if (currentTeam === liveMatch?.team2) {
      setSelectedGoalScorer();
      setSelectedAssistedBy();
      setSelectedRedCarder();
      setSelectedYellowCarder();
      setMinute("");
      setconditionallyRenderedTeam(team2MatchUpdateData);
      console.log(team2MatchUpdateData);
    }
  };

  const [minute, setMinute] = useState("");

  // Function to handle changes in the minute input
  const handleMinuteChange = (event) => {
    setMinute(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedOption === "1") {
      if (selectedTeam && selectedGoalScorer && minute) {
        if (selectedAssistedBy) {
          const data = {
            team: selectedTeam.teamIdentity,
            scorer: selectedGoalScorer._id,
            assist: selectedAssistedBy._id,
            minute: minute,
          };
          const event = "goal";
          console.log("Data:", data);
          // setScoreUpdatedMessage(true);
          SubmitMatchUpdates(data, event);
        } else {
          const data = {
            team: selectedTeam.teamIdentity,
            scorer: selectedGoalScorer._id,
            minute: minute,
          };
          const event = "goal";
          console.log("Data:", data);
          // setScoreUpdatedMessage(true);
          SubmitMatchUpdates(data, event);
        }
      } else {
        alert("Please fill out all required fields");
      }
    } else if (selectedOption === "2") {
      if (selectedTeam && selectedYellowCarder && minute) {
        const data = {
          team: selectedTeam.teamIdentity,
          player: selectedYellowCarder._id,
          minute: minute,
        };
        const event = "yellowCards";
        console.log("Data:", data);
        // setYellowcardUpdatedMessage(true);
        SubmitMatchUpdates(data, event);
      } else {
        alert("Please fill out all required fields");
      }
    } else if (selectedOption === "3") {
      if (selectedTeam && selectedRedCarder && minute) {
        const data = {
          team: selectedTeam.teamIdentity,
          player: selectedRedCarder._id,
          minute: minute,
        };
        const event = "redCards";
        console.log("Data:", data);
        // ReflectChangeButton(event)
        SubmitMatchUpdates(data, event);
        // setRedcardUpdatedMessage(true);
      } else {
        alert("Please fill out all required fields");
      }
    }
    console.log("Event:", event);

    // const data = {
    //   team: selectedTeam.teamName,
    //   scorer: selectedGoalScorer.name,
    //   assistedby: selectedAssistedBy.name,
    //   yellowcardTo: selectedYellowCarder.name,
    //   redCardTo: selectedRedCarder.name,
    // };
    // Do something with data, e.g., send it to an API

    // setScoreUpdatedMessage(true); // ******* temporarily placed after log normall should be in api call as below

    // SubmitMatchUpdates(data, event);
  };

  const SubmitMatchUpdates = async (data, event) => {
    try {
      console.log("event:", event);
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/match/${liveMatch?._id}/event/${event}`, // replace this with update match api
        data
      );
      console.log("Response:", response.data);

      if (event === "goal") {
        setScoreUpdatedMessage(true);
        setTimeout(() => {
          setScoreUpdatedMessage(false); // Hide error message after 2 seconds
        }, 2000);
        setSelectedGoalScorer();
        setSelectedAssistedBy();
        setMinute("");
      } else if (event === "yellowCards") {
        setYellowcardUpdatedMessage(true);
        setTimeout(() => {
          setYellowcardUpdatedMessage(false); // Hide error message after 2 seconds
        }, 2000);
        setSelectedYellowCarder();
        setMinute("");
      } else if (event === "redCards") {
        setRedcardUpdatedMessage(true);
        setTimeout(() => {
          setRedcardUpdatedMessage(false); // Hide error message after 2 seconds
        }, 2000);
        setSelectedRedCarder();
        setMinute("");
      }
      // Handle response or do something with the data
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  const [selectedOption, setSelectedOption] = useState();
  const [goalScoredMenu, setGoalScoredMenu] = useState(false);
  const [yellowCardMenu, setYellowCardMenu] = useState(false);
  const [redCardMenu, setRedCardMenu] = useState(false);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    console.log(value);
    setScoreUpdatedMessage(false);
    setRedcardUpdatedMessage(false);
    setYellowcardUpdatedMessage(false);
    // Update menu visibility based on selected option
    if (value === "1") {
      setGoalScoredMenu(true);
      setYellowCardMenu(false);
      setRedCardMenu(false);
    } else if (value === "2") {
      setGoalScoredMenu(false);
      setYellowCardMenu(true);
      setRedCardMenu(false);
      // setSelectedRedCarder();

      // setMinute('');
    } else if (value === "3") {
      setGoalScoredMenu(false);
      setYellowCardMenu(false);
      setRedCardMenu(true);
      // setSelectedYellowCarder();
      // setSelectedGoalScorer();
      // setSelectedAssistedBy();
      // setMinute('');
    }
  };

  // Can be used to simply button function but not tested yet
  // const ReflectChangeButton = (prop) => {
  //   if (prop === "goal") {
  //     setDisplayText("Goal Updated");
  //   } else if (prop === "yellowCard") {
  //     setDisplayText("Yellow Card Updated");
  //   } else if (prop === "redCard") {
  //     setDisplayText("Red Card Updated");
  //   } else {
  //     console.log("unexpected behviour ,input : ", prop);
  //   }
  //   setTimeout(() => {
  //     setDisplayText("Update Stat");
  //   }, 2000);
  // };

  return (
    <>
      <li className="flex w-full py-5">
        <details className="group w-full">
          <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
            <div
              className="flex justify-between w-full"
              onClick={() => setShowDetails(true)}
            >
              <div className="flex gap-5">
                <div className="min-w-0 flex-auto">
                  <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-teal-600">
                    Want to provide match Updates?
                  </p>
                </div>
              </div>
            </div>
            <svg
              className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </summary>
          <div className=" mx-auto max-w-[850px] bg-white ">
            <div className="mb-5">
              {liveMatch ? (
                <p className="mt-6 text-xl leading-8 text-green-800 font-bold text-center">
                  Live match : {liveMatch.team1} vs {liveMatch.team2} , week{" "}
                  {liveMatch.week}
                </p>
              ) : (
                <p className="mt-6 text-xl leading-8 text-red-800 font-bold text-center">
                  go to upper section and select live match adn team
                </p>
              )}
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center gap-4"
            >
              {/* <div className="flex flex-col items-center sm:flex-row sm:gap-4 justify-center mt-10 w-[80%] sm:w-auto m-10 gap-4 "> */}
              <div className="flex flex-col sm:gap-4  mt-10  m-10 gap-4 ">
                <Listbox value={selectedTeam} onChange={handleTeamtoMap}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Team whose Stats need to be updated :
                      </Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                          <span className="flex items-center">
                            <img
                              src={selectedTeam?.team_logo}
                              alt=""
                              className="h-5 w-5 flex-shrink-0 rounded-full"
                            />
                            <span className="ml-3 block truncate">
                              {selectedTeam?.teamName}
                            </span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {scoringTeam?.map((team, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={team}
                              >
                                {({ scoringTeam, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <img
                                        src={team.team_logo}
                                        alt=""
                                        className="h-5 w-5 flex-shrink-0 rounded-full"
                                      />
                                      <span
                                        className={classNames(
                                          scoringTeam
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {team.teamName}
                                      </span>
                                    </div>

                                    {scoringTeam ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                {/* <div className="grid items-center mt-24 mb-5  grid-cols-3 gap-2  relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6">
                  <div>
                    <input
                      type="radio"
                      name="option"
                      id="1"
                      value="1"
                      className="peer hidden"
                      defaultChecked
                    />
                    <label
                      htmlFor="1"
                      className="block cursor-pointer select-none rounded-md p-2 text-center peer-checked:bg-indigo-500 peer-checked:font-bold peer-checked:text-white"
                    >
                      Goal scored
                    </label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      name="option"
                      id="2"
                      value="2"
                      className="peer hidden"
                    />
                    <label
                      htmlFor="2"
                      className="block cursor-pointer select-none rounded-md p-2 text-center peer-checked:bg-indigo-500 peer-checked:font-bold peer-checked:text-white"
                    >
                      Yellow Card
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="option"
                      id="3"
                      value="3"
                      className="peer hidden"
                    />
                    <label
                      htmlFor="3"
                      className="block cursor-pointer select-none rounded-md p-2 text-center peer-checked:bg-indigo-500 peer-checked:font-bold peer-checked:text-white"
                    >
                      Red Card
                    </label>
                  </div>
                </div> */}
                {selectedTeam ? (
                  <div className="grid items-center mt-24 mb-5 grid-cols-3 gap-2 relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
                    <div>
                      <input
                        type="radio"
                        name="option"
                        id="1"
                        value="1"
                        className="peer hidden"
                        checked={selectedOption === "1"}
                        onChange={handleOptionChange}
                      />
                      <label
                        htmlFor="1"
                        className="block cursor-pointer select-none rounded-md p-2 text-center peer-checked:bg-indigo-500 peer-checked:font-bold peer-checked:text-white"
                      >
                        Goal scored
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        name="option"
                        id="2"
                        value="2"
                        className="peer hidden"
                        checked={selectedOption === "2"}
                        onChange={handleOptionChange}
                      />
                      <label
                        htmlFor="2"
                        className="block cursor-pointer select-none rounded-md p-2 text-center peer-checked:bg-indigo-500 peer-checked:font-bold peer-checked:text-white"
                      >
                        Yellow Card
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="option"
                        id="3"
                        value="3"
                        className="peer hidden"
                        checked={selectedOption === "3"}
                        onChange={handleOptionChange}
                      />
                      <label
                        htmlFor="3"
                        className="block cursor-pointer select-none rounded-md p-2 text-center peer-checked:bg-indigo-500 peer-checked:font-bold peer-checked:text-white"
                      >
                        Red Card
                      </label>
                    </div>

                    {/* Render menus based on selected option
                  {goalScoredMenu && <div>Goal Scored Menu</div>}
                  {yellowCardMenu && <div>Yellow Card Menu</div>}
                  {redCardMenu && <div>Red Card Menu</div>} */}
                  </div>
                ) : (
                  <></>
                )}

                {goalScoredMenu ? (
                  <Listbox
                    value={selectedGoalScorer}
                    onChange={setSelectedGoalScorer}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                          Goal scored by :
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                              <img
                                src={selectedGoalScorer?.photo}
                                alt=""
                                className="h-5 w-5 flex-shrink-0 rounded-full"
                              />
                              <span className="ml-3 block truncate">
                                {selectedGoalScorer?.name}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {conditionallyRenderedTeam?.map(
                                (person, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={person}
                                  >
                                    {({ selectedGoalScorer, active }) => (
                                      <>
                                        <div className="flex items-center">
                                          <img
                                            src={person.photo}
                                            alt=""
                                            className="h-5 w-5 flex-shrink-0 rounded-full"
                                          />
                                          <span
                                            className={classNames(
                                              selectedGoalScorer
                                                ? "font-semibold"
                                                : "font-normal",
                                              "ml-3 block truncate"
                                            )}
                                          >
                                            {person.name}
                                          </span>
                                        </div>

                                        {selectedGoalScorer ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                )
                              )}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                ) : (
                  <></>
                )}
                {goalScoredMenu ? (
                  <Listbox
                    value={selectedAssistedBy}
                    onChange={setSelectedAssistedBy}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                          Assisted by :
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                              <img
                                src={selectedAssistedBy?.photo}
                                alt=""
                                className="h-5 w-5 flex-shrink-0 rounded-full"
                              />
                              <span className="ml-3 block truncate">
                                {selectedAssistedBy?.name}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {conditionallyRenderedTeam?.map(
                                (person, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={person}
                                  >
                                    {({ selectedAssistedBy, active }) => (
                                      <>
                                        <div className="flex items-center">
                                          <img
                                            src={person.photo}
                                            alt=""
                                            className="h-5 w-5 flex-shrink-0 rounded-full"
                                          />
                                          <span
                                            className={classNames(
                                              selectedAssistedBy
                                                ? "font-semibold"
                                                : "font-normal",
                                              "ml-3 block truncate"
                                            )}
                                          >
                                            {person.name}
                                          </span>
                                        </div>

                                        {selectedAssistedBy ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                )
                              )}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                ) : (
                  <></>
                )}

                {yellowCardMenu ? (
                  <Listbox
                    value={selectedYellowCarder}
                    onChange={setSelectedYellowCarder}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                          Yellow Card To :
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                              <img
                                src={selectedYellowCarder?.photo}
                                alt=""
                                className="h-5 w-5 flex-shrink-0 rounded-full"
                              />
                              <span className="ml-3 block truncate">
                                {selectedYellowCarder?.name}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {conditionallyRenderedTeam?.map(
                                (person, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={person}
                                  >
                                    {({ selectedYellowCarder, active }) => (
                                      <>
                                        <div className="flex items-center">
                                          <img
                                            src={person.photo}
                                            alt=""
                                            className="h-5 w-5 flex-shrink-0 rounded-full"
                                          />
                                          <span
                                            className={classNames(
                                              selectedYellowCarder
                                                ? "font-semibold"
                                                : "font-normal",
                                              "ml-3 block truncate"
                                            )}
                                          >
                                            {person.name}
                                          </span>
                                        </div>

                                        {selectedYellowCarder ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                )
                              )}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                ) : (
                  <></>
                )}
                {redCardMenu ? (
                  <Listbox
                    value={selectedRedCarder}
                    onChange={setSelectedRedCarder}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                          Red Card To :
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                              <img
                                src={selectedRedCarder?.photo}
                                alt=""
                                className="h-5 w-5 flex-shrink-0 rounded-full"
                              />
                              <span className="ml-3 block truncate">
                                {selectedRedCarder?.name}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {conditionallyRenderedTeam?.map(
                                (person, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={person}
                                  >
                                    {({ selectedRedCarder, active }) => (
                                      <>
                                        <div className="flex items-center">
                                          <img
                                            src={person.photo}
                                            alt=""
                                            className="h-5 w-5 flex-shrink-0 rounded-full"
                                          />
                                          <span
                                            className={classNames(
                                              selectedRedCarder
                                                ? "font-semibold"
                                                : "font-normal",
                                              "ml-3 block truncate"
                                            )}
                                          >
                                            {person.name}
                                          </span>
                                        </div>

                                        {selectedRedCarder ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                )
                              )}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                ) : (
                  <></>
                )}
                {(selectedTeam && goalScoredMenu) ||
                yellowCardMenu ||
                redCardMenu ? (
                  <div>
                    <label
                      htmlFor="minute"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Minute
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="number"
                        name="minute"
                        id="minute"
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00"
                        value={minute}
                        onChange={handleMinuteChange} // Call handleMinuteChange when input changes
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* <button >Submit</button> */}
              </div>

              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  className={` relative hover:shadow-form w-[80%] sm:w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  
                  ${
                    scoreUpdatedMessage ||
                    yellowcardUpdatedMessage ||
                    redcardUpdatedMessage
                      ? "bg-[#117C13]"
                      : "bg-[#6A64F1]"
                  }`}
                  // onClick={() => livematchFunc(match)} bg-indigo-700
                >
                  {!scoreUpdatedMessage &&
                  !yellowcardUpdatedMessage &&
                  !redcardUpdatedMessage
                    ? "Update stat"
                    : scoreUpdatedMessage &&
                      !yellowcardUpdatedMessage &&
                      !redcardUpdatedMessage
                    ? "Match Score Updated"
                    : !scoreUpdatedMessage &&
                      yellowcardUpdatedMessage &&
                      !redcardUpdatedMessage
                    ? "Yellow Card Updated"
                    : !scoreUpdatedMessage &&
                      !yellowcardUpdatedMessage &&
                      redcardUpdatedMessage
                    ? "Red Card Updated"
                    : `Not satisfying any condn`}
                </button>
              </div>
            </form>
          </div>
        </details>
      </li>
    </>
  );
}

export default MatchUpdates;
