import titleSponsor from "../assets/sia_No_BG.png";
import jerseySponsor from "../assets/SCS_NEW_LOGO_NoBg.png";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export default function Sponsors() {
  const [teamData, setTeamData] = useState();

  useEffect(() => {
    fetchTeamData();
  }, []);

  const fetchTeamData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/team`
      );
      const data = response.data.teams;
      // console.log(data);
      setTeamData(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const firstFour = teamData?.slice(0, 4);
  const nextFour = teamData?.slice(4);

  const linkTeamSponsor = (prop) => {
    const name_team = prop?.name;
    if (name_team == "Mango Marvels") {
      return "https://www.instagram.com/mangoartandstationery?igsh=MWtuajR5cjVicXFhOQ==";
    } else if (name_team == "Feonn Furies") {
      return "https://www.instagram.com/feonnethnic?igsh=YzFudTFkM2RocHlv";
    } else if (name_team == "Carron Capitals") {
      return "https://www.instagram.com/carronclothing?igsh=MXFodXU3Zm8xeDRoaQ==";
    } else if (name_team == "Vandana Warriors") {
      return "https://www.instagram.com/vandana_linking_road?igsh=cTBhMzR2NGtvZzk=";
    } else if (name_team == "Bellicosa FC") {
      return "https://www.instagram.com/bellicosastudio?igsh=MWF4d2JnZ2dzMzZmbA==";
    } else if (name_team == "Sejal Scorchers") {
      return "https://www.instagram.com/sejalgroup?igsh=MXFyNXo2M2l5N2V4aw==";
    } else if (name_team == "Team Sagar") {
      return "https://www.instagram.com/sagar_bandra?igsh=NnU3aDB3MG82dGw4";
    } else if (name_team == "Sakori Smashers") {
      return "https://www.instagram.com/sakori_ethnic?igsh=dnJrZXA2a3U3NHht";
    } else {
      return "/";
    }
  };
  const gaTeamSponsor = (prop) => {
    const name_team = prop?.name;
    if (name_team == "Mango Marvels") {
      ReactGA.event({
        category: "viewed sponsors profile",
        action: "clicked sponsors link new",
        label: "Mango",
      });
    } else if (name_team == "Feonn Furies") {
      ReactGA.event({
        category: "viewed_sponsors_profile",
        action: "clicked_sponsors_link_new",
        label: "Feonn",
      });
    } else if (name_team == "Feonn Furies") {
      ReactGA.event({
        category: "viewed_sponsors_profile",
        action: "clicked_sponsors_link_new",
        label: "Feonn",
      });
    } else if (name_team == "Vandana Warriors") {
      ReactGA.event({
        category: "viewed_sponsors_profile",
        action: "clicked_sponsors_link_new",
        label: "Vandana",
      });
    } else if (name_team == "Bellicosa FC") {
      ReactGA.event({
        category: "viewed_sponsors_profile",
        action: "clicked_sponsors_link_new",
        label: "Bellicosa",
      });
    } else if (name_team == "Sejal Scorchers") {
      ReactGA.event({
        category: "viewed_sponsors_profile",
        action: "clicked_sponsors_link_new",
        label: "Sejal",
      });
    } else if (name_team == "Team Sagar") {
      ReactGA.event({
        category: "viewed_sponsors_profile",
        action: "clicked_sponsors_link_new",
        label: "Sagar",
      });
    } else if (name_team == "Sakori Smashers") {
      ReactGA.event({
        category: "viewed_sponsors_profile",
        action: "clicked_sponsors_link_new",
        label: "Sakori",
      });
    }
  };
  // clicked_sponsors_link_new --- Date:28-6-24

  const titleGa = () => {
    ReactGA.event({
      category: "viewed_Top_sponsors_profile",
      action: "clicked_titleSponsor_link",
      label: "Sia",
      // value: match._id,
    });
  };
  const jerseyGa = () => {
    ReactGA.event({
      category: "viewed_Top_sponsors_profile",
      action: "clicked_JerseySponsor_link",
      label: "SCS",
      // value: match._id,
    });
  };

  return (
    <div className="bg-gray-900 pb-12 pt-6 sm:py-16">
      {" "}
      {/* Swap background color */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="mb-8 text-center text-lg font-semibold leading-8 text-white">
          {/* Swap text color */}
          Powered by our amazing sponsors
        </h2>
        {/* <div className="mb-10 mx-auto mt-10 grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div className="flex flex-col justify-center text-center">
            <img
              className="col-span-2 max-h-24 w-full object-contain lg:col-span-1"
              src={titleSponsor}
              alt="Transistor"
              width={158}
              height={48}
            />
            <p className="m-2 text-[#E1C564] font-semibold text-lg">
              TITLE SPONSOR
            </p>
          </div>

          <div className="flex flex-col justify-center text-center">
            <img
              className="col-span-2 max-h-24 w-full object-contain lg:col-span-1 "
              src={jerseySponsor}
              alt="Tuple"
              width={158}
              height={48}
            />
            <p className="m-2 text-[#E1C564] font-semibold text-lg">
              JERSEY SPONSOR
            </p>
          </div>
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1 filter brightness-0 invert"
            src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
            alt="SavvyCal"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1 filter brightness-0 invert "
            src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
            alt="Statamic"
            width={158}
            height={48}
          />
        </div> */}
        <div className="mb-16 grid grid-cols-1 gap-8 ">
          <div className="flex items-center justify-center col-span-1 md:col-span-1 lg:col-span-1">
            <a
              href="https://www.instagram.com/siaartjewellery?igsh=bWY5bWxzcTJtaXV3
            "
              onClick={() => {
                titleGa();
              }}
            >
              {" "}
              <div className="flex flex-col">
                <img
                  className="h-36 md:h-56 md:w-72 text-gray-500 fill-current dark:text-gray-300 "
                  src={titleSponsor}
                  alt="Tuple"
                  width={158}
                  height={48}
                />
                <p className="m-2 text-[#E1C564] font-semibold text-xl text-center">
                  TITLE SPONSOR
                </p>
              </div>
            </a>
          </div>
          <div className="flex items-end justify-center col-span-1 md:col-span-1 lg:col-span-1">
            <a
              href="
            https://www.instagram.com/scssports.in?igsh=MWVlZmxhbm1uZzI3Zw=="
              onClick={() => {
                jerseyGa();
              }}
            >
              <div className="flex flex-col justify-center items-center">
                <img
                  className="h-24 md:h-56 md:w-72 text-gray-500 fill-current dark:text-gray-300 "
                  src={jerseySponsor}
                  alt="Tuple"
                  width={158}
                  height={48}
                />
                <p className="m-2 text-gray-500 font-semibold text-xl text-center">
                  JERSEY SPONSOR
                </p>
              </div>
            </a>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8 md:grid-cols-8  lg:grid-cols-4">
          {firstFour?.map((team, index) => (
            <a
              href={`${linkTeamSponsor(team)}`}
              key={index}
              onClick={() => {
                gaTeamSponsor(team);
              }}
            >
              <div
                key={index}
                className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1 "
              >
                <img
                  className="h-28 bg-white rounded-md"
                  src={team?.sponsor_logo}
                  alt="Tuple"
                  width={158}
                  height={48}
                />
              </div>
            </a>
          ))}
        </div>
        <div className="mt-4 grid grid-cols-2 gap-8 md:grid-cols-8 md:mt-16 lg:grid-cols-4">
          {nextFour?.map((team, index) => (
            <a
              href={`${linkTeamSponsor(team)}`}
              key={index}
              onClick={() => {
                gaTeamSponsor(team);
              }}
            >
              <div
                key={index}
                className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1"
              >
                <img
                  className="bg-white h-28  rounded-md "
                  src={team?.sponsor_logo}
                  alt="Tuple"
                  width={158}
                  height={24}
                />
              </div>
            </a>
          ))}
        </div>
        <h2 className="m-8 text-center text-xl font-semibold  text-white">
          {" "}
          Team Sponsors
        </h2>
      </div>
    </div>
  );
}
