import React, { useEffect, useState } from "react";
import axios from "axios";
import OthersFantasyTeam from "../othersFantasyTeam/OthersFantasyTeam";

function ViewOnlyFantasy({ user, teamData, fantasyWeek }) {
  //   const [user, setUser] = useState();
  const [team, setTeam] = useState();
  // const [currentWeek, setCurrentWeek] = useState(0);

  //   useEffect(() => {
  //     removeOnClickAttribute(teamData);
  //   }, []);

  //   //   const userProfileFunc = async () => {
  //   //     try {
  //   //       const response = await axios.get(
  //   //         `${process.env.REACT_APP_SERVER_URL}/api/user/${userId}`
  //   //       );
  //   //       const data = response.data;
  //   //       setUser(data);
  //   //       console.log(data);
  //   //       getWeekNumber(data);
  //   //     } catch (error) {
  //   //       console.error("Fetch error:", error);
  //   //     }
  //   //   };

  //   //   const getWeekNumber = (userData) => {
  //   //     // Define the start date of the league
  //   //     const startDate = new Date("2024-5-14"); // Assuming the year is 2024

  //   //     // Get today's date
  //   //     const today = new Date();

  //   //     // Calculate the difference in days between today's date and the start date
  //   //     const diffInDays = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));

  //   //     // Calculate the week number by dividing the difference in days by 7 and rounding up
  //   //     const weekNum = Math.ceil(diffInDays / 7);
  //   //     console.log(weekNum);
  //   //     // setCurrentWeek(weekNum);
  //   //     extractFantasy(weekNum, userData);
  //   //     setCurrentWeek(weekNum);
  //   //   };
  //   //   const extractFantasy = (weekNum, userData) => {
  //   //     // fantasy extract process below -------------------------------
  //   //     const fantasyTeamWeek = userData?.fantasyTeam?.find(
  //   //       (team) => team.week === weekNum
  //   //     );
  //   //     const captainId = fantasyTeamWeek.captain?._id;
  //   //     const viceCaptainId = fantasyTeamWeek.viceCaptain?._id;

  //   //     console.log("fantasyTeamWeek4", fantasyTeamWeek);
  //   //     const categorizedPlayers = {
  //   //       fw: [],
  //   //       cm: [],
  //   //       df: [],
  //   //       gk: [],
  //   //     };
  //   //     // checking user team
  //   //     fantasyTeamWeek?.players?.forEach((player) => {
  //   //       let role = "normal"; // Default role
  //   //       if (captainId === player?._id) {
  //   //         role = "captain";
  //   //       } else if (viceCaptainId === player?._id) {
  //   //         role = "vice-captain";
  //   //       }
  //   //       const posn = player.position.split(",")[0].trim();
  //   //       const temp = {
  //   //         id: player._id,
  //   //         name: player.name,
  //   //         photo: player.photo,
  //   //         playerPosition: posn,
  //   //         role: role,
  //   //         points: player.points,
  //   //       };
  //   //       switch (posn) {
  //   //         case "Attacker":
  //   //           categorizedPlayers.fw.push(temp);
  //   //           break;
  //   //         case "Midfielder":
  //   //           categorizedPlayers.cm.push(temp);
  //   //           break;
  //   //         case "Defender":
  //   //           categorizedPlayers.df.push(temp);
  //   //           break;
  //   //         case "Goalkeeper":
  //   //           categorizedPlayers.gk.push(temp);
  //   //           break;
  //   //         default:
  //   //           break;
  //   //       }
  //   //     });
  //   //     console.log(categorizedPlayers);
  //   //     const updatedTeams = {
  //   //       squad: categorizedPlayers,
  //   //     };
  //   //     console.log("updatedTeams", updatedTeams);
  //   //     setTeam(updatedTeams);
  //   //   };

  //   function removeOnClickAttribute(teamProp) {
  //     const positions = ["fw", "cm", "df", "gk"];

  //     // Iterate through each position array
  //     positions.forEach((position) => {
  //       if (teamProp[position] && Array.isArray(teamProp[position])) {
  //         teamProp[position] = teamProp[position].map((player) => {
  //           // Create a copy of the player object
  //           const playerCopy = { ...player };
  //           // Delete the onClick attribute
  //           delete playerCopy.onClick;
  //           return playerCopy;
  //         });
  //       }
  //     });

  //     setTeam(teamProp);
  //   }

  useEffect(() => {
    if (teamData === undefined) {
      setTeam({
        squad: {
          fw: [],
          cm: [],
          df: [],
          gk: [],
        },
      });
    } else {
      setTeam(teamData);
    }
  }, []);

  const WeekPoints = () => {
    //console.log(teamData);
    //console.log(user);
    //console.log(fantasyWeek);
    if (fantasyWeek !== 0) {
      const index = user?.fantasyTeam?.findIndex(
        (team) => team.week === fantasyWeek
      );
      // alert("Index:", index);
      return user?.fantasyTeam[index]?.points;
    } else {
      // alert("hi");
    }
  };

  return (
    <>
      <div className="mx-auto max-w-screen bg-[#192347]   shadow-lg">
        <div className="flex flex-col justify-center items-center">
          <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#E1C564] rounded-lg  shadow-lg pt-2 pb-5 px-2">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div
              align="center"
              className="text-white font-extrabold text-2xl mt-3"
            >
              {/* Season Points : {user?.seasonPoints[0].points} */}
              {/* {currentWeek !== 0 ? WeekPoints : "0"} */}
              Season Points : {user?.seasonPoints[0]?.points}
            </div>
          </div>
          {/* <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#028391] rounded-t-lg  shadow-lg pt-2 pb-5 px-2 mt-4">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div align="center" className="text-white font-extrabold text-2xl">
              {user?.username}'s Team week({fantasyWeek})
            </div>
            <div align="center" className="text-white font-extrabold text-2xl">
              week ({fantasyWeek}){" "}
            </div>
          </div> */}
          <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#16697A] rounded-t-lg  shadow-lg pt-3 pb-3 px-2 mt-4">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div
              align="center"
              className="text-[#FFFBDA] font-extrabold text-2xl"
            >
              {/* <p>{user?.username}'s Team </p> */}
              WEEK {fantasyWeek}
            </div>
          </div>
          <div className="w-full">
            {team !== undefined && <OthersFantasyTeam team={team} />}
          </div>
          <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#16697A] rounded-b-lg  shadow-lg pt-4 pb-5 px-2">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div
              align="center"
              className="text-[#FFFBDA] font-extrabold text-xl"
            >
              {/* Season Points : {user?.seasonPoints[0].points} */}
              POINTS :{" "}
              {/*logic for grtting last element points ----> {user?.fantasyTeam[user.fantasyTeam.length - 1]?.points} */}
              {WeekPoints()}
            </div>
            {(user?.captainCard?.usedWeek === fantasyWeek &&
              user?.captainCard?.used === true) ||
              (user?.extraPlayerCard?.usedWeek === fantasyWeek &&
                user?.extraPlayerCard?.used === true && (
                  <div className="flex flex-col  p-2 ">
                    <div className="col-span-1 md:col-span-2  mt-4  mb-2">
                      <div className="flex-col">
                        <div className="flex gap-x-4">
                          {user?.captainCard?.usedWeek === fantasyWeek &&
                            user?.captainCard?.used === true && (
                              <button
                                className="w-full  relative overflow-hidden  rounded-lg  shadow-lg  px-2 py-4 
                        
                           bg-[#343232]"
                              >
                                <svg
                                  className="absolute bottom-0 left-0 mb-8"
                                  viewBox="0 0 375 283"
                                  fill="none"
                                  style={{
                                    transform: "scale(1.5)",
                                    opacity: "0.1",
                                  }}
                                >
                                  <rect
                                    x="159.52"
                                    y="175"
                                    width="152"
                                    height="152"
                                    rx="8"
                                    transform="rotate(-45 159.52 175)"
                                    fill="white"
                                  />
                                  <rect
                                    y="107.48"
                                    width="152"
                                    height="152"
                                    rx="8"
                                    transform="rotate(-45 0 107.48)"
                                    fill="white"
                                  />
                                  <rect
                                    y="107.48"
                                    width="152"
                                    height="152"
                                    rx="8"
                                    transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                                    fill="white"
                                  />
                                </svg>

                                <p
                                  align="center"
                                  className="text-white font-bold text-2xl px-4 ${
                          py-1"
                                >
                                  3X Captain
                                </p>

                                <p className="text-[#E72929] font-bold text-lg text-shadow">
                                  CARD USED
                                </p>
                              </button>
                            )}

                          {user?.extraPlayerCard?.usedWeek === fantasyWeek &&
                            user?.extraPlayerCard?.used === true && (
                              <button
                                className="w-full  relative overflow-hidden  rounded-lg  shadow-lg  px-2 py-4 
                        
                           bg-[#343232]"
                              >
                                <svg
                                  className="absolute bottom-0 left-0 mb-8"
                                  viewBox="0 0 375 283"
                                  fill="none"
                                  style={{
                                    transform: "scale(1.5)",
                                    opacity: "0.1",
                                  }}
                                >
                                  <rect
                                    x="159.52"
                                    y="175"
                                    width="152"
                                    height="152"
                                    rx="8"
                                    transform="rotate(-45 159.52 175)"
                                    fill="white"
                                  />
                                  <rect
                                    y="107.48"
                                    width="152"
                                    height="152"
                                    rx="8"
                                    transform="rotate(-45 0 107.48)"
                                    fill="white"
                                  />
                                  <rect
                                    y="107.48"
                                    width="152"
                                    height="152"
                                    rx="8"
                                    transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                                    fill="white"
                                  />
                                </svg>

                                <p
                                  align="center"
                                  className="text-white font-bold text-2xl px-4 ${
                          py-1"
                                >
                                  Extra Player
                                </p>

                                <p className="text-[#E72929] font-bold text-lg text-shadow">
                                  CARD USED
                                </p>
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewOnlyFantasy;
