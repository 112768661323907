import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Mainintro from "../components/Mainintro";
import vagad from "../assets/vagad.png";
import vagadBigLogo from "../assets/VFLLogo.png";
import cc from "../assets/Charla.jpg";
import va from "../assets/VA.jpg";
import { NavLink } from "react-router-dom";
import { useApp } from "../context/app-context";
// import MatchData from "../data/MatchData";
import MatchCard from "../components/matchCard/MatchCard";
import axios from "axios";
import WeekPagination from "../components/weekPagination/WeekPagination";
import ReactGA from "react-ga4";
import { Alert } from "bootstrap";
import matchWeeks from "../data/MatchData";

function Matches() {
  const val = "matches";
  const { matchData, setMatchData } = useApp();

  const matchData8 = [
    {
      id: 1,
      team1: "Team 1",
      team2: "Team 4",
    },
    {
      id: 1,
      team1: "Team 2",
      team2: "Team 3",
    },
    {
      id: 1,
      team1: "Team 5",
      team2: "Team 8",
    },
    {
      id: 1,
      team1: "Team 6",
      team2: "Team 7",
    },
  ];
  const matchData9 = [
    {
      id: 1,
      team1: "Finalist 1",
      team2: "Finalist 2",
      context: "TOP 4",
    },
    {
      id: 2,
      team1: "Finalist 1",
      team2: "Finalist 2",
      context: "BOTTOM 4",
    },
  ];

  //week pagination
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  // const [weekNumber, setWeekNumber] = useState(0);
  const [getAllMatchDataApi, setGetAllMatchDataApi] = useState(false);
  const [error, setError] = useState(null);
  let weekTemp = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
    // getWeekNumber();
    // getAllMatchData(`${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`);
    demo();
  }, []);
  // const matchWeeks = [
  //   {
  //     week: 1,
  //     startDate: new Date("2024-06-16"),
  //     endDate: new Date("2024-06-16"),
  //   },
  //   {
  //     week: 2,
  //     startDate: new Date("2024-06-17"),
  //     endDate: new Date("2024-06-23"),
  //   },
  //   {
  //     week: 3,
  //     startDate: new Date("2024-06-24"),
  //     endDate: new Date("2024-06-27"),
  //   },
  //   {
  //     week: 4,
  //     startDate: new Date("2024-06-28"),
  //     endDate: new Date("2024-06-30"),
  //   },
  //   {
  //     week: 5,
  //     startDate: new Date("2024-07-01"),
  //     endDate: new Date("2024-07-07"),
  //   },
  //   {
  //     week: 6,
  //     startDate: new Date("2024-07-08"),
  //     endDate: new Date("2024-07-11"),
  //   },
  //   {
  //     week: 7,
  //     startDate: new Date("2024-07-12"),
  //     endDate: new Date("2024-07-14"),
  //   },
  //   {
  //     week: 8,
  //     startDate: new Date("2024-07-12"),
  //     endDate: new Date("2024-07-14"),
  //   },
  //   {
  //     week: 8,
  //     startDate: new Date("2024-07-15"),
  //     endDate: new Date("2024-07-21"),
  //   },
  //   {
  //     week: 9,
  //     startDate: new Date("2024-07-22"),
  //     endDate: new Date("2024-08-4"),
  //   },
  // ];
  const demo = () => {
    const getCurrentWeek = (date) => {
      for (let i = 0; i < matchWeeks.length; i++) {
        const { week, startDate, endDate } = matchWeeks[i];
        if (date >= startDate && date <= endDate) {
          //console.log(date >= startDate && date <= endDate);
          return week;
        }
      }
      const end_l = matchWeeks[matchWeeks?.length - 1];
      console.log(end_l.endDate);
      if (date > end_l.endDate) {
        return 1;
      }
      return null; // If the date doesn't fall within any of the predefined ranges
    };

    // Example usage:
    function floorDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    const currentDate = new Date();
    const flooredDate = floorDate(currentDate);
    const weekNumber = getCurrentWeek(flooredDate);
    setPageIndex(weekNumber - 1);
    setPageCount(matchWeeks.length);
    weekTemp = matchWeeks.length;
    //console.log("Current Week Number:", weekNumber);
    // alert(weekNumber);
    // if (response.data.matches.length) {
    setGetAllMatchDataApi(true);
    // }
    getThisWeekMatchData(
      `${process.env.REACT_APP_SERVER_URL}/api/match?week=${weekNumber}`
    );
  };

  // async function getAllMatchData(url) {
  //   let weekNum = 1;
  //   try {
  //     const response = await axios.get(url);
  //     console.log(response.data);
  //     const sortedMatches = response.data.matches.sort(
  //       (a, b) => a.week - b.week
  //     );
  //     const dates = sortedMatches.map((match) => match.date);
  //     const uniqueDates = [...new Set(dates)];
  //     console.log(uniqueDates);

  //     const convertToDate = (dateString) => {
  //       const [day, month, year] = dateString.split("-").map(Number);
  //       // Assuming year is 20YY, convert to 19YY if year < 50, else convert to 20YY
  //       const fullYear = year < 50 ? 2000 + year : 1900 + year;
  //       return new Date(fullYear, month - 1, day); // month is zero-based
  //     };

  //     const matchWeekDates = uniqueDates.map(convertToDate);

  //     console.log(matchWeekDates);

  //     const today = new Date(); // Get today's date

  //     const getCurrentMatchWeek = (currentDate, matchWeekDates) => {
  //       // index considered as 1 to handle case of current dat before starting date
  //       for (let index = 1; index < matchWeekDates.length; index++) {
  //         const element = matchWeekDates[index];
  //         const tempC = new Date(currentDate);
  //         const tempW = new Date(element);
  //         console.log(element);
  //         console.log(currentDate);
  //         if (tempC < tempW) {
  //           console.log(currentDate < element);
  //           console.log(index);
  //           return index;
  //         }
  //       }
  //       const last = matchWeekDates[matchWeekDates.length - 1];
  //       const diffInDays = Math.ceil((today - last) / (1000 * 60 * 60 * 24));
  //       console.log(diffInDays);

  //       if (diffInDays <= 7) {
  //         return matchWeekDates.length;
  //       }
  //       return 1;
  //     };

  //     weekNum = getCurrentMatchWeek(today, matchWeekDates);
  //     console.log(`Current Match Week: ${weekNum}`);
  //     const endingWeek = uniqueDates.length;
  //     console.log("Ending Week:", endingWeek);
  //     // setPageIndex(startingWeek - 1); orignal
  //     setPageCount(endingWeek);
  //     // setMatchData(response.data);
  //     if (response.data.matches.length) {
  //       setGetAllMatchDataApi(true);
  //     }

  //     setPageIndex(weekNum - 1); // workaround

  //     getThisWeekMatchData(
  //       `${process.env.REACT_APP_SERVER_URL}/api/match?week=${weekNum}`
  //     );
  //   } catch (error) {
  //     console.error("Error making GET match request:", error);

  //     // Handle errors
  //     setError("Error fetching match data. Please try again later.");
  //   }
  // }
  async function getThisWeekMatchData(url) {
    try {
      const response = await axios.get(url);
      //console.log("GET This Week Match request successful");
      // console.log(response.data);
      const dataSort = response.data;
      dataSort.matches.sort((a, b) => {
        const idA = a._id;
        const idB = b._id;
        if (idA < idB) {
          return -1;
        }
        if (idA > idB) {
          return 1;
        }
        return 0;
      });

      setMatchData(dataSort);
    } catch (error) {
      console.error("Error making GET This Week match request:", error);
      // Handle errors
      setError("Error fetching match data. Please try again later.");
    }
  }
  // analytics
  const MatchAnalytics = (match) => {
    const teamNameMapping = {
      "Mango Marvels": "Mango",
      "Feonn Furies": "Feonn",
      "Vandana Warriors": "Vandana",
      "Bellicosa FC": "Bellicosa",
      "Sejal Scorchers": "Sejal",
      "Team Sagar": "Sagar",
      "Sakori Smashers": "Sakori",
    };
    const team1 = teamNameMapping[match?.team1];
    const team2 = teamNameMapping[match?.team2];
    const temp = `${team1} vs ${team2}`;
    ReactGA.event({
      category: "normal users",
      action: "clicked on match card new",
      label: temp,
    });
  };
  // clicked_on_match_card_new --- Date:28-6-24

  return (
    <>
      {/* <div className=" overflow-hiddenbg-gray-800 bg-gradient-to-b from-cyan-300 to-[#192347] py-24   lg:px-0"> */}
      <div className=" overflow-hidden bg-[#192347] py-24   lg:px-0">
        <div className="mx-auto">
          <div className="lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className=" text-base leading-7 text-gray-300 "></div>{" "}
              <h1 className="text-4xl font-bold tracking-tight text-[#E1C564]  sm:text-6xl  text-center">
                Live Matches
              </h1>
              <div className="grid w-[21rem] grid-cols-4 gap-2 rounded-xl bg-gray-200 p-2 mx-auto mt-5">
                {getAllMatchDataApi ? (
                  <WeekPagination
                    gotoPage={setPageIndex}
                    canPreviousPage={pageIndex > 0}
                    canNextPage={pageIndex < pageCount - 1}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                  />
                ) : (
                  <></>
                )}
              </div>
              <p className="mt-6 text-xl leading-8 text-white font-bold text-center mx-2">
                Experience all the thrilling matches from our Vagad Football
                League!
              </p>
              {/* className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-r from-indigo-500 to-pink-500 opacity-0 transition duration-300 ease-in-out group-hover:opacity-70"> */}
              <div className="flex-shrink-0 m-6 relative overflow-hidden bg-[#912BBC] rounded-lg max-w shadow-lg ">
                <svg
                  className="absolute bottom-0 left-0 mb-8"
                  viewBox="0 0 375 283"
                  fill="none"
                  style={{ transform: "scale(1.5)", opacity: "0.1" }}
                >
                  <rect
                    x="159.52"
                    y="175"
                    width="152"
                    height="152"
                    rx="8"
                    transform="rotate(-45 159.52 175)"
                    fill="white"
                  />
                  <rect
                    y="107.48"
                    width="152"
                    height="152"
                    rx="8"
                    transform="rotate(-45 0 107.48)"
                    fill="white"
                  />
                </svg>
                <div className="flex justify-center mt-4">
                  {/* <span className="block bg-white rounded-md text-[#912BBC] text-lg font-bold p-2 leading-none flex items-center">
                    6 p.m. - 10 p.m.
                  </span> */}
                </div>
                <div className="relative pt-5 px-5 flex flex-col items-center justify-center">
                  <img
                    src={vagadBigLogo}
                    alt="logo"
                    className="w-96 sm:w-96 "
                  />
                  <p className="text-white justify-end text-lg font-bold right-10 ml-20">
                    POWERED BY SIA
                  </p>
                </div>
                <div className="relative text-white px-4 pb-6 mt-2">
                  <span className="block opacity-75 -mb-1">Location</span>
                  <div className="flex justify-between">
                    <span className="block font-semibold text-xl">
                      {pageIndex + 1 == 8 ? (
                        <div className="text-center font-bold text-lg">
                          St Andrews Bandra
                        </div>
                      ) : (
                        <div className="text-center font-bold text-xl">
                          St Andrews Bandra
                        </div>
                      )}
                      {/* <div className="text-center font-bold text-lg">
                        St Andrews Bandra
                      </div> */}
                    </span>
                    {pageIndex + 1 == 8 ? (
                      <span className="block bg-white rounded-full text-[#912BBC] text-md font-bold p-1.5 leading-none flex items-center">
                        SEMI-FINALS
                      </span>
                    ) : pageIndex + 1 == 9 ? (
                      <span className="block bg-white rounded-full text-[#912BBC] text-lg font-bold p-2 leading-none flex items-center">
                        FINALS
                      </span>
                    ) : (
                      <span className="block bg-white rounded-full text-[#912BBC] text-lg font-bold p-2 leading-none flex items-center">
                        WEEK{" "}
                        {matchData?.matches[0]?.week !== undefined
                          ? matchData?.matches[0]?.week
                          : pageIndex + 1}
                      </span>
                    )}
                  </div>
                  {/* <div className="flex justify-between mt-1">
                    <span className="block font-semibold text-xl">
                      <span className="block bg-white rounded-md text-teal-500 text-lg font-bold p-2 leading-none flex items-center">
                        {matchData ? matchData?.matches[0].date : ""}
                      </span>
                    </span>
                    <span className="block bg-white rounded-md text-teal-500 text-lg font-bold p-2 leading-none flex items-center">
                      WEEK {matchData ? matchData?.matches[0].week : ""}
                    </span>
                  </div> */}
                </div>
              </div>
              {matchData?.matches?.map((match, index) => (
                <NavLink
                  key={match._id}
                  to={`/matches/${match._id}`}
                  onClick={() => {
                    MatchAnalytics(match);
                  }}
                >
                  <div
                    key={match._id}
                    className="flex-shrink-0 m-6 relative pb-2 overflow-hidden   bg-gradient-to-r from-indigo-500 to-pink-500 rounded-lg  shadow-lg   my-8"
                  >
                    <div
                      className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                      style={{
                        background: "radial-gradient(black, transparent 60%)",
                        transform:
                          "rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)",
                        opacity: "0.2",
                      }}
                    ></div>

                    <div className="flex flex-row justify-between  mb-2 py-2 inset-0 bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur px-4 ">
                      {/* <div className="absolute inset-0 bg-black bg-opacity-10 backdrop-blur-md"></div> */}
                      {/* <div className=" text-center text-white font-semibold text-xl justify-center flex"></div> */}
                      <div className=" text-center text-white font-semibold text-xl justify-center  ">
                        {match.live && (
                          <div className=" text-center text-red-600 font-extrabold text-lg text-shadow-md block   p-2 leading-none  items-center  justify-center rounded-md">
                            LIVE
                          </div>
                        )}
                        {match.winner && (
                          <div className=" text-center text-slate-800 font-bold text-lg block  p-2 leading-none  items-center  justify-center rounded-md">
                            Match Ended
                          </div>
                        )}
                        {!match.winner && !match.live && (
                          <div className=" text-center text-[#B5C0D0] font-bold text-lg block  p-2 leading-none  items-center  justify-center rounded-md">
                            Scheduled
                          </div>
                        )}
                      </div>
                      {match.live && (
                        <p className=" text-center text-[#B5C0D0] font-bold text-lg block  rounded-ful p-2 leading-none  items-center  justify-center rounded-md">
                          {match.date}
                        </p>
                      )}
                      {match.winner && (
                        <p className=" text-center text-slate-800 font-bold text-lg block  rounded-ful p-2 leading-none  items-center  justify-center rounded-md">
                          {match.date}
                        </p>
                      )}
                      {!match.winner && !match.live && (
                        <p className=" text-center text-[#B5C0D0] font-bold text-lg block  rounded-ful p-2 leading-none  items-center  justify-center rounded-md">
                          {match.date}
                        </p>
                      )}

                      {/* <p className=" text-center text-[#B5C0D0] font-bold text-lg block  rounded-ful p-2 leading-none  items-center  justify-center rounded-md">
                        {match.date}
                      </p> */}
                    </div>
                    {/* {match?.week == 8 && (
                      <div className="flex justify-center  pb-2  inset-0  px-4 ">
                        <span className="  mx-none rounded-md text-[#D1D5DB] text-2xl font-bold p-2 leading-none items-center   rounded-t-lg bg-blur">
                          SEMI-FINALS
                        </span>
                      </div>
                    )} */}
                    {match?.week == 9 && (
                      <div className="flex justify-center uppercase">
                        {index == 0 ? (
                          <span className=" mb-2 mx-none rounded-md text-[#ff9c39] text-lg font-bold p-2 leading-none items-center  bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                            Bronze Cup
                          </span>
                        ) : index == 1 ? (
                          <span className=" mb-2 mx-none rounded-md text-[#FFC94A] text-lg font-bold p-2 leading-none items-center  bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                            VFL Cup
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    <div className="flex justify-center ">
                      {match?.week == 8 ? (
                        // <span className=" mb-2 mx-none rounded-md text-[#D1D5DB] text-lg font-bold p-2 leading-none items-center  bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                        //   SEMI-FINALS
                        // </span>

                        <span className=" mb-2 mx-none rounded-md text-gray-400 text-lg font-bold p-2 leading-none items-center  bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                          {index == 0 ? (
                            <span> 6 p.m. - 7 p.m.</span>
                          ) : index == 1 ? (
                            <span> 7 p.m. - 8 p.m.</span>
                          ) : index == 2 ? (
                            <span> 8 p.m. - 9:30 p.m.</span>
                          ) : index == 3 ? (
                            <span> 9:30 p.m. - 11 p.m.</span>
                          ) : (
                            <></>
                          )}
                        </span>
                      ) : match?.week == 9 ? (
                        //    <span className=" mb-2 mx-none rounded-md text-gray-400 text-lg font-bold p-2 leading-none items-center  bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                        //   {index == 0 ? (
                        //     <span> 6 p.m. - 7 p.m.</span>
                        //   ) : index == 1 ? (
                        //     <span> 7 p.m. - 8 p.m.</span>
                        //   ) : (
                        //     <></>
                        //   )}
                        // </span>
                        <></>
                      ) : (
                        <span className=" mb-2 mx-none rounded-md text-gray-400 text-lg font-bold p-2 leading-none items-center  bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                          {index == 0 ? (
                            <span> 6 p.m. - 7 p.m.</span>
                          ) : index == 1 ? (
                            <span> 7 p.m. - 8 p.m.</span>
                          ) : index == 2 ? (
                            <span> 8 p.m. - 9 p.m.</span>
                          ) : index == 3 ? (
                            <span> 9 p.m. - 10 p.m.</span>
                          ) : (
                            <></>
                          )}
                        </span>
                      )}
                    </div>

                    <div className="w-full flex p-1 justify-around sm:justify-between ">
                      <div className=" w-[25%] text-center">
                        <div className="flex flex-col items-center">
                          <img
                            src={match?.team1logo}
                            width={80}
                            alt="logo"
                            className="rounded-md"
                          />
                        </div>
                        <div className="mt-2 text-[#F5EEE6] text-shadow block font-bold text-xl ">
                          {match?.team1}
                        </div>
                      </div>

                      <div className="hidden md:block w-[32%] text-right text-xl text-white font-bold uppercase">
                        {match?.team1}
                      </div>

                      <div className="w-[16%] text-center text-white font-bold text-xl mt-5">
                        {match?.stats?.team1?.totalGoals} :{" "}
                        {match?.stats?.team2?.totalGoals}
                      </div>

                      <div className="hidden md:flex w-[32%] text-left  text-white font-bold text-xl uppercase">
                        {match?.team2}
                      </div>
                      <div className="w-[25%] text-center">
                        <div className="flex flex-col items-center ">
                          <img
                            src={match?.team2logo}
                            width={80}
                            alt="logo"
                            className="rounded-md"
                          />
                        </div>
                        <div className="mt-2 text-[#F5EEE6] text-shadow  block font-bold text-xl ">
                          {match?.team2}
                        </div>
                      </div>
                    </div>

                    {/* <div className="text-center text-green-600 font-semibold text-xl">
                      {match.time}1'
                    </div> */}
                  </div>
                </NavLink>
              ))}
              {pageIndex + 1 == 8 && matchData?.matches[0]?.week !== 8 ? (
                <>
                  {matchData8.map((match, index) => (
                    <div className="flex-shrink-0 m-6 relative pb-2 overflow-hidden   bg-gradient-to-r from-indigo-500 to-pink-500 rounded-lg  shadow-lg   my-8">
                      <div
                        className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                        style={{
                          background: "radial-gradient(black, transparent 60%)",
                          transform:
                            "rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)",
                          opacity: "0.2",
                        }}
                      ></div>

                      <div className="flex flex-row justify-between  mb-2 py-2 inset-0 bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur px-4 ">
                        {/* <div className="absolute inset-0 bg-black bg-opacity-10 backdrop-blur-md"></div> */}
                        {/* <div className=" text-center text-white font-semibold text-xl justify-center flex"></div> */}
                        <div className=" text-center text-white font-semibold text-xl justify-center  ">
                          <div className=" text-center text-[#B5C0D0] font-bold text-lg block  p-2 leading-none  items-center  justify-center rounded-md">
                            Scheduled
                          </div>
                        </div>
                        <p className=" text-center text-[#B5C0D0] font-bold text-lg block  rounded-ful p-2 leading-none  items-center  justify-center rounded-md">
                          21-07-2024
                        </p>
                      </div>
                      <div className="flex justify-center ">
                        <span className=" mb-2 mx-none rounded-md text-[#D1D5DB] text-lg font-bold p-2 leading-none items-center  bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                          {/* {index == 0 ? (
                            <span> 6 p.m. - 7 p.m.</span>
                          ) : index == 1 ? (
                            <span> 7 p.m. - 8 p.m.</span>
                          ) : index == 2 ? (
                            <span> 8 p.m. - 9 p.m.</span>
                          ) : index == 3 ? (
                            <span> 9 p.m. - 10 p.m.</span>
                          ) : (
                            <></>
                          )} */}
                          SEMI-FINALS
                        </span>
                      </div>

                      <div className="w-full flex p-1 justify-around sm:justify-between ">
                        <div className=" w-[25%] text-center">
                          <div className="flex flex-col items-center">
                            <div className="relative w-20 h-20  rounded-2xl">
                              <div className="absolute inset-0 bg-gray-700 bg-opacity-70 filter blur-sm rounded-2xl"></div>
                              <div className="absolute inset-0 flex items-center justify-center text-white"></div>
                            </div>
                          </div>
                          <div className="mt-2 text-[#F5EEE6] text-shadow block font-bold text-xl ">
                            {match?.team1}
                          </div>
                        </div>

                        <div className="hidden md:block w-[32%] text-right text-xl text-white font-bold uppercase">
                          {match?.team1}
                        </div>

                        <div className="w-[16%] text-center text-white font-bold text-xl mt-5">
                          0 : 0
                        </div>

                        <div className="hidden md:flex w-[32%] text-left  text-white font-bold text-xl uppercase">
                          {match?.team2}
                        </div>
                        <div className="w-[25%] text-center">
                          <div className="flex flex-col items-center ">
                            <div className="relative w-20 h-20  rounded-2xl">
                              <div className="absolute inset-0 bg-gray-700 bg-opacity-70 filter blur-sm rounded-2xl"></div>
                              <div className="absolute inset-0 flex items-center justify-center text-white"></div>
                            </div>
                          </div>
                          <div className="mt-2 text-[#F5EEE6] text-shadow  block font-bold text-xl ">
                            {match?.team2}
                          </div>
                        </div>
                      </div>

                      {/* <div className="text-center text-green-600 font-semibold text-xl">
                      {match.time}1'
                    </div> */}
                    </div>
                  ))}
                </>
              ) : pageIndex + 1 == 9 && matchData?.matches[0]?.week !== 9 ? (
                <>
                  {matchData9.map((match, index) => (
                    <div className="flex-shrink-0 m-6 relative pb-2 overflow-hidden   bg-gradient-to-r from-indigo-500 to-pink-500 rounded-lg  shadow-lg   my-8">
                      <div
                        className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                        style={{
                          background: "radial-gradient(black, transparent 60%)",
                          transform:
                            "rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)",
                          opacity: "0.2",
                        }}
                      ></div>

                      <div className="flex flex-row justify-between  mb-2 py-2 inset-0 bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur px-4 ">
                        {/* <div className="absolute inset-0 bg-black bg-opacity-10 backdrop-blur-md"></div> */}
                        {/* <div className=" text-center text-white font-semibold text-xl justify-center flex"></div> */}
                        <div className=" text-center text-white font-semibold text-xl justify-center  ">
                          <div className=" text-center text-[#B5C0D0] font-bold text-lg block  p-2 leading-none  items-center  justify-center rounded-md">
                            Scheduled
                          </div>
                        </div>
                        <p className=" text-center text-[#B5C0D0] font-bold text-lg block  rounded-ful p-2 leading-none  items-center  justify-center rounded-md">
                          2024-08-4
                        </p>
                      </div>
                      <div className="flex justify-center ">
                        <span className=" mb-2 mx-none rounded-md text-[#FBBF24] text-lg font-bold p-2 leading-none items-center  bg-black transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                          {/* {index == 0 ? (
                            <span> 6 p.m. - 7 p.m.</span>
                          ) : index == 1 ? (
                            <span> 7 p.m. - 8 p.m.</span>
                          ) : index == 2 ? (
                            <span> 8 p.m. - 9 p.m.</span>
                          ) : index == 3 ? (
                            <span> 9 p.m. - 10 p.m.</span>
                          ) : (
                            <></>
                          )} */}
                          FINALS
                        </span>
                      </div>
                      <div className="flex justify-center ">
                        <span className=" mb-2 mx-none rounded-md text-[#B5C0D0] text-lg font-bold p-2 leading-none items-center   transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur rounded-t-lg bg-blur">
                          {match?.context}
                        </span>
                      </div>

                      <div className="w-full flex p-1 justify-around sm:justify-between ">
                        <div className=" w-[25%] text-center">
                          <div className="flex flex-col items-center">
                            <div className="relative w-20 h-20  rounded-2xl">
                              <div className="absolute inset-0 bg-gray-700 bg-opacity-70 filter blur-sm rounded-2xl"></div>
                              <div className="absolute inset-0 flex items-center justify-center text-white"></div>
                            </div>
                          </div>
                          <div className="mt-2 text-[#F5EEE6] text-shadow block font-bold text-xl ">
                            {match?.team1}
                          </div>
                        </div>

                        <div className="hidden md:block w-[32%] text-right text-xl text-white font-bold uppercase">
                          {match?.team1}
                        </div>

                        <div className="w-[16%] text-center text-white font-bold text-xl mt-5">
                          0 : 0
                        </div>

                        <div className="hidden md:flex w-[32%] text-left  text-white font-bold text-xl uppercase">
                          {match?.team2}
                        </div>
                        <div className="w-[25%] text-center">
                          <div className="flex flex-col items-center ">
                            <div className="relative w-20 h-20  rounded-2xl">
                              <div className="absolute inset-0 bg-gray-700 bg-opacity-70 filter blur-sm rounded-2xl"></div>
                              <div className="absolute inset-0 flex items-center justify-center text-white"></div>
                            </div>
                          </div>
                          <div className="mt-2 text-[#F5EEE6] text-shadow  block font-bold text-xl ">
                            {match?.team2}
                          </div>
                        </div>
                      </div>

                      {/* <div className="text-center text-green-600 font-semibold text-xl">
                      {match.time}1'
                    </div> */}
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
              {/* -------------------------------------------------- */}
              {/* {matchData?.matches?.map((match) => (
                <NavLink key={match._id} to={`/matches/${match._id}`}>
                  <div
                    key={match._id}
                    className="flex-shrink-0 m-6 relative overflow-hidden bg-gradient-to-r from-indigo-500 to-pink-500 rounded-lg  shadow-lg pt-2 pb-5 px-2 my-8"
                  >
                    <svg
                      className="absolute bottom-0 left-0 mb-8"
                      viewBox="0 0 375 283"
                      fill="none"
                      style={{ transform: "scale(1.5)", opacity: "0.1" }}
                    >
                      <rect
                        x="159.52"
                        y="175"
                        width="152"
                        height="152"
                        rx="8"
                        transform="rotate(-45 159.52 175)"
                        fill="white"
                      />
                      <rect
                        y="107.48"
                        width="152"
                        height="152"
                        rx="8"
                        transform="rotate(-45 0 107.48)"
                        fill="white"
                      />
                      <rect
                        y="107.48"
                        width="152"
                        height="152"
                        rx="8"
                        transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                        fill="white"
                      />
                    </svg>

                    <div
                      className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                      style={{
                        background: "radial-gradient(black, transparent 60%)",
                        transform:
                          "rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)",
                        opacity: "0.2",
                      }}
                    ></div>
                    <div
                      align="center"
                      className="text-white font-extrabold text-2xl"
                    >
                      <img
                        src={vagadBigLogo}
                        alt="logo"
                        className="w-64 sm:w-96 "
                      />
                    </div>

                    <div className="text-center font-semibold text-lg">
                      {match.location}
                    </div>
                    <div className=" text-center text-white font-semibold text-xl">
                      Week {match.week}
                    </div>
                    {match.live && (
                      <div className=" text-center text-red-700 font-semibold text-lg">
                        Live
                      </div>
                    )}
                    {match.winner && (
                      <div className=" text-center text-slate-800 font-semibold text-lg">
                        Match Ended
                      </div>
                    )}
                    {!match.winner && !match.live && (
                      <div className=" text-center text-gray-600 font-semibold text-lg">
                        Scheduled
                      </div>
                    )}

                    <div className="w-full flex p-1 justify-around sm:justify-between ">
                      <div className="md:w-[15%] w-[20%] text-center">
                        <div className="flex flex-col items-center">
                          <img
                            src={match.team1logo}
                            width={80}
                            alt="logo"
                            className="rounded-md"
                          />
                          <div className="mt-2 text-md text-indigo-900 font-semibold text-xl ">
                            {match.team1}
                          </div>
                        </div>
                      </div>

                      <div className="hidden md:block w-[32%] text-right text-xl text-blue-300 font-bold opacity-75 -mb-1 uppercase">
                        {match.team1}
                      </div>

                      <div className="w-[16%] text-center text-white font-bold text-xl mt-5">
                        {match?.stats?.team1?.totalGoals} :{" "}
                        {match?.stats?.team2?.totalGoals}
                      </div>

                      <div className="hidden md:flex w-[32%] text-left  text-violet-800 font-bold text-xl uppercase">
                        {match.team2}
                      </div>
                      <div className="md:w-[15%] w-[20%] text-center">
                        <div className="flex flex-col items-center ">
                          <img
                            src={match.team2logo}
                            width={80}
                            alt="logo"
                            className="rounded-md"
                          />
                          <div className="mt-2 text-indigo-600 block font-semibold text-xl ">
                            {match.team2}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Matches;
