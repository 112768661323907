import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useApp } from "../../context/app-context";
import swal from "sweetalert";

function EndLiveMatch() {
  //League Matches
  const { activeTab, setActiveTab, liveMatch, setLiveMatch, setLiveLineup } =
    useApp();
  const [error, setError] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [liveMatchEnded, setLiveMatchEnded] = useState(false);

  useEffect(() => {}, []);

  //lineup selection

  const handleSubmit = (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "Once clicked on okay , match will be ended !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(liveMatch._id);
        submitData();
        // dataTry();
      } else {
        swal("Current Match is still Live!");
      }
    });
  };
  const submitData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/match/endmatch/${liveMatch._id}`
      );
      console.log("Response:", response.data);
      setLiveMatchEnded(true);
      // setLiveMatch(); ------------------- work on this func it should ideally make live upadtes section empty
      swal(
        `${liveMatch.team1} vs ${liveMatch.team2} , week ${liveMatch.week} has ended`,
        {
          icon: "success",
        }
      );
      // Handle response or do something with the data
    } catch (error) {
      setError("Error:", error);
    }
  };
  //just to check sweet alert
  const dataTry = () => {
    setLiveMatchEnded(true);
    swal(
      `${liveMatch.team1} vs ${liveMatch.team2} , week ${liveMatch.week} has ended`,
      {
        icon: "success",
      }
    );
  };
  return (
    <>
      <li className="flex w-full py-5">
        <details className="group w-full">
          <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
            <div
              className="flex justify-between w-full"
              onClick={() => setShowDetails(true)}
            >
              <div className="flex gap-5">
                <div className="min-w-0 flex-auto">
                  <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-teal-600">
                    Want to End a Live Match?
                  </p>
                </div>
              </div>
            </div>
            <svg
              className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </summary>
          <div className=" mx-auto max-w-[850px] bg-white ">
            <div className="mb-5">
              {liveMatch ? (
                <p className="mt-6 text-xl leading-8 text-green-800 font-bold text-center">
                  Live match : {liveMatch.team1} vs {liveMatch.team2} , week{" "}
                  {liveMatch.week}
                </p>
              ) : (
                <p className="mt-6 text-xl leading-8 text-red-800 font-bold text-center">
                  go to upper section and select live match
                </p>
              )}
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center gap-4 "
            >
              <div className="flex justify-center items-center">
                <button
                  type="submit"
                  className={` relative hover:shadow-form w-[60%] rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  
                  ${liveMatchEnded ? "bg-lime-500" : "bg-orange-700"}`}
                  // onClick={() => livematchFunc(match)} bg-indigo-700
                >
                  {!liveMatchEnded ? "End This Match" : "Match Ended"}
                </button>
              </div>
            </form>
          </div>
        </details>
      </li>
    </>
  );
}

export default EndLiveMatch;
