import axios from "axios";
import React, { useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { useApp } from "../context/app-context";
import yellowRedCardImg from "../assets/yellowRedCard.png";
import ball from "../assets/scorer.png";
import assistImg from "../assets/assist.png";

const SOCKET_URL = `${process.env.REACT_APP_SERVER_URL}`;
// const socket = io.connect(SOCKET_URL);

var socket = io(SOCKET_URL, { transports: ["websocket"] });

function MatchScoreCard() {
  const { eachMatchData, setEachMatchData, liveMatch } = useApp();
  const [eachMatchStat, setEachMatchStat] = useState(); // only this part keeps chnaging so only update this
  const [updatedMatchstate, setUpdatedMatchstate] = useState();
  const [team1Data, setTeam1Data] = useState();
  const [team2Data, setTeam2Data] = useState();
  const [error, setError] = useState(null);

  const matchId = liveMatch._id;
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to the server");
    });
    socket.on("stats-updated", (updatedMatch) => {
      console.log("Match updated:", updatedMatch);
      setUpdatedMatchstate(updatedMatch);
      setEachMatchStat(updatedMatch?.stats);
      // Handle the updated match data (e.g., update the state or UI)
    });
  }, []);
  useEffect(() => {
    getEachMatchData(
      `${process.env.REACT_APP_SERVER_URL}/api/match/${matchId}`
    );
  }, []);

  async function getEachMatchData(url) {
    try {
      const response = await axios.get(url);
      console.log("GET Match request successful");
      console.log(response.data.match);
      setEachMatchData(response.data.match);
      setEachMatchStat(response.data.match.stats);
      //call players of team
      // ******* passed response.data.match in getTeam1Data,getTeam2Data as props beacuse when i was reloading eachData wasnt setting on time causing the player list to empty
      //********** I can either filter the players acc to teams and then again filter according to lineup or directly filter them from total player list
      const team1Name = response.data.match.team1;
      getTeam1Data(
        `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team1Name}&limit=1000`,
        response.data.match
      );
      const team2Name = response.data.match.team2;
      getTeam2Data(
        `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team2Name}&limit=1000`,
        response.data.match
      );
      console.log(eachMatchData);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }
  async function getTeam1Data(url, propsEachmatchData) {
    try {
      const response = await axios.get(url);
      console.log("GET Team1 Players request successful");
      console.log(response.data.players);
      // setTeam1Data(response.data.players);
      console.log(propsEachmatchData);
      const filteredTeam1Players = response.data.players.filter((player) =>
        propsEachmatchData?.lineup1.includes(player._id)
      );
      setTeam1Data(filteredTeam1Players);

      console.log("filtered team1 players", filteredTeam1Players);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }
  async function getTeam2Data(url, propsEachmatchData) {
    try {
      const response = await axios.get(url);
      console.log("GET Team2 Players request successful");
      console.log(response.data.players);
      // setTeam2Data(response.data.players);
      console.log(propsEachmatchData);

      const filteredTeam2Players = response.data.players.filter((player) =>
        propsEachmatchData?.lineup2.includes(player._id)
      );
      setTeam2Data(filteredTeam2Players);

      console.log("filtered team2 players", filteredTeam2Players);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }
  return (
    <>
      <div className="mx-auto my-5 max-w-xl rounded overflow-hidden shadow-md text-xs">
        <div className="flex bg-gray-200 px-2 py-2 justify-between">
          <div className="w-5/12 text-gray-700 text-left ">
            Week {eachMatchData?.week}
          </div>
          {/* <div className="w-5/12 flex justify-end items-center">
                      <p className="w-8 px-2 text-center">1</p>
                      <p className="w-8 px-2 text-center">2</p>
                      <p className="w-8 px-2 text-center">3</p>
                      <p className="w-8 px-2 text-center">4</p>
                    </div> */}
          {eachMatchData?.live ? (
            <div className="w-1/6 text-red-700 text-right">Live</div>
          ) : (
            <div className="w-1/6 text-gray-700 text-right">Not Live</div>
          )}
        </div>
        <div className="flex items-center bg-white">
          <div className="flex px-2 py-2 items-end bg-white flex-col w-full cursor-pointer">
            {/* Raptors Section */}
            <div className="w-full flex items-center flex-col">
              <img
                className="w-12 sm:w-10 mr-2 self-center justify-center"
                alt="away-logo"
                src={eachMatchData?.team1logo}
              />

              <div className="flex ">
                <p className=" text-base font-bold text-center">
                  {eachMatchData?.team1}
                </p>
              </div>
            </div>
          </div>
          <p className=" bg-white text-2xl flex px-2 py-2  text-nowrap">
            {eachMatchStat?.team1?.totalGoals} -{" "}
            {eachMatchStat?.team2?.totalGoals}
          </p>

          <div className="flex px-2 py-2 items-end bg-white flex-col w-full cursor-pointer">
            {/* Raptors Section */}
            <div className="w-full flex items-center flex-col">
              <img
                className="w-12 sm:w-10 mr-2 self-center justify-center"
                alt="away-logo"
                src={eachMatchData?.team2logo}
              />

              <div className="flex ">
                <p className=" text-base font-bold text-center">
                  {eachMatchData?.team2}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="block border-t-2 border-gray-300  bg-white">
          <div className="flex justify-center my-2 items-center">
            <p className="text-center  text-gray-500 font-semibold text-md  uppercase">
              Goals Scored
            </p>
            <img src={ball} className="mx-2 w-5" />
          </div>

          {/* column wise sepration */}

          <div className="flex">
            <div className="w-1/2   flex">
              <div className="flex flex-col">
                {eachMatchStat?.team1?.goals?.map((goalScored) => (
                  <>
                    <div className=" px-2 py-2 flex flex-col  mb-1">
                      <p className="justify-center text-center  mb-2 text-green-600">
                        {goalScored.minute}'
                      </p>
                      <div className="flex items-center">
                        <img
                          className="w-10 mr-2 self-center rounded-md  "
                          src={goalScored.scorer.photo}
                          alt="Player 1"
                        />
                        <div className="flex flex-col">
                          <p className="font-semibold">
                            {goalScored.scorer.name}
                          </p>
                          <p className="text-gray-600">Goal Scored</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                  </>
                ))}
              </div>
            </div>

            <div className=" w-1/2 flex  justify-end px-1">
              <div className="flex flex-col">
                {eachMatchStat?.team2?.goals?.map((goalScored) => (
                  <>
                    {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                    <div className=" px-2 py-2 flex flex-col  mb-1">
                      <p className="justify-center text-center  mb-2 text-green-600">
                        {goalScored.minute}'
                      </p>
                      <div className="flex items-center">
                        <div className="flex flex-col items-end">
                          <p className="font-semibold">
                            {goalScored.scorer.name}
                          </p>
                          <p className="text-gray-600">Goal Scored</p>
                        </div>
                        <img
                          className="w-10 ml-2 self-center rounded-md"
                          src={goalScored.scorer.photo}
                          alt="Player 1"
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          {/* <p className="text-center  text-gray-500 font-semibold text-md m-1 mb-2 uppercase ">
                      Assist
                    </p> */}
          <div className="flex justify-center p-2 border-t border-gray-300 items-center">
            <p className="text-center  text-gray-500 font-semibold text-md  uppercase">
              Assist
            </p>
            <img src={assistImg} className="mx-2 w-5" />
          </div>
          {/* column wise sepration */}

          <div className="flex">
            <div className="w-1/2   flex">
              <div className="flex flex-col">
                {eachMatchStat?.team1?.goals?.map((goalScored) => (
                  <>
                    {goalScored.assist !== undefined && (
                      <div className=" px-2 py-2 flex flex-col  mb-1">
                        <p className="justify-center text-center  mb-2 text-green-600">
                          {goalScored.minute}'
                        </p>
                        <div className="flex items-center">
                          <img
                            className="w-10 mr-2 self-center rounded-md"
                            src={goalScored.assist.photo}
                            alt="Player 1"
                          />
                          <div className="flex flex-col">
                            <p className="font-semibold">
                              {goalScored.assist.name}
                            </p>
                            <p className="text-gray-600">Assisted</p>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                  </>
                ))}
              </div>
            </div>

            <div className=" w-1/2 flex  justify-end px-1">
              <div className="flex flex-col">
                {eachMatchStat?.team2?.goals?.map((goalScored) => (
                  <>
                    {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                    {goalScored.assist !== undefined && (
                      <div className=" px-2 py-2 flex flex-col  mb-1">
                        <p className="justify-center text-center  mb-2 text-green-600">
                          {goalScored.minute}'
                        </p>
                        <div className="flex items-center">
                          <div className="flex flex-col items-end">
                            <p className="font-semibold">
                              {goalScored.assist.name}
                            </p>
                            <p className="text-gray-600">Assisted</p>
                          </div>
                          <img
                            className="w-10 ml-2 self-center rounded-md"
                            src={goalScored.assist.photo}
                            alt="Player 1"
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-center p-2 border-t border-gray-300 items-center">
            <p className="text-center  text-gray-500 font-semibold text-md  uppercase">
              Cards
            </p>
            <img src={yellowRedCardImg} className="mx-2 w-5" />
          </div>
          <div className="flex">
            <div className="w-1/2   flex">
              <div className="flex flex-col">
                {eachMatchStat?.team1?.yellowCards?.map((yellowCard) => (
                  <>
                    <div className=" px-2 py-2 flex flex-col  mb-1">
                      <p className="justify-center text-center  mb-2 text-green-600">
                        {yellowCard.minute}'
                      </p>
                      <div className="flex items-center">
                        <img
                          className="w-10 mr-2 self-center rounded-md"
                          src={yellowCard.player.photo}
                          alt="Player 1"
                        />
                        <div className="flex flex-col">
                          <p className="font-semibold">
                            {yellowCard.player.name}
                          </p>
                          <p className="text-yellow-500">Yellow Card</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                  </>
                ))}
                {eachMatchStat?.team1?.redCards?.map((redCard) => (
                  <>
                    <div className=" px-2 py-2 flex flex-col  mb-1">
                      <p className="justify-center text-center  mb-2 text-green-600">
                        {redCard.minute}'
                      </p>
                      <div className="flex items-center">
                        <img
                          className="w-10 mr-2 self-center rounded-md"
                          src={redCard.player.photo}
                          alt="Player 1"
                        />
                        <div className="flex flex-col">
                          <p className="font-semibold">{redCard.player.name}</p>
                          <p className="text-red-500">Red Card</p>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>

            <div className=" w-1/2 flex  justify-end px-1">
              <div className="flex flex-col">
                {eachMatchStat?.team2?.yellowCards?.map((yellowCard) => (
                  <>
                    <div className=" px-2 py-2 flex flex-col  mb-1">
                      <p className="justify-center text-center  mb-2 text-green-600">
                        {yellowCard.minute}'
                      </p>
                      <div className="flex items-center justify-end">
                        <div className="flex flex-col items-end">
                          <p className="font-semibold">
                            {yellowCard.player.name}
                          </p>
                          <p className="text-yellow-500">Yellow Card</p>
                        </div>
                        <img
                          className="w-10 ml-2 self-center rounded-md"
                          src={yellowCard.player.photo}
                          alt="Player 1"
                        />
                      </div>
                    </div>
                  </>
                ))}
                {eachMatchStat?.team2?.redCards?.map((redCard) => (
                  <>
                    <div className=" px-2 py-2 flex flex-col  mb-1">
                      <p className="justify-center text-center  mb-2 text-green-600">
                        {redCard.minute}'
                      </p>
                      <div className="flex items-end justify-end">
                        <div className="flex flex-col items-end">
                          <p className="font-semibold">{redCard.player.name}</p>
                          <p className="text-red-500">Red Card</p>
                        </div>
                        <img
                          className="w-10 ml-2 self-center rounded-md"
                          src={redCard.player.photo}
                          alt="Player 1"
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <p className="text-center font-medium text-lg  text-gray-500 m-1 uppercase">
              Game log
            </p>
          </div>
        </div>
        {/* Top Performers Section */}

        {/* Game Info Section */}
        <div className="border-t bg-gray-200 text-center px-1 py-1 border-b border-gray-300">
          <p className="text-gray-600">League Match</p>
        </div>

        {/* Venue Info Section */}
        <div className="flex border-t bg-gray-200">
          {/* Stadium Section */}
          <div className="w-1/2 px-2 py-2 text-center">
            <p className="font-semibold text-gray-700">
              {eachMatchData?.location}
            </p>
          </div>
          {/* Line and O/U Section */}
          <div className="w-1/2 px-2 py-2 text-center">
            <p className="text-gray-600">
              <span className="font-semibold">Vagad League</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MatchScoreCard;
