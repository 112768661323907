import React, { useEffect, useState } from "react";

import pitch from "../../assets/footballPitchVertical.jpg";
import tshirt from "../../assets/tshirt.png";
import captainBand from "../../assets/captain-band.png";
import { useApp } from "../../context/app-context";
import DummyImg from "../../assets/user.png";
import PlayerModal from "../PlayerModal";

// DEFINED ONE MORE FUNCTION BELOW THIS FOR HANDLING BROKEN IMAGES

function OthersFantasyTeam(playersprop) {
  //   useEffect(() => {
  // console.log("Modified", allModifiedPlayers);
  // console.log("selectedPlayer", selectedPlayer);
  //console.log(playersprop);
  const playerListNew = playersprop?.team?.squad;
  //console.log("playerListNew", playerListNew);
  // const point = 1;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [team_logo, setTeam_logo] = useState(DummyImg);

  const closeModal = () => setIsModalOpen(false);

  const openPhotoModalFunc = (photo) => {
    setIsModalOpen(true);
    setTeam_logo(photo);
  };
  return (
    <div className="flex justify-center">
      <PlayerModal
        isOpen={isModalOpen}
        onClose={closeModal}
        team_logo={team_logo}
      />
      {/* <img src={pitch} alt="" /> */}
      <div
        className="flex relative w-full max-w-2xl bg-no-repeat bg-cover  justify-around items-center flex-col"
        style={{
          backgroundImage: `url(${pitch})`,
          backgroundPosition: "bottom",
          aspectRatio: "2 / 3",
        }}
      >
        {Object.keys(playerListNew).map((position) => (
          <div className="flex text-sm font-semibold justify-around w-full">
            {playerListNew[position].map((player) => (
              <div className="flex flex-col items-center">
                {player?.id ? (
                  //   <img
                  //     src={player.photo}
                  //     alt=""
                  //     className="w-12 h-12 rounded-full"
                  //     onClick={player?.onClick}
                  //   />
                  <ImageWithFallback
                    src={player.photo}
                    fallbackSrc={tshirt}
                    alt="Original Image"
                    points={player?.points}
                    role={player?.role}
                    onClick={() => openPhotoModalFunc(player?.photo)}
                  />
                ) : (
                  <img
                    src={tshirt}
                    alt=""
                    className="w-8 h-8"
                    onClick={() => openPhotoModalFunc(player?.photo)}
                  />
                )}
                {/* <img src={tshirt} alt="" className="w-8 h-8" /> */}
                <p className="mt-1 truncate text-lg font-bold leading-4 text-[#3ABEF9] text-shadow">
                  {player?.playerPosition}
                </p>
                <div className="min-w-0 flex-auto items-center text-center  bg-[#028391] rounded-md px-1 mt-2">
                  <p className="text-md font-bold leading-6 text-white text-shadow-md">
                    {player?.name}
                  </p>
                </div>
                {/* <p>{player?.name}</p> */}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OthersFantasyTeam;

function ImageWithFallback({
  src,
  fallbackSrc,
  alt,
  points,
  role,
  onClick,
  ...props
}) {
  const [imgSrc, setImgSrc] = useState(src);
  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const onError = () => {
    setImgSrc(fallbackSrc);
  };

  return (
    <div className="relative">
      <img
        src={imgSrc}
        onError={onError}
        alt={alt}
        {...props}
        className="w-12 h-12 rounded-full"
        onClick={onClick}
      />
      <div className="absolute font-bold bottom-9 left-8 h-7 w-7 rounded-full  bg-indigo-600 flex items-center justify-center text-white text-xs -mt-3 mr-2">
        {points}
      </div>
      {role == "captain" ? (
        <div className="absolute bottom-0 right-8 h-6 w-6 rounded-full font-bold   bg-[#DC5F00] flex items-center justify-center text-white text-xs -mt-3 mr-2">
          C
        </div>
      ) : // <div className="absolute bottom-0 right-8 h-6 w-6 rounded-full font-bold  bg-indigo-600 flex items-center justify-center text-white text-xs -mt-3 mr-2">
      //   C
      // </div>
      role == "vice-captain" ? (
        <div className="absolute bottom-0 right-8 h-6 w-6 rounded-full font-bold  bg-[#DC5F00] flex items-center justify-center text-white text-xs -mt-3 mr-2">
          VC
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
