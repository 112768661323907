import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useCallback, useState } from "react";
import { useApp } from "../../context/app-context";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

function Button2({ content, onClick, active, disabled }) {
  return (
    <button
      className={`flex flex-col cursor-pointer items-center justify-center w-[3.6rem] h-12 shadow-sm  transition-colors rounded-lg font-bold text-sm 
      ${active ? "bg-blue-800 text-white" : "text-blue-800 bg-white"}
      ${
        !disabled
          ? "hover:bg-blue-800 hover:text-white"
          : "text-blue-300 bg-white cursor-not-allowed"
      }
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
}

function WeekPagination({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
}) {
  const { setMatchData } = useApp();
  const [error, setError] = useState(null);

  //new
  const changeNcall = (pageIndexToMap) => {
    getMatchDatanew(
      `${process.env.REACT_APP_SERVER_URL}/api/match?week=${pageIndexToMap}`
    );
  };
  async function getMatchDatanew(url) {
    try {
      //console.log(url);
      const response = await axios.get(url);
      console.log("GET Match request successful");
      //console.log(response.data);
      // console.log(response.data);
      const dataSort = response.data;
      dataSort.matches.sort((a, b) => {
        const idA = a._id;
        const idB = b._id;
        if (idA < idB) {
          return -1;
        }
        if (idA > idB) {
          return 1;
        }
        return 0;
      });
      setMatchData(dataSort);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }

  const renderPageLinks = useCallback(() => {
    if (pageCount === 0) return null;
    const visiblePageButtonCount = 3;
    let numberOfButtons =
      pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
    const pageIndices = [pageIndex];
    numberOfButtons--;
    [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
      const pageNumberBefore = pageIndices[0] - 1;
      const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
      if (
        pageNumberBefore >= 0 &&
        (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
      ) {
        pageIndices.unshift(pageNumberBefore);
      } else {
        pageIndices.push(pageNumberAfter);
      }
    });

    return pageIndices?.map((pageIndexToMap) => (
      <li key={pageIndexToMap}>
        {pageIndexToMap + 1 == 8 ? (
          <Button2
            content={`Semi-F`}
            onClick={() => {
              gotoPage(pageIndexToMap);
              // changeNcall(pageIndexToMap);  orignal
              changeNcall(pageIndexToMap + 1); // work around
            }}
            active={pageIndex === pageIndexToMap}
          />
        ) : pageIndexToMap + 1 == 9 ? (
          <Button2
            content={`Finals`}
            onClick={() => {
              gotoPage(pageIndexToMap);
              // changeNcall(pageIndexToMap);  orignal
              changeNcall(pageIndexToMap + 1); // work around
            }}
            active={pageIndex === pageIndexToMap}
          />
        ) : (
          <Button2
            content={`Week ${pageIndexToMap + 1}`}
            onClick={() => {
              gotoPage(pageIndexToMap);
              // changeNcall(pageIndexToMap);  orignal
              changeNcall(pageIndexToMap + 1); // work around
            }}
            active={pageIndex === pageIndexToMap}
          />
        )}
        {/* <Button2
          content={`Week ${pageIndexToMap + 1}`}
          onClick={() => {
            gotoPage(pageIndexToMap);
            // changeNcall(pageIndexToMap);  orignal
            changeNcall(pageIndexToMap + 1); // work around
          }}
          active={pageIndex === pageIndexToMap}
        /> */}
      </li>
    ));
  }, [pageCount, pageIndex]);
  return (
    <ul className="flex gap-2">
      <li>
        <Button2
          content={
            <div className="flex ml-1">
              <ChevronLeftIcon className="w-4 h-4" />
              <ChevronLeftIcon className="w-4 h-4 -translate-x-1/2" />
            </div>
          }
          onClick={() => {
            gotoPage(0);
            changeNcall(1);
          }}
          disabled={!canPreviousPage}
        />
      </li>
      {renderPageLinks()}
      <li>
        <Button2
          content={
            <div className="flex ml-1">
              <ChevronRightIcon className="w-4 h-4" />
              <ChevronRightIcon className="w-4 h-4 -translate-x-1/2" />
            </div>
          }
          onClick={() => {
            gotoPage(pageCount - 1);
            changeNcall(pageCount);
          }}
          disabled={!canNextPage}
        />
      </li>
    </ul>
  );
}

// function PaginationNav1Presentation() {
//   const [pageIndex, setPageIndex] = useState(0);
//   const pageCount = 10;
//   return (

//   );
// }

export default WeekPagination;
