import React, { useEffect, useState } from "react";
import axios from "axios";
import LoaderFootball from "../loader/LoaderFootball";

function PlayerTeamChanges() {
  //Player
  const [filePlayer, setFilePlayer] = useState(null);
  const [showMessage, setShowMessage] = useState("");
  //Team
  const [fileTeam, setFileTeam] = useState(null);
  const [showMessageTeam, setShowMessageTeam] = useState("");
  const [teamUploaded, setTeamUploaded] = useState(false);
  //check
  const [teamPresent, setTeamPresent] = useState(false);
  const [playerPresent, setPlayerPresent] = useState(false);
  const [delteAll, setdelteAll] = useState(false);
  const [showTeamUpload, setShowTeamUpload] = useState(false);
  const [showMessageDeleteTeam, setShowMessageDeleteTeam] = useState("");
  const [showMessageDeletePlayer, setShowMessageDeletePlayer] = useState("");
  const [playerTeamUploaded, setPlayerTeamUploaded] = useState(false);

  const [loading, setLoading] = useState(false);

  //Check if Admin uploaded : (Team and players)
  useEffect(() => {
    // console.log(teamName);
    fetchTeamData();
    fetchPlayerData();
    // CheckData();
  }, []);

  const CheckData1 = () => {
    console.log("hello");
    if (playerPresent && teamPresent) {
      console.log("in 1");
      setdelteAll(true);
      setShowTeamUpload(true);
    }
    if (!playerPresent && teamPresent) {
      console.log("in 2");

      setTeamUploaded(true);
    } else if (!playerPresent && !teamPresent) {
      console.log("in 3");

      setShowTeamUpload(true);
    }
  };
  const deleteAllDataFunc = () => {
    deleteTeamData(`${process.env.REACT_APP_SERVER_URL}/api/team/`);
    deletePlayerData(`${process.env.REACT_APP_SERVER_URL}/api/player/`);
    // setShowDetails(false);
    setShowTeamUpload(true);
  };
  const fetchTeamData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/team`
      );
      console.log(response.data.teams.length);

      if (response.data.teams.length) {
        setTeamPresent(true);
      } else {
        console.log("Team not present");
      }
      console.log(response.data);
    } catch (error) {
      console.error("Axios error:", error);
    }
  };

  const fetchPlayerData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/player`
      );
      console.log(response.data.players.length);
      if (response.data.players.length) {
        setPlayerPresent(true);
      } else {
        console.log("Player not present");
      }
      console.log(response.data);
    } catch (error) {
      console.error("Axios error:", error);
    }
  };

  //Delete Data-----------------------------------------
  async function deleteTeamData(url) {
    try {
      const response = await axios.delete(url);
      console.log("DELETE Team request successful");
      console.log(response.data); // Handle the response data
      setShowMessageDeleteTeam(response.data);
    } catch (error) {
      console.error("Error making  DELETE Team request:", error);
      // Handle errors
    }
  }
  async function deletePlayerData(url) {
    try {
      const response = await axios.delete(url);
      console.log("DELETE Player request successful");
      console.log(response.data); // Handle the response data
      setShowMessageDeletePlayer(response.data);
    } catch (error) {
      console.error("Error making DELETE Player request:", error);
      // Handle errors
    }
  }
  // ------------------------------------------Delete Data
  ///Team ------------------------------------------------------
  const handleFileChangeTeam = (e) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type === "text/csv") {
        setFileTeam(selectedFile);
        // Call the onFileUpload callback function and pass the file
        // onFileUpload(selectedFile);
      } else {
        alert("Please select a CSV file.");
      }
    }
  };
  const handleUploadClickTeam = (e) => {
    e.preventDefault();
    console.log("clicked Team");
    if (!fileTeam) {
      alert("Enter Team");
      return;
    }
    //TeamData
    uploadTeamData();
  };
  const uploadTeamData = async () => {
    setLoading(true);

    const formDataTeam = new FormData();
    formDataTeam.append("file", fileTeam);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/team`,
        formDataTeam
      );
      setLoading(false);

      if (response.data.message) {
        // Display the message on the screen
        setShowMessageTeam(response.data.message);
        setTeamUploaded(true);
      }
    } catch (error) {
      console.error("Axios error:", error);
      alert("Axios error:", error);
    }
  };

  // ------------------------------------------------------Team

  //Player ------------------------------------------------------
  const handleFileChange = (e) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type === "text/csv") {
        setFilePlayer(selectedFile);
        // Call the onFileUpload callback function and pass the file
        // onFileUpload(selectedFile);
      } else {
        alert("Please select a CSV file.");
      }
    }
  };
  const handleUploadClick = (e) => {
    e.preventDefault();
    console.log("clicked");
    if (!filePlayer) {
      alert("Enter Players");
      return;
    }

    //PlayerData
    uploadPlayerData();
  };

  const uploadPlayerData = async () => {
    const formData = new FormData();
    formData.append("file", filePlayer);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/player`,
        formData
      );
      if (response.data.message) {
        // Display the message on the screen
        setShowMessage(response.data.message);
        setTeamUploaded(true);
        funcWithTwoSecondsDelay();
      }
    } catch (error) {
      console.error("Axios error:", error);
      alert("Axios error:", error);
    }
  };
  function funcWithTwoSecondsDelay() {
    setTimeout(() => {
      setShowTeamUpload(false);
      setPlayerTeamUploaded(true);
    }, 2000); // 2000 milliseconds = 2 seconds
  }
  // ------------------------------------------------------Player
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <li className="flex w-full py-5">
        <details className="group w-full">
          <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
            <div
              className="flex justify-between w-full"
              onClick={() => setShowDetails(true)}
            >
              <div className="flex gap-5">
                <div className="min-w-0 flex-auto">
                  <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-indigo-600">
                    Changes in player or team data?
                  </p>
                </div>
              </div>
            </div>
            <svg
              className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </summary>
          <div className=" mx-auto max-w-[650px] bg-white ">
            <div className="mb-5">
              {!delteAll & !showTeamUpload ? (
                <p className="mt-6 text-xl leading-8 text-slate-800 font-bold text-center">
                  Not sure if you have already uploaded Data ?
                </p>
              ) : (
                <></>
              )}
            </div>
            {!delteAll & !showTeamUpload ? (
              <div className="mb-6 pt-4">
                <div className="mb-8">
                  <div className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] m-8 text-center">
                    <button
                      className={`hover:shadow-form w-[70%] rounded-md py-3 px-8 text-center text-base font-semibold text-white outline-none bg-[#117C13]
                  }`}
                      // className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                      // onClick={CheckData}
                      onClick={() => CheckData1()}
                    >
                      Check
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {delteAll && fileTeam === null ? (
              <div className="flex justify-center m-5 sm:m-0 ">
                <div className="flex flex-col">
                  <p className="mt-6 text-xl leading-8 mb-5">
                    Data already Exists , click on button below to delete
                  </p>
                  <button
                    className={`hover:shadow-form  rounded-md py-3 px-8 text-center text-base font-semibold text-white outline-none ${
                      showMessageDeleteTeam && showMessageDeleteTeam
                        ? "bg-[#117C13]"
                        : "bg-[#6A64F1]"
                    }`}
                    // className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                    onClick={deleteAllDataFunc}
                  >
                    {showMessageDeletePlayer.length < 1 &&
                    showMessageDeleteTeam.length < 1
                      ? "Delete"
                      : "Deleted"}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
            {!playerTeamUploaded &&
            showMessageDeletePlayer &&
            showMessageDeleteTeam ? (
              <p className="mt-6 text-xl leading-8 text-red-800 font-semibold text-center">
                Please Enter team and player data
              </p>
            ) : (
              <></>
            )}
            {playerTeamUploaded ? (
              <p className="mt-6 text-xl leading-8 text-green-800 font-semibold text-center">
                Team and Player data uploaded
              </p>
            ) : (
              <></>
            )}

            {showTeamUpload ? (
              <form className="py-4 px-9">
                <div className="mb-5">
                  <p className="mt-6 text-xl leading-8 text-slate-800 font-bold text-center">
                    Submit this to fill Team Data
                  </p>
                </div>

                <div className="mb-6 pt-4">
                  <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                    Upload File
                  </label>

                  <div className="mb-8">
                    <input
                      type="file"
                      name="fileTeam"
                      id="fileTeam"
                      className="sr-only"
                      accept=".csv"
                      onChange={handleFileChangeTeam}
                    />
                    <label
                      htmlFor="fileTeam"
                      className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                    >
                      <div>
                        <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                          Drop files here
                        </span>
                        <span className="mb-2 block text-base font-medium text-[#6B7280]">
                          Or
                        </span>
                        <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                          Browse
                        </span>
                      </div>
                    </label>
                  </div>

                  <div className="mb-5 rounded-md bg-[#F5F7FB] py-4 px-8">
                    <div className="flex items-center justify-between">
                      <span className="truncate pr-3 text-base font-medium text-[#07074D]">
                        <div className="mt-2">
                          {fileTeam && `${fileTeam.name} -  ${fileTeam.type}`}
                        </div>
                      </span>
                      {!showMessageTeam ? (
                        <button className="text-[#07074D]">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {loading ? (
                    <div
                      className={`hover:shadow-form w-full h-12 rounded-md py-3 px-8 text-center text-base font-semibold text-white outline-none bg-orange-500
                     "
                    }`}
                    >
                      <LoaderFootball height={24} width={24} />
                    </div>
                  ) : (
                    <button
                      className={`hover:shadow-form w-full rounded-md py-3 px-8 text-center text-base font-semibold text-white outline-none ${
                        showMessageTeam ? "bg-[#117C13]" : "bg-[#6A64F1]"
                      }`}
                      // className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                      onClick={handleUploadClickTeam}
                    >
                      {!showMessageTeam ? "Send" : "Sent"}
                    </button>
                  )}
                </div>
                <div className="flex m-4 justify-center">
                  {/* <button className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none">
       extra
     </button> */}
                  <p className="mb-4 text-xl font-bold text-green-500">
                    {showMessageTeam}
                  </p>
                </div>
              </form>
            ) : (
              <></>
            )}

            {teamUploaded && !playerTeamUploaded ? (
              <form className="py-4 px-9">
                <div className="mb-5">
                  <p className="mt-6 text-xl leading-8 text-slate-800 font-bold text-center">
                    Submit this to fill Player data
                  </p>
                </div>

                <div className="mb-6 pt-4">
                  <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                    Upload File
                  </label>

                  <div className="mb-8">
                    <input
                      type="file"
                      name="filePlayer"
                      id="filePlayer"
                      className="sr-only"
                      accept=".csv"
                      onChange={handleFileChange}
                    />
                    <label
                      htmlFor="filePlayer"
                      className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                    >
                      <div>
                        <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                          Drop files here
                        </span>
                        <span className="mb-2 block text-base font-medium text-[#6B7280]">
                          Or
                        </span>
                        <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                          Browse
                        </span>
                      </div>
                    </label>
                  </div>

                  <div className="mb-5 rounded-md bg-[#F5F7FB] py-4 px-8">
                    <div className="flex items-center justify-between">
                      <span className="truncate pr-3 text-base font-medium text-[#07074D]">
                        <div className="mt-2">
                          {filePlayer &&
                            `${filePlayer.name} - ${filePlayer.type}`}
                        </div>
                      </span>
                      {!showMessage ? (
                        <button className="text-[#07074D]">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    className={`hover:shadow-form w-full rounded-md py-3 px-8 text-center text-base font-semibold text-white outline-none ${
                      showMessage ? "bg-[#117C13]" : "bg-[#6A64F1]"
                    }`}
                    // className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                    onClick={handleUploadClick}
                  >
                    {!showMessage ? "Send" : "Sent"}
                  </button>
                </div>
                <div className="flex m-4 justify-center">
                  <p className="mb-4 text-xl font-bold text-green-500">
                    {showMessage}
                  </p>
                </div>
              </form>
            ) : (
              <></>
            )}
          </div>
        </details>
      </li>
    </>
  );
}

export default PlayerTeamChanges;
