import axios from "axios";
import React, { useEffect, useState } from "react";
import CarouselTemplate from "../carousel/CarouselTemplate";
import { logDOM } from "@testing-library/react";

function ChangeMatchLink() {
  const [showDetails, setShowDetails] = useState(false);
  const [linkPlayoff, setLinkPlayoff] = useState();
  const [newLink, setNewLink] = useState();
  const [uniqueWeeks, setUniqueWeeks] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [linkDate, setLinkDate] = useState();
  const [matches, setMatches] = useState();
  const [successfullUpdatesMsg, setSuccessfullUpdatesMsg] = useState(false);
  let successfulUpdates = 0;
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(matches);
    const getIDsByDate = (data, targetDate) => {
      return data
        .filter((match) => match.date === targetDate)
        .map((match) => match._id);
    };
    const data = {
      newLink: linkPlayoff,
    };
    if (linkDate === undefined) {
    }
    // Example usage to get IDs of matches with date "27/06/24"
    const matchIDs = getIDsByDate(matches, linkDate);
    if (linkDate === undefined) {
      alert("enter Date");
    } else if (linkPlayoff?.length === 0 || linkPlayoff === undefined) {
      alert("enter link");
    } else {
      matchIDs.forEach((matchId) => {
        const Id = `localhost:8000/api/match/updateLink/${matchId}`; // Replace with your new link or logic to generate new link
        updateLinkForMatch(matchId, data, matchIDs);
      });
    }

    console.log(matchIDs);
    console.log(linkDate);

    console.log(data);
  };

  const updateLinkForMatch = async (matchId, data, matchIDs) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/match/updateLink/${matchId}`,
        data
      );
      console.log("Live match Response:", response.data);
      if (response) {
        successfulUpdates++;
      }
      if (successfulUpdates === matchIDs?.length) {
        setSuccessfullUpdatesMsg(true);
        setTimeout(() => {
          setSuccessfullUpdatesMsg(false); // Hide error message after 2 seconds
        }, 2000);

        successfulUpdates = 0;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLinkChange = (event) => {
    setLinkPlayoff(event.target.value);
  };

  const handleChange = (event) => {
    // setSelectedDate(event.target.value);

    setLinkDate(event.target.value); // Assuming setLinkDate is defined somewhere
    console.log(event.target.value);
  };

  useEffect(() => {
    // getAllMatchData(`${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`);
    // getWeekNumber();
    getAllMatchData(`${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`);
  }, []);

  // const getWeekNumber = () => {
  //   const startDate = new Date("2024-06-15"); // Assuming the year is 2024

  //   const today = new Date();

  //   const diffInDays = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));

  //   let weekNum = 0;
  //   if (diffInDays <= 0) {
  //     weekNum = 1;
  //   } else {
  //     weekNum = Math.ceil(diffInDays / 7);
  //   }

  //   console.log(weekNum);

  //   getAllMatchData(
  //     `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`,
  //     weekNum
  //   );
  // };

  async function getAllMatchData(url) {
    try {
      const response = await axios.get(url);
      // const matches = response.data.matches;
      const sortedMatches = response.data.matches.sort(
        (a, b) => a.week - b.week
      );
      // const matchesNotPlayed = sortedMatches.filter(
      //   (match) => match.week >= weekNum
      // );
      const matchesNotPlayed = sortedMatches;
      setMatches(matchesNotPlayed);
      const weeksSet = new Set(sortedMatches.map((match) => match.week));
      setUniqueWeeks(Array.from(weeksSet));
      console.log(Array.from(weeksSet));
      const datesSet = new Set(sortedMatches.map((match) => match.date));
      setUniqueDates(Array.from(datesSet));
      console.log(Array.from(datesSet));
      setFilteredMatches(matchesNotPlayed);
      console.log(matchesNotPlayed);
    } catch (error) {
      console.error("Error making GET match request:", error);
    }
  }
  return (
    <li className="flex w-full py-5">
      <details className="group w-full">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
          <div
            className="flex justify-between w-full"
            onClick={() => setShowDetails(true)}
          >
            <div className="flex gap-5">
              <div className="min-w-0 flex-auto">
                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-indigo-600">
                  Want to add match link ?
                </p>
              </div>
            </div>
          </div>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>
        <div className=" mx-auto max-w-[850px] bg-white ">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-center "
          >
            <select
              className="mx-10 mt-5 block w-sm text-sm font-medium transition duration-75 border border-gray-800 rounded-lg shadow-sm h-9 focus:border-blue-600 focus:ring-1 focus:ring-inset focus:ring-blue-600 bg-none"
              defaultValue=""
              value={linkDate}
              onChange={handleChange}
            >
              <option value="">Select a date</option>
              {uniqueDates?.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>
            <div className="flex flex-col sm:gap-4  my-5  mx-10 gap-4 ">
              <div>
                <label
                  htmlFor="playoffLink"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Embedded link :
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="playoffLink"
                    id="playoffLink"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="eg. 0FBiyFpV__g?si=i9YFHJ9eeUxO0i8a"
                    // value="0FBiyFpV__g?si=i9YFHJ9eeUxO0i8a"
                    value={newLink}
                    onChange={handleLinkChange}
                  />
                </div>
              </div>{" "}
              {/* <button >Submit</button> */}
            </div>

            {/* <CarouselTemplate /> */}
            <div className="flex justify-center w-full">
              <button
                type="submit"
                className={` relative hover:shadow-form w-[80%] sm:w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  
                  ${successfullUpdatesMsg ? "bg-[#117C13]" : "bg-[#6A64F1]"}`}
                // onClick={() => livematchFunc(match)} bg-indigo-700
              >
                {!successfullUpdatesMsg ? "Change link" : "Succesfully changed"}
              </button>
            </div>
          </form>
        </div>
      </details>
    </li>
  );
}

export default ChangeMatchLink;
