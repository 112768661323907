import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import userPic from "../assets/user.png";
import PublicPagination from "../components/publicPagination/PublicPagination";
import { useApp } from "../context/app-context";
import publicIcon from "../assets/public.png";
import matchWeeks from "../data/MatchData";
import Confetti from "react-confetti";
import ReactGA from "react-ga4";

const clients = [
  {
    id: 1,
    name: "Eshal Rosas",
    email: "dries.vincent@example.com",

    users: [
      "https://randomuser.me/api/portraits/men/1.jpg",
      "https://randomuser.me/api/portraits/women/2.jpg",
    ],
    points: 10,
  },
  {
    id: 2,
    name: "John Doe",
    email: "leslie.alexander@example.com",

    users: [
      "https://randomuser.me/api/portraits/men/3.jpg",
      "https://randomuser.me/api/portraits/women/4.jpg",
    ],
    points: 90,
  },
  {
    id: 3,
    name: "Jane Smith",
    email: "michael.foster@example.com",

    users: [
      "https://randomuser.me/api/portraits/men/5.jpg",
      "https://randomuser.me/api/portraits/women/6.jpg",
    ],
    points: 80,
  },
  {
    id: 4,
    name: "Alice Johnson",
    email: "lindsay.walton@example.com",

    users: [
      "https://randomuser.me/api/portraits/men/7.jpg",
      "https://randomuser.me/api/portraits/women/8.jpg",
    ],
    points: 70,
  },
  {
    id: 5,
    name: "Bob Brown",
    email: "courtney.henry@example.com",

    users: [
      "https://randomuser.me/api/portraits/men/9.jpg",
      "https://randomuser.me/api/portraits/women/10.jpg",
    ],
    points: 50,
  },
  {
    id: 6,
    name: "Charlie Davis",
    email: "tom.cook@example.com",

    users: [
      "https://randomuser.me/api/portraits/men/11.jpg",
      "https://randomuser.me/api/portraits/women/12.jpg",
    ],
    points: 30,
  },
];

const prizeClasses = [
  "bg-[#FBBF24]", // Gold
  "bg-[#D1D5DB]", // Silver
  "bg-[#A78B45]", // Bronze
];

const prizeLabels = ["1st", "2nd", "3rd"];

function PublicFantasy() {
  const [showDetails, setShowDetails] = useState(false);

  const [sortedClients, setSortedClients] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [getAllMatchDataApi, setGetAllMatchDataApi] = useState(false);
  const [leagueNotStarted, setLeagueNotStarted] = useState(false);

  const { allUser, setAllUser, setPaginatedWeekPublic, paginatedWeekPublic } =
    useApp();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // getWeekNumber();
    // sortUsers();
    // getAllMatchData(`${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`);
    demo();
  }, []);
  // let weekExecuted = false;
  let weekNum = 0;
  // const getWeekNumber = () => {
  //   // Define the start date of the league
  //   const startDate = new Date("2024-06-15"); // Assuming the year is 2024
  //   // Get today's date
  //   const today = new Date();
  //   // Calculate the difference in days between today's date and the start date
  //   const diffInDays = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));
  //   // Calculate the week number by dividing the difference in days by 7 and rounding up
  //   let weekNum = 0;
  //   if (diffInDays <= 0) {
  //     setLeagueNotStarted(true);
  //   }
  //   //  else {
  //   weekNum = Math.ceil(diffInDays / 7);
  //   // }
  //   weekExecuted = true;
  //   // allUserProfile(weekNum);
  //   setCurrentWeek(weekNum);
  //   getAllMatchData(
  //     `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`,
  //     weekNum
  //   );
  // };
  // async function getAllMatchData(url, weekNum) {
  //   try {
  //     const response = await axios.get(url);
  //     // console.log("GET Match request successful");
  //     console.log(response.data);

  //     const weeks = response.data.matches.map((match) => match.week);
  //     // Find the starting week (minimum week number)
  //     const startingWeek = Math.min(...weeks);
  //     // Find the ending week (maximum week number)
  //     const endingWeek = Math.max(...weeks);
  //     console.log("Starting Week:", startingWeek - 1);
  //     console.log("Ending Week:", endingWeek);
  //     // setPageIndex(startingWeek - 1); orignal
  //     setPageCount(endingWeek);
  //     let temp = weekNum;
  //     if (weekNum > endingWeek) {
  //       temp = 1;
  //       setCurrentWeek(endingWeek);
  //     }
  //     setPaginatedWeekPublic(temp);

  //     setPageIndex(temp - 1); // workaround
  //     allUserProfile(temp);

  //     // setMatchData(response.data);
  //     if (response.data.matches.length) {
  //       setGetAllMatchDataApi(true);
  //     }
  //   } catch (error) {
  //     console.error("Error making GET match request:", error);

  //     // Handle errors
  //   }
  // }

  const demo = () => {
    const getCurrentWeek = (date) => {
      for (let i = 0; i < matchWeeks.length; i++) {
        const { week, startDate, endDate } = matchWeeks[i];
        if (date >= startDate && date <= endDate) {
          // console.log(date >= startDate && date <= endDate);

          console.log(week);
          // To exclude finals week from public fantasy week ****************************
          if (week == 9) {
            return 8;
          } else {
            return week;
          }
          // To exclude finals week from public fantasy week ****************************
          // previous code:
          // return week; only no if else
        }
        // len =
        const end_l = matchWeeks[matchWeeks?.length - 1];
        console.log(end_l.endDate);
        if (date > end_l.endDate) {
          return 8;
        }
      }
      setLeagueNotStarted(true); // If the date doesn't fall within any of the predefined ranges
    };
    function floorDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    // const originalDate = new Date("Sat Jun 15 2024 18:38:55 GMT+0530");

    //  const currentDate = new Date();;    // const date15 = new Date("2024-06-15");
    // const DF = floorDate(date15);
    // const TD = floorDate(currentDate);
    // // console.log();
    // if (DF.getTime() === TD.getTime()) {
    //   setLeagueNotStarted(true);
    // }
    // console.log(DF);
    // console.log(TD);

    const currentDate = new Date();
    const flooredDate = floorDate(currentDate);
    const weekNumber = getCurrentWeek(flooredDate);

    // console.log(weekNumber);
    const end_l = matchWeeks[matchWeeks?.length - 1];
    console.log(end_l.endDate);
    if (flooredDate > end_l.endDate) {
      setPageIndex(0); // workaround
      allUserProfile(1);
    } else {
      setPageIndex(weekNumber - 1); // workaround
      allUserProfile(weekNumber);
    }

    setPaginatedWeekPublic(weekNumber);
    setPageCount(weekNumber);

    seasonPointLeader();
    setCurrentWeek(weekNumber);
  };

  // const getCurrentWeek = (date) => {
  //   for (let i = 0; i < matchWeeks.length; i++) {
  //     const { week, startDate, endDate, lockingDate } = matchWeeks[i];
  //     if (date <= lockingDate) {
  //       // console.log(date <= lockingDate);
  //       return week;
  //     }
  //   }
  //   return null; // If the date doesn't fall within any of the predefined ranges
  // };

  // const currentDate = new Date("2024-06-22");

  // async function getAllMatchData(url) {
  //   let weekNum = 1;
  //   const today = new Date(); // Get today's date

  //   const firstDay = new Date("2024-06-16");
  //   const diffWithStart = Math.ceil((today - firstDay) / (1000 * 60 * 60 * 24));
  //   console.log(diffWithStart);
  //   if (diffWithStart < 0) {
  //     setLeagueNotStarted(true);
  //   }
  //   try {
  //     const response = await axios.get(url);
  //     console.log(response.data);
  //     const sortedMatches = response.data.matches.sort(
  //       (a, b) => a.week - b.week
  //     );
  //     const dates = sortedMatches.map((match) => match.date);
  //     const uniqueDates = [...new Set(dates)];
  //     console.log(uniqueDates);

  //     const convertToDate = (dateString) => {
  //       const [day, month, year] = dateString.split("-").map(Number);
  //       // Assuming year is 20YY, convert to 19YY if year < 50, else convert to 20YY
  //       const fullYear = year < 50 ? 2000 + year : 1900 + year;
  //       return new Date(fullYear, month - 1, day); // month is zero-based
  //     };

  //     const matchWeekDates = uniqueDates.map(convertToDate);

  //     console.log(matchWeekDates);

  //     // const firstDay =

  //     const getCurrentMatchWeek = (currentDate, matchWeekDates) => {
  //       // index considered as 1 to handle case of current dat before starting date
  //       for (let index = 0; index < matchWeekDates.length; index++) {
  //         const element = matchWeekDates[index];
  //         console.log(element);
  //         console.log(currentDate);
  //         if (currentDate < element) {
  //           console.log(currentDate < element);
  //           setCurrentWeek(index);

  //           return index;
  //         }
  //       }
  //       const last = matchWeekDates[matchWeekDates.length - 1];
  //       const diffInDays = Math.ceil((today - last) / (1000 * 60 * 60 * 24));
  //       console.log(diffInDays);

  //       if (diffInDays <= 7) {
  //         return matchWeekDates.length;
  //       }
  //       setCurrentWeek(matchWeekDates.length);
  //       return 1;
  //     };
  //     const endingWeek = uniqueDates.length;
  //     console.log("Ending Week:", endingWeek);
  //     weekNum = getCurrentMatchWeek(today, matchWeekDates);
  //     console.log(`Current Match Week: ${weekNum}`);
  //     setPaginatedWeekPublic(weekNum);
  //     setPageCount(endingWeek);
  //     setPageIndex(weekNum - 1); // workaround
  //     allUserProfile(weekNum);

  //     if (response.data.matches.length) {
  //       setGetAllMatchDataApi(true);
  //     }
  //   } catch (error) {
  //     console.error("Error making GET match request:", error);

  //     // Handle errors
  //   }
  // }

  const seasonPointLeader = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/getusers?limit=1000&hasFantasyTeam=true`
      );
      const users = response.data.users;
      // Adding points attribute to each user
      const temp = users.sort((a, b) => {
        // console.log(a.seasonPoints[0].points);
        const aPoints = a.seasonPoints[0].points;
        const bPoints = b.seasonPoints[0].points;
        return bPoints - aPoints;
      });
      const result = temp.map((user) => ({
        username: user.username,
        points: user.seasonPoints[0].points,
      }));

      console.log(result);
      // console.log(temp);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const allUserProfile = async (week) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/getusers?limit=1000&week=${week}&hasFantasyTeam=true`
      );
      const data = response.data;
      const users = response.data.users;
      // Adding points attribute to each user
      const temp = users.sort((a, b) => {
        const aPoints = WeekPointsFunc(a, week);
        const bPoints = WeekPointsFunc(b, week);
        return bPoints - aPoints;
      });
      // console.log(temp);
      setAllUser(temp);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  // const addPointsToUsers = (users) => {
  //   return users?.map((user) => ({
  //     ...user,
  //     points: Math.floor(Math.random() * 100) + 1, // Random number between 1 and 100
  //   }));
  // };
  // console.log(allUser);

  // const sortUsers = () => {
  //   const temp = [...allUser].sort((a, b) => b.points - a.points);
  //   setAllUser(temp);
  // };

  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // const sortedClients1 = Array.isArray(allUser) ? allUser.slice(0, 3) : [];

  // const topThree1 = sortedClients1.slice(0, 3);
  // const rest1 = sortedClients1.slice(3);
  // console.log("sorted:", sortedClients1);

  // const sortedClients = [...clients].sort((a, b) => b.points - a.points);
  // const topThree = sortedClients.slice(0, 3);
  // const rest = sortedClients.slice(3);

  const CaptainImgFunc = (fantasyTeamProp) => {
    const tempTeam = fantasyTeamProp.find((team) => team.week === weekNum);

    if (tempTeam && tempTeam.captain) {
      return tempTeam.captain.photo;
    } else {
      return userPic;
    }
  };

  const viceCaptainImgFunc = (fantasyTeamProp) => {
    const tempTeam = fantasyTeamProp.find((team) => team.week === weekNum);

    if (tempTeam && tempTeam.viceCaptain) {
      return tempTeam.viceCaptain.photo;
    } else {
      return userPic;
    }
  };

  const WeekPointsFunc = (client, week) => {
    // console.log(paginatedWeekPublic);
    // PublicPagination;
    let weekN = paginatedWeekPublic;
    if (paginatedWeekPublic == 0) {
      weekN = week;
    }
    // console.log(weekN);

    const teamForWeek = client?.fantasyTeam?.find(
      (team) => team.week === weekN
    );
    // console.log(teamForWeek);
    const points = teamForWeek?.points;

    // console.log(client, pageIndex);
    // const fantasyTeamWeekX = client?.fantasyTeam?.find(
    //   (team) => team.week === pageIndex
    // );
    // // const teamForWeek = fantasyTeam.find(team => team.week === weekToFind);
    // console.log(fantasyTeamWeekX);
    // const points = fantasyTeamWeekX?.points;
    return points;
  };

  const clicked_Team = (prop) => {
    const temp = `checked : ${prop}'s team`;
    ReactGA.event({
      category: "Fantasy Leader board",
      action: "checking others team new",
      label: temp,
      value: currentWeek,
    });
  };

  return (
    <>
      <div className="mx-auto max-w-screen bg-[#192347]  py-20  shadow-lg">
        <div
          className={`z-50 fixed top-0 left-0 w-full h-full transition-opacity duration-1000 pointer-events-none ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={100}
            recycle={true}
            gravity={0.4}
          />
        </div>
        <div
          align="center"
          className="text-white font-extrabold text-3xl mt-3  flex justify-center gap-x-4"
        >
          {/* {user?.username}'s TEAM */}
          <span>FANTASY TEAMS</span>
        </div>
        {!leagueNotStarted ? (
          <div>
            {setGetAllMatchDataApi ? (
              <PublicPagination
                gotoPage={setPageIndex}
                canPreviousPage={pageIndex > 0}
                canNextPage={pageIndex < pageCount - 1}
                pageCount={currentWeek}
                pageIndex={pageIndex}
                // fantasyTeam={user?.fantasyTeam}
              />
            ) : (
              <></>
            )}
            <div className="flex-shrink-0 relative overflow-hidden bg-blue-500 rounded-t-lg  shadow-lg pt-2 pb-5 px-2 mt-2">
              <svg
                className="absolute bottom-0 left-0 mb-8"
                viewBox="0 0 375 283"
                fill="none"
                style={{ transform: "scale(1.5)", opacity: "0.1" }}
              >
                <rect
                  x="159.52"
                  y="175"
                  width="152"
                  height="152"
                  rx="8"
                  transform="rotate(-45 159.52 175)"
                  fill="white"
                />
                <rect
                  y="107.48"
                  width="152"
                  height="152"
                  rx="8"
                  transform="rotate(-45 0 107.48)"
                  fill="white"
                />
                <rect
                  y="107.48"
                  width="152"
                  height="152"
                  rx="8"
                  transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                  fill="white"
                />
              </svg>
              <div
                align="center"
                className="text-white font-extrabold text-2xl"
              >
                Fantasy Leader Board
              </div>
            </div>
            <div className="overflow-x-auto ">
              <div className="min-w-screen bg-gray-100 flex items-center justify-center font-sans overflow-hidden ">
                <div className="w-full mx-2 rounded-lg  bg-transparent my-3">
                  <div className="bg-white shadow-md rounded-lg ">
                    <table className="min-w-max w-full table-auto">
                      <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase leading-normal text-md font-bold">
                          <th className="py-3 px-2 text-left">User</th>
                          {/* <th className="py-3 px-2 text-center">Captain</th> */}
                          <th className="py-3 px-2 text-center">Points</th>
                          <th className="py-3 px-2 text-center">Prize</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 text-sm font-light">
                        {allUser?.slice(0, 3).map((client, index) => (
                          <tr
                            key={client._id}
                            className={`border-b border-gray-200 hover:bg-gray-100`}
                          >
                            <td className="py-3 px-2 text-left">
                              <div className="flex items-center">
                                {/* <div className="mr-2">
                              <img
                                className="w-6 h-6 rounded-full"
                                src={client.image}
                                alt="Client"
                              />
                            </div> */}
                                <span className="text-md font-semibold">
                                  {client.username}
                                </span>
                              </div>
                            </td>
                            {/* <td className="py-3 px-2 text-center">
                          {weekExecuted === true && (
                            <div className="flex items-center justify-center">
                              <img
                                key={index}
                                className={`w-6 h-6 rounded-full border-gray-200 border ${
                                  index !== 0 && "-m-1"
                                } transform hover:scale-125`}
                                src={CaptainImgFunc(client?.fantasyTeam)}
                                alt={`User `}
                              />

                              <img
                                key={index}
                                className={`w-6 h-6 rounded-full border-gray-200 border ${
                                  index !== 0 && "-m-1"
                                } transform hover:scale-125`}
                                src={viceCaptainImgFunc(client?.fantasyTeam)}
                                alt={`User `}
                              />
                            </div>
                          )}
                          <div className="flex items-center justify-center">
                            <img
                              key={index}
                              className={`w-6 h-6 rounded-full border-gray-200 border ${
                                index !== 0 && "-m-1"
                              } transform hover:scale-125`}
                              src={CaptainImgFunc(client?.fantasyTeam)}
                              alt={`User `}
                            />

                            <img
                              key={index}
                              className={`w-6 h-6 rounded-full border-gray-200 border ${
                                index !== 0 && "-m-1"
                              } transform hover:scale-125`}
                              src={viceCaptainImgFunc(client?.fantasyTeam)}
                              alt={`User `}
                            />
                          </div>
                        </td> */}
                            <td className="py-3 px-2 text-center">
                              <div className="flex items-center justify-center text-md font-semibold">
                                {WeekPointsFunc(client)}
                              </div>
                            </td>
                            <td className="py-3 px-2 text-center">
                              {/* <span className="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs"> */}
                              <span
                                className={`border-b border-gray-200 hover:bg-gray-100 py-1 px-3 rounded-full text-sm font-semibold text-white ${
                                  prizeClasses[index] || ""
                                }`}
                              >
                                {prizeLabels[index] || ""}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <details className=" bg-white  border-b-2  border  border-gray-200">
              <summary className="  flex items-center justify-between gap-2 p-2 font-medium  cursor-pointer   py-1.5 pl-3  text-left h-12 ">
                <div
                  className="flex justify-between w-full "
                  onClick={() => setShowDetails(true)}
                >
                  <div className="flex gap-5 ">
                    <div className="min-w-0 flex-auto flex items-center">
                      <p className="text-xl  sm:text-2xl md:text-4xl font-semibold leading-6  text-sky-500 hover:text-sky-700">
                        Actions
                      </p>
                      <div className="mx-2 ml-4 cursor-pointer text-sky-500 ">
                        {/* Your SVG Icon */}
                        <svg
                          aria-haspopup="true"
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-info-circle"
                          width={25}
                          height={25}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#A0AEC0"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <circle cx={12} cy={12} r={9} />
                          <line x1={12} y1={8} x2="12.01" y2={8} />
                          <polyline points="11 12 12 12 12 16 13 16" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </summary>
              {/* <div className="flex flex-col px-2  ">
                <p className="text-lg font-semibold text-sky-500  bg-white">
                  Tap on team icon on left to view team
                </p>
                <p className="text-lg font-semibold text-sky-500  bg-white">
                  Tap on the user card on right to view ranking
                </p>
              </div> */}
              <ul
                role="list"
                className="divide-y divide-gray-100 bg-white px-2 pb-2 w-full"
              >
                <li className="w-full flex-1 py-5 items-center group relative cursor-pointer overflow-hidden bg-white px-4  shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl  rounded-lg ">
                  <span class="absolute top-15 z-0 h-12 w-12 rounded-full bg-sky-500 transition-all duration-300 group-hover:scale-[50]"></span>
                  <div className="relative flex flex-end">
                    <div
                      className="h-12 w-12 flex  flex-none justify-between rounded-full bg-transparent text-center  items-center bg-white transition-all duration-300 hover:bg-white "
                      // src={person.image}
                      alt=""
                    >
                      <p className="text-md text-sky-500 font-extrabold text-center -mr-5  ">
                        Team
                      </p>
                    </div>
                    <div className="ml-4 items-center flex">
                      <p className="text-2xl font-extrabold leading-6 text-sky-500  justify-end items-end">
                        Tap on card
                      </p>
                    </div>

                    <div class="ml-0 space-y-6 flex-end  pt-5 text-2xl leading-7 font-extrabold text-white transition-all duration-300 group-hover:text-white/90">
                      <p>Ranking</p>
                    </div>
                  </div>
                </li>
                <li className="w-full flex mt-2 justify-between py-5 items-center group relative cursor-pointer overflow-hidden bg-white px-4  shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl mx-auto rounded-lg ">
                  <div className="relative flex min-w-0 gap-x-4 justify-between">
                    <div
                      className="h-12 w-12 border border-sky-100 flex-none rounded-full bg-transparent flex justify-center items-center bg-white transition-all duration-300 hover:bg-white "
                      // src={person.image}
                      alt=""
                    >
                      <p className="text-md text-sky-500 font-extrabold text-center">
                        Team
                      </p>
                    </div>
                    {/* <div className="min-w-0 flex-auto"></div> */}
                    <div class=" space-y-6  pt-5 text-2xl leading-7 font-extrabold text-sky-500">
                      <p>Tap on Teams</p>
                    </div>
                  </div>
                </li>
              </ul>
            </details>
            <ul
              role="list"
              className="divide-y divide-gray-100 bg-white p-2 rounded-b-lg"
            >
              {allUser?.map((person, index) => (
                <li
                  key={person.email}
                  className="w-full flex mt-2 justify-between py-5 items-center group relative cursor-pointer overflow-hidden bg-white px-4  shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl mx-auto rounded-lg "
                >
                  <span class="absolute top-15 z-0 h-12 w-12 rounded-full bg-sky-500 transition-all duration-300 group-hover:scale-[50]"></span>
                  <div className="relative flex min-w-0 gap-x-4 justify-between items-center">
                    <div
                      className="h-12 w-12 flex-none rounded-full bg-transparent flex justify-center items-center bg-white transition-all duration-300 hover:bg-white "
                      // src={person.image}
                      alt=""
                    >
                      <NavLink
                        to={`/viewOthersFantasy/${person._id}/${paginatedWeekPublic}`}
                      >
                        <p
                          className="text-md text-sky-500 font-extrabold text-center"
                          onClick={() => {
                            clicked_Team(person.username);
                          }}
                        >
                          Team
                        </p>
                      </NavLink>
                    </div>
                    {/* <div className="flex justify-center items-center ">
                  <div class=" hidden hover:flex  h-12 w-12 text-black bg-white transition-all duration-300 group-hover:block rounded-full"></div>
                </div> */}
                    <div className="min-w-0 flex-auto">
                      <p className="text-md font-bold leading-6 text-gray-900">
                        {person.username}
                      </p>
                      <p className=" truncate text-sm leading-5 text-gray-500 font-semibold">
                        {person.email}
                      </p>
                    </div>
                    <div class="hidden group-hover:block item-center  space-y-6   text-2xl leading-7 font-extrabold text-white transition-all duration-300 group-hover:text-white/90">
                      <p>{getOrdinal(index + 1)}</p>
                    </div>
                  </div>

                  <div className=" shrink-0 sm:flex sm:flex-col sm:items-end group-hover:hidden">
                    <p className="text-sm leading-6 text-gray-900">
                      {WeekPointsFunc(person)} points
                    </p>
                  </div>
                </li>
              ))}
              {pageIndex == 0 && (
                // <div>
                //   <p>hello</p>
                // </div>
                <li className="w-full flex mt-2 justify-center py-5 items-center group relative cursor-pointer overflow-hidden bg-sky-500 px-4  shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl mx-auto rounded-lg ">
                  {/* <div className="relative flex min-w-0 gap-x-4 justify-between items-center"> */}
                  {/* <div className="min-w-0 flex-auto">
                      <p className="text-md font-bold leading-6 text-gray-900">
                        4
                      </p>
                      <p className=" truncate text-sm leading-5 text-gray-500 font-semibold">
                        2
                      </p>
                    </div> */}
                  <div class=" item-center text-center    text-2xl leading-7 font-extrabold text-white transition-all duration-300 group-hover:text-white/90">
                    Trial Week
                  </div>
                  {/* </div> */}
                </li>
              )}
            </ul>
          </div>
        ) : (
          <div className=" mt-4  mx-4 shadow-xl rounded-md bg-white ">
            <div className="p-6 pt-10 text-center ">
              <svg
                className="w-20 h-20 text-red-600 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p className="text-2xl font-semibold text-gray-500 mt-5 mb-2">
                The League has not started
              </p>
              <p className="text-md  font-medium text-gray-500  mb-6">
                You'll be able to see others week1 fantasy team after league
                starts
              </p>
              <NavLink to="/Fantasy">
                <button
                  href="#"
                  className="text-white bg-indigo-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2"
                >
                  Make your own fantasy team
                </button>
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PublicFantasy;
