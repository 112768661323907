import React, { useEffect, useState } from "react";
import { useApp } from "../context/app-context";
import { useNavigate } from "react-router-dom";

function ProtectedRoute(prop) {
  const { login, setLogin } = useApp();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { PropPassed } = prop;
  const storedUser = JSON.parse(localStorage.getItem("loginCheck"));
  const adminCheck = JSON.parse(localStorage.getItem("adminCheck"));

  console.log(storedUser);
  const checklogincred = login || storedUser;
  useEffect(() => {
    if (!checklogincred) {
      navigate("/login");
    } else if (adminCheck) {
      navigate("/login");
    } else {
      setIsLoading(false); // Allow rendering if adminCheck passes
    }
  }, [storedUser, navigate]);

  if (isLoading) {
    return (
      <div className="w-screen h-screen bg-[#192347] ">
        {/* <LoaderFootball height={96} weight={96} />; */}
      </div>
    );
  }

  return <PropPassed />;
}

export default ProtectedRoute;
