const matchWeeks = [
  {
    week: 1,
    startDate: new Date("2024-06-12T00:00:00+05:30"),
    endDate: new Date("2024-06-16T00:00:00+05:30"),
    lockingDate: new Date("2024-06-15T00:00:00+05:30"),
    adminDate: new Date("2024-06-17T00:00:00+05:30"),
  },
  {
    week: 2,
    startDate: new Date("2024-06-17T00:00:00+05:30"),
    endDate: new Date("2024-06-23T00:00:00+05:30"),
    lockingDate: new Date("2024-06-22T00:00:00+05:30"),
    adminDate: new Date("2024-06-24T00:00:00+05:30"),
  },
  {
    week: 3,
    startDate: new Date("2024-06-24T00:00:00+05:30"),
    endDate: new Date("2024-06-27T00:00:00+05:30"),
    lockingDate: new Date("2024-06-26T00:00:00+05:30"),
    adminDate: new Date("2024-06-28T00:00:00+05:30"),
  },
  {
    week: 4,
    startDate: new Date("2024-06-28T00:00:00+05:30"),
    endDate: new Date("2024-06-30T00:00:00+05:30"),
    lockingDate: new Date("2024-06-29T00:00:00+05:30"),
    adminDate: new Date("2024-07-01T00:00:00+05:30"),
  },
  {
    week: 5,
    startDate: new Date("2024-07-01T00:00:00+05:30"),
    endDate: new Date("2024-07-07T00:00:00+05:30"),
    lockingDate: new Date("2024-07-06T00:00:00+05:30"),
    adminDate: new Date("2024-07-08T00:00:00+05:30"),
  },
  {
    week: 6,
    startDate: new Date("2024-07-08T00:00:00+05:30"),
    endDate: new Date("2024-07-11T00:00:00+05:30"),
    lockingDate: new Date("2024-07-10T00:00:00+05:30"),
    adminDate: new Date("2024-07-12T00:00:00+05:30"),
  },
  {
    week: 7,
    startDate: new Date("2024-07-12T00:00:00+05:30"),
    endDate: new Date("2024-07-14T00:00:00+05:30"),
    lockingDate: new Date("2024-07-13T00:00:00+05:30"),
    adminDate: new Date("2024-07-15T00:00:00+05:30"),
  },
  {
    week: 8,
    startDate: new Date("2024-07-15T00:00:00+05:30"),
    endDate: new Date("2024-07-21T00:00:00+05:30"),
    lockingDate: new Date("2024-07-20T00:00:00+05:30"),
    adminDate: new Date("2024-07-22T00:00:00+05:30"),
  },
  {
    week: 9,
    startDate: new Date("2024-07-22T00:00:00+05:30"),
    endDate: new Date("2024-08-04T00:00:00+05:30"),
    lockingDate: new Date("2024-08-03T00:00:00+05:30"),
    adminDate: new Date("2024-08-05T00:00:00+05:30"),
  },
];
export default matchWeeks;
