import React, { useEffect, useState } from "react";
import FantasyComponent from "../../components/fantasyComponent/FantasyComponent";
import FantasyPlayerSelection from "../../components/fantasyComponent/FantasyPlayerSelection";
import FantasyLineupDemo from "../../components/fantasyComponent/FantasyLineupDemo";
import FantasyTeamNew from "../../components/fantasyComponent/FantasyTeamNew";
import axios from "axios";
import { NavLink } from "react-router-dom";
import FantasyTab from "../../components/fantasyTab/FantasyTab";
import matchWeeks from "../../data/MatchData";

function FantasyPage() {
  const [error, setError] = useState(null);
  const [user, setUser] = useState();
  const [team, setTeam] = useState();
  const [teamView, setTeamView] = useState();
  const [teamEdit, setTeamEdit] = useState();
  const [week, setWeek] = useState(0);
  const [leagueEnded, setLeagueEnded] = useState(false);

  // const [role, setRole] = useState("normal");
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("loginCheck"));
    //console.log(storedUser);
    const refresh = JSON.parse(localStorage.getItem("refreshToken"));
    const access = JSON.parse(localStorage.getItem("accessToken"));
    //console.log("refreshToken", refresh);
    //console.log("accessToken", access);
    checkToken();
    getProfile(
      `${process.env.REACT_APP_SERVER_URL}/api/user/getProfile`,
      access
    );
  }, []);

  async function getProfile(url, access) {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("GET profile request successful");
      //console.log(response.data);
      setUser(response.data);
      // getAllMatchData(
      //   `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`,
      //   response.data
      // );
      demo(response.data);
    } catch (error) {
      console.error("Error making GET profile request:", error);
      setError("Error fetching GET profile request. Please try again later.");
    }
  }

  const playerRemove = (team, position, id) => {
    if (team === "team") {
      if (position === "Goalkeeper") {
        setTeamEdit((team) => ({
          ...team,
          squad: { ...team.squad, gk: [] },
        }));
      } else if (position === "Defender") {
        setTeamEdit((team) => ({
          ...team,
          squad: {
            ...team.squad,
            df: team.squad.df.filter((player) => player.id !== id),
          },
        }));
      } else if (position === "Midfielder") {
        setTeamEdit((team) => ({
          ...team,
          squad: {
            ...team.squad,
            cm: team.squad.cm.filter((player) => player.id !== id),
          },
        }));
      } else {
        setTeamEdit((team) => ({
          ...team,
          squad: {
            ...team.squad,
            fw: team.squad.fw.filter((player) => player.id !== id),
          },
        }));
      }
    }
  };
  // const getWeekNumber = (userData) => {
  //   // Define the start date of the league
  //   const startDate = new Date("2024-06-15"); // Assuming the year is 2024

  //   // Get today's date
  //   const today = new Date();

  //   // Calculate the difference in days between today's date and the start date
  //   const diffInDays = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));

  //   // Calculate the week number by dividing the difference in days by 7 and rounding up
  //   let weekNum = 0;
  //   if (diffInDays <= 0) {
  //     weekNum = 1;
  //   } else {
  //     weekNum = Math.ceil(diffInDays / 7) + 1;
  //   }
  //   console.log(weekNum);
  //   // setCurrentWeek(weekNum);
  //   setWeek(weekNum);
  //   extractFantasyEdit(weekNum, userData);
  //   extractFantasyView(weekNum - 1, userData);
  //   getAllMatchData(
  //     `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`,
  //     weekNum
  //   );
  // };

  // async function getAllMatchData(url, weekNum) {
  //   try {
  //     const response = await axios.get(url);
  //     // console.log("GET Match request successful");
  //     console.log(response.data);

  //     const weeks = response.data.matches.map((match) => match.week);
  //     // Find the starting week (minimum week number)
  //     const startingWeek = Math.min(...weeks);
  //     // Find the ending week (maximum week number)
  //     const endingWeek = Math.max(...weeks);
  //     console.log("Starting Week:", startingWeek - 1);
  //     console.log("Ending Week:", endingWeek);
  //     //
  //     if (weekNum > endingWeek) {
  //       setLeagueEnded(true);
  //     }
  //   } catch (error) {
  //     console.error("Error making GET match request:", error);
  //   }
  // }
  const demo = (userData) => {
    const getCurrentWeek = (date) => {
      for (let i = 0; i < matchWeeks.length; i++) {
        const { week, startDate, endDate, lockingDate } = matchWeeks[i];
        if (date <= lockingDate) {
          // console.log(date <= lockingDate);
          return week;
        }
      }
      setLeagueEnded(true);
      return null; // If the date doesn't fall within any of the predefined ranges
    };

    function floorDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    // const originalDate = new Date("Sat Jun 15 2024 18:38:55 GMT+0530");

    //  const currentDate = new Date();;
    const currentDate = new Date();
    const flooredDate = floorDate(currentDate);
    // alert(currentDate);
    const weekNumber = getCurrentWeek(flooredDate);
    // alert(weekNumber);
    setWeek(weekNumber);
    // alert(weekNumber);
    extractFantasyEdit(weekNumber, userData);
    extractFantasyView(weekNumber - 1, userData);

    const endingWeek = matchWeeks.length - 1;
    if (weekNumber > endingWeek) {
      setLeagueEnded(true);
    }
  };

  // async function getAllMatchData(url, userData) {
  //   let weekNum = 1;
  //   try {
  //     const response = await axios.get(url);
  //     console.log(response.data);
  //     const sortedMatches = response.data.matches.sort(
  //       (a, b) => a.week - b.week
  //     );
  //     const dates = sortedMatches.map((match) => match.date);
  //     const uniqueDates = [...new Set(dates)];
  //     console.log(uniqueDates);

  //     const convertToDate = (dateString) => {
  //       const [day, month, year] = dateString.split("-").map(Number);
  //       // Assuming year is 20YY, convert to 19YY if year < 50, else convert to 20YY
  //       const fullYear = year < 50 ? 2000 + year : 1900 + year;
  //       return new Date(fullYear, month - 1, day); // month is zero-based
  //     };

  //     const matchWeekDates = uniqueDates.map(convertToDate);

  //     console.log(matchWeekDates);

  //     const today = new Date(); // Get today's date

  //     const getCurrentMatchWeek = (currentDate, matchWeekDates) => {
  //       // index considered as 1 to handle case of current dat before starting date
  //       for (let index = 1; index < matchWeekDates.length; index++) {
  //         const element = matchWeekDates[index];
  //         console.log(element);
  //         console.log(currentDate);
  //         if (currentDate < element) {
  //           console.log(currentDate < element);
  //           return index;
  //         }
  //       }
  //       const last = matchWeekDates[matchWeekDates.length - 1];
  //       const diffInDays = Math.ceil((today - last) / (1000 * 60 * 60 * 24));
  //       console.log(diffInDays);

  //       if (diffInDays <= 7) {
  //         return matchWeekDates.length;
  //       }
  //       return matchWeekDates.length + 1;
  //     };

  //     weekNum = getCurrentMatchWeek(today, matchWeekDates);
  //     console.log(`Current Match Week: ${weekNum}`);
  //     setWeek(weekNum);
  //     extractFantasyEdit(weekNum, userData);
  //     extractFantasyView(weekNum - 1, userData);

  //     const endingWeek = uniqueDates.length;
  //     console.log("Ending Week:", endingWeek);
  //     if (weekNum > endingWeek) {
  //       setLeagueEnded(true);
  //     }
  //   } catch (error) {
  //     console.error("Error making GET match request:", error);
  //   }
  // }

  const extractFantasyView = (weekNum, userData) => {
    // alert(weekNum);
    //console.log(weekNum);

    // fantasy extract process below -------------------------------
    //console.log(weekNum, userData);
    const fantasyTeamWeek4 = userData?.fantasyTeam?.find(
      (team) => team.week === weekNum
    );

    const captainId = fantasyTeamWeek4?.captain?._id;
    const viceCaptainId = fantasyTeamWeek4?.viceCaptain?._id;

    //console.log("fantasyTeamWeek4", fantasyTeamWeek4);
    const categorizedPlayers = {
      fw: [],
      cm: [],
      df: [],
      gk: [],
    };
    // checking user team
    //console.log(fantasyTeamWeek4.players);

    fantasyTeamWeek4?.players?.forEach((player) => {
      let role = "normal"; // Default role
      let pointsWeekly = 0;
      // console.log(player.fantasyPoints.length);
      const weeklyPoints = player?.fantasyPoints?.find(
        (match) => match.week == weekNum
      );
      if (weeklyPoints === undefined) {
        pointsWeekly = 0;
      } else {
        //console.log(player.fantasyPoints);

        pointsWeekly = weeklyPoints.points;
        // alert(pointsWeekly);
      }
      if (captainId === player._id) {
        role = "captain";
        pointsWeekly *= 2;
      } else if (viceCaptainId === player._id) {
        role = "vice-captain";
        pointsWeekly *= 1.5;
      }
      const posn = player.position.split(",")[0].trim();

      const temp = {
        id: player._id,
        name: player.name,
        photo: player.photo,
        playerPosition: posn,
        role: role,
        cost: player?.cost,
        points: pointsWeekly,
      };
      switch (posn) {
        case "Attacker":
          categorizedPlayers.fw.push(temp);
          break;
        case "Midfielder":
          categorizedPlayers.cm.push(temp);
          break;
        case "Defender":
          categorizedPlayers.df.push(temp);
          break;
        case "Goalkeeper":
          categorizedPlayers.gk.push(temp);
          break;
        default:
          break;
      }
    });

    //console.log(categorizedPlayers);
    const updatedTeams = {
      squad: categorizedPlayers,
    };
    //console.log("updatedTeams", updatedTeams);

    setTeamView(updatedTeams);
  };
  const extractFantasyEdit = (weekNum, userData) => {
    // fantasy extract process below -------------------------------
    const fantasyTeamWeek4 = userData?.fantasyTeam?.find(
      (team) => team.week === weekNum
    );

    const captainId = fantasyTeamWeek4?.captain?._id;
    const viceCaptainId = fantasyTeamWeek4?.viceCaptain?._id;

    //console.log("fantasyTeamWeek4", fantasyTeamWeek4);
    const categorizedPlayers = {
      fw: [],
      cm: [],
      df: [],
      gk: [],
    };
    // checking user team

    fantasyTeamWeek4?.players?.forEach((player) => {
      let role = "normal"; // Default role
      let pointsWeekly = 0;
      // console.log(player.fantasyPoints.length);
      const weeklyPoints = player?.fantasyPoints?.find(
        (match) => match.week == weekNum
      );
      if (weeklyPoints === undefined) {
        pointsWeekly = 0;
      } else {
        //console.log(player.fantasyPoints);

        pointsWeekly = weeklyPoints.points;
        // alert(pointsWeekly);
      }
      if (captainId === player._id) {
        role = "captain";
        pointsWeekly *= 2;
      } else if (viceCaptainId === player._id) {
        role = "vice-captain";
        pointsWeekly *= 1.5;
      }
      const posn = player.position.split(",")[0].trim();

      const temp = {
        id: player._id,
        name: player.name,
        photo: player.photo,
        playerPosition: posn,
        role: role,
        cost: player?.cost,
        points: pointsWeekly,
      };
      switch (posn) {
        case "Attacker":
          temp.onClick = () => playerRemove("team", "Attacker", temp.id);
          categorizedPlayers.fw.push(temp);
          break;
        case "Midfielder":
          categorizedPlayers.cm.push(temp);
          temp.onClick = () => playerRemove("team", "Midfielder", temp.id);
          break;
        case "Defender":
          categorizedPlayers.df.push(temp);
          temp.onClick = () => playerRemove("team", "Defender", temp.id);
          break;
        case "Goalkeeper":
          categorizedPlayers.gk.push(temp);
          temp.onClick = () => playerRemove("team", "Goalkeeper", temp.id);
          break;
        default:
          break;
      }
    });

    //console.log(categorizedPlayers);
    const updatedTeams = {
      squad: categorizedPlayers,
    };
    //console.log("updatedTeams", updatedTeams);

    setTeamEdit(updatedTeams);
  };

  const checkToken = () => {
    const refresh = JSON.parse(localStorage.getItem("refreshToken"));
    if (refresh !== null) {
      // alert(`No issue , access check: ${refresh}`);
      // console.log(refresh);
      // console.log("if");
      getNewAccess(refresh);
    } else {
      // alert("refresh token issue , refresh token :", refresh);
      // alert("else");
    }
  };
  const getNewAccess = async (token) => {
    // alert("func called");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user/token`,
        { token: token }
      );
      const temp = response.data;
      //console.log("New Access : ", temp);
      localStorage.setItem("accessToken", JSON.stringify(temp.accessToken));
      getProfileNew(
        `${process.env.REACT_APP_SERVER_URL}/api/user/getProfile`,
        response.data.accessToken
      );
    } catch (error) {
      // alert("Error");
      //console.error("Error:", error);
    }
  };
  async function getProfileNew(url, access) {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("GET profile request successful");
      //console.log(response.data);
      setUser(response.data);
      // getAllMatchData(
      //   `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`,
      //   response.data
      // );
      demo(response.data);
      localStorage.setItem("userData", response.data);
    } catch (error) {
      console.error("Error making GET profile request:", error);
      setError("Error fetching GET profile request. Please try again later.");
    }
  }

  return (
    <>
      <div>
        <div className="mx-auto max-w-screen bg-[#192347] px-4 py-20   sm:px-6 lg:px-8 shadow-lg">
          {!leagueEnded ? (
            // <FantasyLineupDemo user={user} teamData={teamEdit} />
            <FantasyTab
              user={user}
              teamEdit={teamEdit}
              teamView={teamView}
              fantasyWeek={week}
            />
          ) : (
            <div className=" mt-4   shadow-xl rounded-md bg-white ">
              <div className="py-6 px-4 pt-10 text-center ">
                <svg
                  className="w-20 h-20 text-red-600 mx-auto"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="text-2xl font-semibold text-gray-500 mt-5 mb-6">
                  Fantasy League has ended
                </p>
                <NavLink to="/publicFantasy">
                  <button
                    href="#"
                    className="text-white bg-indigo-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2"
                  >
                    Checkout old fantasy teams
                  </button>
                </NavLink>
              </div>
            </div>
          )}
          {/* <FantasyComponent /> */}
          {/* <FantasyTeamNew /> */}
        </div>
      </div>
    </>
  );
}

export default FantasyPage;
