import React from "react";
import userPic from "../../assets/user.png";
import { NavLink } from "react-router-dom";
function ProfileComp({ clearFunc, user }) {
  return (
    <div className=" px-5 pt-10">
      <div className="relative mt-16  max-w-sm mx-auto mt-24">
        <div className="rounded overflow-hidden shadow-md bg-white">
          <div className="absolute -mt-20 w-full flex justify-center">
            <div className="h-32 w-32">
              <img
                src={userPic}
                className="rounded-full object-cover h-full w-full shadow-md"
              />
            </div>
          </div>
          <div className="px-6 mt-16">
            <h1 className="font-bold text-3xl text-center mb-1">
              {user?.username}
            </h1>
            {user?.number && (
              <p className="text-center text-gray-800 text-xl   font-semibold">
                {user?.number}
              </p>
            )}

            <p className="text-center text-gray-800 text-xl pt-1  mb-2 font-semibold">
              {user?.email}
            </p>
            {user?.role !== "admin" && (
              <p className="text-center text-lg text-gray-500">
                Want to create your own fantasy team?{" "}
                <NavLink
                  to={`/fantasy`}
                  className=" text-indigo-600 text-base font-semibold"
                >
                  Click Here
                </NavLink>
              </p>
            )}

            {/* <p className=" text-black  text-2xl font-semibold">Logged in</p> */}

            <div className="w-full flex justify-center pt-5 pb-5 cursor-pointer mb-6">
              <button
                className=" mb-1 hover:bg-rose-900 text-white  bg-pink-800  rounded-md mt-2 px-8 py-2 text-center text-base font-semibold "
                onClick={() => {
                  clearFunc();
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileComp;
