import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

const InstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the default prompt from showing
      e.preventDefault();

      // Check if the prompt has already been shown
      // if (!localStorage.getItem("installPromptShown")) {
      setDeferredPrompt(e);
      setShowPrompt(true);
      // }
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.platform);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          installedPopup();
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
        setShowPrompt(false);
        // localStorage.setItem("installPromptShown", "true"); // Set flag in localStorage
      });
    }
  };

  const installedPopup = () => {
    ReactGA.event({
      category: "PWA",
      action: "clicked Download",
      label: window.location.pathname, // Optional
    });
  };

  if (!showPrompt) {
    return null;
  }

  return (
    <div className="mx-auto max-w-screen bg-[#192347]  py-24  shadow-lg">
      {/* fixed bottom-4 mx-auto max-w-lg rounded-lg  shadow-lg  */}
      {/* <div className="fixed bottom-4 flex justify-center bg-white p-4 shadow-lg border rounded-lg mx-auto w-full max-w-lg">
        <div className="flex flex-col items-center  bg-green-50 p-4 text-white dark:text-slate-400 md:p-12">
          <div className="flex justify-between ">
            <p className="bg-green-50">a</p>
            <button
              onClick={() => {
                setShowPrompt(false);
              }}
              type="button"
              className={`text-black  bg-transparent hover:text-teal-500 rounded-lg text-sm pb-1.5 ml-auto inline-flex items-center`}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="inline-flex items-center">
            <svg
              className="mr-2 h-6 w-6 fill-current text-indigo-600 dark:text-indigo-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.838 11.03 15.55 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM480 352c0 17.6-14.4 32-32 32h-144.1c-6.928 0-13.67 2.248-19.21 6.406L192 460v-60c0-8.838-7.164-16-16-16H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32V352zM432 224h-160C263.2 224 256 231.2 256 240S263.2 256 272 256h160C440.8 256 448 248.8 448 240S440.8 224 432 224zM224 240C224 231.2 216.8 224 208 224h-128C71.16 224 64 231.2 64 240S71.16 256 80 256h128C216.8 256 224 248.8 224 240zM144 288h-32C103.2 288 96 295.2 96 304S103.2 320 112 320h32C152.8 320 160 312.8 160 304S152.8 288 144 288zM400 288h-32C359.2 288 352 295.2 352 304s7.156 16 16 16h32c8.844 0 16-7.156 16-16S408.8 288 400 288zM304 288h-96C199.2 288 192 295.2 192 304S199.2 320 208 320h96c8.844 0 16-7.156 16-16S312.8 288 304 288z" />
            </svg>

            <h2 className="text-indigo-600 dark:text-indigo-600 text-xl font-semibold">
              Install Prompt
            </h2>
          </div>
          <p className="mt-2 mb-2 max-w-2xl text-center text-white dark:text-indigo-600">
            Install this app on your device for a better experience.
          </p>
          <button
            className="rounded-lg bg-indigo-600 px-16 py-3 font-bold text-white "
            onClick={handleInstallClick}
          >
            Install
          </button>
        </div>
      </div> */}
      {/* <div className="fixed inset-0 z-50 bottom-4  w-full flex items-center justify-center">
        <div className="relative mx-8 shadow-xl rounded-md bg-white p-4   max-w-md w-full">
          <div className="flex justify-end">
            <button
              onClick={() => {
                setShowPrompt(false);
              }}
              type="button"
              className={`text-blue-400 bg-transparent hover:text-indigo-400 rounded-lg text-sm pb-1.5 ml-auto inline-flex items-center`}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="flex items-center justify-center">
            <svg
              className="mr-2 h-6 w-6 fill-current text-indigo-600 dark:text-indigo-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.838 11.03 15.55 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM480 352c0 17.6-14.4 32-32 32h-144.1c-6.928 0-13.67 2.248-19.21 6.406L192 460v-60c0-8.838-7.164-16-16-16H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32V352zM432 224h-160C263.2 224 256 231.2 256 240S263.2 256 272 256h160C440.8 256 448 248.8 448 240S440.8 224 432 224zM224 240C224 231.2 216.8 224 208 224h-128C71.16 224 64 231.2 64 240S71.16 256 80 256h128C216.8 256 224 248.8 224 240zM144 288h-32C103.2 288 96 295.2 96 304S103.2 320 112 320h32C152.8 320 160 312.8 160 304S152.8 288 144 288zM400 288h-32C359.2 288 352 295.2 352 304s7.156 16 16 16h32c8.844 0 16-7.156 16-16S408.8 288 400 288zM304 288h-96C199.2 288 192 295.2 192 304S199.2 320 208 320h96c8.844 0 16-7.156 16-16S312.8 288 304 288z" />
            </svg>

            <h2 className="text-indigo-600 dark:text-indigo-600 text-xl font-semibold">
              Install Prompt
            </h2>
          </div>
          <p className="mt-2 mb-2 max-w-2xl text-center text-white dark:text-indigo-600">
            Install this app on your device for a better experience.
          </p>
          <div className="flex justify-center">
            <button
              className="flex  justify-center rounded-lg bg-indigo-600 px-16 py-3 font-bold w-full text-white "
              onClick={handleInstallClick}
            >
              Install
            </button>
          </div>
        </div>
      </div> */}

      <div className="z-50 fixed  inset-0 w-full flex items-end justify-center ">
        {/* <div className="relative mx-2 shadow-xl rounded-md bg-none p-4  max-w-md w-full"> */}
        <div
          id="toast-success"
          className=" m-4 flex items-center w-full max-w-md p-4  text-gray-500 bg-[#D8EFD3] rounded-lg shadow dark:text-gray-400"
        >
          <div
            className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-[#EEF7FF] rounded-lg bg-[#028391]"
            onClick={handleInstallClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m-6 3.75 3 3m0 0 3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75"
              />
            </svg>
            <span className="sr-only">Download icon</span>
          </div>
          <div className="ml-3 text-md font-bold w-full text-[#55AD9B]">
            Click here to Install WebApp
          </div>
          <button
            type="button"
            className="ml-auto -mx-1.5 -my-1.5 font-bold  text-gray-400  rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:text-gray-600"
            data-dismiss-target="#toast-success"
            aria-label="Close"
            onClick={() => {
              setShowPrompt(false);
            }}
          >
            <span className="sr-only">Close</span>
            <svg
              className="w-3 h-3 font-semibold"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
              // strokeWidth={}
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
        </div>
        {/* </div> */}
      </div>

      {/* {isIOS() && (
        //   <div className="fixed bottom-4 right-4 bg-white p-4 shadow-lg border rounded-lg mx-4">
        //     <p className="mb-2">
        //       Install this app on your device for a better experience.
        //     </p>
        //     <div className="flex gap-x-4 justify-between">
        //       <button
        //         onClick={handleInstallClick}
        //         className="bg-blue-500 text-white px-4 py-2 rounded"
        //       >
        //         Install
        //       </button>
        //       <button
        //         onClick={() => {
        //           setShowPrompt(false);
        //         }}
        //         className="bg-red-500 text-white px-4 py-2 rounded"
        //       >
        //         cancel
        //       </button>
        //     </div>
        //   </div>
        // <div className="fixed bottom-0 left-0 right-0 p-4 bg-gray-800 text-white text-center">
        //   <p className="mb-2">
        //     To install the app, tap Share and then "Add to Home Screen".
        //   </p>
        //   <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
        //     Instructions
        //   </button>
        // </div>
      )} */}
    </div>
  );
};

export default InstallPrompt;
