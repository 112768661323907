import axios from "axios";
import fixtures from "../assets/fixtures.png";
import committeeImg from "../assets/committee_img.jpg";
import { useApp } from "../context/app-context";
import TeamLeaderBoard from "./teamLeaderBoard/TeamLeaderBoard";
import { useEffect, useState } from "react";
import LeagueInfoBanner from "./LeagueInfoBanner";

export default function Mainintro() {
  const [teamData, setTeamData] = useState();

  useEffect(() => {
    //console.log("hi");
    fetchTeamData();
  }, []);
  const fetchTeamData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/team`
      );
      const data = response.data;
      // const sortedTeams = data?.teams?.sort((a, b) => {
      //   const pointsA = a.wins * 3 + a.draws;
      //   const pointsB = b.wins * 3 + b.draws;
      // });

      // setTeamData(sortedTeams);
      // console.log(sortedTeams);
      sortFunc(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const sortFunc = (data) => {
    const sortedTeams = data?.teams?.sort((a, b) => {
      // Calculate points
      const pointsA = a.wins * 3 + a.draws;
      const pointsB = b.wins * 3 + b.draws;

      // Sort by points descending
      if (pointsB !== pointsA) {
        return pointsB - pointsA;
      }

      // If points are equal, sort by goal difference (GD) descending
      const gdDiff =
        b.goal_scored - b.goal_conceded - (a.goal_scored - a.goal_conceded);
      if (gdDiff !== 0) {
        return gdDiff;
      }

      // If goal difference is also equal, sort by goals scored descending
      if (b.goal_scored !== a.goal_scored) {
        return b.goal_scored - a.goal_scored;
      }

      // If goals scored are also equal, sort by goals conceded ascending
      return a.goal_conceded - b.goal_conceded;
    });

    setTeamData(sortedTeams);
    //console.log(sortedTeams);
  };

  return (
    <div className="relative isolate overflow-hiddenbg-gray-800 bg-gray-900 px-6  pb-2 lg:overflow-visible lg:px-0">
      <TeamLeaderBoard teamData={teamData} />
      <div className="absolute inset-0 -z-10 overflow-hidden">
        {/* <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        > */}
        <defs>
          <pattern
            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        {/* <svg x="50%" y={-1} className="overflow-visible fill-bg-gray-900">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
            strokeWidth={0}
          />
        </svg> */}
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
        />
        {/* </svg> */}
      </div>
      <div className="mx-auto mb-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-[#E1C564]  sm:text-4xl">
                Format & Fixtures
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-300">
                🎉 Get ready to kick off an unforgettable football extravaganza!
                🎉
              </p>
              <div className="max-w-xl mt-2 text-base leading-7 text-gray-300 lg:max-w-lg">
                <p>
                  The "Vagad Football League," comprising eight teams, each with
                  a squad upto sixteen players, features a format designed to
                  engage fans and showcase the talents of participating teams.
                  The league kicks off with a spirited league stage,
                  characterized by a round-robin format where each team faces
                  off against the others once. Points are awarded based on
                  performance, with victories securing three points, draws
                  earning one point, and no points awarded for losses. These
                  matches serve as a litmus test for teams, shaping their
                  rankings and setting the stage for the knockout rounds.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:-ml-12 lg:-mt-12 lg:p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 ">
          <img
            className="w-[28rem]   rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[37rem]"
            src={committeeImg}
            alt=""
          />
          <p className="text-center text-gray-300 font-bold text-3xl mt-4">
            Our Team
          </p>
        </div>
        {/* 
        <div className="lg:top-4 lg:col-start-2 lg:row-span-2 -ml-12 -mt-12 p-12 lg:sticky">
          <img
            className="w-[28rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[37rem]"
            src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            alt=""
            srcSet=""
          />
        </div> */}
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-300 lg:max-w-lg">
              {/* <p>
                The "Vagad League," comprising eight teams, each with a squad of
                fourteen players, features a format designed to engage fans and
                showcase the talents of participating teams. The league kicks
                off with a spirited league stage, characterized by a round-robin
                format where each team faces off against the others once. Points
                are awarded based on performance, with victories securing three
                points, draws earning one point, and no points awarded for
                losses. These matches serve as a litmus test for teams, shaping
                their rankings and setting the stage for the knockout rounds.
              </p> */}

              <h2 className="mt-2 text-2xl font-bold tracking-tight text-[#E1C564] ">
                A message form our team.
              </h2>
              <p className="mt-6">
                Hey there, football fanatics! Get your jerseys on and your game
                faces ready because we're about to dive headfirst into the
                ultimate football frenzy! As the organizing committee behind the
                most electrifying football league in town, we're pulling out all
                the stops to bring you a spectacle like no other. From
                heart-stopping goals to jaw-dropping saves, our league promises
                non-stop action, thrills, and spills that will keep you on the
                edge of your seat. Whether you're a die-hard supporter or a
                casual observer, get ready to experience the magic of football
                like never before. So, grab your popcorn, rally your squad, and
                join us for a football extravaganza that will have you cheering,
                chanting, and celebrating the beautiful game in style! See you
                on the field! ⚽🏆
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <LeagueInfoBanner />
      </div>
    </div>
  );
}
