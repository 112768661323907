import React from "react";
import Option1 from "./options/Option1";
import Option2 from "./options/Option2";

function Community() {
  return (
    <>
      {/* <Option1 /> */}
      <Option2 />
    </>
  );
}

export default Community;
