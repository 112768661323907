import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import va from "../assets/VA.jpg";
import cc from "../assets/Charla.jpg";
import new_cc from "../assets/Carron_team_logo.png";
import TeamLeaderBoard from "../components/teamLeaderBoard/TeamLeaderBoard";
import UploadCsv from "../components/uploadCsv.js/UploadCsv";
import axios from "axios";
import ReactGA from "react-ga4";

// const teams = [
//   {
//     id: 1,
//     name: "Vagad Avengers",
//     photoUrl: va,
//     year: 2021,
//   },
//   {
//     id: 2,
//     name: "Vagad Avengers",
//     photoUrl: va,
//     year: 2021,
//   },
//   {
//     id: 3,
//     name: "Vagad Avengers",
//     photoUrl: va,
//     year: 2021,
//   },
//   {
//     id: 4,
//     name: "Vagad Avengers",
//     photoUrl: va,
//     year: 2021,
//   },
//   {
//     id: 5,
//     name: "Vagad Avengers",
//     photoUrl: va,
//     year: 2021,
//   },
//   {
//     id: 6,
//     name: "Vagad Avengers",
//     photoUrl: va,
//     year: 2021,
//   },
//   {
//     id: 7,
//     name: "Vagad Avengers",
//     photoUrl: va,
//     year: 2021,
//   },
//   {
//     id: 8,
//     name: "Vagad Avengers",
//     photoUrl: va,
//     year: 2021,
//   },
//   {
//     id: 9,
//     name: "Charla Challengers",
//     photoUrl: cc,
//     year: 2022,
//   },
//   {
//     id: 10,
//     name: "Charla Challengers",
//     photoUrl: cc,
//     year: 2022,
//   },
//   {
//     id: 11,
//     name: "Charla Challengers",
//     photoUrl: cc,
//     year: 2022,
//   },
//   {
//     id: 12,
//     name: "Charla Challengers",
//     photoUrl: cc,
//     year: 2022,
//   },
//   {
//     id: 13,
//     name: "Charla Challengers",
//     photoUrl: cc,
//     year: 2022,
//   },
//   {
//     id: 14,
//     name: "Charla Challengers",
//     photoUrl: cc,
//     year: 2022,
//   },
//   {
//     id: 15,
//     name: "Charla Challengers",
//     photoUrl: cc,
//     year: 2022,
//   },
//   {
//     id: 16,
//     name: "Charla Challengers",
//     photoUrl: cc,
//     year: 2022,
//   },
// ];

// Not optimized for big screen view

function Teams() {
  const [teamData, setTeamData] = useState();
  const cardRefs = useRef([]);
  const teamsByYear = {};

  useEffect(() => {
    //console.log("hi");
    window.scrollTo(0, 0);

    fetchTeamData();
  }, []);

  // useEffect(() => {
  // teamData?.forEach((team) => {
  //   if (!teamsByYear[team.year]) {
  //     teamsByYear[team.year] = [];
  //   }
  //   teamsByYear[team.year].push(team);
  // });
  // console.log(teamsByYear);
  // }, []);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop =
  //       window.pageYOffset || document.documentElement.scrollTop;

  //     // Calculate which card is currently in view
  //     let currentCardIndex = null;
  //     cardRefs.current.forEach((ref, index) => {
  //       if (ref.offsetTop <= scrollTop + window.innerHeight / 2) {
  //         currentCardIndex = index;
  //       }
  //     });

  //     // Update the hovered index
  //     setHoveredIndex(currentCardIndex);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const fetchTeamData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/team`
      );
      const data = response.data;
      // For each pair of objects a and b in the array, the expression b.wins * 3 + b.draws - a.wins * 3 - a.
      // const sortedTeams = data?.teams?.sort(
      //   (a, b) => b.wins * 3 + b.draws - a.wins * 3 - a.draws
      // ); // im sorting data according to wins
      // setTeamData(sortedTeams);
      // console.log(sortedTeams);
      sortFunc(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const sortFunc = (data) => {
    const sortedTeams = data?.teams?.sort((a, b) => {
      // Calculate points
      const pointsA = a.wins * 3 + a.draws;
      const pointsB = b.wins * 3 + b.draws;

      // Sort by points descending
      if (pointsB !== pointsA) {
        return pointsB - pointsA;
      }

      // If points are equal, sort by goal difference (GD) descending
      const gdDiff =
        b.goal_scored - b.goal_conceded - (a.goal_scored - a.goal_conceded);
      if (gdDiff !== 0) {
        return gdDiff;
      }

      // If goal difference is also equal, sort by goals scored descending
      if (b.goal_scored !== a.goal_scored) {
        return b.goal_scored - a.goal_scored;
      }

      // If goals scored are also equal, sort by goals conceded ascending
      return a.goal_conceded - b.goal_conceded;
    });

    setTeamData(sortedTeams);
    //console.log(sortedTeams);
  };

  const openedTeam = (team) => {
    ReactGA.event({
      category: "Team",
      action: "clicked on team card new",
      label: team?.name, // Optional
    });
  };

  return (
    <>
      <div className="overflow-hiddenbg-gray-800 bg-[#111827] py-24   lg:px-0">
        {/* <div className=" bg-[#192347]"> */}

        {/* <div className="grid w-[20rem] grid-cols-2 gap-2 rounded-xl bg-[#192347] - bg-opacity-50 p-2 mx-auto mt-5 ">
          <div>
            <input
              type="radio"
              name="option"
              id="1"
              value="1"
              className="peer hidden"
              defaultChecked
            />
            <label
              htmlFor="1"
              className="block cursor-pointer text-indigo-600 font-bold select-none rounded-lg p-2 text-center peer-checked:bg-indigo-600 peer-checked:font-bold peer-checked:text-white"
            >
              2023
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="option"
              id="2"
              value="2"
              className="peer hidden"
            />
            <label
              htmlFor="2"
              className="block cursor-pointer font-bold  text-indigo-600 select-none rounded-lg p-2 text-center peer-checked:bg-indigo-600 peer-checked:font-bold peer-checked:text-white"
            >
              2024
            </label>
          </div>
        </div> */}
        <div className="grid w-[20rem] rounded-md bg-[#192347] - bg-opacity-0 p-2 mx-auto mt-5 ">
          <div className="block  rounded-md p-2 text-center bg-[#003285] font-bold text-lg text-white">
            2024
          </div>
        </div>
        <TeamLeaderBoard teamData={teamData} />
        <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 md:gap-10  gap-4 p-4 mt-10 bg-transparent">
          {/* {teams.map((team, index) => (
          <div
            key={index}
            ref={(el) => (cardRefs.current[index] = el)}
            className="relative border border-gray-300 rounded-lg overflow-hidden "
          >
            <NavLink to={`/teams/${team.name}`}>
              <img
                src={team.photoUrl}
                alt={team.name}
                className="w-full h-40 object-cover"
              />

              <div className=" inset-0 bg-transparent  flex justify-center  items-end">
                <p className="py-2 text-center font-bold uppercase tracking-wide text-gray-800">
                  {team.name && team.name.length > 0
                    ? team.name
                    : "No category"}
                </p>
              </div>

              <div className="absolute inset-0 flex items-center justify-center">
                <button className="bg-transparent w-full text-white px-4 py-2 absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 hover:text-indigo-500 ">
                  {/* <NavLink to={`/${team.id}`}>{team.name}</NavLink> 
                </button>
              </div>
            </NavLink>
          </div>
        ))} */}
          {/* <div className="flex flex-col justify-center items-center max-w-sm mx-auto my-8"> */}
          {teamData?.map((team, index) => (
            <div
              key={index}
              ref={(el) => (cardRefs.current[index] = el)}
              className="relative "
            >
              <NavLink to={`/teams/${team.name}/null`}>
                <div
                  className="flex flex-col  items-center"
                  onClick={() => {
                    openedTeam(team);
                  }}
                >
                  {/* <div
                  style={{
                    backgroundImage: `url(${team.team_logo})`,
                  }}
                  // className="bg-white h-64 w-full rounded-lg shadow-md bg-contain bg-center bg-no-repeat "
                  // className="bg-white h-96 md:h-80 w-full rounded-lg shadow-md bg-cover bg-center "
                  // className="bg-white h-96 md:h-80 w-full rounded-lg shadow-md bg-cover bg-center "

                ></div> */}
                  {/* <div className="w-56 md:w-64 bg-white -mt-6 shadow-lg rounded-lg overflow-hidden">
                  <div className="py-2 text-center font-bold uppercase tracking-wide text-gray-800">
                    {team.name && team.name.length > 0
                      ? team.name
                      : "No category"}
                  </div>
                </div> */}
                  <img
                    src={team.team_logo}
                    // width={360}
                    alt="logo"
                    className=" hover:-translate-1 hover:scale-105 rounded-md object-cover w-[90%] sm:w-full shadow-xl - shadow-sky-600 h-full my-4"
                  />
                </div>
              </NavLink>
            </div>
          ))}
          {/* <div className="relative ">
          <div className="flex flex-col justify-center items-center ">
            <div
              style={{
                backgroundImage: `url(${new_cc})`,
              }}
              className=" h-64 w-full rounded-lg shadow-md bg-cover bg-center"
            ></div>
          </div>
        </div> */}
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default Teams;
