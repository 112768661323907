import React from "react";
import { NavLink } from "react-router-dom";

function CommunityNav() {
  return (
    <div className=" bg-neutral-900 w-full text-center py-4">
      <NavLink to="community" className=" text-lg font-bold  text-indigo-600">
        CommunityNav
      </NavLink>
    </div>
  );
}

export default CommunityNav;
