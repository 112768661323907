import React from "react";
import Tabs from "../components/tabs/Tabs";

function AdminPage() {
  return (
    <>
      <Tabs />
    </>
  );
}

export default AdminPage;
