import React, { useEffect, useState } from "react";
import OthersFantasyTeam from "../components/othersFantasyTeam/OthersFantasyTeam";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useApp } from "../context/app-context";

function ViewOthersFantasy() {
  const { userId, fantasyIndex } = useParams();
  const [user, setUser] = useState();
  const [team, setTeam] = useState();
  const [currentWeek, setCurrentWeek] = useState(0);
  const [pointsWeekly, setPointsWeekly] = useState();
  useEffect(() => {
    const access = JSON.parse(localStorage.getItem("accessToken"));
    // getProfile("http://localhost:8000/api/user/getProfile", access);
    if (userId !== undefined) {
      userProfileFunc();
    }
  }, []);
  // ----------switch getProfile with get profile func to get team of ither users
  // async function getProfile(url, access) {
  //   try {
  //     const response = await axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${access}`,
  //       },
  //     });
  //     console.log("GET profile request successful");
  //     console.log(response.data);
  //     setUser(response.data);
  //     getWeekNumber(response.data);
  //   } catch (error) {
  //     console.error("Error making GET profile request:", error);
  //   }
  // }
  const userProfileFunc = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${userId}`
      );
      const data = response.data;
      setUser(data);
      //console.log(data);
      // getWeekNumber(data);
      extractFantasy(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  // const getWeekNumber = (userData) => {
  //   // Define the start date of the league
  //   const startDate = new Date("2024-5-14"); // Assuming the year is 2024

  //   // Get today's date
  //   const today = new Date();

  //   // Calculate the difference in days between today's date and the start date
  //   const diffInDays = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));

  //   // Calculate the week number by dividing the difference in days by 7 and rounding up
  //   const weekNum = Math.ceil(diffInDays / 7);
  //   console.log(weekNum);
  //   // setCurrentWeek(weekNum);
  // };
  const extractFantasy = (userData) => {
    // fantasy extract process below -------------------------------
    const num = parseInt(fantasyIndex, 10);
    //console.log(num);
    setCurrentWeek(num);

    const fantasyTeamWeek = userData?.fantasyTeam?.find(
      (team) => team.week === num
    );
    const points = fantasyTeamWeek ? fantasyTeamWeek.points : "No";
    setPointsWeekly(points);
    const captainId = fantasyTeamWeek?.captain?._id;
    const viceCaptainId = fantasyTeamWeek?.viceCaptain?._id;

    //console.log("fantasyTeamWeek4", fantasyTeamWeek);
    const categorizedPlayers = {
      fw: [],
      cm: [],
      df: [],
      gk: [],
    };
    // checking user team
    fantasyTeamWeek?.players?.forEach((player) => {
      let role = "normal"; // Default role
      let pointsWeekly = 0;
      // console.log(player.fantasyPoints.length);
      const weeklyPoints = player?.fantasyPoints?.find(
        (match) => match.week == num
      );
      if (weeklyPoints === undefined) {
        pointsWeekly = 0;
      } else {
        //console.log(player.fantasyPoints);

        pointsWeekly = weeklyPoints.points;
        // alert(pointsWeekly);
      }
      if (captainId === player._id) {
        role = "captain";
        pointsWeekly *= 2;
      } else if (viceCaptainId === player._id) {
        role = "vice-captain";
        pointsWeekly *= 1.5;
      }
      const posn = player.position.split(",")[0].trim();

      const temp = {
        id: player._id,
        name: player.name,
        photo: player.photo,
        playerPosition: posn,
        role: role,
        cost: player?.cost,
        points: pointsWeekly,
      };
      switch (posn) {
        case "Attacker":
          categorizedPlayers.fw.push(temp);
          break;
        case "Midfielder":
          categorizedPlayers.cm.push(temp);
          break;
        case "Defender":
          categorizedPlayers.df.push(temp);
          break;
        case "Goalkeeper":
          categorizedPlayers.gk.push(temp);
          break;
        default:
          break;
      }
    });
    //console.log(categorizedPlayers);
    const updatedTeams = {
      squad: categorizedPlayers,
    };
    //console.log("updatedTeams", updatedTeams);
    setTeam(updatedTeams);
  };

  return (
    <>
      <div className="mx-auto max-w-screen bg-[#192347] px-4 py-24   sm:px-6 lg:px-8 shadow-lg">
        <div className="flex flex-col justify-center items-center">
          <div className=" w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#55AD9B] rounded-lg  shadow-lg pt-2 pb-5 px-2">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div
              align="center"
              className="text-white font-extrabold text-2xl mt-3"
            >
              {/* Season Points : {user?.seasonPoints[0].points} */}
              {/* {currentWeek !== 0 ? WeekPoints : "0"} */}
              WEEK {currentWeek}
            </div>
          </div>
          <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#028391] rounded-t-lg  shadow-lg pt-2 pb-5 px-2 mt-4">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div align="center" className="text-white font-extrabold text-2xl">
              {user?.username}'s Team
            </div>
          </div>
          <div className="w-full">
            {team !== undefined && <OthersFantasyTeam team={team} />}
          </div>
          <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#028391] rounded-b-lg  shadow-lg pt-4 pb-5 px-2">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div align="center" className="text-white font-extrabold text-xl">
              {/* Season Points : {user?.seasonPoints[0].points} */}
              POINTS :{" "}
              {/*logic for grtting last element points ----> {user?.fantasyTeam[user.fantasyTeam.length - 1]?.points} */}
              {pointsWeekly}
            </div>
            {user?.captainCard?.usedWeek === currentWeek ||
            user?.extraPlayerCard?.usedWeek === currentWeek ? (
              <div className="flex flex-col  p-2 ">
                <div className="col-span-1 md:col-span-2  mt-4  mb-2">
                  <div className="flex-col">
                    <div className="flex gap-x-4">
                      {user?.captainCard?.usedWeek === currentWeek &&
                        user?.captainCard?.used === true && (
                          <button
                            className="w-full  relative overflow-hidden  rounded-lg  shadow-lg  px-2 py-4 
                        
                           bg-[#343232]"
                          >
                            <svg
                              className="absolute bottom-0 left-0 mb-8"
                              viewBox="0 0 375 283"
                              fill="none"
                              style={{
                                transform: "scale(1.5)",
                                opacity: "0.1",
                              }}
                            >
                              <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                              />
                              <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                              />
                              <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                                fill="white"
                              />
                            </svg>

                            <p
                              align="center"
                              className="text-white font-bold text-2xl px-4 ${
                          py-1"
                            >
                              3X Captain
                            </p>

                            <p className="text-[#E72929] font-bold text-lg text-shadow">
                              CARD USED
                            </p>
                          </button>
                        )}

                      {user?.extraPlayerCard?.usedWeek === currentWeek &&
                        user?.extraPlayerCard?.used === true && (
                          <button
                            className="w-full  relative overflow-hidden  rounded-lg  shadow-lg  px-2 py-4 
                        
                           bg-[#343232]"
                          >
                            <svg
                              className="absolute bottom-0 left-0 mb-8"
                              viewBox="0 0 375 283"
                              fill="none"
                              style={{
                                transform: "scale(1.5)",
                                opacity: "0.1",
                              }}
                            >
                              <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                              />
                              <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                              />
                              <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                                fill="white"
                              />
                            </svg>

                            <p
                              align="center"
                              className="text-white font-bold text-2xl px-4 ${
                          py-1"
                            >
                              Extra Player
                            </p>

                            <p className="text-[#E72929] font-bold text-lg text-shadow">
                              CARD USED
                            </p>
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="mt-5 w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#E1C564] rounded-lg  shadow-lg pt-2 pb-5 px-2">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div
              align="center"
              className="text-white font-extrabold text-2xl mt-3"
            >
              {/* Season Points : {user?.seasonPoints[0].points} */}
              {/* {currentWeek !== 0 ? WeekPoints : "0"} */}
              Season Points : {user?.seasonPoints[0]?.points}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewOthersFantasy;
