import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import redCardImg from "../assets/redCard.png";
import yellowRedCardImg from "../assets/yellowRedCard.png";
import goalsImg from "../assets/scorer.png";
import assistImg from "../assets/assist.png";
import DummyImg from "../assets/user.png";
import pointsIcon from "../assets/pointsIcon.png";
// import cleenSheetImg from "../assets/goalkeeper.png";
// import cleenSheetImg from "../assets/goalieSave.png";
import cleenSheetImg from "../assets/goal-post.png";

import axios from "axios";
import PlayerModal from "../components/PlayerModal";

// const people = [
//   {
//     name: "Leslie Alexander",
//     position: "Center Forward (CF)",
//     role: "Player",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     lastInjured: "3h ago",
//     lastInjuredDateTime: "2023-01-23T13:23Z",
//   },
//   {
//     name: "Michael Foster",
//     position: "Center Back (CB)",
//     role: "Player",
//     imageUrl:
//       "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     lastInjured: "3h ago",
//     lastInjuredDateTime: "2023-01-23T13:23Z",
//   },
//   {
//     name: "Dries Vincent",
//     position: "Center Back (CB)",
//     role: "Player",
//     imageUrl:
//       "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     lastInjured: null,
//   },
//   {
//     name: "Lindsay Walton",
//     position: "Mid-fielder (MF)",
//     role: "Player",
//     imageUrl:
//       "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     lastInjured: "3h ago",
//     lastInjuredDateTime: "2023-01-23T13:23Z",
//   },
//   {
//     name: "Courtney Henry",
//     position: "Mid-fielder (MF)",
//     role: "Captain",
//     imageUrl:
//       "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     lastInjured: "3h ago",
//     lastInjuredDateTime: "2023-01-23T13:23Z",
//   },
//   {
//     name: "Tom Cook",
//     position: "Goal Keeper (GK)",
//     role: "Player",
//     imageUrl:
//       "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     lastInjured: null,
//   },
// ];

function EachTeam() {
  // const val = "teams";
  const [eachteamData, setEachTeamData] = useState();
  const { teamName, topPlayerId } = useParams();
  const [showDetails, setShowDetails] = useState(false);
  const [thisTeamData, setThisTeamData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [team_logo, setTeam_logo] = useState(DummyImg);

  // const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const detailsTopPlayer = useRef(null); // Ref to access the <details> element
  const targetRefTopPlayer = useRef(null);

  const scrollToTargetTopPlayer = () => {
    const targetPosition =
      targetRefTopPlayer.current.getBoundingClientRect().top +
      window.pageYOffset;
    const offsetPosition =
      targetPosition -
      window.innerHeight / 2 +
      targetRefTopPlayer.current.clientHeight / 2;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    detailsTopPlayer.current.open = true;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // if (topPlayerId !== "null") {
    //   scrollToTargetTopPlayer();
    // } else {
    //   console.log("topplayer: ", topPlayerId);
    // }
    console.log(teamName);
    fetchData();

    fetchThisTeamData();
  }, [teamName, topPlayerId]);

  const fetchData = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/player?team=${encodeURIComponent(
        teamName
      )}&limit=1000`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setEachTeamData(data);
        //console.log(data);
        if (topPlayerId !== "null") {
          scrollToTargetTopPlayer();
        } else {
          console.log("topplayer: ", topPlayerId);
        }
      })
      .catch((error) => console.error("Fetch error:", error));
  };

  const fetchThisTeamData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/team/?name=${teamName}`
      );
      // converts array to an object
      const [data] = response.data.teams;
      setThisTeamData(data);
      //console.log(data);
      // console.log(response.data.teams.team_logo);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const openPhotoModalFunc = (photo) => {
    setIsModalOpen(true);
    setTeam_logo(photo);
  };

  const playerPoints = (prop) => {
    if (prop?.length === 0) {
      {
        return 0;
      }
    } else {
      return prop[0].points;
    }
    // console.log(prop);
  };
  return (
    <>
      <div className="  px-6 py-24  lg:px-10 flex-shrink-0 relative overflow-hidden bg-teal-500 ">
        <PlayerModal
          isOpen={isModalOpen}
          onClose={closeModal}
          team_logo={team_logo}
        />

        <ul role="list" className="  md:p-5">
          <div className="flex-shrink-0 relative overflow-hidden bg-indigo-500 rounded-lg  shadow-lg pt-2 pb-5 px-2 my-8">
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <img
              src={thisTeamData?.team_logo}
              className="w-full md:hidden rounded-lg"
              width={100}
              height={100}
              onClick={() => setShowDetails(true)}
            />
            {/* <div className="relative pt-10 px-10 flex items-center justify-center"> */}

            <div
              align="center"
              className="text-white font-extrabold text-2xl mt-5"
            >
              {teamName}
            </div>

            {/* <div className="text-center text-green-600">
                      {match.time}
                    </div> */}
          </div>
          <div
            className="flex-shrink-0 relative overflow-hidden bg-indigo-900 rounded-t-lg  shadow-lg pt-2 pb-5 px-2 mt-4"
            onClick={() => scrollToTargetTopPlayer()}
          >
            <svg
              className="absolute bottom-0 left-0 mb-8"
              viewBox="0 0 375 283"
              fill="none"
              style={{ transform: "scale(1.5)", opacity: "0.1" }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                fill="white"
              />
            </svg>
            <div align="center" className="text-white font-extrabold text-2xl">
              Squad ({eachteamData?.players?.length} players)
            </div>
          </div>
          {eachteamData?.players?.map((person) => (
            <li
              key={person.id}
              className="flex w-full py-2"
              ref={person._id == topPlayerId ? targetRefTopPlayer : null}
            >
              <details
                className="group w-full"
                ref={person._id == topPlayerId ? detailsTopPlayer : null}
              >
                <summary className="flex items-center justify-between  p-2 font-medium w-full  py-5 group relative cursor-pointer overflow-hidden bg-white px-4  shadow-xl  transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl mx-auto rounded-lg">
                  <div
                    className="flex justify-between w-full"
                    onClick={() => setShowDetails(true)}
                  >
                    <div className="flex gap-5">
                      <ImageWithFallback
                        src={person?.photo}
                        fallbackSrc={DummyImg}
                        onClick={() => openPhotoModalFunc(person?.photo)}
                      />

                      <div className="min-w-0 flex-auto">
                        <p className="text-xl font-semibold leading-6 text-gray-900">
                          {person?.name}
                        </p>
                        <p className="mt-1 truncate text-sm leading-5 text-indigo-600">
                          {person?.position}
                        </p>
                      </div>
                    </div>
                  </div>
                  <svg
                    className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    ></path>
                  </svg>
                </summary>
                <article className="mt-1 pb-4 ">
                  <ul className=" flex flex-col gap-2 px-4  p-2 font-medium w-full  py-5 group relative cursor-pointer overflow-hidden bg-white px-4  shadow-xl duration-300 hover:shadow-2xl mx-auto rounded-lg">
                    {person.cleanSheets?.length !== 0 && (
                      <li className="flex">
                        <img
                          src={cleenSheetImg}
                          width={30}
                          className="ml-2 mr-7"
                        />
                        <p className="mt-1 truncate text-md leading-5 font-semibold">
                          Clean sheets : {person?.cleanSheets}
                        </p>
                      </li>
                    )}
                    <li className="flex ">
                      <img src={goalsImg} width={30} className="ml-2 mr-7" />
                      <p className="mt-1 truncate text-md leading-5 font-semibold">
                        Goals Scored : {person?.goal_scored}{" "}
                      </p>
                    </li>
                    <li className="flex">
                      <img src={assistImg} width={30} className="ml-2 mr-7" />
                      <p className="mt-1 truncate text-md leading-5 font-semibold">
                        Assists : {person?.assists}
                      </p>
                    </li>

                    <li className="flex">
                      <img
                        src={yellowRedCardImg}
                        width={30}
                        className="ml-2 mr-7"
                      />
                      <p className="mt-1 truncate text-md leading-5 font-semibold">
                        Yellow Card : {person?.yellowCard}
                      </p>
                    </li>
                    <li className="flex">
                      <img
                        src={yellowRedCardImg}
                        width={30}
                        className="ml-2 mr-7"
                      />
                      <p className="mt-1 truncate text-md leading-5 font-semibold">
                        Red card : {person?.redCard}
                      </p>
                    </li>
                    <li className="flex">
                      <img src={pointsIcon} width={30} className="ml-2 mr-7" />
                      <p className="mt-1 truncate text-md leading-5 font-semibold">
                        Player Points : {playerPoints(person?.totalPoints)}
                      </p>
                    </li>
                  </ul>
                </article>
              </details>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default EachTeam;

function ImageWithFallback({ src, fallbackSrc, ...props }) {
  const [imgSrc, setImgSrc] = useState(src);

  const onError = () => {
    setImgSrc(fallbackSrc);
  };

  return (
    <div className="relative">
      <img
        src={imgSrc}
        onError={onError}
        alt="Player img"
        className="h-12 w-12 flex-none rounded-full bg-gray-50"
        {...props}
      />
    </div>
  );
}
