import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { AppProvider } from "./context/app-context";
import Home from "./pages/Home";
import Teams from "./pages/Teams";
import Matches from "./pages/Matches";
import EachTeam from "./pages/EachTeam";
import EachMatch from "./pages/EachMatch";
import PrivateRoute from "./routing/PrivateRoute";
import AdminPage from "./pages/AdminPage";
import LoginPage from "./pages/LoginPage";
// import swDev from "./serviceWorkerCustom";
import ProtectedRoute from "./routing/ProtectedRoute";
import Community from "./pages/Community";
import FantasyPage from "./pages/fantasyPage/FantasyPage";
import SignUp from "./pages/SignUp";
import PublicFantasy from "./pages/PublicFantasy";
import ViewOthersFantasy from "./pages/ViewOthersFantasy";
import ProtectedRouteAdmin from "./routing/ProtectedRouteAdmin";
import NotFound from "./pages/NotFound";
import ReactGA from "react-ga4";
// Initialize React Ga with your tracking ID
// ReactGA.initialize("G-KXEY1J33SH");

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     children: [
//       {
//         path: "",
//         element: <Home />,
//       },
//       {
//         path: "teams",
//         element: <Teams />,
//       },
//       {
//         path: "matches",
//         element: <Matches />,
//       },
//     ],
//   },
// ]);
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<Home />} />
      <Route path="teams" element={<Teams />} />
      <Route path="matches" element={<Matches />} />
      {/* <Route path="admin" element={<AdminPage />} /> */}
      <Route
        exact
        path="/teams/:teamName/:topPlayerId"
        element={<EachTeam />}
      />
      <Route exact path="/matches/:matchId" element={<EachMatch />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="signUp" element={<SignUp />} />
      <Route
        path="admin"
        element={<ProtectedRouteAdmin PropPassed={AdminPage} />}
      />
      <Route path="community" element={<Community />} />
      <Route
        path="fantasy"
        element={<ProtectedRoute PropPassed={FantasyPage} />}
      />
      <Route path="publicFantasy" element={<PublicFantasy />} />
      <Route
        path="viewOthersFantasy/:userId/:fantasyIndex"
        element={<ViewOthersFantasy />}
      />
      {/* <Route loader={githubInfoLoader} path="github" element={<Github />} /> */}
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// swDev();
