import React, { useEffect, useState } from "react";

import vagad from "../assets/vagad.png";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useApp } from "../context/app-context";

function Navbar() {
  // const { backgroundColorTeam, setBackgroundColorTeam, login } = useApp();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [navigation, setNavigation] = useState([
    { displayName: "Home", name: "home", path: "/" },
    { displayName: "Teams", name: "teams", path: "teams" },
    { displayName: "Matches", name: "matches", path: "matches" },
    { displayName: "Login", name: "login", path: "login" },
  ]);

  const [check, setCheck] = useState(false);

  useEffect(() => {
    // const storedUser = JSON.parse(localStorage.getItem("loginCheck"));
    // console.log(storedUser);

    if (check) {
      // Create a new array with the updated items
      const updatedNavigation = [
        { displayName: "Home", name: "home", path: "/" },
        { displayName: "Teams", name: "teams", path: "teams" },
        { displayName: "Matches", name: "matches", path: "matches" },
        { displayName: "Login", name: "login", path: "login" },
        { displayName: "Admin", name: "admin", path: "admin" },
      ];
      // Set the state with the new array
      setNavigation(updatedNavigation);
    }
  }, [check]);
  //   const filteredArray = navigation.filter((item) => {
  //     return item.name !== props.hide;
  //   });

  //   console.log(filteredArray);
  //   console.log(props.hide);

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50">
        {/* <header className="relative inset-x-0 top-0 z-50">  if you need a partition between component and nvbar*/}
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-10 w-auto rounded-full" src={vagad} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden ">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-blue-900 font-bold bg-slate-900 bg-opacity-100"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation?.map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                // className="text-lg font-semibold leading-6 text-gray-900"
                className={({ isActive }) =>
                  ` text-xl font-bold ont-semibold block py-2 pr-4 pl-3 duration-200 ${
                    isActive ? "text-indigo-500" : "text-gray-400"
                  } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0`
                }
              >
                {item.displayName}
              </NavLink>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {/* <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </a> */}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-10 w-auto rounded-full" src={vagad} alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation?.map((item, index) => (
                    <NavLink
                      key={index}
                      to={item.path}
                      className={({ isActive }) =>
                        `text-xl font-bold  block py-2 pr-4 pl-3 duration-200 ${
                          isActive ? "text-indigo-700" : "text-gray-900"
                        } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0`
                      }
                      onClick={() => setMobileMenuOpen(false)} // Close the mobile menu on click
                    >
                      {item.displayName}
                    </NavLink>
                  ))}
                </div>
                <div className="py-6">
                  {/* <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a> */}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
}

export default Navbar;
