import React, { useEffect, useState } from "react";
import LineUp from "../liveChangescomponent/LineUp";
import { useApp } from "../../context/app-context";
import axios from "axios";
import MatchCarousel from "../carousel/MatchCarousel";
import MatchUpdates from "../liveChangescomponent/MatchUpdates";
import LineupForAnyMatch from "../liveChangescomponent/LineupForAnyMatch";
import EndLiveMatch from "../liveChangescomponent/EndLiveMatch";
import MatchScoreCard from "../MatchScoreCard";
import CheckMatchUpdates from "../liveChangescomponent/CheckMatchUpdates";
import matchWeeks from "../../data/MatchData";

function LiveChanges() {
  const {
    // activeTab,
    setActiveTab,
    // MatchThisWeek,
    setMatchThisWeek,
    liveMatch,
    setLiveMatch,
    liveLineup,
    setLiveLineup,
  } = useApp();

  const [nonLiveUploaded, setnonLiveUploaded] = useState(false);
  const [error, setError] = useState(null);
  const [leagueEnded, setLeagueEnded] = useState(false);

  useEffect(() => {
    setnonLiveUploaded(true);
  }, []);
  const gotoNonLive = () => {
    setActiveTab("tab1"); // Assuming "tab1" is the ID of the tab you want to navigate to
  };
  //
  useEffect(() => {
    // getAllMatchData(`${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`);
    demo();

    // getWeekNumber();
  }, []);

  // const getWeekNumber = () => {
  //   // Define the start date of the league
  //   const startDate = new Date("2024-06-15"); // Assuming the year is 2024
  //   // Get today's date
  //   const today = new Date();
  //   // Calculate the difference in days between today's date and the start date
  //   const diffInDays = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));
  //   // Calculate the week number by dividing the difference in days by 7 and rounding up
  //   let weekNum = 0;
  //   if (diffInDays <= 0) {
  //     weekNum = 1;
  //   } else {
  //     weekNum = Math.ceil(diffInDays / 7);
  //   }
  //   getThisWeekMatchData(
  //     `${process.env.REACT_APP_SERVER_URL}/api/match?week=${weekNum}`
  //   );
  // };
  const demo = () => {
    const getCurrentWeek = (date) => {
      for (let i = 0; i < matchWeeks.length; i++) {
        const { week, startDate, endDate, adminDate } = matchWeeks[i];
        if (date >= startDate && date <= adminDate) {
          return week;
        }
        //
        const end_l = matchWeeks[matchWeeks?.length - 1];
        console.log(end_l.endDate);
        if (date > end_l.endDate) {
          return 9;
        }
        //
      }
      return null; // If the date doesn't fall within any of the predefined ranges
    };

    function floorDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    const currentDate = new Date();
    const flooredDate = floorDate(currentDate);
    const weekNumber = getCurrentWeek(flooredDate);
    getAllMatchDataLoad(
      `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`,
      weekNumber
    );
  };
  async function getAllMatchDataLoad(url, weekNumber) {
    // let weekNum = 1;
    try {
      const response = await axios.get(url);
      console.log(response.data);

      const matches = response.data.matches;
      const weeklyMatches = matches.filter(
        (match) => match.week === weekNumber
      );
      console.log("match this week : ", weeklyMatches);
      setMatchThisWeek(weeklyMatches);
      // if (weeklyMatches.length === 0) {
      //   setLeagueEnded(true);
      // }
      //check for live match
      const liveMatch = weeklyMatches.find((match) => match.live === true);
      console.log("livematch : ", liveMatch);
      setLiveMatch(liveMatch);
      // alert(liveMatch);

      if (liveMatch) {
        liveMatch.lineup1 && liveMatch.lineup2
          ? setLiveLineup(true)
          : setLiveLineup(null);
      }
      // check for lineup of live match

      console.log("match this week : ", weeklyMatches);
    } catch (error) {
      console.error("Error making GET match request:", error);
    }
  }

  async function getAllMatchData(url) {
    let weekNum = 1;
    try {
      const response = await axios.get(url);
      console.log(response.data);
      const sortedMatches = response.data.matches.sort(
        (a, b) => a.week - b.week
      );
      const dates = sortedMatches.map((match) => match.date);
      const uniqueDates = [...new Set(dates)];
      console.log(uniqueDates);

      const convertToDate = (dateString) => {
        const [day, month, year] = dateString.split("-").map(Number);
        // Assuming year is 20YY, convert to 19YY if year < 50, else convert to 20YY
        const fullYear = year < 50 ? 2000 + year : 1900 + year;
        return new Date(fullYear, month - 1, day); // month is zero-based
      };

      const matchWeekDates = uniqueDates.map(convertToDate);

      console.log(matchWeekDates);

      const today = new Date("2024-06-20"); // Get today's date

      const getCurrentMatchWeek = (currentDate, matchWeekDates) => {
        for (let index = 0; index < matchWeekDates.length; index++) {
          const element = matchWeekDates[index];
          console.log(element);
          console.log(currentDate);
          if (currentDate < element) {
            console.log(currentDate < element);
            return index;
          }
        }
        const last = matchWeekDates[matchWeekDates.length - 1];
        const diffInDays = Math.ceil((today - last) / (1000 * 60 * 60 * 24));
        console.log(diffInDays);

        if (diffInDays <= 7) {
          return matchWeekDates.length;
        }
        return matchWeekDates.length + 10;
      };

      weekNum = getCurrentMatchWeek(today, matchWeekDates);
      console.log(`Current Match Week: ${weekNum}`);

      const matches = response.data.matches;
      const weeklyMatches = matches.filter((match) => match.week === weekNum);
      console.log(weeklyMatches);
      // if (weeklyMatches.length === 0) {
      //   setLeagueEnded(true);
      // }
      //check for live match
      const liveMatch = weeklyMatches.find((match) => match.live === true);
      console.log("livematch : ", liveMatch);
      setLiveMatch(liveMatch);

      if (liveMatch) {
        liveMatch.lineup1 && liveMatch.lineup2
          ? setLiveLineup(true)
          : setLiveLineup(null);
      }
      // check for lineup of live match

      console.log("match this week : ", weeklyMatches);
      setMatchThisWeek(weeklyMatches);
    } catch (error) {
      console.error("Error making GET match request:", error);
    }
  }
  //
  // async function getThisWeekMatchData(url) {
  //   try {
  //     const response = await axios.get(url);
  //     console.log("GET This Week Match request successful");
  //     console.log(response.data);

  //     if (response.data.matches.length === 0) {
  //       setLeagueEnded(true);
  //     }
  //     //check for live match
  //     const liveMatch = response.data.matches.find(
  //       (match) => match.live === true
  //     );
  //     console.log("livematch : ", liveMatch);
  //     setLiveMatch(liveMatch);
  //     if (liveMatch) {
  //       liveMatch.lineup1 && liveMatch.lineup2
  //         ? setLiveLineup(true)
  //         : setLiveLineup(null);
  //     }
  //     // check for lineup of live match

  //     console.log("match this week : ", response.data.matches);
  //     setMatchThisWeek(response.data.matches);
  //     // setMatchData(response.data);
  //   } catch (error) {
  //     console.error("Error making GET This Week match request:", error);
  //     // Handle errors
  //     setError(
  //       "Error fetching GET This Week match request. Please try again later."
  //     );
  //   }
  // }
  // const checkLivematch = () => {
  //   getLiveMatchData("http://localhost:8000/api/match")
  //   console.log("inside live match check func");
  // };
  // async function getThisWeekMatchData(url) {
  //   try {
  //     const response = await axios.get(url);
  //     console.log("GET This Week Match request successful");
  //     console.log(response.data);
  //     setMatchThisWeek(response.data.matches);
  //     // setMatchData(response.data);
  //   } catch (error) {
  //     console.error("Error making GET This Week match request:", error);
  //     // Handle errors
  //     setError(
  //       "Error fetching GET This Week match request. Please try again later."
  //     );
  //   }
  // }
  return (
    <>
      <div className="flex items-center justify-center">
        {!leagueEnded ? (
          <div className=" mx-auto w-full  bg-white">
            <div className=" flex justify-center flex-auto">
              {nonLiveUploaded ? (
                <p className="text-lg sm:text-xl md:text-2xl font-semibold leading-6  text-[#117C13] m-5">
                  You have already uploaded necessary non Live data
                </p>
              ) : (
                <div className="flex justify-center flex-col items-center">
                  <p className="text-lg sm:text-xl md:text-2xl font-semibold leading-6  text-red-500 m-5">
                    Please upload necessary non Live data
                  </p>
                  <button
                    className={`hover:shadow-form w-[70%] rounded-md py-3 px-8 text-center text-base font-semibold text-white outline-none bg-[#117C13]
                      }`}
                    // className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                    onClick={gotoNonLive}
                  >
                    Got to non Live
                  </button>
                </div>
              )}
            </div>

            {nonLiveUploaded ? (
              <ul role="list" className=" divide-y divide-gray-100 md:p-5">
                {/* was passing this earlier now im directly running func in matchcarousel file */}
                {/* <MatchCarousel MatchWeek={MatchThisWeek} />  */}
                <LineupForAnyMatch />
                <MatchCarousel />
                {liveMatch ? <LineUp /> : <></>}
                {liveLineup ? <MatchUpdates /> : <></>}
                {liveLineup && liveMatch && <EndLiveMatch />}
                {liveMatch ? <CheckMatchUpdates /> : <></>}
                {/* {liveMatch && liveLineup ? <MatchUpdates /> : <></>}  open only when both condn are true */}
              </ul>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <p className="text-2xl font-semibold leading-6  text-red-500 m-5">
            League isn't active
          </p>
        )}
      </div>
      ;
    </>
  );
}

export default LiveChanges;
