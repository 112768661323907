import { createContext, useContext, useRef, useState } from "react";

const defaultProviderValues = {};

const AppContext = createContext(defaultProviderValues);

export const AppProvider = ({ children }) => {
  //   const [currentUser, setCurrentUser] = useState("");
  const [test, SetTest] = useState(true);
  const [newtest, setNewtest] = useState(false);
  const [targetRef, SetTargetRef] = useState(useRef(null));
  const [backgroundColorTeam, setBackgroundColorTeam] = useState(null);
  const [eachMatchData, setEachMatchData] = useState(null);
  const [matchData, setMatchData] = useState();
  const [activeTab, setActiveTab] = useState("tab1"); // whwnever you rfresh it will lead back to this page
  const [liveMatch, setLiveMatch] = useState();
  const [liveLineup, setLiveLineup] = useState();

  const [MatchThisWeek, setMatchThisWeek] = useState();
  const [targetRefTeams, SetTargetRefTeams] = useState(useRef(null));
  const [login, setLogin] = useState(false);

  const [navigation, setNavigation] = useState([
    { displayName: "Home", name: "home", path: "/" },
    { displayName: "Teams", name: "teams", path: "teams" },
    { displayName: "Matches", name: "matches", path: "matches" },

    { displayName: "Fantasy", name: "fantasy", path: "fantasy" },
    {
      displayName: "Fantasy Leaderboard",
      name: "publicFantasy",
      path: "publicFantasy",
    },
    { displayName: "VFL Gallery", name: "community", path: "community" },
    { displayName: "Login", name: "login", path: "login" },
  ]);

  const [allModifiedPlayers, setAllModifiedPlayers] = useState();
  const [selectedPlayer, setSelectedPlayer] = useState();

  const [weeklyFantasyTeam, setweeklyFantasyTeam] = useState();
  const [paginatedWeek, setPaginatedWeek] = useState(0);
  const [allUser, setAllUser] = useState();
  const [paginatedWeekPublic, setPaginatedWeekPublic] = useState(0);

  return (
    <AppContext.Provider
      value={{
        test,
        SetTest,
        newtest,
        setNewtest,
        targetRef,
        SetTargetRef,
        backgroundColorTeam,
        setBackgroundColorTeam,
        eachMatchData,
        setEachMatchData,
        matchData,
        setMatchData,
        activeTab,
        setActiveTab,
        liveMatch,
        setLiveMatch,
        MatchThisWeek,
        setMatchThisWeek,
        liveLineup,
        setLiveLineup,
        targetRefTeams,
        SetTargetRefTeams,
        login,
        setLogin,
        navigation,
        setNavigation,
        allModifiedPlayers,
        setAllModifiedPlayers,
        selectedPlayer,
        setSelectedPlayer,
        weeklyFantasyTeam,
        setweeklyFantasyTeam,
        paginatedWeek,
        setPaginatedWeek,
        allUser,
        setAllUser,
        paginatedWeekPublic,
        setPaginatedWeekPublic,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
