import React, { useEffect, useState } from "react";
import axios from "axios";
import { useApp } from "../../context/app-context";
//
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
//
const people = [
  {
    id: 1,
    name: "Wade Cooper",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Arlene Mccoy",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Devon Webb",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 4,
    name: "Tom Cook",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 5,
    name: "Tanya Fox",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 6,
    name: "Hellen Schmidt",
    avatar:
      "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 7,
    name: "Caroline Schultz",
    avatar:
      "https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 8,
    name: "Mason Heaney",
    avatar:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 9,
    name: "Claudie Smitham",
    avatar:
      "https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 10,
    name: "Emil Schaefer",
    avatar:
      "https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

const stage = ["Quarter-final", "Semi-final", "Final"];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function PlayoffMatchSchedule() {
  const { activeTab, setActiveTab, liveMatch } = useApp();
  const [showDetails, setShowDetails] = useState(false);
  //
  const [locationPlayoff, setlLocationPlayoff] = useState();
  const [linkPlayoff, setlLinkPlayoff] = useState();
  const [weekPlayoff, setlWeekPlayoff] = useState();
  const [selectedStage, setSelectedStage] = useState();
  const [date, setDate] = useState();

  //
  const [playoffTeamData, setPlayoffTeamData] = useState();
  const [team1, setTeam1] = useState();
  const [team2, setTeam2] = useState();
  const [selectedTeam1, setSelectedTeam1] = useState();
  const [selectedTeam2, setSelectedTeam2] = useState();
  const [playoffMatchScheduledMessage, setPlayoffMatchScheduledMessage] =
    useState();

  useEffect(() => {
    // getMatchgettingUpdated();
    fetchTeamData();
  }, []);

  //
  const handleLocationChange = (event) => {
    setlLocationPlayoff(event.target.value);
  };
  const handleLinkChange = (event) => {
    setlLinkPlayoff(event.target.value);
  };
  const handleWeekChange = (event) => {
    setlWeekPlayoff(event.target.value);
  };
  const handleChangeStage = (selectedStage) => {
    setSelectedStage(selectedStage);
  };
  const handleChangeDate = (event) => {
    setDate(event.target.value);
    console.log(event.target.value);
  };
  //
  const fetchTeamData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/team`
      );
      const data = response.data.teams;
      // getTopTeams(response.data.teams);
      setPlayoffTeamData(data);

      setTeam1(data);
      setTeam2(data);
      console.log(data);

      console.log(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  // const getTopTeams = (teams) => {
  //   teams.sort((a, b) => b.wins * 3 + b.draws - a.wins * 3 - a.draws); // sorting array according to top teams
  //   const top4Teams = teams.slice(0, 4); // getting top 4 teams out of sorted array
  //   setPlayoffTeamData(top4Teams);
  //   // setSelectedTeam1(top4Teams[0]);
  //   // setSelectedTeam2(top4Teams[1]);
  //   setTeam1(top4Teams);
  //   setTeam2(top4Teams);
  // };
  const handleChangeTeam1 = (selectedTeam1prop) => {
    // playoffTeamData.filter((team) => !selectedTeams.includes(team))
    const filteredTeams = playoffTeamData?.filter(
      (team) => team._id !== selectedTeam1prop._id
    );
    setSelectedTeam1(selectedTeam1prop);
    setTeam2(filteredTeams);
  };
  const handleChangeTeam2 = (selectedTeam2prop) => {
    // playoffTeamData.filter((team) => !selectedTeams.includes(team))
    const filteredTeams = playoffTeamData?.filter(
      (team) => team._id !== selectedTeam2prop._id
    );
    setSelectedTeam2(selectedTeam2prop);
    setTeam1(filteredTeams);
  };
  //

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      selectedTeam1 &&
      selectedTeam2 &&
      locationPlayoff &&
      linkPlayoff &&
      weekPlayoff &&
      selectedStage
    ) {
      const temp = parseInt(weekPlayoff, 10);
      const data = {
        team1: selectedTeam1.name,
        team2: selectedTeam2.name,
        location: locationPlayoff,
        link: linkPlayoff,
        week: temp,
        stage: selectedStage,
        date: date,
      };
      // Do something with data, e.g., send it to an API
      console.log("Submitted data:", data);
      setPlayoffMatchScheduledMessage(true); // ******* temporarily placed after log normall should be in api call as below
      SubmitPlayoffMatch(data);
    } else {
      alert("Please fill out all required fields");
    }

    // SubmitMatchUpdates(data);
  };
  // const SubmitMatchUpdates = async (data) => {
  //   try {
  //     const response = await axios.patch(
  //       `${process.env.REACT_APP_SERVER_URL}/api/match/lineup/${liveMatch?._id}`, // replace this with update match api
  //       data
  //     );
  //     console.log("Response:", response.data);
  //     setPlayoffMatchScheduledMessage(true);
  //     // Handle response or do something with the data
  //   } catch (error) {
  //     console.error("Error:", error);
  //     // Handle error
  //   }
  // };

  const SubmitPlayoffMatch = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/match/playoff`, // replace this with update match api
        data
      );
      console.log("Response:", response.data);
      setPlayoffMatchScheduledMessage(true);
      // Handle response or do something with the data
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };
  return (
    <>
      <li className="flex w-full py-5">
        <details className="group w-full">
          <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
            <div
              className="flex justify-between w-full"
              onClick={() => setShowDetails(true)}
            >
              <div className="flex gap-5">
                <div className="min-w-0 flex-auto">
                  <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-indigo-600">
                    Want to Schedule Playoff matches ?
                  </p>
                </div>
              </div>
            </div>
            <svg
              className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </summary>
          <div className=" mx-auto max-w-[850px] bg-white ">
            {/* <div className="mb-5">
              {liveMatch ? (
                <p className="mt-6 text-xl leading-8 text-green-800 font-bold text-center">
                  Live match : {liveMatch.team1} vs {liveMatch.team2} , week{" "}
                  {liveMatch.week}
                </p>
              ) : (
                <p className="mt-6 text-xl leading-8 text-red-800 font-bold text-center">
                  go to upper section and select live match adn team
                </p>
              )}
            </div> */}
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center gap-4"
            >
              {/* <div className="flex flex-col items-center sm:flex-row sm:gap-4 justify-center mt-10 w-[80%] sm:w-auto m-10 gap-4 "> */}
              <div className="flex flex-col sm:gap-4  mt-10  m-10 gap-4 ">
                <Listbox value={selectedTeam1} onChange={handleChangeTeam1}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Team 1 :
                      </Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                          <span className="flex items-center">
                            <img
                              src={selectedTeam1?.team_logo}
                              alt=""
                              className="h-5 w-5 flex-shrink-0 rounded-full"
                            />
                            <span className="ml-3 block truncate">
                              {selectedTeam1?.name}
                            </span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {team1?.map((team, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={team}
                              >
                                {({ selectedTeam1, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <img
                                        src={team.team_logo}
                                        alt=""
                                        className="h-5 w-5 flex-shrink-0 rounded-full"
                                      />
                                      <span
                                        className={classNames(
                                          selectedTeam1
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {team.name}
                                      </span>
                                    </div>

                                    {selectedTeam1 ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                <Listbox value={selectedTeam2} onChange={handleChangeTeam2}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Team 2 :
                      </Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                          <span className="flex items-center">
                            <img
                              src={selectedTeam2?.team_logo}
                              alt=""
                              className="h-5 w-5 flex-shrink-0 rounded-full"
                            />
                            <span className="ml-3 block truncate">
                              {selectedTeam2?.name}
                            </span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {team2?.map((team, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={team}
                              >
                                {({ selectedTeam2, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <img
                                        src={team.team_logo}
                                        alt=""
                                        className="h-5 w-5 flex-shrink-0 rounded-full"
                                      />
                                      <span
                                        className={classNames(
                                          selectedTeam2
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {team.name}
                                      </span>
                                    </div>

                                    {selectedTeam2 ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                <Listbox value={selectedStage} onChange={handleChangeStage}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Stage :
                      </Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                          <span className="flex items-center">
                            <span className="ml-3 block truncate h-5">
                              {selectedStage}
                            </span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        {/* <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {team2?.map((team, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={team}
                              >
                                {({ selectedStage, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          selectedStage
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {team.name}
                                      </span>
                                    </div>

                                    {selectedStage ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition> */}
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {stage?.map((stageName, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={stageName}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {stageName}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                <div>
                  <label
                    htmlFor="playoffLocation"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Location :
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="text"
                      name="playoffLocation"
                      id="playoffLocation"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="eg. Andheri Sports Club"
                      value={locationPlayoff}
                      onChange={handleLocationChange} // Call handleMinuteChange when input changes
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="playoffLink"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Embedded link :
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="text"
                      name="playoffLink"
                      id="playoffLink"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="eg. 0FBiyFpV__g?si=i9YFHJ9eeUxO0i8a"
                      // value="0FBiyFpV__g?si=i9YFHJ9eeUxO0i8a"
                      value={linkPlayoff}
                      onChange={handleLinkChange}
                    />
                  </div>
                </div>{" "}
                <div>
                  <label
                    htmlFor="playoffWeek"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Week:
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="number"
                      name="playoffWeek"
                      id="playoffWeek"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="8"
                      min="1"
                      value={weekPlayoff}
                      onChange={handleWeekChange}
                    />
                  </div>
                </div>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <label
                    for="Birthday"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Match Date :
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="date"
                      placeholder="John Doe"
                      classNameName="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300"
                      value={date}
                      onChange={handleChangeDate}
                    />
                  </div>
                </div>
                {/* <button >Submit</button> */}
              </div>
              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  className={` relative hover:shadow-form w-[80%] sm:w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  
                  ${
                    playoffMatchScheduledMessage
                      ? "bg-[#117C13]"
                      : "bg-[#6A64F1]"
                  }`}
                  // onClick={() => livematchFunc(match)} bg-indigo-700
                >
                  {!playoffMatchScheduledMessage
                    ? "Schedule Playoff match"
                    : "Playoff match scheduled"}
                </button>
              </div>
            </form>
          </div>
        </details>
      </li>
    </>
  );
}

export default PlayoffMatchSchedule;
