import React, { useState } from "react";
import pic1 from "../../assets/beach1.jpg";
import pic2 from "../../assets/beach2.jpeg";
import pic3 from "../../assets/beach3.jpg";
import pic4 from "../../assets/beach4.jpg";

import image1 from "../../assets/photos_community/photo_1.jpg";
import image2 from "../../assets/photos_community/photo_2.jpg";
import image3 from "../../assets/photos_community/photo_3.jpg";
import image4 from "../../assets/photos_community/photo_4.jpg";
import image5 from "../../assets/photos_community/photo_5.jpg";
import image6 from "../../assets/photos_community/photo_6.jpg";
import image7 from "../../assets/photos_community/photo_7.jpg";
import video1 from "../../assets/photos_community/video1.mp4";
import a_1 from "../../assets/auction_photos/a_1.jpg";
import a_2 from "../../assets/auction_photos/a_2.jpg";
import a_3 from "../../assets/auction_photos/a_3.jpg";
import a_4 from "../../assets/auction_photos/a_4.jpg";
import a_5 from "../../assets/auction_photos/a_5.jpg";
import a_6 from "../../assets/auction_photos/a_6.jpg";
import a_7 from "../../assets/auction_photos/a_7.jpg";
import video_auc from "../../assets/auction_photos/video_auc.mp4";
import mega_auc from "../../assets/marquee_auction/mega_auc.mp4";
import marq_photo from "../../assets/marquee_auction/Auc_photo.jpg";
import jersey_reel from "../../assets/jersey/jersey_reel.mp4";
import j_1 from "../../assets/jersey/j_1.jpg";
import j_2 from "../../assets/jersey/j_2.jpg";
import j_3 from "../../assets/jersey/j_3.jpg";
import j_4 from "../../assets/jersey/j_4.jpg";
import j_5 from "../../assets/jersey/j_5.jpg";
import j_6 from "../../assets/jersey/j_6.jpg";
import j_7 from "../../assets/jersey/j_7.jpg";
import j_8 from "../../assets/jersey/j_8.jpg";
import j_9 from "../../assets/jersey/j_9.jpg";
import ReactGA from "react-ga4";

function Option2() {
  const [showDetails, setShowDetails] = useState(false);
  const checked_cEvent = () => {
    ReactGA.event({
      category: "Community page",
      action: "viewed community event",
      label: "opened community event",
    });
  };
  const checked_Marquee_Auction = () => {
    ReactGA.event({
      category: "Community page",
      action: "viewed Marquee Auction",
      label: "opened Marquee Auction",
    });
  };
  const checked_Auction = () => {
    ReactGA.event({
      category: "Community page",
      action: "viewed Auction",
      label: "opened Auction",
    });
  };
  const checked_Jersey_Reveal = () => {
    ReactGA.event({
      category: "Community page",
      action: "viewed Jersey Reveal",
      label: "opened Jersey Reveal",
    });
  };
  return (
    <div className="m-0 p-0 max-w-screen bg-[#0f1528] py-16   shadow-lg">
      <div className=" py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8 flex justify-between">
          <div className="flex items-center gap-12">
            <h2 className="text-2xl font-bold lg:text-3xl text-white">
              Memory Lane
            </h2>
          </div>

          <button className="inline-block rounded-lg border bg-gray-700 border-none p-4 py-2 text-center text-sm font-semibold text-[#DFD0B8] dark:text-gray-200 outline-none ring-indigo-300 transition duration-100 hover:bg-orange-700 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base">
            <a href="https://www.instagram.com/vagadfootballleague?igsh=aXFmdGpzdjh1d2hx">
              Follow Us
            </a>
          </button>
        </div>
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8 mt-10 mb-10">
          <details className="  mt-2  rounded-lg  max-w-screen-2xl mx-auto">
            <summary
              className="bg-[#102C57] py-8 max-w-screen-2xl mx-auto flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer  relative w-full cursor-default  pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#DFD0B8] sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden"
              onClick={checked_cEvent}
            >
              <div
                className="flex justify-between w-full"
                onClick={() => setShowDetails(true)}
              >
                <div className="flex gap-5">
                  <div className="min-w-0 flex-auto">
                    <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#DFD0B8]">
                      Community event
                    </p>
                  </div>
                </div>
              </div>
              <svg
                className="w-8 h-8 text-[#DFD0B8] transition group-open:rotate-90 self-center"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                // className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </summary>

            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
              <div className="gap-6 mt-8  md:flex">
                {/* <div className="md:w-1/2 my-4">
              <div className="wrap-video">
                <video
                  className="object-cover h-96 w-full bg-black rounded-lg"
                  loop
                  autoPlay
                  muted
                >
                  <source src={video1} type="video/mp4"></source>
                </video>
              </div>
            </div> */}
                {/* <div className="md:w-1/2 my-4">
              <div className="wrap-video">
                <video
                  className="object-cover h-96 w-full bg-black rounded-lg"
                  loop
                  autoPlay
                  muted
                >
                  <source src={video1} type="video/mp4"></source>
                </video>
              </div>
            </div> */}
                <div className="md:w-1/2 my-4">
                  <div className="wrap-video"></div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:gap-6 xl:gap-8">
                <button className="wrap-video group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <video
                    className="object-cover h-96 w-full bg-black rounded-lg"
                    loop
                    controls
                  >
                    <source src={video1} type="video/mp4"></source>
                  </video>

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <img
                    src={image6}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                {/* image - start */}
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 ">
                  <img
                    src={image1}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                  <img
                    src={image2}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                {/* image - end */}

                {/* image - start */}
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2 ">
                  <img
                    src={image4}
                    loading="lazy"
                    alt="Photo by Magicle"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                {/* image - end */}

                {/* image - start */}
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-4 md:h-80">
                  <img
                    src={image5}
                    loading="lazy"
                    alt="Photo by Martin Sanchez"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                {/* image - end */}

                {/* image - start */}

                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2">
                  <img
                    src={image3}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2">
                  <img
                    src={image7}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                {/* image - end */}
              </div>
            </div>
          </details>
        </div>
        {/* <div className="mx-auto max-w-screen-2xl px-4 md:px-8 mt-10 mb-10">
          <details className="  mt-2  rounded-lg  max-w-screen-2xl mx-auto">
            <summary className="bg-[#102C57] py-8 max-w-screen-2xl mx-auto flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer  relative w-full cursor-default  pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#DFD0B8] sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden">
              <div
                className="flex justify-between w-full"
                onClick={() => setShowDetails(true)}
              >
                <div className="flex gap-5">
                  <div className="min-w-0 flex-auto">
                    <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#DFD0B8]">
                      Events
                    </p>
                  </div>
                </div>
              </div>
              <svg
                className="w-8 h-8 text-[#DFD0B8] transition group-open:rotate-90 self-center"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </summary>

            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
              <div className="gap-6 mt-8  md:flex">
                <div className="md:w-1/2 my-4">
                  <div className="wrap-video"></div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:gap-6 xl:gap-8">
                <button className="wrap-video group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <video
                    className="object-cover h-96 w-full bg-black rounded-lg"
                    loop
                    controls
                  >
                    <source src={video1} type="video/mp4"></source>
                  </video>

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <img
                    src={image6}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                  <img
                    src={image1}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                  <img
                    src={image2}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2 ">
                  <img
                    src={image4}
                    loading="lazy"
                    alt="Photo by Magicle"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-4 md:h-80">
                  <img
                    src={image5}
                    loading="lazy"
                    alt="Photo by Martin Sanchez"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2">
                  <img
                    src={image3}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2">
                  <img
                    src={image7}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
              </div>
            </div>
          </details>
        </div> */}
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8 mt-10 mb-10">
          <details className="  mt-2  rounded-lg  max-w-screen-2xl mx-auto">
            <summary
              className="bg-[#102C57] py-8 max-w-screen-2xl mx-auto flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer  relative w-full cursor-default  pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#DFD0B8] sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden"
              onClick={checked_Marquee_Auction}
            >
              <div
                className="flex justify-between w-full"
                onClick={() => setShowDetails(true)}
              >
                <div className="flex gap-5">
                  <div className="min-w-0 flex-auto">
                    <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#DFD0B8]">
                      Marquee Auction
                    </p>
                  </div>
                </div>
              </div>
              <svg
                className="w-8 h-8 text-[#DFD0B8] transition group-open:rotate-90 self-center"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                // className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </summary>
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
              <div className="gap-6 mt-8  md:flex">
                <div className="md:w-1/2 my-4">
                  <div className="wrap-video"></div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:gap-6 xl:gap-8">
                <button className="wrap-video group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <video
                    className="object-cover h-96 w-full bg-black rounded-lg"
                    loop
                    controls
                  >
                    <source src={video_auc} type="video/mp4"></source>
                  </video>

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <img
                    src={marq_photo}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 "
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
              </div>
            </div>
          </details>
        </div>
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8 mt-10 mb-10">
          <details className="  mt-2  rounded-lg  max-w-screen-2xl mx-auto">
            <summary
              className="bg-[#102C57] py-8 max-w-screen-2xl mx-auto flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer  relative w-full cursor-default  pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#DFD0B8] sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden"
              onClick={checked_Auction}
            >
              <div
                className="flex justify-between w-full"
                onClick={() => setShowDetails(true)}
              >
                <div className="flex gap-5">
                  <div className="min-w-0 flex-auto">
                    <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#DFD0B8]">
                      Auction
                    </p>
                  </div>
                </div>
              </div>
              <svg
                className="w-8 h-8 text-[#DFD0B8] transition group-open:rotate-90 self-center"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                // className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </summary>
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
              <div className="gap-6 mt-8  md:flex">
                <div className="md:w-1/2 my-4">
                  <div className="wrap-video"></div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:gap-6 xl:gap-8">
                <button className="wrap-video group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <video
                    className="object-cover h-96 w-full bg-black rounded-lg"
                    loop
                    controls
                  >
                    <source src={mega_auc} type="video/mp4"></source>
                  </video>

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <img
                    src={a_1}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 "
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                {/* image - start */}
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2 ">
                  <img
                    src={a_2}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2 ">
                  <img
                    src={a_3}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                {/* image - end */}

                {/* image - start */}
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2 ">
                  <img
                    src={a_4}
                    loading="lazy"
                    alt="Photo by Magicle"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                {/* image - end */}

                {/* image - start */}
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                  <img
                    src={a_5}
                    loading="lazy"
                    alt="Photo by Martin Sanchez"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                {/* image - end */}

                {/* image - start */}

                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2">
                  <img
                    src={a_6}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2">
                  <img
                    src={a_7}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                {/* image - end */}
              </div>
            </div>
          </details>
        </div>
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8 mt-10 mb-10">
          <details className="  mt-2  rounded-lg  max-w-screen-2xl mx-auto">
            <summary
              className="bg-[#102C57] py-8 max-w-screen-2xl mx-auto flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer  relative w-full cursor-default  pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#DFD0B8] sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden"
              onClick={checked_Jersey_Reveal}
            >
              <div
                className="flex justify-between w-full"
                onClick={() => setShowDetails(true)}
              >
                <div className="flex gap-5">
                  <div className="min-w-0 flex-auto">
                    <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#DFD0B8]">
                      Jersey Reveal
                    </p>
                  </div>
                </div>
              </div>
              <svg
                className="w-8 h-8 text-[#DFD0B8] transition group-open:rotate-90 self-center"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                // className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </summary>
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
              <div className="gap-6 mt-8  md:flex">
                <div className="md:w-1/2 my-4">
                  <div className="wrap-video"></div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:gap-6 xl:gap-8">
                <button className="wrap-video group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <video
                    className="object-cover h-96 w-full bg-black rounded-lg"
                    loop
                    controls
                  >
                    <source src={jersey_reel} type="video/mp4"></source>
                  </video>

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative flex  h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg  md:col-span-2">
                  <img
                    src={j_1}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 "
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                {/* image - start */}
                <button className="group relative pb-[125%] flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-96 md:col-span-2 ">
                  <img
                    src={j_2}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative pb-[125%]  flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-96 md:col-span-2 ">
                  <img
                    src={j_3}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                {/* image - end */}

                {/* image - start */}
                <button className="group relative pb-[125%]  flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-96 md:col-span-2 ">
                  <img
                    src={j_4}
                    loading="lazy"
                    alt="Photo by Magicle"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>

                {/* image - end */}

                {/* image - start */}
                <button className="group relative pb-[125%]  flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-96">
                  <img
                    src={j_5}
                    loading="lazy"
                    alt="Photo by Martin Sanchez"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                {/* image - end */}

                {/* image - start */}

                <button className="group relative pb-[125%]  flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-96 md:col-span-2">
                  <img
                    src={j_6}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative pb-[125%]  flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-96 md:col-span-2">
                  <img
                    src={j_7}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative pb-[125%]  flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-96 md:col-span-2">
                  <img
                    src={j_8}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                <button className="group relative pb-[125%]  flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-96 md:col-span-2">
                  <img
                    src={j_9}
                    loading="lazy"
                    alt="Photo by Lorenzo Herrera"
                    className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />

                  <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                </button>
                {/* image - end */}
              </div>
            </div>
          </details>
        </div>
      </div>
    </div>
  );
}

export default Option2;
