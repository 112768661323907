import "./App.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Sponsors from "./components/Sponsors";
import NavbarMobile from "./components/navbarMobile";
import CommunityNav from "./components/CommunityNav";
import TopBanner from "./components/TopBanner";
import { useEffect, useState } from "react";
import axios from "axios";
import { useApp } from "./context/app-context";
import Footer from "./components/Footer";
import LeagueInfoBanner from "./components/LeagueInfoBanner";
import InstallPrompt from "./components/pwa/InstallPrompt";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Maintenance from "./components/maintenance/Maintenance";
function App() {
  ReactGA.initialize("G-KXEY1J33SH");

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [location]);

  // useEffect(() => {
  //   const accessTokenExpiryTime = 10000; // = 1 minute
  //   const accessTokenCheckInterval = setInterval(
  //     checkToken,
  //     accessTokenExpiryTime * 1
  //   ); // Check every 10 seconds
  //   return () => clearInterval(accessTokenCheckInterval); // Cleanup on unmount
  // }, []);

  const { login, setUser } = useApp();
  const [error, setError] = useState(null);
  const [maintenance, setMaintenance] = useState(false);
  const navigate = useNavigate();
  const sixDaysInMilliseconds = 6 * 24 * 60 * 60 * 1000;
  const runEverySixDays = () => {
    //console.log("This function runs every 6 days.");
    alert("Please Login out and login again");
    navigate("/login");
  };

  useEffect(() => {
    check();
  }, []);
  useEffect(() => {
    const checkUser = JSON.parse(localStorage.getItem("loginCheck"));
    //console.log(checkUser);
    // alert(checkUser);

    if (checkUser) {
      // alert(`No issue , access check: ${checkUser}`);
      // Check token every 12 minutes
      const accessTokenExpiryTime = 60000; // 1 minute
      const accessTokenCheckInterval = setInterval(
        checkToken,
        accessTokenExpiryTime * 10
      );

      // Run function every 6 days
      const sixDaysInterval = setInterval(
        runEverySixDays,
        sixDaysInMilliseconds
      );

      return () => {
        clearInterval(accessTokenCheckInterval); // Cleanup on unmount
        clearInterval(sixDaysInterval); // Cleanup on unmount
      };
    } else {
      // Handle the case where the user is not logged in
      // alert("Login check issue:", checkUser);
    }
  }, [login]);

  const checkToken = () => {
    const refresh = JSON.parse(localStorage.getItem("refreshToken"));
    if (refresh !== null) {
      // alert(`No issue , access check: ${refresh}`);
      // console.log(refresh);
      // console.log("if");
      getNewAccess(refresh);
    } else {
      // alert("refresh token issue , refresh token :", refresh);
      // alert("else");
    }
  };
  const getNewAccess = async (token) => {
    // alert("func called");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user/token`,
        { token: token }
      );
      const temp = response.data;
      //console.log("New Access : ", temp);
      localStorage.setItem("accessToken", JSON.stringify(temp.accessToken));
      getProfile(
        `${process.env.REACT_APP_SERVER_URL}/api/user/getProfile`,
        response.data.accessToken
      );
    } catch (error) {
      // alert("Error");
      console.error("Error:", error);
    }
  };
  async function getProfile(url, access) {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("GET profile request successful");
      //console.log(response.data);
      // alert(response.data._id);
      localStorage.setItem("userData", response.data);
    } catch (error) {
      console.error("Error making GET profile request:", error);
      setError("Error fetching GET profile request. Please try again later.");
    }
  }

  const check = () => {
    // axios
    //   .get(`${process.env.REACT_APP_SERVER_URL}/test`)
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     setMaintenance(true);
    //     console.error("Error:", error);
    //   });
    fetch(`${process.env.REACT_APP_SERVER_URL}/test`)
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => {
        setMaintenance(true);
        console.error("Error:", error);
      });
  };

  return (
    <>
      {/* {!maintenance ? (
        <>
          <NavbarMobile />
          <Outlet />
          <InstallPrompt />
          <Sponsors />
          <Footer />
        </>
      ) : (
        <>
      <Maintenance />
      </>
      )} */}
      <NavbarMobile />
      <Outlet />
      <InstallPrompt />
      <Sponsors />
      <Footer />
    </>
  );
}

export default App;
