import React, { useState } from "react";
import { useApp } from "../context/app-context";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import ProfileComp from "../components/profile/ProfileComp";
// import axiosInstance from "../services/axiosInstance";
import ReactGA from "react-ga4";

function LoginForm() {
  const { login, setLogin, navigation, setNavigation } = useApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cred, setCred] = useState("notEntered");
  const [validCred, setValidCred] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    console.log("toggle");
    setIsPasswordVisible(!isPasswordVisible);
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("loginCheck"));
    console.log(storedUser);
    const refresh = JSON.parse(localStorage.getItem("refreshToken"));
    const access = JSON.parse(localStorage.getItem("accessToken"));
    // console.log("refreshToken", refresh);
    // console.log("accessToken", access);
    console.log("login", login);
    const userData = localStorage.getItem("userData");
    setUser(userData);
    console.log(userData);
    if (storedUser) {
      setLogin(true);
      getProfile(
        `${process.env.REACT_APP_SERVER_URL}/api/user/getProfile`,
        access
      );
    }
    checkToken();
  }, []);

  const checkToken = () => {
    const refresh = JSON.parse(localStorage.getItem("refreshToken"));
    if (refresh !== null) {
      // alert(`No issue , access check: ${refresh}`);
      // console.log(refresh);
      // console.log("if");
      getNewAccess(refresh);
    } else {
      // alert("refresh token issue , refresh token :", refresh);
      // alert("else");
    }
  };
  const getNewAccess = async (token) => {
    // alert("func called");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user/token`,
        { token: token }
      );
      const temp = response.data;
      // console.log("New Access : ", temp);
      localStorage.setItem("accessToken", JSON.stringify(temp.accessToken));
      getProfileNew(
        `${process.env.REACT_APP_SERVER_URL}/api/user/getProfile`,
        response.data.accessToken
      );
    } catch (error) {
      // alert("Error");
      setLogin(false);
      console.log("refresh expired");
      console.error("Error:", error);
    }
  };
  async function getProfileNew(url, access) {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("GET profile request successful");
      //console.log(response.data);
      setUser(response.data);
      user_Refresh(response.data);

      localStorage.setItem("userData", response.data);
    } catch (error) {
      console.error("Error making GET profile request:", error);
      setError("Error fetching GET profile request. Please try again later.");
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log("Email:", email);
    //console.log("Password:", password);
    const data = {
      email: email,
      password: password,
    };
    postLoginCred(data);
  };

  async function getProfile(url, access) {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      // user_Profile_ga(response.data.user);
      console.log("GET profile request successful");
      //console.log(response.data);
      // ----------------------------admin check
      if (response.data.role === "admin") {
        const updatedNavigation = [
          { displayName: "Home", name: "home", path: "/" },
          { displayName: "Teams", name: "teams", path: "teams" },
          { displayName: "Matches", name: "matches", path: "matches" },
          // remove the object of login navlink if you want login to dissapear when you login in

          {
            displayName: "Fantasy Leaderboard",
            name: "publicFantasy",
            path: "publicFantasy",
          },

          { displayName: "VFL Gallery", name: "community", path: "community" },
          { displayName: "Profile", name: "profile", path: "login" },
          { displayName: "Admin", name: "admin", path: "admin" },
        ];
        //console.log(updatedNavigation);
        setNavigation(updatedNavigation);
        // const adminCheckOld = JSON.parse(localStorage.getItem("adminCheck"));
        // if (adminCheckOld !== true) {
        console.log("Inside set admin true");
        localStorage.setItem("adminCheck", true);
        // }
        const temp = JSON.parse(localStorage.getItem("adminCheck"));
        //console.log("admin check : ", temp);
      }
      // ----------------------------admin check
      const temp = JSON.parse(localStorage.getItem("adminCheck"));
      //console.log(temp);
      setUser(response.data);
      ga_for_pfp(response.data);
    } catch (error) {
      console.error("Error making GET profile request:", error);
      setError("Error fetching GET profile request. Please try again later.");
    }
  }

  const postLoginCred = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user/login`,
        data
      );
      // console.log("Response:", response.data);

      if (response.data.token.length !== 0) {
        localStorage.setItem(
          "accessToken",
          JSON.stringify(response.data.token)
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(response.data.refreshToken)
        );
        // console.log("refreshToken", response.data.refreshToken);
        // console.log("accessToken", response.data.token);
        setCred("valid");
        AuthSuccesfull();
        setEmail();
        setPassword();
      } else {
        setCred("invalid");
      }
    } catch (error) {
      console.error("Error:", error);
      setCred("invalid");
      console.log("hello");
    }
  };

  const AuthSuccesfull = () => {
    const access = JSON.parse(localStorage.getItem("accessToken"));

    getProfile(
      `${process.env.REACT_APP_SERVER_URL}/api/user/getProfile`,
      access
    );

    const updatedNavigation = [
      { displayName: "Home", name: "home", path: "/" },
      { displayName: "Teams", name: "teams", path: "teams" },
      { displayName: "Matches", name: "matches", path: "matches" },
      // remove the object of login navlink if you want login to dissapear when you login in
      { displayName: "Fantasy", name: "fantasy", path: "fantasy" },
      {
        displayName: "Fantasy Leaderboard",
        name: "publicFantasy",
        path: "publicFantasy",
      },

      { displayName: "VFL Gallery", name: "community", path: "community" },
      { displayName: "Profile", name: "profile", path: "login" },
    ];
    // Set the state with the new array
    console.log(updatedNavigation);
    setNavigation(updatedNavigation);

    setLogin(true);
    console.log(login);

    navigate("/login");
    const loginCheck = true;
    localStorage.setItem("loginCheck", loginCheck);
  };
  const clearCred = () => {
    setCred("notEntered");
    const access = JSON.parse(localStorage.getItem("accessToken"));
    const refresh = JSON.parse(localStorage.getItem("refreshToken"));
    console.log("refreshToken Logout", refresh);
    logoutFunc(access, refresh);
    const updatedNavigation = [
      { displayName: "Home", name: "home", path: "/" },
      { displayName: "Teams", name: "teams", path: "teams" },
      { displayName: "Matches", name: "matches", path: "matches" },
      // remove the object of login navlink if you want login to dissapear when you login in

      { displayName: "Fantasy", name: "fantasy", path: "fantasy" },
      {
        displayName: "Fantasy Leaderboard",
        name: "publicFantasy",
        path: "publicFantasy",
      },

      { displayName: "VFL Gallery", name: "community", path: "community" },
    ];
    setNavigation(updatedNavigation);
    navigate("/login");
    const loginCheck = false;
    localStorage.setItem("loginCheck", loginCheck);
    setLogin(false);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    const adminCheck = JSON.parse(localStorage.getItem("adminCheck"));
    if (adminCheck === true) {
      localStorage.setItem("adminCheck", false);
    }
  };
  const logoutFunc = async (access, refresh) => {
    console.log("refreshToken logout", refresh);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user/logout`,
        { refreshToken: refresh },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      const message = response.data;
      console.log("Logout msg : ", message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  let colorClass = "";

  if (cred === "notEntered") {
    colorClass = "border-b-2 border-gray-600";
  } else if (cred === "valid") {
    colorClass = "border-b-2 border-green-600";
  } else {
    colorClass = "border-b-2 border-red-600";
  }

  // const user_Login = (prop) => {
  //   ReactGA.event({
  //     category: "User",
  //     action: "Logged In",
  //     label: prop.name,
  //   });
  // };
  const ga_for_pfp = (prop) => {
    ReactGA.event({
      category: "User",
      action: "Profile",
      label: prop?.username,
    });
  };
  const user_Refresh = (prop) => {
    ReactGA.event({
      category: "User",
      action: "Refresh access token",
      label: prop?.username,
    });
  };
  return (
    <div className="mx-auto max-w-screen bg-[#192347] px-4 py-24   sm:px-6 lg:px-8 shadow-lg">
      {/* <div className=" overflow-hidden bg-[#192347] py-24   lg:px-0"> */}
      {login ? (
        <div className="flex flex-col">
          <ProfileComp clearFunc={clearCred} user={user} />
        </div>
      ) : (
        <></>
      )}
      {!login ? (
        <div className="mx-auto max-w-lg bg-white rounded-md py-5">
          <h1 className="text-center text-2xl font-bold text-indigo-600 sm:text-3xl">
            Welcome Back
          </h1>

          <p className="mx-auto mt-4 max-w-md text-center text-gray-500 px-2">
            Enter the valid email Id and password with which you have signed up.
          </p>

          <form
            onSubmit={handleSubmit}
            className="mb-0  space-y-4 rounded-lg p-4  sm:p-6 lg:p-8"
          >
            <p className="text-center text-lg font-medium">
              Login in to your account
            </p>

            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className={`w-full rounded-lg ${colorClass}  p-4 pe-12 text-sm shadow-sm`}
                  placeholder="Enter email"
                />
                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  className={`w-full rounded-lg ${colorClass} p-4 pe-12 text-sm shadow-sm`}
                  placeholder="Enter password"
                />
                <span
                  className="absolute inset-y-0 end-0 grid place-content-center px-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13.875 18.825A10.012 10.012 0 0112 19c-4.478 0-8.268-2.943-9.542-7A10.013 10.013 0 0112 5c2.027 0 3.913.597 5.475 1.625M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20.084 20.084a1.517 1.517 0 000-2.134L4.055 4.055a1.517 1.517 0 10-2.134 2.134l16.029 16.029a1.517 1.517 0 002.134 0z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  )}
                </span>
              </div>
            </div>
            {/* <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="relative">
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className={`w-full rounded-lg ${colorClass} p-4 pe-12 text-sm shadow-sm`}
                  placeholder="Enter password"
                />
                <span className="absolute inset-y-0 end-0 grid place-content-center px-4 cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                </span>
              </div>
            </div> */}
            {cred === "invalid" ? (
              <p className="w-full text-center text-red-600 p-4 pe-12 text-base font-semibold ">
                Invalid Credentials{" "}
              </p>
            ) : (
              <></>
            )}
            <button
              type="submit"
              className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
            >
              Login
            </button>

            <p className="text-center text-sm text-gray-500">
              Already have an account?{" "}
              <NavLink
                to={`/signUp`}
                className="underline text-indigo-600 text-base font-semibold"
              >
                SignUp
              </NavLink>
            </p>
          </form>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default LoginForm;
