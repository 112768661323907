import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import "../fantasyComponent/styles.css";
import { useApp } from "../../context/app-context";
import FantasyTeamNew from "./FantasyTeamNew";
import FantasyPlayerSelection from "./FantasyPlayerSelection";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import matchWeeks from "../../data/MatchData";

// when you reload the page we are not able to remove players from team selected before the page was refreshed
function FantasyLineupDemo({ user, teamData, fantasyWeek }) {
  const {
    selectedPlayer,
    setSelectedPlayer,
    allModifiedPlayers,
    setAllModifiedPlayers,
  } = useApp();

  // console.log("Modified", allModifiedPlayers);
  // console.log("selectedPlayer", selectedPlayer);

  const [currentWeek, setCurrentWeek] = useState();

  const [tooltipStatus, setTooltipStatus] = useState(0);
  const [query, setQuery] = useState("");
  const [allPlayersModifiedAllTeams, setallPlayersModifiedAllTeams] =
    useState();
  const [selectedRole, setSelectedRole] = useState();
  const [searchedPerson, setSearchedPerson] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [errorAssign, setErrorAssign] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [activate3xCard, setaActivate3xCard] = useState(false);
  const [maxLimit, setMaxLimit] = useState(8);
  const [activateExtraPlayerCard, setActivateExtraPlayerCard] = useState(false);

  const [weekNumForSpecialCard, setWeekNumForSpecialCard] = useState(0);
  const [roleAssigned, setRoleAssigned] = useState(null);

  const wallet = 100;
  let expense = 0;
  const [expenseState, setExpenseState] = useState(0);

  const [team, setTeam] = useState(
    localStorage.getItem("team") === null
      ? {
          squad: {
            fw: [],
            cm: [],
            df: [],
            gk: [],
          },
        }
      : JSON.parse(localStorage.getItem("team"))
  );

  const playerRemove = (team, position, id) => {
    if (team === "team") {
      if (position === "Goalkeeper") {
        setTeam((team) => ({
          ...team,
          squad: { ...team.squad, gk: [] },
        }));
      } else if (position === "Defender") {
        setTeam((team) => ({
          ...team,
          squad: {
            ...team.squad,
            df: team.squad.df.filter((player) => player.id !== id),
          },
        }));
      } else if (position === "Midfielder") {
        setTeam((team) => ({
          ...team,
          squad: {
            ...team.squad,
            cm: team.squad.cm.filter((player) => player.id !== id),
          },
        }));
      } else {
        setTeam((team) => ({
          ...team,
          squad: {
            ...team.squad,
            fw: team.squad.fw.filter((player) => player.id !== id),
          },
        }));
      }
    }
  };

  function isPlayerPresent(squad, playerId) {
    // Combine all arrays in the squad object into a single array
    const allPlayers = [...squad.cm, ...squad.df, ...squad.fw, ...squad.gk];

    // Check if any player in the combined array has the given playerId
    return allPlayers.some((player) => player.id === playerId);
  }

  const addPlayer = () => {
    //console.log("expense : ", expense + selectedPlayer?.cost);
    setExpenseState(expense + selectedPlayer?.cost);
    const totalPlayers = Object.values(team.squad).reduce(
      (total, currentArray) => total + currentArray.length,
      0
    );
    //console.log("Total number of players:", totalPlayers);
    if (selectedPlayer === undefined) {
      setErrorAssign("Select Player");
      return;
    }
    if (totalPlayers < maxLimit) {
      setErrorAssign(false);
      if (isPlayerPresent(team.squad, selectedPlayer._id)) {
        setErrorAssign("Player already exists in the squad.");
      } else if (expense + selectedPlayer?.cost > wallet) {
        setErrorAssign("You have exceded your wallet");
      } else {
        if (
          totalPlayers == 7 &&
          team?.squad?.gk?.length == 0 &&
          selectedPlayer.playerPosition !== "Goalkeeper"
        ) {
          setErrorAssign("Can't make a team without a keeper");
        } else {
          if (selectedPlayer?.name.length === undefined) {
            setErrorAssign("Player Name required!");
          } else {
            let playerObj = {
              id: selectedPlayer._id,
              name: selectedPlayer?.name,
              photo: selectedPlayer?.photo,
              playerPosition: selectedPlayer?.playerPosition,
              role: "normal",
              cost: selectedPlayer?.cost,
              points: 0,
            };

            if (selectedPlayer?.playerPosition === "Goalkeeper") {
              if (team.squad.gk.length >= 1) {
                setErrorAssign(" Team goal keeper is full!");
              } else {
                playerObj.onClick = () =>
                  playerRemove("team", "Goalkeeper", playerObj.id);
                //console.log("called");
                setTeam((team) => ({
                  ...team,
                  squad: { ...team.squad, gk: [playerObj] },
                }));
              }
            } else if (selectedPlayer?.playerPosition === "Defender") {
              if (team.squad.df.length === 3) {
                setErrorAssign(" Team defence is full!");
              } else {
                playerObj.onClick = () =>
                  playerRemove("team", "Defender", playerObj.id);
                setTeam((team) => ({
                  ...team,
                  squad: {
                    ...team.squad,
                    df: [...team.squad.df, playerObj],
                  },
                }));
              }
            } else if (selectedPlayer?.playerPosition === "Midfielder") {
              if (team.squad.cm.length === 3) {
                setErrorAssign(" Team Midfield is full!");
              } else {
                playerObj.onClick = () =>
                  playerRemove("team", "Midfielder", playerObj.id);
                setTeam((team) => ({
                  ...team,
                  squad: {
                    ...team.squad,
                    cm: [...team.squad.cm, playerObj],
                  },
                }));
              }
            } else {
              if (team.squad.fw.length === 3) {
                setErrorAssign(" Team forward is full!");
              } else {
                playerObj.onClick = () =>
                  playerRemove("team", "Attacker", playerObj.id);
                setTeam((team) => ({
                  ...team,
                  squad: {
                    ...team.squad,
                    fw: [...team.squad.fw, playerObj],
                  },
                }));
              }
            }
          }
          setSelectedPlayer();
          //console.log(selectedPlayer?.name);
        }
      }
    } else {
      setErrorAssign(`Cant select more than ${maxLimit} players`);
    }
  };

  useEffect(() => {
    // ReactGA.event({
    //   category: "fantasy_users",
    //   action: "viewed_fantasy",
    //   label: user?.username,
    // });
    // if (user?.userName !== undefined) {
    //   console.log("user name :", user?.username);
    // }
    window.scrollTo(0, 0);
    // getAllMatchDataLoad(
    //   `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`
    // );
    demo();
    PlayerDataFunc(`${process.env.REACT_APP_SERVER_URL}/api/player?limit=1000`);
    //console.log("team", team);
    //console.log("team", team);
    setSelectedRole();
    // localStorage.removeItem("team");
    // localStorage.setItem("team", JSON.stringify(team));
    // if (teamData !== undefined) {
    //   setTeam(teamData);
    // }
    setWeekNumForSpecialCard(fantasyWeek);
    //console.log(fantasyWeek);
    // setCurrentWeek(fantasyWeek);
    // extractFantasy();
    // getWeekNumber();
  }, []);

  useEffect(() => {
    if (user?.username !== undefined) {
      ReactGA.event({
        category: "fantasy_users",
        action: "viewed_fantasy",
        label: user?.username,
      });
      console.log("user name :", user?.username);
    }
  }, [user?.username]);

  useEffect(() => {
    if (user !== undefined && weekNumForSpecialCard !== 0) {
      if (
        user?.extraPlayerCard?.usedWeek === weekNumForSpecialCard &&
        user?.extraPlayerCard?.used === true
      ) {
        setActivateExtraPlayerCard(true);
        setMaxLimit(9);
      } else {
      }
    }
  }, [user]);

  useEffect(() => {
    if (teamData !== undefined) {
      setTeam(teamData);
      const totalPlayers = Object.values(team.squad).reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );
      //console.log("Total number of players:", totalPlayers);
    }
  }, [teamData]);

  //console.log(team);
  useEffect(() => {
    if (errorAssign) {
      const timer = setTimeout(() => {
        setErrorAssign(false);
      }, 3000); // Adjust the duration as needed

      return () => clearTimeout(timer);
    }
  }, [errorAssign]);
  useEffect(() => {
    if (errorSubmit) {
      const timer = setTimeout(() => {
        setErrorSubmit(false);
      }, 3000); // Adjust the duration as needed

      return () => clearTimeout(timer);
    }
  }, [errorSubmit]);

  useEffect(() => {
    if (submitSuccessful) {
      const timer = setTimeout(() => {
        setSubmitSuccessful(false);
        window.location.reload();
        window.scrollTo(0, 0);
      }, 1000); // Adjust the duration as needed

      return () => clearTimeout(timer);
    }
  }, [submitSuccessful]);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const assignCaptain = (playerId) => {
    const updatedSquad = { ...team.squad };

    // Iterate over each position group to find the player and assign captain role
    Object.keys(updatedSquad).forEach((position) => {
      updatedSquad[position] = updatedSquad[position].map((player) => {
        if (player.id === playerId) {
          return { ...player, role: "captain" };
        } else if (player.role === "captain") {
          return { ...player, role: "normal" };
        }
        return player;
      });
    });

    setTeam({ squad: updatedSquad });
  };
  // const assignViceCaptain = (playerId) => {
  //   const updatedSquad = { ...team.squad };

  //   // Iterate over each position group to find the player and assign captain role
  //   Object.keys(updatedSquad).forEach((position) => {
  //     updatedSquad[position] = updatedSquad[position].map((player) => {
  //       if (player.id === playerId) {
  //         return { ...player, role: "vice-captain" };
  //       } else if (player.role === "captain") {
  //         return { ...player, role: "normal" };
  //       }
  //       return player;
  //     });
  //   });

  //   setTeam({ squad: updatedSquad });
  // };

  function updatePlayerRole(playerId, newRole) {
    //console.log("playerId", playerId);
    //console.log("newRole", newRole);

    const updatedSquad = { ...team.squad };
    //console.log(updatedSquad);
    // Iterate over each position in the squad
    Object.keys(updatedSquad).forEach((position) => {
      updatedSquad[position] = updatedSquad[position].map((player) => {
        // Check if the current player is the one to be assigned the new role
        if (player.id === playerId) {
          // //console.log("player", player);
          setRoleAssigned(`${newRole}`); // Show error message
          setTimeout(() => {
            setRoleAssigned(null); // Hide error message after 2 seconds
          }, 2000);
          return { ...player, role: newRole };
        }

        // If the player is currently a captain or vice-captain and the new role is 'captain' or 'vice-captain', change their role to 'normal'
        if (
          (player.role === "captain" && newRole === "captain") ||
          (player.role === "vice-captain" && newRole === "vice-captain")
        ) {
          //console.log("player", player);

          return { ...player, role: "normal" };
        }
        //console.log("player", player);
        return player;
      });
    });

    setTeam({ squad: updatedSquad });
  }

  //console.log("user fantasy lineup demo :", user);

  const filteredPlayers = useMemo(() => {
    return query === ""
      ? allPlayersModifiedAllTeams
      : allPlayersModifiedAllTeams?.filter((person) =>
          person?.name?.toLowerCase()?.includes(query?.toLowerCase())
        );
  }, [query, allPlayersModifiedAllTeams]);

  const positionAbbreviations = {
    attacker: "ATK",
    goalkeeper: "GK",
    midfielder: "MF",
    defender: "DF",
    // Add more positions as needed
  };

  async function PlayerDataFunc(url) {
    try {
      const response = await axios.get(url);
      //console.log("GET Player Data request successful");
      const formattedPlayers = response.data.players.map((player) => {
        // Get the abbreviation based on the full position name
        const position = player.position?.split(",")[0].trim().toLowerCase();
        const abbreviation = positionAbbreviations[position];
        // console.log(abbreviation);
        return {
          _id: player._id,
          name: player.name,
          playerPosition: abbreviation, // Assign the abbreviation instead of the full position name
          photo: player.photo,
          team: player.team,
          cost: player.cost,
        };
      });
      setallPlayersModifiedAllTeams(formattedPlayers);
    } catch (error) {
      console.error("Error making GET Player request:", error);
    }
  }

  // const getWeekNumber = () => {
  //   // Define the start date of the league
  //   const startDate = new Date("2024-06-15"); // Assuming the year is 2024

  //   // Get today's date
  //   const today = new Date();

  //   // Calculate the difference in days between today's date and the start date
  //   const diffInDays = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));

  //   // Calculate the week number by dividing the difference in days by 7 and rounding up
  //   let weekNum = 0;
  //   if (diffInDays < 0) {
  //     weekNum = 1;
  //   } else {
  //     weekNum = Math.ceil(diffInDays / 7) + 1;
  //   }
  //   setWeekNumForSpecialCard(weekNum);
  //   console.log(weekNum);
  //   setCurrentWeek(weekNum);
  // };

  // async function getAllMatchDataLoad(url) {
  //   let weekNum = 1;
  //   try {
  //     const response = await axios.get(url);
  //     console.log(response.data);
  //     const sortedMatches = response.data.matches.sort(
  //       (a, b) => a.week - b.week
  //     );
  //     const dates = sortedMatches.map((match) => match.date);
  //     const uniqueDates = [...new Set(dates)];
  //     console.log(uniqueDates);

  //     const convertToDate = (dateString) => {
  //       const [day, month, year] = dateString.split("-").map(Number);
  //       // Assuming year is 20YY, convert to 19YY if year < 50, else convert to 20YY
  //       const fullYear = year < 50 ? 2000 + year : 1900 + year;
  //       return new Date(fullYear, month - 1, day); // month is zero-based
  //     };

  //     const matchWeekDates = uniqueDates.map(convertToDate);

  //     console.log(matchWeekDates);

  //     const today = new Date("2024-06-20"); // Get today's date

  //     const getCurrentMatchWeek = (currentDate, matchWeekDates) => {
  //       // index considered as 1 to handle case of current dat before starting date
  //       for (let index = 1; index < matchWeekDates.length; index++) {
  //         const element = matchWeekDates[index];
  //         console.log(element);
  //         console.log(currentDate);
  //         if (currentDate < element) {
  //           console.log(currentDate < element);
  //           return index;
  //         }
  //       }
  //       const last = matchWeekDates[matchWeekDates.length - 1];
  //       const diffInDays = Math.ceil((today - last) / (1000 * 60 * 60 * 24));
  //       console.log(diffInDays);

  //       if (diffInDays <= 7) {
  //         return matchWeekDates.length;
  //       }
  //       return matchWeekDates.length + 1;
  //     };

  //     weekNum = getCurrentMatchWeek(today, matchWeekDates);
  //     console.log(`Current Match Week: ${weekNum}`);

  //     setWeekNumForSpecialCard(weekNum);
  //     console.log(weekNum);
  //     setCurrentWeek(weekNum);
  //   } catch (error) {
  //     console.error("Error making GET match request:", error);
  //   }
  // }

  const demo = () => {
    const getCurrentWeek = (date) => {
      for (let i = 0; i < matchWeeks.length; i++) {
        const { week, startDate, endDate } = matchWeeks[i];
        if (date >= startDate && date <= endDate) {
          //console.log(date >= startDate && date <= endDate);
          return week;
        }
      }
      return null; // If the date doesn't fall within any of the predefined ranges
    };

    // Example usage:

    function floorDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    // const originalDate = new Date("Sat Jun 15 2024 18:38:55 GMT+0530");

    //  const currentDate = new Date();;
    const currentDate = new Date();
    const flooredDate = floorDate(currentDate);
    const weekNumber = getCurrentWeek(flooredDate);

    //console.log("Current Week Number:", weekNumber);
    setWeekNumForSpecialCard(weekNumber);
    // setCurrentWeek(weekNumber);
  };
  const DateSubmitFunc = () => {
    const getCurrentWeek = (date) => {
      for (let i = 0; i < matchWeeks.length; i++) {
        const { week, startDate, endDate, lockingDate } = matchWeeks[i];
        if (date <= lockingDate) {
          //console.log(date <= lockingDate);
          return week;
        }
      }
      return null; // If the date doesn't fall within any of the predefined ranges
    };

    function floorDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    const currentDate = new Date();
    const flooredDate = floorDate(currentDate);
    const weekNumber = getCurrentWeek(flooredDate);
    // alert(weekNumber);
    //console.log("Current Week Number:", weekNumber);
    return weekNumber;
  };
  // function checkDateExceeded(specifiedDateString) {
  //   // Get today's date
  //   const today = new Date("2024-6-18");
  //   today.setHours(0, 0, 0, 0); // Set time to 00:00:00 for accurate comparison

  //   // Parse the specified date
  //   const specifiedDate = new Date(specifiedDateString);
  //   specifiedDate.setHours(0, 0, 0, 0); // Set time to 00:00:00 for accurate comparison

  //   // Compare the dates
  //   if (today.getTime() >= specifiedDate.getTime()) {
  //     // Date has exceeded or is the same
  //     // return "Error: The specified date has been reached or exceeded.";
  //     return "locked";
  //   }
  // }
  // fUNCTION FOR FINAL SUBMISSION--------------------------------------

  // const FuncSubmit = () => {
  //   const data = team?.squad;
  //   const playerIds = [];
  //   Object.values(data).forEach((playerList) => {
  //     playerList.forEach((player) => {
  //       playerIds.push(player.id);
  //     });
  //   });

  //   // Extracting captain ID
  //   let captainId = null;
  //   Object.values(data).forEach((playerList) => {
  //     const captain = playerList.find((player) => player.role === "captain");
  //     if (captain) {
  //       captainId = captain.id;
  //     }
  //   });

  //   // Creating the object
  //   const obj = JSON.stringify({
  //     season: 2024,
  //     week: currentWeek,
  //     players: playerIds,
  //   });
  //   console.log(obj);
  //   // SubmitFantasyApi(obj);
  //   submitFantasyApi();
  // };

  // const SubmitFantasyApi = async (obj) => {
  //   const access = JSON.parse(localStorage.getItem("accessToken"));
  //   console.log(access);

  //   try {
  //     const response = await axios.put(
  //       "http://localhost:8000/api/user/fantasyTeam",
  //       obj,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access}`,
  //         },
  //       }
  //     );
  //     const temp = response.data;
  //     console.log("Fantasy response : ", temp);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const FuncSubmit = async () => {
    const weekWhileSubmit = DateSubmitFunc();
    // alert(`weekWhileSubmit": ${weekWhileSubmit}`);
    // alert(`fantasyWeek": ${fantasyWeek}`);

    ReactGA.event({
      category: "fantasy_users",
      action: "Tried_submitting",
      label: user?.username,
    });
    const data = team?.squad;
    // console.log("Team in submit", data);
    const { squad } = team;
    const { fw, cm, df, gk } = squad;
    const temp = squad;
    // console.log("Team in submit", squad);

    // --------------------------------
    let isCaptainAssigned = false;
    let isViceCaptainAssigned = false;

    Object.keys(temp).forEach((position) => {
      temp[position].map((player) => {
        if (player.role === "captain") {
          //console.log("captain", player.name);
          isCaptainAssigned = true;
        } else if (player.role === "vice-captain") {
          isViceCaptainAssigned = true;
          //console.log("vice", player.name);
        }
      });
    });

    // --------------------------
    let totalPlayers = 0;
    Object.keys(temp).forEach((position) => {
      totalPlayers += temp[position].length;
    });
    // for (const position in squad) {
    //   if (Array.isArray(team[position])) {
    //     totalPlayers += team[position].length;
    //   }
    // }

    //console.log("Captain exists :", isCaptainAssigned);
    //console.log("Vice captain assigned : ", isViceCaptainAssigned);
    //console.log("Data len : ", totalPlayers);

    // const specifiedDate = "2024-07-08"; // Replace with your specified date
    // if (checkDateExceeded(specifiedDate) === "locked") {
    //   setErrorSubmit(`Fantasy is locked till week ${currentWeek + 1}`);
    // } else

    if (weekWhileSubmit != fantasyWeek) {
      setErrorSubmit(`week ${weekWhileSubmit} has started, refresh!`);
    } else if (!gk || gk.length === 0) {
      setErrorSubmit("Goal Keeper Required");
    } else if (totalPlayers !== maxLimit) {
      setErrorSubmit(`Team should have ${maxLimit} players`);
    } else if (!isCaptainAssigned) {
      setErrorSubmit("Assign a captain");
    } else if (!isViceCaptainAssigned) {
      setErrorSubmit("Assign a vice captain");
    } else {
      const playerIds = [];
      Object.values(data).forEach((playerList) => {
        playerList.forEach((player) => {
          playerIds.push(player.id);
        });
      });

      // Extracting captain ID
      let captainId = null;
      Object.values(data).forEach((playerList) => {
        const captain = playerList.find((player) => player.role === "captain");
        if (captain) {
          captainId = captain.id;
        }
      });
      let viceCaptainId = null;
      Object.values(data).forEach((playerList) => {
        const viceCaptain = playerList.find(
          (player) => player.role === "vice-captain"
        );
        if (viceCaptain) {
          viceCaptainId = viceCaptain.id;
        }
      });
      let useCaptainCardUsed = {};
      let useExtraPlayerCardUsed = {};

      if (activate3xCard) {
        useCaptainCardUsed = {
          used: true,
          usedSeason: 2024,
          usedWeek: weekNumForSpecialCard,
        };
        //console.log(useCaptainCardUsed);
      }

      // if condn handles caes where the player has used the extra player card and wants to chnage the team during THAT WEEK ONLY
      if (
        user?.extraPlayerCard?.usedWeek === weekNumForSpecialCard &&
        user?.extraPlayerCard?.used === true
      ) {
        useExtraPlayerCardUsed = {};
      } else if (activateExtraPlayerCard) {
        useExtraPlayerCardUsed = {
          used: true,
          usedSeason: 2024,
          usedWeek: weekNumForSpecialCard,
        };
        //console.log(activateExtraPlayerCard);
      }

      const obj = JSON.stringify({
        season: 2024,
        week: fantasyWeek,
        players: playerIds,
        captain: captainId,
        viceCaptain: viceCaptainId,
        useCaptainCard: useCaptainCardUsed,
        useExtraPlayerCard: useExtraPlayerCardUsed,
        //   "useCaptainCard": {
        //     "used": true
        // },
        // "useExtraPlayerCard": {
        //     "used": false
        // }
      });

      const access = JSON.parse(localStorage.getItem("accessToken"));

      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_SERVER_URL}/api/user/fantasyTeam`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        data: obj,
      };

      try {
        const response = await axios.request(config);
        setSubmitSuccessful(true);
        setActivateExtraPlayerCard(false);
        //console.log(response.data);
        ReactGA.event({
          category: "fantasy_users",
          action: "succesful_submit",
          label: user?.username,
        });
      } catch (error) {
        //console.error("Error:", error);
        ReactGA.event({
          category: "fantasy_users",
          action: "unsuccesful_submit",
          label: user?.username,
        });
      }
    }
  };
  // fUNCTION FOR FINAL SUBMISSION ABOVE--------------------------------------

  // SHIFTED THIS FUNCTION TO FANTASY PAGE -------------------------------------------
  const extractFantasy = () => {
    // fantasy extract process below -------------------------------
    const fantasyTeamWeek4 = user?.fantasyTeam?.find(
      (team) => team.week === fantasyWeek
    );
    //console.log("fantasyTeamWeek4", fantasyTeamWeek4);
    const categorizedPlayers = {
      fw: [],
      cm: [],
      df: [],
      gk: [],
    };
    // checking user team
    fantasyTeamWeek4?.players?.forEach((player) => {
      switch (player.position) {
        case "Attacker":
          categorizedPlayers.fw.push({
            id: player._id,
            name: player.name,
            photo: player.photo,
            playerPosition: player.position,
            // other properties...
          });
          break;
        case "Midfielder":
          categorizedPlayers.cm.push({
            id: player._id,
            name: player.name,
            photo: player.photo,
            playerPosition: player.position,
            // other properties...
          });
          break;
        case "Defender":
          categorizedPlayers.df.push({
            id: player._id,
            name: player.name,
            photo: player.photo,
            playerPosition: player.position,
            // other properties...
          });
          break;
        case "Goalkeeper":
          categorizedPlayers.gk.push({
            id: player._id,
            name: player.name,
            photo: player.photo,
            playerPosition: player.position,
            // other properties...
          });
          break;
        default:
          break;
      }
    });

    // setTeam(categorizedPlayers);

    // Log the categorized players
    //console.log(categorizedPlayers);
    const updatedTeams = {
      squad: categorizedPlayers,
    };
    //console.log("updatedTeams", updatedTeams);

    // Assuming setTeam is the function to update the state
    setTeam(updatedTeams);
    //console.log("Team Data: ", teamData);
  };
  // SHIFTED ABOVE FUNCTION TO FANTASY PAGE -------------------------------------------

  const displayRole = (role) => {
    if (role === "captain") {
      return "C";
    } else if (role === "vice-captain") {
      return "VC";
    } else {
      return "P";
    }
  };
  const displayRoleColor = (role) => {
    if (role === "captain") {
      return "text-indigo-600";
    } else if (role === "vice-captain") {
      return "text-indigo-600";
    } else {
      return "";
    }
  };

  const extraPlayerFunc = () => {
    setMaxLimit(9);
    setActivateExtraPlayerCard(true);
  };
  let playerNumber = 1;

  const calculateTotalCost = (team) => {
    let totalCost = 0;
    for (const position in team) {
      team[position].forEach((player) => {
        totalCost += player.cost;
      });
    }
    return totalCost;
  };

  const WeekPoints = () => {
    if (fantasyWeek !== 0) {
      //console.log(user.fantasyTeam);
      const index = user?.fantasyTeam?.findIndex(
        (team) => team.week === fantasyWeek
      );
      // alert("Index:", index);
      return user?.fantasyTeam[index]?.points;
    } else {
      // alert("hi");
    }
  };

  return (
    <div className="">
      <div className="flex flex-col justify-center items-center">
        <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#E1C564] rounded-lg  shadow-lg pt-2 pb-5 px-2">
          <svg
            className="absolute bottom-0 left-0 mb-8"
            viewBox="0 0 375 283"
            fill="none"
            style={{ transform: "scale(1.5)", opacity: "0.1" }}
          >
            <rect
              x="159.52"
              y="175"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 159.52 175)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 0 107.48)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 100 107.48)" // Adjusted the rotation center
              fill="white"
            />
          </svg>
          <div
            align="center"
            className="text-white font-extrabold text-2xl mt-3"
          >
            {/* Season Points : {user?.seasonPoints[0].points} */}
            {/* {currentWeek !== 0 ? WeekPoints : "0"} */}
            Season Points : {user?.seasonPoints[0]?.points}
          </div>
        </div>
        <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#16697A] rounded-t-lg  shadow-lg pt-3 pb-3 px-2 mt-4">
          <svg
            className="absolute bottom-0 left-0 mb-8"
            viewBox="0 0 375 283"
            fill="none"
            style={{ transform: "scale(1.5)", opacity: "0.1" }}
          >
            <rect
              x="159.52"
              y="175"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 159.52 175)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 0 107.48)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 100 107.48)" // Adjusted the rotation center
              fill="white"
            />
          </svg>
          <div align="center" className="text-white font-extrabold text-2xl">
            {/* {user?.username}'s Team */}
            <span className="text-[#FFFBDA]"> WEEK {fantasyWeek} </span>
          </div>
        </div>
        <div className="w-full">
          {team !== undefined && <FantasyTeamNew team={team} />}
          {/* <FantasyTeamNew team={team} /> */}
        </div>
        <div className="w-full max-w-2xl flex-shrink-0 relative overflow-hidden bg-[#16697A] rounded-b-lg  shadow-lg pt-3 pb-3 px-2">
          <svg
            className="absolute bottom-0 left-0 mb-8"
            viewBox="0 0 375 283"
            fill="none"
            style={{ transform: "scale(1.5)", opacity: "0.1" }}
          >
            <rect
              x="159.52"
              y="175"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 159.52 175)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 0 107.48)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 100 107.48)" // Adjusted the rotation center
              fill="white"
            />
          </svg>
          <div align="center" className="text-[#FFFBDA] font-extrabold text-xl">
            POINTS : {WeekPoints()}
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col items-center justify-between p-2 font-medium marker:content-none hover:cursor-pointer bg-black relative w-full cursor-default text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 rounded-lg focus-within:shadow-lg ">
        <div className="flex items-center mb-2">
          <div className="relative">
            <div
              className="mr-2 cursor-pointer"
              onMouseEnter={() => setTooltipStatus(1)}
              onMouseLeave={() => setTooltipStatus(0)}
            >
              {/* Your SVG Icon */}
              <svg
                aria-haspopup="true"
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-info-circle"
                width={25}
                height={25}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#A0AEC0"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx={12} cy={12} r={9} />
                <line x1={12} y1={8} x2="12.01" y2={8} />
                <polyline points="11 12 12 12 12 16 13 16" />
              </svg>
            </div>
            {tooltipStatus === 1 && (
              <div
                role="tooltip"
                className="z-200 -mt-20 w-48 z-100 absolute transition duration-150 ease-in-out left-0 bottom-8 ml-8 shadow-lg bg-indigo-700 p-4 rounded"
                style={{ zIndex: 1000 }}
              >
                {/* Tooltip content */}
                <p className="text-sm font-bold text-white pb-1">
                  Search players
                </p>
                <p className="text-sm leading-4 text-white pb-3">
                  This is only for searching players and not for choosing
                </p>
              </div>
            )}
          </div>
          <p className="text-white text-lg font-bold ">Search players</p>
        </div>

        <Combobox value={searchedPerson} onChange={setSearchedPerson}>
          <div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
            <div className="grid place-items-center h-full w-12 text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <Combobox.Input
              className="peer h-full w-full outline-none text-md text-gray-700 pr-2 font-medium"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(person) => person?.name}
            />
          </div>

          <Combobox.Options className="mt-1 w-full bg-white border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            {filteredPlayers?.length === 0 && query !== "" ? (
              <div className="cursor-default select-none relative py-2 pl-3 pr-9 text-gray-700">
                No results found
              </div>
            ) : (
              filteredPlayers?.map((person) => (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className="flex cursor-default select-none relative py-2 pl-3 pr-9 justify-between items-center"
                >
                  <div className="flex items-center">
                    <img
                      src={person.photo}
                      alt=""
                      className="h-8 w-8 flex-shrink-0 rounded-full mr-4"
                    />
                    <div className="flex flex-col">
                      <div className="flex">
                        <p className="pr-2 ">{person.name}</p>
                        <p className="font-bold text">
                          ({person.playerPosition})
                        </p>
                      </div>
                      <p className="text-indigo-600">{person.team}</p>
                    </div>
                  </div>
                  <p className="font-bold text-green-800">${person?.cost}</p>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Combobox>
      </div>

      <details className="group w-full mt-2">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer bg-black relative w-full cursor-default  py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden">
          <div
            className="flex justify-between w-full"
            onClick={() => setShowDetails(true)}
          >
            <div className="flex gap-5">
              <div className="min-w-0 flex-auto">
                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#F0EEED]">
                  Choose Player
                </p>
              </div>
            </div>
          </div>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>
        <div className="flex flex-col bg-black p-2 rounded-b-md">
          <div
            className={`flex p-4 bg-[#95D2B3] justify-between rounded-lg  ${
              wallet - calculateTotalCost(team?.squad) === 0
                ? "bg-[#FF8F8F]"
                : "bg-[#95D2B3]"
            }`}
          >
            <div className="flex gap-x-2 items-center">
              <p className="text-lg font-semibold text-white px-1">
                Money Left :{" "}
              </p>
              <div className="relative">
                <div
                  className=" cursor-pointer"
                  onMouseEnter={() => setTooltipStatus(3)}
                  onMouseLeave={() => setTooltipStatus(0)}
                >
                  {/* Your SVG Icon */}
                  <svg
                    aria-haspopup="true"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-info-circle text-white bg-transparent rounded-full filter brightness-0 invert"
                    width={25}
                    height={25}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#A0AEC0"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx={12} cy={12} r={9} />
                    <line x1={12} y1={8} x2="12.01" y2={8} />
                    <polyline points="11 12 12 12 12 16 13 16" />
                  </svg>
                </div>
                {tooltipStatus === 3 && (
                  <div
                    role="tooltip"
                    className="z-200 -mt-20 w-44 z-100 absolute transition duration-150 ease-in-out -left-8 bottom-8 ml-8 shadow-lg bg-indigo-700 p-4 rounded"
                    style={{ zIndex: 1000 }}
                  >
                    {/* Tooltip content */}
                    <div className="flex flex-col">
                      <div>
                        <p className="text-sm font-bold text-white pb-1">
                          Your Wallet
                        </p>
                        <p className="text-sm leading-4 leading-relaxed text-white  pb-3">
                          Make your team using $ {wallet}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <p
              className={`text-lg font-extrabold  ${
                wallet - calculateTotalCost(team?.squad) === 0
                  ? "text-red-600"
                  : "text-green-600"
              } px-1`}
            >
              {/* Issues is that wallet doesnt decrease with removing of players */}
              {/* {wallet - expenseState} */}$
              {wallet - calculateTotalCost(team?.squad)}
            </p>
          </div>
          <div className="flex items-center justify-end mt-4 gap-x-4 ">
            {/* <p className="text-white text-md font-medium text-center ">INFO </p> */}
            <div className="relative">
              <div
                className="mr-2 cursor-pointer"
                onMouseEnter={() => setTooltipStatus(5)}
                onMouseLeave={() => setTooltipStatus(0)}
              >
                {/* Your SVG Icon */}
                <svg
                  aria-haspopup="true"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-info-circle"
                  width={25}
                  height={25}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#A0AEC0"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <circle cx={12} cy={12} r={9} />
                  <line x1={12} y1={8} x2="12.01" y2={8} />
                  <polyline points="11 12 12 12 12 16 13 16" />
                </svg>
              </div>
              {tooltipStatus === 5 && (
                <div
                  role="tooltip"
                  className="z-200 -mt-20 w-48 z-100 absolute transition duration-150 ease-in-out right-8 bottom-4 ml-8 shadow-lg bg-indigo-700 p-4 rounded"
                  style={{ zIndex: 1000 }}
                >
                  {/* Tooltip content */}
                  <p className="text-sm font-bold text-white pb-1">
                    Checkout the rulebook
                  </p>
                  <p className="text-sm leading-4 text-white mb-1">
                    Tap open the hamburger menu on top right
                  </p>
                  <p className="text-sm leading-4 text-white mb-1">
                    Slide down to rule book
                  </p>
                  <p className="text-sm leading-4 text-white ">
                    Tap on rule book
                  </p>
                </div>
              )}
            </div>
          </div>

          <FantasyPlayerSelection />
          <div className="col-span-1 md:col-span-2  mt-2 mb-4">
            {/* {errorAssign !== null && (
                <div className="mb-2 bg-red-400">
                  <div className="alert alert-danger" role="alert">
                    {errorAssign}
                  </div>
                </div>
              )} */}
            {errorAssign !== false && (
              <div className="mt-2 bg-red-200 px-4 py-4  mb- rounded-md text-md flex items-center mx-auto max-w-lg">
                <svg
                  viewBox="0 0 24 24"
                  className="text-red-600 w-5 h-5 sm:w-5 sm:h-5 mr-3"
                >
                  <path
                    fill="currentColor"
                    d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
                  ></path>
                </svg>
                <span className="text-red-800"> {errorAssign} </span>
              </div>
            )}
            <button
              className="mt-2 w-full p-4 bg-indigo-600 rounded-md text-white font-semibold"
              onClick={addPlayer}
            >
              Add Player
            </button>
          </div>
        </div>
      </details>
      <details className="group w-full mt-2 bg-indigo-600 rounded-lg">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer bg-black relative w-full cursor-default  py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden">
          <div
            className="flex justify-between w-full"
            onClick={() => setShowDetails(true)}
          >
            <div className="flex gap-5">
              <div className="min-w-0 flex-auto">
                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#F0EEED]">
                  Remove Player
                </p>
              </div>
            </div>
          </div>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>
        <div className="flex flex-col bg-indigo-600 p-2 rounded-b-md">
          <p className="text-md font-bold text-white text-center">
            Tap on the player avatar on the field
          </p>
        </div>
      </details>
      <details className="group w-full mt-2">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer bg-black relative w-full cursor-default  py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden">
          <div
            className="flex justify-between w-full "
            onClick={() => setShowDetails(true)}
          >
            <div className="flex gap-5">
              <div className="min-w-0 flex-auto">
                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#F0EEED]  ">
                  Assign Special Role
                </p>
              </div>
            </div>
          </div>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>
        <div className="flex flex-col bg-black p-2 rounded-b-lg">
          <div className="col-span-1 md:col-span-2  mt-4  mb-4">
            <div className="">
              <Listbox value={selectedRole} onChange={setSelectedRole}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium leading-6 text-white flex">
                      Select Player from team :
                      <div className="relative">
                        <div
                          className="mx-2 cursor-pointer"
                          onMouseEnter={() => setTooltipStatus(2)}
                          onMouseLeave={() => setTooltipStatus(0)}
                        >
                          {/* Your SVG Icon */}
                          <svg
                            aria-haspopup="true"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-info-circle"
                            width={25}
                            height={25}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#A0AEC0"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx={12} cy={12} r={9} />
                            <line x1={12} y1={8} x2="12.01" y2={8} />
                            <polyline points="11 12 12 12 12 16 13 16" />
                          </svg>
                        </div>
                        {tooltipStatus === 2 && (
                          <div
                            role="tooltip"
                            className="z-200 -mt-20 w-44 z-100 absolute transition duration-150 ease-in-out -left-8 bottom-8 ml-8 shadow-lg bg-indigo-700 p-4 rounded"
                            style={{ zIndex: 1000 }}
                          >
                            {/* Tooltip content */}
                            <div className="flex flex-col">
                              <div>
                                <p className="text-sm font-bold text-white pb-1">
                                  Captain
                                </p>
                                <p className="text-sm leading-4 text-white pb-3">
                                  Player's fantasy points get 2 X
                                </p>
                              </div>
                              <div>
                                <p className="text-sm font-bold text-white pb-1">
                                  Vice-Captain
                                </p>
                                <p className="text-sm leading-4 text-white pb-3">
                                  Player's fantasy points get 1.5 X
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Listbox.Label>
                    <div className="relative mt-2">
                      <Listbox.Button className="relative w-full cursor-default  py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 flex items-center h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                        <span className="flex items-center">
                          <img
                            src={selectedRole?.photo}
                            alt=""
                            className="h-8 w-8 flex-shrink-0 rounded-full"
                          />
                          <span className="ml-3 block truncate">
                            {selectedRole?.name}
                          </span>
                          <span className="ml-3 block truncate">
                            {selectedRole
                              ? `(${selectedRole?.playerPosition
                                  ?.split(",")[0]
                                  .trim()})`
                              : ""}
                            {/* ({selectedPlayer?.playerPosition?.split(",")[0].trim()}) */}
                          </span>
                          <span className="ml-3 block truncate text-indigo-600 font-semibold text-md">
                            {selectedRole ? `$${selectedRole?.cost}` : ""}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {Object.values(team?.squad).map((position, index) =>
                            position.map((person) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                                value={person}
                              >
                                {({ selected: selectedPerson, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <img
                                        src={person.photo}
                                        alt=""
                                        className="h-8 w-8 flex-shrink-0 rounded-full"
                                      />
                                      <div className="flex flex-col justify-between w-full">
                                        <span
                                          className={classNames(
                                            selectedPerson
                                              ? "font-semibold "
                                              : "font-normal",
                                            "ml-3 block truncate"
                                          )}
                                        >
                                          {person.name}
                                        </span>
                                        <span
                                          className={classNames(
                                            selectedPerson
                                              ? "font-semibold"
                                              : "font-normal",
                                            "ml-3 block truncate"
                                          )}
                                        >
                                          {person?.playerPosition
                                            ?.split(",")[0]
                                            .trim()}
                                        </span>
                                      </div>
                                      <div>
                                        <p className="text-indigo-600 font-semibold text-lg">
                                          ${person?.cost}
                                        </p>
                                      </div>
                                    </div>
                                    {selectedPerson ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))
                          )}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              <div className="flex mt-5 ">
                {/* <button
                  className={` w-full px-4 py-3 mx-1 ${
                    roleAssigned === "captain"
                      ? "bg-green-700"
                      : "bg-indigo-600"
                  }  rounded-md text-white font-semibold`}
                  // onClick={() => assignCaptain(selectedRole.id)}
                  onClick={() =>
                    updatePlayerRole(selectedRole?.id, "captain")
                  }
                >
                  {roleAssigned == "captain" ? "Made Captain" : "Make Captain"}
                </button> */}
                {roleAssigned == "captain" ? (
                  <button
                    className=" w-full px-4 py-3 mx-1 
                      
                        bg-green-700
                      rounded-md text-white font-semibold"
                  >
                    Made Captain
                  </button>
                ) : (
                  <button
                    className=" w-full px-4 py-3 mx-1 
                      
                        bg-indigo-700
                      rounded-md text-white font-semibold"
                    onClick={() =>
                      updatePlayerRole(selectedRole?.id, "captain")
                    }
                  >
                    Make Captain
                  </button>
                )}
                {roleAssigned == "vice-captain" ? (
                  <button
                    className=" w-full px-4 py-3 mx-1 
                      
                        bg-green-700
                      rounded-md text-white font-semibold"
                  >
                    Made Vice-Captain
                  </button>
                ) : (
                  <button
                    className=" w-full px-4 py-3 mx-1 
                      
                        bg-indigo-700
                      rounded-md text-white font-semibold"
                    onClick={() =>
                      updatePlayerRole(selectedRole?.id, "vice-captain")
                    }
                  >
                    Make Vice-Captain
                  </button>
                )}

                {/* Can be also done using this approach */}
                {/* <button
                  className={` w-full px-4 py-3 mx-1 ${
                    roleAssigned == "vice-captain"
                      ? "bg-green-700"
                      : "bg-indigo-600"
                  }  rounded-md text-white font-semibold`}
                  onClick={() =>
                    updatePlayerRole(selectedRole?.id, "vice-captain")
                  }
                >
                  {roleAssigned == "vice-captain"
                    ? "Made Vice-Captain"
                    : "Make Vice-Captain"}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </details>
      {/* <details className="group w-full mt-2">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer bg-black relative w-full cursor-default  py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6  h-12 rounded-lg focus-within:shadow-lg overflow-hidden">
          <div
            className="flex justify-between w-full "
            onClick={() => setShowDetails(true)}
          >
            <div className="flex gap-5">
              <div className="min-w-0 flex-auto">
                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#F0EEED] ">
                  Use Special Cards
                </p>
              </div>
            </div>
          </div>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>
        <div className="flex flex-col bg-black p-2 rounded-b-lg ">
          <div className="col-span-1 md:col-span-2  mt-4  mb-4">
            <div className="flex-col">
              {user?.captainCard?.usedWeek === weekNumForSpecialCard &&
              user?.captainCard?.used === true &&
              user?.extraPlayerCard?.usedWeek === weekNumForSpecialCard &&
              user?.extraPlayerCard?.used === true ? (
                <div
                  className={`flex p-4  justify-center rounded-lg bg-[#6DC5D1] mb-4`}
                >
                  <p className="text-lg font-bold text-white px-1 text-center">
                    Captain 3X and Extra Player Card can be both used through
                    out the week
                  </p>
                </div>
              ) : user?.extraPlayerCard?.usedWeek === weekNumForSpecialCard &&
                user?.extraPlayerCard?.used === true ? (
                <div
                  className={`flex p-4  justify-center rounded-lg bg-[#6DC5D1] mb-4`}
                >
                  <p className="text-lg font-bold text-white px-1 text-center">
                    Extra Player Card can be used through out the week
                  </p>
                </div>
              ) : user?.captainCard?.usedWeek === weekNumForSpecialCard &&
                user?.captainCard?.used === true ? (
                <div
                  className={`flex p-4  justify-center rounded-lg bg-[#6DC5D1] mb-4`}
                >
                  <p className="text-lg font-bold text-white px-1 text-center">
                    Captain 3X Card can be used through out the week
                  </p>
                </div>
              ) : (
                <></>
              )}

              <div className="flex gap-x-4">
                <button
                  className={`w-full  relative overflow-hidden  rounded-lg  shadow-lg  px-2 py-4 ${
                    !user?.captainCard?.used ? "bg-[#37ac3f]" : "bg-[#343232]"
                  }`}
                  onClick={() => {
                    setaActivate3xCard(true);
                  }}
                  disabled={user?.captainCard?.used}
                >
                  <svg
                    className="absolute bottom-0 left-0 mb-8"
                    viewBox="0 0 375 283"
                    fill="none"
                    style={{ transform: "scale(1.5)", opacity: "0.1" }}
                  >
                    <rect
                      x="159.52"
                      y="175"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 159.52 175)"
                      fill="white"
                    />
                    <rect
                      y="107.48"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 0 107.48)"
                      fill="white"
                    />
                    <rect
                      y="107.48"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                      fill="white"
                    />
                  </svg>
                  {activate3xCard ? (
                    <p
                      align="center"
                      className={`text-[#343232] font-bold text-2xl px-4 ${
                        !user?.captainCard?.used ? "py-3" : "py-1"
                      }`}
                    >
                      Card Used
                    </p>
                  ) : (
                    <p
                      align="center"
                      className={`text-white font-bold text-2xl px-4 ${
                        !user?.captainCard?.used ? "py-3" : "py-1"
                      }`}
                    >
                      3X Captain
                    </p>
                  )}

                  {activate3xCard ? (
                    <></>
                  ) : user?.captainCard?.used ? (
                    <p className="text-[#E72929] font-bold text-lg text-shadow">
                      CARD USED
                    </p>
                  ) : (
                    <p className="text-[#A1DD70] font-bold text-lg text-shadow">
                      NOT USED
                    </p>
                  )}
                </button>
 
                <button
                  className={`w-full  relative overflow-hidden  rounded-lg  shadow-lg  px-2 py-4 ${
                    !user?.extraPlayerCard?.used
                      ? "bg-[#37ac3f]"
                      : "bg-[#343232]"
                  }`}
                  onClick={() => {
                    extraPlayerFunc();
                  }}
                  disabled={user?.extraPlayerCard?.used}
                >
                  <svg
                    className="absolute bottom-0 left-0 mb-8"
                    viewBox="0 0 375 283"
                    fill="none"
                    style={{ transform: "scale(1.5)", opacity: "0.1" }}
                  >
                    <rect
                      x="159.52"
                      y="175"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 159.52 175)"
                      fill="white"
                    />
                    <rect
                      y="107.48"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 0 107.48)"
                      fill="white"
                    />
                    <rect
                      y="107.48"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 100 107.48)" // Adjusted the rotation center
                      fill="white"
                    />
                  </svg>
                  {activateExtraPlayerCard && !user?.extraPlayerCard?.used ? (
                    <p
                      align="center"
                      className={`text-[#343232] font-bold text-2xl px-4 ${
                        !user?.extraPlayerCard?.used ? "py-3" : "py-1"
                      }`}
                    >
                      Card Used
                    </p>
                  ) : (
                    <p
                      align="center"
                      className={`text-white font-bold text-2xl px-4 ${
                        !user?.extraPlayerCard?.used ? "py-3" : "py-1"
                      }`}
                    >
                      Extra Player
                    </p>
                  )}


                  {activateExtraPlayerCard ? (
                    <></>
                  ) : user?.extraPlayerCard?.used ? (
                    <p className="text-[#E72929] font-bold text-lg text-shadow">
                      CARD USED
                    </p>
                  ) : (
                    <p className="text-[#A1DD70] font-bold text-lg text-shadow">
                      NOT USED
                    </p>
                  )}
                </button>
              </div>
              {activateExtraPlayerCard !== false && (
                <div className="mt-2 bg-green-200 px-4 py-4  mb- rounded-md text-md flex items-center mx-auto max-w-lg">
                  <svg
                    viewBox="0 0 24 24"
                    className="text-green-800 w-5 h-5 sm:w-5 sm:h-5 mr-3"
                  >
                    <path
                      fill="currentColor"
                      d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
                    ></path>
                  </svg>
                  <span className="text-green-800 text-center mx-auto font-semibold text-lg">
                    {" "}
                    You can now select 9 players{" "}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </details> */}

      <div>
        <div className="flex-shrink-0 relative overflow-hidden bg-orange-500 rounded-t-lg  shadow-lg pt-2 pb-5 px-2 mt-4">
          <svg
            className="absolute bottom-0 left-0 mb-8"
            viewBox="0 0 375 283"
            fill="none"
            style={{ transform: "scale(1.5)", opacity: "0.1" }}
          >
            <rect
              x="159.52"
              y="175"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 159.52 175)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 0 107.48)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 100 107.48)" // Adjusted the rotation center
              fill="white"
            />
          </svg>
          <div align="center" className="text-white font-extrabold text-2xl">
            Team List
          </div>
        </div>
        <ul className="col-span-2 bg-white justify-center rounded-b-lg">
          {Object.values(team?.squad).map((position) =>
            position.map((player, index) => (
              <li key={player.id} className="mx-2 flex justify-between ">
                <div className="flex justify-between items-center relative py-2 px-4 my-2 w-full bg-white border-gray-300 rounded-md shadow-lg overflow-auto text-lg font-semibold  ">
                  <div className="flex text-center items-center">
                    <p className="mr-2">{playerNumber++}.</p>
                    <img
                      src={player?.photo}
                      alt=""
                      className="h-10 w-10 flex-shrink-0 rounded-full mr-4"
                    />
                    <div className="flex flex-col justify-end">
                      <div className="flex">
                        <p className="m-0"> {player?.name}</p>
                        <p className={`${displayRoleColor(player?.role)}`}>
                          ({displayRole(player?.role)})
                        </p>
                      </div>
                      <p className="text-left">{player?.playerPosition}</p>
                      <p className="hidden">{(expense += player?.cost)}</p>
                    </div>
                  </div>
                  <p>${player?.cost}</p>
                </div>
              </li>
            ))
          )}
          <div className="flex justify-between items-center relative py-2 px-6 mt-2 w-full bg-white border-gray-300 rounded-md text-lg font-bold  ">
            <p> Amount Left :</p>
            <p>${wallet - expense}</p>
          </div>
          <li className="mx-2 flex justify-between items-center">
            <div className="flex justify-between items-center relative py-2 px-4 my-2 w-full rounded-md   ">
              {errorSubmit === false && submitSuccessful === false ? (
                <button
                  className=" w-full p-4 bg-orange-600 rounded-md "
                  onClick={FuncSubmit}
                >
                  <p className="font-bold text-xl font-sans text-white">
                    Submit Lineup
                  </p>
                </button>
              ) : errorSubmit === false && submitSuccessful === true ? (
                <button
                  className=" w-full p-4 bg-green-700 rounded-md "
                  // onClick={FuncSubmit}
                >
                  <p className="font-bold text-xl font-sans text-white">
                    Submitted
                  </p>
                </button>
              ) : (
                <button className=" w-full p-4 bg-red-600 rounded-md ">
                  <p className="text-white font-bold text-xl font-sans">
                    {" "}
                    {errorSubmit}{" "}
                  </p>
                </button>
              )}
            </div>
          </li>
          {/* <li className="mx-2 flex justify-center items-center">
            {errorSubmit !== false && (
              <div className="w-full bg-red-200 px-4 py-4  my-2 rounded-md text-md flex items-center mx-auto justify-center">
                <svg
                  viewBox="0 0 24 24"
                  className="text-red-600 w-5 h-5 sm:w-5 sm:h-5 mr-3"
                >
                  <path
                    fill="currentColor"
                    d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
                  ></path>
                </svg>
                <p className="text-red-800"> {errorSubmit} </p>
              </div>
            )}
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default FantasyLineupDemo;
