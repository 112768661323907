import React, { useEffect, useRef, useState } from "react";
import { useApp } from "../context/app-context";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sia from "../assets/sia.png";
import scs from "../assets/scs.jpeg";
//Here instead i=of importing match data we we would params to make an api call
import MatchData from "../data/MatchData";
import { useParams } from "react-router-dom";
import cc from "../assets/Charla.jpg";
import va from "../assets/VA.jpg";
import axios from "axios";
import io from "socket.io-client";
import yellowRedCardImg from "../assets/yellowRedCard.png";
import ball from "../assets/scorer.png";
import assistImg from "../assets/assist.png";
import DummyImg from "../assets/user.png";
import PlayerModal from "../components/PlayerModal";
import ReactGA from "react-ga4";

const SOCKET_URL = `${process.env.REACT_APP_SERVER_URL}`;
// const socket = io.connect(SOCKET_URL);

var socket = io(SOCKET_URL, { transports: ["websocket"] });

function EachMatch() {
  // pageYOffset.Url throws error
  // useEffect(() => {
  //   setUrl(MatchData[matchNumber - 1].ytUrl);
  // }, []);
  // this method would give any error if we reload
  // console.log(`https://www.youtube.com/embed/${eachMatchData?.ytUrl}`);
  // wont give an error if we reload
  // console.log(`https://www.youtube.com/embed/${url}`);
  //   const url = "LKdaVy3bOOY?si=thwx3ggnSJh4QYdX";\
  // Convert matchId to a number if needed
  // const matchUniqueId = parseInt(matchId);
  // Assuming MatchData contains the YouTube video ID
  //  const [url, setUrl] = useState("");
  // ---------------------trash ends

  const { eachMatchData, setEachMatchData } = useApp();
  const { matchId } = useParams(); // Assuming the parameter is named matchId
  const [eachMatchStat, setEachMatchStat] = useState(); // only this part keeps chnaging so only update this
  const [team1Data, setTeam1Data] = useState();
  const [team2Data, setTeam2Data] = useState();
  const [error, setError] = useState(null);
  const [updatedMatchstate, setUpdatedMatchstate] = useState();
  // const [filteredteam1Data, setFilteredTeam1Data] = useState();
  // const [filteredteam2Data, setFilteredTeam2Data] = useState();
  const detailsRefTeam1 = useRef(null); // Ref to access the <details> element
  const detailsRefTeam2 = useRef(null); // Ref to access the <details> element

  const targetRefTeams = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [team_logo, setTeam_logo] = useState(DummyImg);
  const [matchToday, setMatchToday] = useState();

  const closeModal = () => setIsModalOpen(false);

  const scrollToTargetTeam = (teamProp) => {
    //console.log("hello");
    targetRefTeams.current.scrollIntoView({ behavior: "smooth" });
    if (teamProp === "team1") {
      toggleDetailsTeam1();
    } else if (teamProp === "team2") {
      toggleDetailsTeam2();
    } else {
      //console.log("no ref fo team 1 or 2");
    }
  };

  // Function to toggle the open state of <details>
  const toggleDetailsTeam1 = () => {
    if (detailsRefTeam1.current) {
      detailsRefTeam1.current.open = true; // Toggle the 'open' attribute
    }
  };
  const toggleDetailsTeam2 = () => {
    if (detailsRefTeam2.current) {
      detailsRefTeam2.current.open = true; // Toggle the 'open' attribute
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    socket.on("connect", () => {
      //console.log("Connected to the server");
    });
    socket.on("stats-updated", (updatedMatch) => {
      //console.log("Match updated:", updatedMatch);
      setUpdatedMatchstate(updatedMatch);
      setEachMatchStat(updatedMatch?.stats);
      // Handle the updated match data (e.g., update the state or UI)
    });
  }, []);

  useEffect(() => {
    getEachMatchData(
      `${process.env.REACT_APP_SERVER_URL}/api/match/${matchId}`
    );
  }, []);

  async function getEachMatchData(url) {
    try {
      const response = await axios.get(url);
      //console.log("GET Match request successful");
      //console.log(response.data.match);
      setEachMatchData(response.data.match);
      setEachMatchStat(response.data.match.stats);
      demo(response.data.match.date);
      //call players of team
      // ******* passed response.data.match in getTeam1Data,getTeam2Data as props beacuse when i was reloading eachData wasnt setting on time causing the player list to empty
      //********** I can either filter the players acc to teams and then again filter according to lineup or directly filter them from total player list
      const team1Name = response.data.match.team1;
      getTeam1Data(
        `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team1Name}&limit=1000`,
        response.data.match
      );
      const team2Name = response.data.match.team2;
      getTeam2Data(
        `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team2Name}&limit=1000`,
        response.data.match
      );
      //console.log(eachMatchData);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }
  async function getTeam1Data(url, propsEachmatchData) {
    try {
      const response = await axios.get(url);
      //console.log("GET Team1 Players request successful");
      //console.log(response.data.players);
      // setTeam1Data(response.data.players);
      //console.log(propsEachmatchData);
      const filteredTeam1Players = response.data.players.filter((player) =>
        propsEachmatchData?.lineup1.includes(player._id)
      );
      setTeam1Data(filteredTeam1Players);

      //console.log("filtered team1 players", filteredTeam1Players);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }
  async function getTeam2Data(url, propsEachmatchData) {
    try {
      const response = await axios.get(url);
      //console.log("GET Team2 Players request successful");
      //console.log(response.data.players);
      // setTeam2Data(response.data.players);
      //console.log(propsEachmatchData);

      const filteredTeam2Players = response.data.players.filter((player) =>
        propsEachmatchData?.lineup2.includes(player._id)
      );
      setTeam2Data(filteredTeam2Players);

      //console.log("filtered team2 players", filteredTeam2Players);
    } catch (error) {
      console.error("Error making GET match request:", error);
      // Handle errors
      setError("Error fetching GET match request. Please try again later.");
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Transition speed in milliseconds
    autoplay: true, // Auto play slides
    autoplaySpeed: 3000, // Delay between slides in milliseconds
    slidesToShow: 1, // Number of slides to show at a time --- SELECT THE NUMBER OF SLIDE TO DISPLAY AT A TIME FROM HERE
    slidesToScroll: 1, // Number of slides to scroll at a time
  };

  const sponsors = [
    {
      id: 1,
      imageUrl: "Sia jwellers",
      link: sia,
    },
    {
      id: 2,
      imageUrl: "Selection Centre Sports",
      link: scs,
    },
    // Add more sponsors as needed
  ];
  const people = [
    {
      id: 1,
      imageUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      name: "Leslie",
      position: "Center Forward (CF)",
      role: "Player",
      lastInjured: "3h ago",
      lastInjuredDateTime: "2023-01-23T13:23Z",
    },
    {
      id: 2,
      imageUrl:
        "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      name: "Michael",
      position: "Center Back (CB)",
      role: "Player",
      lastInjured: "3h ago",
      lastInjuredDateTime: "2023-01-23T13:23Z",
    },
    {
      id: 3,
      imageUrl:
        "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      name: "Dries",
      position: "Center Back (CB)",
      role: "Player",
      lastInjured: null,
    },
    {
      id: 4,
      imageUrl:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      name: "Lindsay",
      position: "Mid-fielder (MF)",
      role: "Player",
      lastInjured: "3h ago",
      lastInjuredDateTime: "2023-01-23T13:23Z",
    },
    {
      id: 5,
      imageUrl:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      name: "Courtney",
      position: "Mid-fielder (MF)",
      role: "Captain",
      lastInjured: "3h ago",
      lastInjuredDateTime: "2023-01-23T13:23Z",
    },
    {
      id: 6,
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      name: "Tom",
      position: "Goal Keeper (GK)",
      role: "Player",
      lastInjured: null,
    },
  ];
  const team1Stat = [
    {
      assist: "6617f8fd957b7f5939b1c664",
      minute: 12,
      scorer: "6617f8fd957b7f5",
      _id: "6619556d092f0124ba8e2816",
    },
    // Second object
    {
      assist: "6617f8fd957b7f5939b1c667",
      minute: 25,
      scorer: "6617f8fd957b7f5",
      _id: "6619556d092f0124ba8e2820",
    },
    // Third object
    {
      assist: "6617f8fd957b7f5939b1c671",
      minute: 42,
      scorer: "6617f8fd957b7f5",
      _id: "6619556d092f0124ba8e2830",
    },
  ];
  const team2Stat = [
    {
      assist: "6617f8fd957b7f5939b1c674",
      minute: 15,
      scorer: "6617f8fd957b7f59",
      _id: "6619556d092f0124ba8e2840",
    },
    // Second object
    {
      assist: "6617f8fd957b7f5939b1c677",
      minute: 28,
      scorer: "6617f8fd957b7f59",
      _id: "6619556d092f0124ba8e2850",
    },
    // Third object
    {
      assist: "6617f8fd957b7f5939b1c681",
      minute: 35,
      scorer: "6617f8fd957b7f59",
      _id: "6619556d092f0124ba8e2860",
    },
  ];
  const team1YellowCard = [
    {
      minute: 15,
      player: "6617f8fd957b7f59",
      _id: "6619556d092f0124ba8e24e9",
    },
  ];
  const team1RedCard = [
    {
      minute: 20,
      player: "6617f8fd957b7f59",
      _id: "6619556d092f0124ba8e24f9",
    },
    // Second object
    {
      minute: 40,
      player: "6617f8fd957b7f59",
      _id: "6619556d092f0124ba8e35f9",
    },
  ];

  const team2YellowCard = [
    {
      minute: 10,
      player: "6617f8fd957b7f60",
      _id: "6619556d092f0124ba8e24e1",
    },
    // Second object
    {
      minute: 22,
      player: "6617f8fd957b7f60",
      _id: "6619556d092f0124ba8e35e2",
    },
  ];

  const team2RedCard = [
    {
      minute: 18,
      player: "6617f8fd957b7f60",
      _id: "6619556d092f0124ba8e24f4",
    },
  ];
  const [showDetails, setShowDetails] = useState(false);

  const openPhotoModalFunc = (photo) => {
    setIsModalOpen(true);
    setTeam_logo(photo);
  };
  const demo = (dateProp) => {
    const isTodayMatchDate = (date) => {
      // Parse the match date string into a Date object
      const [day, month, year] = date.split("-").map(Number);
      const matchDate = new Date(year, month - 1, day);

      // Get the current date
      const currentDate = new Date(dateProp);

      // Extract and compare the date parts
      const isSameDate =
        currentDate.getFullYear() === matchDate.getFullYear() &&
        currentDate.getMonth() === matchDate.getMonth() &&
        currentDate.getDate() === matchDate.getDate();

      return isSameDate;
    };

    // Check if today is the match date
    const result = isTodayMatchDate(dateProp);
    setMatchToday(result);
    //console.log(result);
  };

  const watched_yt = () => {
    const teamNameMapping = {
      "Mango Marvels": "Mango",
      "Feonn Furies": "Feonn",
      "Vandana Warriors": "Vandana",
      "Bellicosa FC": "Bellicosa",
      "Sejal Scorchers": "Sejal",
      "Team Sagar": "Sagar",
      "Sakori Smashers": "Sakori",
    };
    const team1 = teamNameMapping[eachMatchData?.team1];
    const team2 = teamNameMapping[eachMatchData?.team2];
    const temp = `${team1} vs ${team2}`;
    ReactGA.event({
      category: "Match",
      action: "click_yt_video_new",
      label: temp,
    });
  };

  const checked_lineup = () => {
    const teamNameMapping = {
      "Mango Marvels": "Mango",
      "Feonn Furies": "Feonn",
      "Vandana Warriors": "Vandana",
      "Bellicosa FC": "Bellicosa",
      "Sejal Scorchers": "Sejal",
      "Team Sagar": "Sagar",
      "Sakori Smashers": "Sakori",
    };
    const team1 = teamNameMapping[eachMatchData?.team1];
    const team2 = teamNameMapping[eachMatchData?.team2];
    const temp = `${team1} vs ${team2}`;
    ReactGA.event({
      category: "Match",
      action: "clicked_match_lineup_new",
      label: temp,
    });
  };
  // clicked_match_lineup_new,click_yt_video_new --- Date:28-6-24

  return (
    <>
      <div className=" overflow-hidden bg-[#192347] px-6 py-24  lg:px-0">
        <PlayerModal
          isOpen={isModalOpen}
          onClose={closeModal}
          team_logo={team_logo}
        />
        <div className="mx-auto">
          <div className="lg:gap-x-8 lg:px-8">
            {eachMatchData ? (
              <div>
                {/* <div className=" text-base leading-7 text-gray-300 "></div>{" "} */}
                <h1
                  className="text-4xl font-bold tracking-tight text-indigo-700 sm:text-6xl  text-center"
                  onClick={() => {
                    //console.log(eachMatchData);
                  }}
                >
                  Live Match
                </h1>
                <p className="flex flex-col  justify-center md:flex-row mt-6 text-4xl leading-8  tracking-tight text-[#E1C564] font-bold bg-gradient-to-l text-center">
                  <span className="mx-1">{eachMatchData.team1}</span>{" "}
                  <span className=" md:items-end items-center">
                    <span className="text-white	 text-xl mx-1 ">VS</span>
                  </span>
                  <span className="mx-1">{eachMatchData.team2}</span>
                </p>
                {/* Embed YouTube video */}

                <div
                  className="relative overflow-hidden mt-5"
                  style={{ paddingBottom: "56.25%" }}
                >
                  {/* 
        The paddingBottom trick here is to maintain the aspect ratio (16:9).
        56.25% is the ratio of height to width (9 / 16 * 100).
    */}
                  <iframe
                    className="absolute top-0 left-0 w-full h-full min-w-full  rounded-xl overflow-hidden"
                    src={`https://www.youtube.com/embed/${eachMatchData.link}?modestbranding=1&showinfo=0`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen={true}
                    onClick={watched_yt}
                  ></iframe>
                </div>

                {/* chnange max-w- to alter center space between players */}
                <div className="mx-auto my-5 max-w-xl rounded overflow-hidden shadow-md text-xs">
                  <div className="flex bg-gray-200 px-2 py-2 justify-between">
                    <div className="w-5/12 text-gray-700 text-left ">
                      Week {eachMatchData?.week}
                    </div>
                    {/* <div className="w-5/12 flex justify-end items-center">
                      <p className="w-8 px-2 text-center">1</p>
                      <p className="w-8 px-2 text-center">2</p>
                      <p className="w-8 px-2 text-center">3</p>
                      <p className="w-8 px-2 text-center">4</p>
                    </div> */}
                    {eachMatchData?.live ? (
                      <div className="w-1/6 text-red-700 text-right">Live</div>
                    ) : (
                      <div className="w-1/6 text-gray-700 text-right">
                        Not Live
                      </div>
                    )}
                  </div>
                  <div className="flex items-center bg-white">
                    <div className="flex px-2 py-2 items-end bg-white flex-col w-full cursor-pointer">
                      {/* Raptors Section */}
                      <div
                        className="w-full flex items-center flex-col"
                        onClick={() => scrollToTargetTeam("team1")}
                      >
                        <img
                          className="w-12 sm:w-10 mr-2 self-center justify-center"
                          alt="away-logo"
                          src={eachMatchData?.team1logo}
                        />

                        <div className="flex ">
                          <p className=" text-base font-bold text-center hover:text-indigo-600">
                            {eachMatchData?.team1}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className=" bg-white text-2xl flex px-2 py-2  text-nowrap">
                      {eachMatchStat?.team1?.totalGoals} -{" "}
                      {eachMatchStat?.team2?.totalGoals}
                    </p>

                    <div className="flex px-2 py-2 items-end bg-white flex-col w-full cursor-pointer">
                      {/* Raptors Section */}
                      <div
                        className="w-full flex items-center flex-col"
                        onClick={() => scrollToTargetTeam("team2")}
                      >
                        <img
                          className="w-12 sm:w-10 mr-2 self-center justify-center"
                          alt="away-logo"
                          src={eachMatchData?.team2logo}
                        />

                        <div className="flex ">
                          <p className=" text-base font-bold text-center hover:text-indigo-600">
                            {eachMatchData?.team2}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="block border-t-2 border-gray-300  bg-white">
                    <div className="flex justify-center my-2 items-center">
                      <p className="text-center  text-gray-500 font-semibold text-md  uppercase">
                        Goals Scored
                      </p>
                      <img src={ball} className="mx-2 w-5" />
                    </div>

                    {/* column wise sepration */}

                    <div className="flex">
                      <div className="w-1/2   flex">
                        <div className="flex flex-col">
                          {eachMatchStat?.team1?.goals?.map((goalScored) => (
                            <>
                              <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600">
                                  {goalScored.minute}'
                                </p>
                                <div className="flex items-center">
                                  <img
                                    className="w-10 mr-2 self-center rounded-md  "
                                    src={goalScored.scorer.photo}
                                    alt="Player 1"
                                    onClick={() =>
                                      openPhotoModalFunc(
                                        goalScored.scorer.photo
                                      )
                                    }
                                  />
                                  <div className="flex flex-col">
                                    <p className="font-semibold">
                                      {goalScored.scorer.name}
                                    </p>
                                    <p className="text-gray-600">Goal Scored</p>
                                  </div>
                                </div>
                              </div>
                              {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                            </>
                          ))}
                        </div>
                      </div>

                      <div className=" w-1/2 flex  justify-end px-1">
                        <div className="flex flex-col">
                          {eachMatchStat?.team2?.goals?.map((goalScored) => (
                            <>
                              {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                              <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600">
                                  {goalScored.minute}'
                                </p>
                                <div className="flex items-center">
                                  <div className="flex flex-col items-end">
                                    <p className="font-semibold">
                                      {goalScored.scorer.name}
                                    </p>
                                    <p className="text-gray-600">Goal Scored</p>
                                  </div>
                                  <img
                                    className="w-10 ml-2 self-center rounded-md"
                                    src={goalScored.scorer.photo}
                                    alt="Player 1"
                                    onClick={() =>
                                      openPhotoModalFunc(
                                        goalScored.scorer.photo
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* <p className="text-center  text-gray-500 font-semibold text-md m-1 mb-2 uppercase ">
                      Assist
                    </p> */}
                    <div className="flex justify-center p-2 border-t border-gray-300 items-center">
                      <p className="text-center  text-gray-500 font-semibold text-md  uppercase">
                        Assist
                      </p>
                      <img src={assistImg} className="mx-2 w-5" />
                    </div>
                    {/* column wise sepration */}

                    <div className="flex">
                      <div className="w-1/2   flex">
                        <div className="flex flex-col">
                          {eachMatchStat?.team1?.goals?.map((goalScored) => (
                            <>
                              {goalScored.assist !== undefined &&
                                goalScored.assist !== null && (
                                  <div className=" px-2 py-2 flex flex-col  mb-1">
                                    <p className="justify-center text-center  mb-2 text-green-600">
                                      {goalScored.minute}'
                                    </p>
                                    <div className="flex items-center">
                                      <img
                                        className="w-10 mr-2 self-center rounded-md"
                                        src={goalScored.assist.photo}
                                        alt="Player 1"
                                        onClick={() =>
                                          openPhotoModalFunc(
                                            goalScored.assist.photo
                                          )
                                        }
                                      />
                                      <div className="flex flex-col">
                                        <p className="font-semibold">
                                          {goalScored.assist.name}
                                        </p>
                                        <p className="text-gray-600">
                                          Assisted
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                            </>
                          ))}
                        </div>
                      </div>

                      <div className=" w-1/2 flex  justify-end px-1">
                        <div className="flex flex-col">
                          {eachMatchStat?.team2?.goals?.map((goalScored) => (
                            <>
                              {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                              {goalScored.assist !== undefined &&
                                goalScored.assist !== null && (
                                  <div className=" px-2 py-2 flex flex-col  mb-1">
                                    <p className="justify-center text-center  mb-2 text-green-600">
                                      {goalScored.minute}'
                                    </p>
                                    <div className="flex items-center">
                                      <div className="flex flex-col items-end">
                                        <p className="font-semibold">
                                          {goalScored.assist.name}
                                        </p>
                                        <p className="text-gray-600">
                                          Assisted
                                        </p>
                                      </div>
                                      <img
                                        className="w-10 ml-2 self-center rounded-md"
                                        src={goalScored.assist.photo}
                                        alt="Player 1"
                                        onClick={() =>
                                          openPhotoModalFunc(
                                            goalScored.assist.photo
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-center p-2 border-t border-gray-300 items-center">
                      <p className="text-center  text-gray-500 font-semibold text-md  uppercase">
                        Cards
                      </p>
                      <img src={yellowRedCardImg} className="mx-2 w-5" />
                    </div>
                    <div className="flex">
                      <div className="w-1/2   flex">
                        <div className="flex flex-col">
                          {eachMatchStat?.team1?.yellowCards?.map(
                            (yellowCard) => (
                              <>
                                <div className=" px-2 py-2 flex flex-col  mb-1">
                                  <p className="justify-center text-center  mb-2 text-green-600">
                                    {yellowCard.minute}'
                                  </p>
                                  <div className="flex items-center">
                                    <img
                                      className="w-10 mr-2 self-center rounded-md"
                                      src={yellowCard.player.photo}
                                      alt="Player 1"
                                      onClick={() =>
                                        openPhotoModalFunc(
                                          yellowCard.player.photo
                                        )
                                      }
                                    />
                                    <div className="flex flex-col">
                                      <p className="font-semibold">
                                        {yellowCard.player.name}
                                      </p>
                                      <p className="text-yellow-500">
                                        Yellow Card
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600"></p>
                                <div className="flex items-center">
                                  <div className="flex flex-col">
                                    <p className="font-semibold"></p>
                                    <p className="text-gray-600"></p>
                                  </div>
                                  <div className="h-10 mr-2 self-center" />
                                </div>
                              </div> */}
                              </>
                            )
                          )}
                          {eachMatchStat?.team1?.redCards?.map((redCard) => (
                            <>
                              <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600">
                                  {redCard.minute}'
                                </p>
                                <div className="flex items-center">
                                  <img
                                    className="w-10 mr-2 self-center rounded-md"
                                    src={redCard.player.photo}
                                    alt="Player 1"
                                    onClick={() =>
                                      openPhotoModalFunc(redCard.player.photo)
                                    }
                                  />
                                  <div className="flex flex-col">
                                    <p className="font-semibold">
                                      {redCard.player.name}
                                    </p>
                                    <p className="text-red-500">Red Card</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      <div className=" w-1/2 flex  justify-end px-1">
                        <div className="flex flex-col">
                          {eachMatchStat?.team2?.yellowCards?.map(
                            (yellowCard) => (
                              <>
                                <div className=" px-2 py-2 flex flex-col  mb-1">
                                  <p className="justify-center text-center  mb-2 text-green-600">
                                    {yellowCard.minute}'
                                  </p>
                                  <div className="flex items-center justify-end">
                                    <div className="flex flex-col items-end">
                                      <p className="font-semibold">
                                        {yellowCard.player.name}
                                      </p>
                                      <p className="text-yellow-500">
                                        Yellow Card
                                      </p>
                                    </div>
                                    <img
                                      className="w-10 ml-2 self-center rounded-md"
                                      src={yellowCard.player.photo}
                                      alt="Player 1"
                                      onClick={() =>
                                        openPhotoModalFunc(
                                          yellowCard.player.photo
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )
                          )}
                          {eachMatchStat?.team2?.redCards?.map((redCard) => (
                            <>
                              <div className=" px-2 py-2 flex flex-col  mb-1">
                                <p className="justify-center text-center  mb-2 text-green-600">
                                  {redCard.minute}'
                                </p>
                                <div className="flex items-end justify-end">
                                  <div className="flex flex-col items-end">
                                    <p className="font-semibold">
                                      {redCard.player.name}
                                    </p>
                                    <p className="text-red-500">Red Card</p>
                                  </div>
                                  <img
                                    className="w-10 ml-2 self-center rounded-md"
                                    src={redCard.player.photo}
                                    alt="Player 1"
                                    onClick={() =>
                                      openPhotoModalFunc(redCard.player.photo)
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <p className="text-center font-medium text-lg  text-gray-500 m-1 uppercase">
                        Game log
                      </p>
                    </div>
                  </div>
                  {/* Top Performers Section */}

                  {/* Game Info Section */}
                  <div className="border-t bg-gray-200 text-center px-1 py-1 border-b border-gray-300 uppercase">
                    {eachMatchData.stage === "Semi-final" ? (
                      <p className="text-gray-600">Semi-Finals</p>
                    ) : eachMatchData.stage === "Final" ? (
                      <p className="text-gray-600">Finals</p>
                    ) : (
                      <p className="text-gray-600">
                        {eachMatchData?.stage} match
                      </p>
                    )}
                  </div>

                  {/* Venue Info Section */}
                  <div className="flex border-t bg-gray-200">
                    {/* Stadium Section */}
                    <div className="w-1/2 px-2 py-2 text-center">
                      <p className="font-semibold text-gray-700 hover:text-indigo-600 cursor-pointer">
                        {eachMatchData?.location === "St Andrews Bandra" ? (
                          <a href="https://maps.app.goo.gl/td7DocNRCuCaUqLh8">
                            {eachMatchData?.location}
                          </a>
                        ) : (
                          <span>{eachMatchData?.location}</span>
                        )}
                      </p>
                    </div>
                    {/* Line and O/U Section */}
                    <div className="w-1/2 px-2 py-2 text-center">
                      <p className="text-gray-600">
                        <span className="font-semibold text-md">VFL 2024</span>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Player List */}
                <div
                  className="flex flex-col justify-center item-center text-center gap-4"
                  ref={targetRefTeams}
                >
                  <ul
                    role="list"
                    className="flex flex-col items-center sm:flex-row sm:gap-4 justify-center mt-10  sm:w-auto gap-4 "
                  >
                    <li
                      className="flex w-full "
                      onClick={() => {
                        checked_lineup(eachMatchData?.team1);
                      }}
                    >
                      <details className="group w-full" ref={detailsRefTeam1}>
                        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
                          <div
                            className="flex justify-between w-full"
                            // className="flex justify-center w-full"

                            onClick={() => setShowDetails(true)}
                          >
                            <div className="flex gap-5">
                              <div className="min-w-0 flex-auto">
                                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#3876BF]">
                                  {eachMatchData?.team1}
                                </p>
                              </div>
                            </div>
                          </div>
                          <svg
                            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            ></path>
                          </svg>
                        </summary>
                        <div className="p-1 rounded-lg border-4 border-[#12486B] mt-5 sm:mt-0  flex justify-center flex-col bg-white">
                          <ul className="w-full my-2">
                            {" "}
                            {/* Add w-full className to make the ul cover the whole width */}
                            {team1Data?.map((person) => (
                              <li
                                key={person._id}
                                className="flex justify-start gap-x-6 py-1 bg-white mx-2"
                              >
                                <div className="flex gap-x-1 w-full">
                                  {" "}
                                  {/* Add w-full className to make the div cover the whole width */}
                                  <div className="relative w-full">
                                    {" "}
                                    {/* Add w-full className to make the relative div cover the whole width */}
                                    {/* Move the comment outside of the JSX element */}
                                    {/* Add w-full className to make the li cover the whole width */}
                                    <label className="w-full h-[80px] border-2 border-[#088395] cursor-pointer flex flex-row justify-between items-center  rounded-lg p-4 active:bg-[#124076] peer-focus:outline-none peer-focus:ring peer-focus:ring-[#8ECDDD] peer-checked:border-[#8ECDDD] peer-checked:bg-violet-100 hover:bg-[#E4F1FF]">
                                      <div className="flex flex-row justify-between items-center text-start w-full ml-1 mr-4">
                                        {" "}
                                        {/* Add w-full className to make the inner div cover the whole width */}
                                        <div>
                                          <h3 className="font-bold">
                                            {person.name}
                                          </h3>
                                          <p className="text-sm text-gray-400">
                                            {person.position}
                                          </p>
                                        </div>
                                        <img
                                          src={person.photo}
                                          alt="No image"
                                          className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                          onClick={() =>
                                            openPhotoModalFunc(person.photo)
                                          }
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </details>
                    </li>
                    <li
                      className="flex w-full "
                      onClick={() => {
                        checked_lineup(eachMatchData?.team2);
                      }}
                    >
                      <details className="group w-full" ref={detailsRefTeam2}>
                        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
                          <div
                            className="flex justify-between w-full"
                            // className="flex justify-center w-full"
                            onClick={() => setShowDetails(true)}
                          >
                            <div className="flex gap-5">
                              <div className="min-w-0 flex-auto text">
                                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-[#3876BF]">
                                  {eachMatchData?.team2}
                                </p>
                              </div>
                            </div>
                          </div>
                          <svg
                            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            ></path>
                          </svg>
                        </summary>
                        <div className="p-1 rounded-lg border-4 border-[#12486B]  mt-5 sm:mt-0  flex justify-center flex-col bg-white">
                          <ul className="w-full my-2">
                            {" "}
                            {/* Add w-full className to make the ul cover the whole width */}
                            {team2Data?.map((person) => (
                              <li
                                key={person._id}
                                className="flex justify-start gap-x-6 py-1 bg-white mx-2  "
                              >
                                <div className="flex gap-x-1 w-full">
                                  {" "}
                                  {/* Add w-full className to make the div cover the whole width */}
                                  <div className="relative w-full">
                                    {" "}
                                    {/* Add w-full className to make the relative div cover the whole width */}
                                    {/* Move the comment outside of the JSX element */}
                                    {/* Add w-full className to make the li cover the whole width */}
                                    <label className="w-full h-[80px] border-2 border-[#088395] cursor-pointer flex flex-row justify-between items-center  rounded-lg p-4 active:bg-[#124076] peer-focus:outline-none peer-focus:ring peer-focus:ring-[#8ECDDD] peer-checked:border-[#8ECDDD] peer-checked:bg-violet-100 hover:bg-[#E4F1FF]">
                                      <div className="flex flex-row justify-between items-center text-start w-full ml-1 mr-4">
                                        {" "}
                                        {/* Add w-full className to make the inner div cover the whole width */}
                                        <div>
                                          <h3 className="font-bold">
                                            {person.name}
                                          </h3>
                                          <p className="text-sm text-gray-400">
                                            {person.position}
                                          </p>
                                        </div>
                                        <img
                                          src={person.photo}
                                          alt="No image"
                                          className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                          onClick={() =>
                                            openPhotoModalFunc(person.photo)
                                          }
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </details>
                    </li>
                    {/* <button >Submit</button> */}
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EachMatch;
