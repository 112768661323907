import { CalendarDaysIcon, HandRaisedIcon } from "@heroicons/react/24/outline";
import vagadLogo from "../assets/league_name-noBg.png";
import ReactGA from "react-ga4";

export default function Footer() {
  const viewProfileUV = () => {
    ReactGA.event({
      category: "view_my_profile",
      action: "clicked_my_profile_link_new",
      label: "yuvraj_profile",
      // value: match._id,
    });
  };
  const viewSocials = (social) => {
    if (social == "instagram") {
      ReactGA.event({
        category: "view_socials",
        action: "clicked_social_link_new",
        label: "instagram",
      });
    } else if (social == "facebook") {
      ReactGA.event({
        category: "view_socials",
        action: "clicked_social_link_new",
        label: "facebook",
      });
    } else if (social == "youtube") {
      ReactGA.event({
        category: "view_socials",
        action: "clicked_social_link_new",
        label: "youtube",
      });
    }
  };
  // clicked_social_link_new,clicked_my_profile_link_new --- Date:28-6-24
  return (
    // changed pb to pb-32 to get profile little up
    <div className="relative pb-32 isolate overflow-hidden bg-white-900 border-gray-700 border-t-2 border-b-2 py-1 sm:py-2 lg:py-4">
      <div className=" pt-4  ">
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4 px-4 md:px-18 lg:px-8 mx-auto">
          <div className="sm:col-span-2">
            <a
              href="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <img
                src={vagadLogo}
                alt=""
                srcSet=""
                className="h-18 w-24 filter invert hue-rotate-180 grayscale-100"
              />
              {/* <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                Company
              </span> */}
            </a>
            <div className="mt-2 lg:max-w-sm">
              <p className="text-md text-gray-800">
                Unleash the roar of football passion!
              </p>
              {/* <p className="mt-4 text-sm text-gray-800">
                Eaque ipsa quae ab illo inventore veritatis et quasi architecto
                beatae vitae dicta sunt explicabo.
              </p> */}
            </div>
          </div>
          <div className="space-y-2 text-md">
            <p className="text-base font-bold tracking-wide text-gray-900">
              Contacts
            </p>
            <div className="flex">
              <p className="mr-1 text-gray-800">Phone:</p>
              <a
                href="tel:850-123-5021"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                8450914850
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 text-gray-800">Email:</p>
              <a
                href="mailto:vagadfootballleague@gmail.com"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                vagadfootballleague@gmail.com
              </a>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide text-gray-900">
              Socials
            </span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="https://www.instagram.com/vagadfootballleague?igsh=aXFmdGpzdjh1d2hx"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400 "
                onClick={() => {
                  viewSocials("instagram");
                }}
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/share/w2UBfm7LtriDnDxE/?mibextid=qi2Omg"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400 "
                onClick={() => {
                  viewSocials("facebook");
                }}
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@vfl2024"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400 "
                onClick={() => {
                  viewSocials("youtube");
                }}
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-6">
                  <path d="M23.499,6.203c-0.28-1.06-1.12-1.891-2.171-2.174C19.496,3.616,12,3.616,12,3.616s-7.496,0-9.329,0.413C1.621,4.312,0.781,5.143,0.5,6.203C0.09,8.03,0,12,0,12s0.09,3.97,0.5,5.797c0.28,1.06,1.12,1.89,2.171,2.173C4.504,20.384,12,20.384,12,20.384s7.496,0,9.329-0.413c1.051-0.283,1.891-1.113,2.171-2.173C23.91,15.97,24,12,24,12S23.91,8.03,23.499,6.203z M9.545,15.568v-7.136L15.545,12L9.545,15.568z" />
                </svg>
              </a>
            </div>
            <p className="mt-4 text-md text-gray-500">
              Join our community for exciting updates and special announcements!
            </p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between pt-5 pb-10 border-t border-gray-700 lg:flex-row px-4 md:px-18 lg:px-8 mx-auto">
          {/* <p className="text-sm text-gray-600">
            © Copyright 2020 Lorem Inc. All rights reserved.
          </p> */}
          {/* <p className="text-gray-600">
            &copy; {new Date().getFullYear()}{" "}
            <a
              href="https://www.example.com"
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Example Company
            </a>
            . All rights reserved.
          </p> */}
          <ul className="flex mt-2 flex-col items-start mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row  gap-x-4 sm:items-end">
            <li className="text-lg ">Contact WebApp Developers</li>
            <div className="flex gap-x-8">
              <li
                className="flex gap-x-1 items-end"
                onClick={() => {
                  viewProfileUV();
                }}
              >
                <a href="https://linkin.bio/yuvrajrasal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#2a619d"
                    className="size-6 hover:text-indigo-600 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </a>

                <a
                  href="https://linkin.bio/yuvrajrasal"
                  className="text-lg text-[#2a619d] transition-colors duration-300 hover:text-indigo-600"
                >
                  Yuvraj Rasal
                </a>
              </li>
              <li className="flex gap-x-1 items-end">
                <a href="mailto:smitdama09@gmail.com">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#2a619d"
                    className="size-6 hover:text-indigo-600 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                </a>

                <a
                  href="mailto:smitdama09@gmail.com"
                  className="text-lg text-[#2a619d] transition-colors duration-300 hover:text-indigo-600"
                >
                  Smit Dama
                </a>
              </li>
            </div>

            {/* <li>
              <a
                href="/"
                className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Terms &amp; Conditions
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
