import React from "react";
import titleSponsor from "../assets/sia.png";
import league_name from "../assets/league_name.png";
import league_logo from "../assets/VFLLogo.png";
import poweredBy from "../assets/poweredBy.png";

function TopBanner() {
  return (
    <>
      {/* <div className=" overflow-hidden bg-[#192347] pt-24 justify-between items-center">
        <div className="px-4 py-4 m-4   md:px-24 lg:px-8 bg-white rounded-lg">
          <div className="grid grid-cols-2 row-gap-2 md:grid-cols-2 h-max-48 max-h-48">
            <div className="flex text-center md:border-r justify-center">
              <img
                src={league_logo}
                alt="title-sponsor"
                srcSet=""
                className="max-w-128 max-h-64 "
              />
            </div>
            <div className="flex text-center justify-center">
              <img
                src={titleSponsor}
                alt="title-sponsor"
                srcSet=""
                className="w-3/5 max-w-96 max-h-64 "
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className="px-4 py-4 sm:m-4  mt-10  sm:mt-10 lg:mt-16 md:px-24 lg:px-8 bg-white rounded-lg">
        <div className="grid grid-cols-2 row-gap-2 md:grid-cols-2 max-h-48">
          <div className="flex text-center justify-center flex-col">
            <img
              src={league_logo}
              alt="title-sponsor"
              srcSet=""
              className=" max-h-36     "
            />
            {/* <p className="leading-2 text-md font-bold text-[#30395f] sm:text-lg md:text-xl lg:text-2xl">
              POWERED BY
            </p> */}
          </div>
          <div className="flex text-center justify-center">
            <img
              src={poweredBy}
              alt="title-sponsor"
              srcSet=""
              className="w-4/5 md:w-2/5 max-w-96 max-h-36 "
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TopBanner;
