import { Listbox, Transition } from "@headlessui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Combobox } from "@headlessui/react";
import { useApp } from "../../context/app-context";
import DummyImg from "../../assets/user.png";

// const people = [
//   "Wade Cooper",
//   "Arlene McCoy",
//   "Devon Webb",
//   "Tom Cook",
//   "Tanya Fox",
//   "Hellen Schmidt",
// ];

function FantasyPlayerSelection() {
  const {
    selectedPlayer,
    setSelectedPlayer,
    allModifiedPlayers,
    setAllModifiedPlayers,
  } = useApp();
  const [error, setError] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState();
  const [allTeamData, setAllTeamData] = useState();
  // const [allPlayers, setAllPlayers] = useState()
  useEffect(() => {
    PlayerDataFunc(`${process.env.REACT_APP_SERVER_URL}/api/player?limit=1000`);
    fetchTeamData();
  }, []);

  async function PlayerDataFunc(url) {
    try {
      const response = await axios.get(url);
      console.log("GET Player Data request successful");
      //   console.log(response.data);
      //   setAllPlayers(response.data.players);
      const formattedPlayers = response.data.players.map((player) => ({
        _id: player._id,
        name: player.name,
        playerPosition: player.position?.split(",")[0].trim(),
        photo: player.photo,
        cost: player.cost,
        points: player.points,
      }));
      //   console.log("formattedPlayers", formattedPlayers);

      setAllModifiedPlayers(formattedPlayers);
      const positions = response.data.players.map((player) =>
        player.position?.split(",")[0].trim()
      );
      // console.log("positions", positions);
    } catch (error) {
      console.error("Error making GET Player request:", error);
      // Handle errors
      setError("Error fetching player GET  request. Please try again later.");
    }
  }
  const fetchTeamData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/team`
      );
      const data = response.data;
      setAllTeamData(data.teams);
      //console.log(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  // -----------------
  // const filteredPeople =
  //   query === ""
  //     ? people
  //     : people.filter((person) => {
  //         return person.toLowerCase().includes(query.toLowerCase());
  //       });
  const teamfilter = (team) => {
    console.log(team);
    PlayerDataFunc(
      `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team.name}&limit=1000`
    );
  };
  return (
    <>
      <div className="">
        <Listbox value={selectedTeam} onChange={setSelectedTeam}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-white">
                Search team:
              </Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default  py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 flex items-center h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                  <span className="flex items-center">
                    <img
                      src={selectedTeam?.team_logo}
                      alt=""
                      className="h-8 w-8flex-shrink-0 rounded-full"
                    />
                    <span className="ml-3 block truncate">
                      {selectedTeam?.name}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {/* <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"> */}
                    {allTeamData?.map((team, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={team}
                      >
                        {({ selectedTeam, active }) => (
                          <>
                            <div
                              className="flex items-center"
                              onClick={() => {
                                teamfilter(team);
                              }}
                            >
                              <img
                                src={team.team_logo}
                                alt=""
                                className="h-8 w-8 flex-shrink-0 rounded-full"
                              />
                              <span
                                className={classNames(
                                  selectedTeam
                                    ? "font-semibold"
                                    : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {team.name}
                              </span>
                            </div>

                            {selectedTeam ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <Listbox value={selectedPlayer} onChange={setSelectedPlayer}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-white">
                Players present :
              </Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default  py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 flex items-center h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                  <span className="flex items-center">
                    {/* <img
                      
                      alt=""
                      className="h-8 w-8 flex-shrink-0 rounded-full"
                    /> */}
                    <ImageWithFallback
                      src={selectedPlayer?.photo}
                      fallbackSrc={DummyImg}
                    />
                    <span className="ml-3 block truncate">
                      {selectedPlayer?.name}
                    </span>
                    <span className="ml-2 block truncate">
                      {selectedPlayer
                        ? `(${selectedPlayer?.playerPosition
                            ?.split(",")[0]
                            .trim()})`
                        : ""}
                      {/* ({selectedPlayer?.playerPosition?.split(",")[0].trim()}) */}
                    </span>
                    <span className="ml-3 block truncate text-indigo-600 font-semibold text-md">
                      {selectedPlayer ? `$${selectedPlayer?.cost}` : ""}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {allModifiedPlayers?.map((person, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={person}
                      >
                        {({ selected: selectedPerson, active }) => (
                          <>
                            <div className="flex items-center">
                              <img
                                src={person.photo}
                                alt=""
                                className="h-8 w-8 flex-shrink-0 rounded-full"
                              />

                              <div className="flex flex-col justify-between w-full">
                                <span
                                  className={classNames(
                                    selectedPerson
                                      ? "font-semibold "
                                      : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {person.name}
                                </span>
                                <span
                                  className={classNames(
                                    selectedPerson
                                      ? "font-semibold"
                                      : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {person?.playerPosition?.split(",")[0].trim()}
                                </span>
                              </div>
                              <div>
                                <p className="text-indigo-600 font-semibold text-lg">
                                  ${person?.cost}
                                </p>
                              </div>
                            </div>
                            {selectedPerson ? (
                              <span
                                className={classNames(
                                  active
                                    ? "text-white"
                                    : "text-indigo-600 font-semibold text-lg",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </>
  );
}

export default FantasyPlayerSelection;

function ImageWithFallback({ src, fallbackSrc, ...props }) {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    if (src !== undefined) {
      setImgSrc(src);
    } else {
      onError();
    }
  }, [src]);

  const onError = () => {
    setImgSrc(fallbackSrc);
  };
  return (
    <img
      src={imgSrc}
      onError={onError}
      alt="Y"
      className="h-8 w-8 flex-shrink-0 rounded-full"
      {...props}
    />
  );
}
