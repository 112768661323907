import React, { useState } from "react";
import { useApp } from "../../context/app-context";
import ViewOnlyFantasy from "../fantasyComponent/ViewOnlyFantasy";
import FantasyLineupDemo from "../fantasyComponent/FantasyLineupDemo";
import publicIcon from "../../assets/public.png";
import lock from "../../assets/lock.png";
import unlocked from "../../assets/unlocked.png";
// import { LockClosedIcon } from "@heroicons/24/outline";

// import { LockClosedIcon } from "@heroicons/react/20/solid"; // Correct import path for solid version
// or
// import { LockClosedIcon } from "@heroicons/react/20/outline";

function TabButton({ id, title, onClick, isActive, icon }) {
  return (
    <button
      className={`w-full justify-center gap-x-1 items-end flex rounded-md py-4 px-4 mx-1 text-white font-semibold  hover:bg-[#265073] focus:outline-none tab-button ${
        isActive ? "border-blue-500 bg-[#265073]" : ""
      }`}
      onClick={() => onClick(id)}
    >
      <p>{title}</p>
      {/* <p>{}</p> */}
      {/* {icon === "locked" ? ( */}
      {/* <img src={icon} alt={`${title} icon`} className="w-6 h-6 mb-1 " /> */}
      {/* ) : (
        <img
          src={unlocked}
          alt={`${title} icon`}
          className="w-6 h-6 ml-1 mb-1"
        />
      )} */}
    </button>
  );
}

function FantasyTab({ user, teamEdit, teamView, fantasyWeek }) {
  const { activeTab, setActiveTab } = useApp();
  const tabComponents = {
    tab1: (
      <FantasyLineupDemo
        user={user}
        teamData={teamEdit}
        fantasyWeek={fantasyWeek}
      />
    ),

    tab2: (
      <ViewOnlyFantasy
        user={user}
        teamData={teamView}
        fantasyWeek={fantasyWeek - 1}
      />
    ),
  };

  const tabs = [
    { id: "tab1", title: "Unlocked Team", icon: lock },
    { id: "tab2", title: "Locked Team", icon: unlocked },
  ];

  const showTab = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className=" w-full mx-auto max-w-lg  shadow-lg">
      {" "}
      {/* Tab Buttons */}
      <div
        align="center"
        className="text-white font-extrabold text-2xl mt-3 mb-3 flex justify-center gap-x-4"
      >
        {/* {user?.username}'s TEAM */}
        <span>YOUR TEAM</span>
      </div>
      <div className="bg-[#006989] p-2  mb-4 rounded">
        <div className="flex justify-center w-full">
          {tabs?.map((tab) => (
            <TabButton
              key={tab.id}
              id={tab.id}
              title={tab.title}
              onClick={showTab}
              isActive={activeTab === tab.id}
              icon={tab.icon}
            />
          ))}
        </div>
      </div>
      {/* Tab Content */}
      {tabComponents[activeTab]}
    </div>
  );
}

export default FantasyTab;
