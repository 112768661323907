import React, { useEffect, useState } from "react";
import vagad from "../../assets/vagad.png";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { ChevronDoubleLeftIcon } from "@heroicons/react/20/solid";
import { useApp } from "../../context/app-context";
import { logDOM } from "@testing-library/react";
import axios from "axios";
import swal from "sweetalert";
import matchWeeks from "../../data/MatchData";

function MatchCarousel() {
  //   const slides = [1, 2, 3, 4];

  //   const [activeSlide, setActiveSlide] = useState(1);
  //     const prevSlide = () => {
  //       setActiveSlide(activeSlide === 1 ? match.length : activeSlide - 1);
  //     };

  //     const nextSlide = () => {
  //       setActiveSlide(activeSlide === match.length ? 1 : activeSlide + 1);
  //     };
  // -------------trash

  const [slides, setSlides] = useState([1]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [error, setError] = useState(null);

  const [liveMatchMessage, setLiveMatchMessage] = useState(false);
  const { liveMatch, setLiveMatch, MatchThisWeek, setMatchThisWeek } = useApp();

  // const livematchFunc = (match) => {
  //   console.log(match);
  //   setLiveMatch(match);
  // };
  //
  //---------------------------------------------------------
  //
  useEffect(() => {
    // getWeekNumber();
    // checkAndSetLiveMatch();
    demo();
  }, [liveMatch]);

  // const getWeekNumber = () => {
  //   // Define the start date of the league
  //   const startDate = new Date("2024-06-15"); // Assuming the year is 2024
  //   // Get today's date
  //   const today = new Date();
  //   // Calculate the difference in days between today's date and the start date
  //   const diffInDays = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));
  //   // Calculate the week number by dividing the difference in days by 7 and rounding up
  //   let weekNum = 0;
  //   if (diffInDays <= 0) {
  //     weekNum = 1;
  //   } else {
  //     weekNum = Math.ceil(diffInDays / 7);
  //   }

  //   getThisWeekMatchData(
  //     `${process.env.REACT_APP_SERVER_URL}/api/match?week=${weekNum}`
  //   );
  // };
  //
  // async function getThisWeekMatchData(url) {
  //   try {
  //     const response = await axios.get(url);
  //     console.log("GET This Week Match request successful");
  //     console.log(response.data);
  //     //check for live match
  //     // check for lineup of live match

  //     console.log("match this week : ", response.data.matches);
  //     setMatchThisWeek(response.data.matches);
  //     // setMatchData(response.data);
  //   } catch (error) {
  //     console.error("Error making GET This Week match request:", error);
  //     // Handle errors
  //     setError(
  //       "Error fetching GET This Week match request. Please try again later."
  //     );
  //   }
  // }

  const demo = () => {
    const getCurrentWeek = (date) => {
      for (let i = 0; i < matchWeeks.length; i++) {
        const { week, startDate, endDate, adminDate } = matchWeeks[i];
        console.log(date);
        const end = floorDate(endDate);
        console.log(i, end);
        if (date >= startDate && date <= adminDate) {
          // alert(date);
          return week;
        }
        //
        const end_l = matchWeeks[matchWeeks?.length - 1];
        console.log(end_l.endDate);
        if (date > end_l.endDate) {
          return 9;
        }
        //
      }
      return null; // If the date doesn't fall within any of the predefined ranges
    };

    function floorDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    const currentDate = new Date();
    const flooredDate = floorDate(currentDate);
    const weekNumber = getCurrentWeek(flooredDate);
    getAllMatchDataLoad(
      `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`,
      weekNumber
    );
  };
  async function getAllMatchDataLoad(url, weekNumber) {
    // let weekNum = 1;
    try {
      const response = await axios.get(url);
      console.log(response.data);

      const matches = response.data.matches;
      const weeklyMatches = matches.filter(
        (match) => match.week === weekNumber
      );
      console.log("match this week : ", weeklyMatches);
      setMatchThisWeek(weeklyMatches);
    } catch (error) {
      console.error("Error making GET match request:", error);
    }
  }

  //-------------------------------------------------------- end any previous live match
  async function getAllMatchData(match) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`
      );
      console.log("GET Match request successful");
      console.log(response.data);
      if (response.data.matches.length) {
        const currentLiveMatch = response?.data?.matches.find(
          (match) => match?.live
        );
        if (currentLiveMatch) {
          console.log("Live Match ID:", currentLiveMatch?._id);
          swal({
            title: "Are you sure?",
            text: "Once clicked on okay , match will be ended !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(currentLiveMatch._id);
              submitData(currentLiveMatch, match);
              // dataTry();
            } else {
              swal("Current Match is still Live!");
            }
          });
        } else {
          console.log("No live match found.");
          livematchFunc(match);
        }
      }
    } catch (error) {
      console.error("Error making GET match request:", error);
      setError("Error fetching match data. Please try again later.");
    }
  }
  //--------------------------------------------------------
  const submitData = async (currentLiveMatch, match) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/match/endmatch/${currentLiveMatch._id}`
      );
      console.log("Response:", response.data);
      // setLiveMatch(); ------------------- work on this func it should ideally make live upadtes section empty
      swal(
        `${currentLiveMatch.team1} vs ${currentLiveMatch.team2} , week ${currentLiveMatch.week} has ended`,
        {
          icon: "success",
        }
      );
      livematchFunc(match);
      // Handle response or do something with the data
    } catch (error) {
      setError("Error:", error);
    }
  };
  //--------------------------------------------------------
  const checkAndSetLiveMatch = (match) => {
    getAllMatchData(match);
  };
  const livematchFunc = async (match) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/match/setLive/${match?._id}`
      );
      console.log("Live match Response:", response.data);
      setLiveMatch(response.data.match);
      setLiveMatchMessage(true);
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };
  //   const match = [
  //     {
  //       _id: "6612cbe63a0bd4e3ba21f583",
  //       team1: "Charla Challengers",
  //       team2: "Mango Marvels",
  //       lineup1: [],
  //       lineup2: [],
  //       location: "Andheri Sports Complex",
  //       link: "lx2CNP7kwhI?si=ajnuSoZ2IkHhakY8",
  //       week: 1,
  //       __v: 0,
  //     },
  //     {
  //       _id: "6612cbe63a0bd4e3ba21f584",
  //       team1: "Carron Capitals",
  //       team2: "Sagar Seahawks",
  //       lineup1: [],
  //       lineup2: [],
  //       location: "Andheri Sports Complex",
  //       link: "lx2CNP7kwhI?si=ajnuSoZ2IkHhakY8",
  //       week: 1,
  //       __v: 0,
  //     },
  //     {
  //       _id: "6612cbe63a0bd4e3ba21f585",
  //       team1: "Vandana Warriors",
  //       team2: "Icon Eagles",
  //       lineup1: [],
  //       lineup2: [],
  //       location: "Andheri Sports Complex",
  //       link: "lx2CNP7kwhI?si=ajnuSoZ2IkHhakY8",
  //       week: 1,
  //       __v: 0,
  //     },
  //     {
  //       _id: "6612cbe63a0bd4e3ba21f586",
  //       team1: "Feonn Furies",
  //       team2: "Vagad Avengers",
  //       lineup1: [],
  //       lineup2: [],
  //       location: "Andheri Sports Complex",
  //       link: "lx2CNP7kwhI?si=ajnuSoZ2IkHhakY8",
  //       week: 1,
  //       __v: 0,
  //     },
  //   ];

  useEffect(() => {
    const slidesFound = Array.from(
      { length: MatchThisWeek?.length },
      (_, index) => index + 1
    );
    setSlides(slidesFound);
    console.log(MatchThisWeek);
  }, [MatchThisWeek]);
  //   console.log(MatchThisWeek);

  // async function getAllMatchData(url) {
  //   try {
  //     const response = await axios.get(url);
  //     // console.log("GET Match request successful");
  //     console.log(response.data);
  //     if (response.data.matches.length) {
  //       setGetAllMatchDataApi(true);
  //     }
  //   } catch (error) {
  //     console.error("Error making GET match request:", error);

  //     // Handle errors
  //     setError("Error fetching match data. Please try again later.");
  //   }
  // }
  //
  const previous = () => {
    setCurrentIndex(
      currentIndex === 1 ? MatchThisWeek?.length : currentIndex - 1
    );
  };

  const forward = () => {
    setCurrentIndex(
      currentIndex === MatchThisWeek?.length ? 1 : currentIndex + 1
    );
  };
  const [showDetails, setShowDetails] = useState(false);

  return (
    <li className="flex w-full py-5">
      <details className="group w-full">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
          <div
            className="flex justify-between w-full"
            onClick={() => setShowDetails(true)}
          >
            <div className="flex gap-5">
              <div className="min-w-0 flex-auto">
                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-teal-600">
                  Want to make a match live?
                </p>
              </div>
            </div>
          </div>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>
        <main className="grid  w-full place-content-center text-center border-emerald-50  border-b-2">
          <div className="flex flex-col relative mx-auto max-w-2xl overflow-hidden rounded-md bg-gray-100 p-2 sm:p-4 h-[430px] sm:h-[460px] my-5">
            <div className="absolute right-5 top-5 z-10 rounded-full bg-gray-600 px-2 text-center text-sm text-white">
              <span>{currentIndex}</span>/<span>{MatchThisWeek?.length}</span>
            </div>

            <button
              onClick={() => previous()}
              className="absolute left-5 top-1/2 z-10 flex h-11 w-11 -translate-y-1/2 items-center justify-center rounded-full bg-gray-100 shadow-md"
            >
              <ChevronDoubleLeftIcon className="text-2xl font-bold text-gray-500" />
            </button>

            <button
              onClick={forward}
              className="absolute right-5 top-1/2 z-10 flex h-11 w-11 -translate-y-1/2 items-center justify-center rounded-full bg-gray-100 shadow-md"
            >
              <ChevronDoubleRightIcon className="text-2xl font-bold text-gray-500" />
            </button>
            <div className="relative h-60">
              {MatchThisWeek?.map((match, index) => (
                <div
                  // key={match._id}
                  key={index}
                  className={` top-0 h-auto flex-shrink-0 mx-6 relative overflow-hidden bg-blue-500 rounded-lg shadow-lg pt-2 pb-5 px-2 my-4 ${
                    currentIndex === index + 1 ? "" : "hidden"
                  } transition transform duration-300`}
                  style={{
                    opacity: currentIndex === index + 1 ? "100" : "0",
                  }}
                  //   className="flex-shrink-0 m-6 relative overflow-hidden bg-blue-500 rounded-lg shadow-lg pt-2 pb-5 px-2 my-8 "
                >
                  <div>
                    {/* Match content */}
                    <div
                      align="center"
                      className="text-white font-extrabold text-2xl"
                    >
                      <img
                        src={vagad}
                        width={40}
                        alt="logo"
                        className="rounded-full"
                      />
                      Vagad League
                    </div>

                    <div className="text-center">{match.location}</div>
                    <div className=" text-center text-white font-semibold text-xl">
                      Week {match.week}
                    </div>

                    <div className="w-full flex p-1 justify-around sm:justify-between ">
                      <div className="md:w-[15%] w-[20%] text-center">
                        <div className="flex flex-col items-center">
                          <img
                            src={match.team1logo}
                            width={70}
                            alt="logo"
                            className="rounded-full"
                          />
                          <div className="mt-2 text-md text-indigo-900 font-semibold text-xl ">
                            {match.team1}
                          </div>
                        </div>
                      </div>

                      <div className="hidden md:block w-[32%] text-right text-blue-300 font-semiboldblock opacity-75 -mb-1">
                        {match.team1}
                      </div>

                      <div className="w-[16%] text-center font-bold text-xl text-purple-700">
                        {match?.stats?.team1?.totalGoals} :{" "}
                        {match?.stats?.team2?.totalGoals}
                      </div>

                      <div className="hidden md:flex w-[32%] text-left  text-teal-500 font-semibold">
                        {match.team2}
                      </div>
                      <div className="md:w-[15%] w-[20%] text-center">
                        <div className="flex flex-col items-center">
                          <img
                            src={match.team2logo}
                            width={70}
                            alt="logo"
                            className="rounded-full"
                          />
                          <div className="mt-2 text-indigo-600 block font-semibold text-xl ">
                            {match.team2}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="justify-center bg-red-300 text-center">button</div> */}
                  {match.live ? (
                    <button
                      disabled
                      className={` relative hover:shadow-form w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  bg-red-700
                                               }`}
                    >
                      Live Match
                    </button>
                  ) : (
                    <></>
                  )}
                  {!match.live ? (
                    <button
                      className={` relative hover:shadow-form w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  bg-indigo-700
                                 }`}
                      // onClick={() => livematchFunc(match)}
                      onClick={() => checkAndSetLiveMatch(match)}
                    >
                      Set This Live
                    </button>
                  ) : (
                    <></>
                  )}

                  {/* <button
                    className={` relative hover:shadow-form w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  bg-indigo-700
                      }`}
                    onClick={() => livematchFunc(match)}
                  >
                    Set This Live
                  </button> */}
                </div>
              ))}
              <div className="w-full flex items-center justify-center px-4">
                {slides?.map((slide) => (
                  <button
                    key={slide}
                    className={`flex-1 w-4 h-2 mt-4 mx-2 mb-0 rounded-full overflow-hidden transition-colors duration-200 ease-out hover:bg-teal-600 hover:shadow-lg ${
                      currentIndex === slide ? "bg-orange-600" : "bg-teal-300"
                    }`}
                    onClick={() => setCurrentIndex(slide)}
                  ></button>
                ))}
              </div>
            </div>
          </div>
          {liveMatchMessage ? (
            <p className="text-lg sm:text-xl md:text-2xl font-semibold leading-6  text-[#117C13] m-5">
              This match is set to live now
            </p>
          ) : (
            <></>
          )}
        </main>
      </details>
    </li>
  );
}

export default MatchCarousel;
