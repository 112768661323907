// import React from "react";

// function PlayerModal() {
//   return <div>PlayerModal</div>;
// }

// export default PlayerModal;

import React from "react";

const PlayerModal = ({ isOpen, onClose, team_logo }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full flex items-center justify-center"
      onMouseLeave={onClose}
    >
      <div className="relative mx-8 shadow-xl rounded-md transition duration-500 backdrop-filter bg-opacity-10 backdrop-blur  bg-blur-sm   max-w-md w-full">
        <div className="flex justify-end">
          <button
            onClick={onClose}
            type="button"
            className={`text-gray-400 mt-1 mr-1 bg-transparent hover:text-indigo-400 rounded-lg text-sm pb-1.5 ml-auto inline-flex items-center`}
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <img
          src={team_logo}
          className="w-full   pb-4 px-4 rounded-lg "
          width={100}
          height={100}
        />
      </div>
    </div>
  );
};

export default PlayerModal;
