import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoaderFootball from "../components/loader/LoaderFootball";

function ProtectedRouteAdmin({ PropPassed }) {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const adminCheck = JSON.parse(localStorage.getItem("adminCheck"));

  useEffect(() => {
    if (!adminCheck) {
      navigate("/login");
    } else {
      setIsLoading(false); // Allow rendering if adminCheck passes
    }
  }, [adminCheck, navigate]);

  if (isLoading) {
    return (
      <div className="w-screen h-screen bg-[#192347] ">
        {/* <LoaderFootball height={96} weight={96} />; */}
      </div>
    );
    // Render nothing or a loading spinner while loading
  }

  return <PropPassed />;
}

export default ProtectedRouteAdmin;
