import React, { useEffect, useRef, useState } from "react";
import vagad from "../../assets/vagad.png";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import { ChevronDoubleLeftIcon } from "@heroicons/react/20/solid";
import { useApp } from "../../context/app-context";
import { logDOM } from "@testing-library/react";
import axios from "axios";
import matchWeeks from "../../data/MatchData";

function LineupForAnyMatch() {
  const [slides, setSlides] = useState([1]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [error, setError] = useState(null);

  const [liveMatchMessage, setLiveMatchMessage] = useState(false);
  //   const {MatchThisWeek, setMatchThisWeek } = useApp();

  const [nonLiveLineup, setNonLiveLineup] = useState(false);
  const [nonLiveLineupMatch, setNonLiveLineupMatch] = useState(false);
  const [nonLiveteam1Data, setNonLiveTeam1Data] = useState();
  const [nonLiveteam2Data, setNonLiveTeam2Data] = useState();
  const [NonLiveMatchThisWeek, setNonLiveMatchThisWeek] = useState();
  //   const [nonLiveLineupMatchData, setNonLiveLineupMatchData] = useState()
  useEffect(() => {
    // getWeekNumber();
    // getAllMatchData(`${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`);
    demo();
  }, []);

  // async function getAllMatchData(url) {
  //   let weekNum = 1;
  //   try {
  //     const response = await axios.get(url);
  //     console.log(response.data);
  //     const sortedMatches = response.data.matches.sort(
  //       (a, b) => a.week - b.week
  //     );
  //     const dates = sortedMatches.map((match) => match.date);
  //     const uniqueDates = [...new Set(dates)];
  //     console.log(uniqueDates);

  //     const convertToDate = (dateString) => {
  //       const [day, month, year] = dateString.split("-").map(Number);
  //       // Assuming year is 20YY, convert to 19YY if year < 50, else convert to 20YY
  //       const fullYear = year < 50 ? 2000 + year : 1900 + year;
  //       return new Date(fullYear, month - 1, day); // month is zero-based
  //     };

  //     const matchWeekDates = uniqueDates.map(convertToDate);

  //     console.log(matchWeekDates);

  //     const today = new Date(); // Get today's date

  //     const getCurrentMatchWeek = (currentDate, matchWeekDates) => {
  //       for (let index = 0; index < matchWeekDates.length; index++) {
  //         const element = matchWeekDates[index];
  //         console.log(element);
  //         console.log(currentDate);
  //         if (currentDate < element) {
  //           console.log(currentDate < element);
  //           return index;
  //         }
  //       }
  //       const last = matchWeekDates[matchWeekDates.length - 1];
  //       const diffInDays = Math.ceil((today - last) / (1000 * 60 * 60 * 24));
  //       console.log(diffInDays);

  //       if (diffInDays <= 7) {
  //         return matchWeekDates.length;
  //       }
  //       return matchWeekDates.length + 10;
  //     };

  //     weekNum = getCurrentMatchWeek(today, matchWeekDates);
  //     console.log(`Current Match Week: ${weekNum}`);

  //     const matches = response.data.matches;
  //     const weeklyMatches = matches.filter((match) => match.week === weekNum);
  //     console.log(weeklyMatches);

  //     const nonLiveMatches = weeklyMatches.filter((match) => !match.live);
  //     console.log("Non Live matches this week : ", nonLiveMatches);
  //     setNonLiveMatchThisWeek(nonLiveMatches);

  //     console.log("match this week : ", weeklyMatches);
  //   } catch (error) {
  //     console.error("Error making GET match request:", error);
  //   }
  // }

  const demo = () => {
    const getCurrentWeek = (date) => {
      for (let i = 0; i < matchWeeks.length; i++) {
        const { week, startDate, endDate } = matchWeeks[i];
        console.log(date);
        const end = floorDate(endDate);
        console.log(i, end);
        if (date >= startDate && date <= endDate) {
          // alert(date);
          return week;
        }
        //
        const end_l = matchWeeks[matchWeeks?.length - 1];
        console.log(end_l.endDate);
        if (date > end_l.endDate) {
          return 9;
        }
        //
      }
      return null; // If the date doesn't fall within any of the predefined ranges
    };

    function floorDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    const currentDate = new Date();
    const flooredDate = floorDate(currentDate);
    const weekNumber = getCurrentWeek(flooredDate);
    getAllMatchDataLoad(
      `${process.env.REACT_APP_SERVER_URL}/api/match?limit=1000`,
      weekNumber
    );
  };
  async function getAllMatchDataLoad(url, weekNumber) {
    // let weekNum = 1;
    try {
      const response = await axios.get(url);
      console.log(response.data);

      const matches = response.data.matches;
      const weeklyMatches = matches.filter(
        (match) => match.week === weekNumber
      );
      console.log("match this week : ", weeklyMatches);

      const nonLiveMatches = weeklyMatches.filter((match) => !match.live);
      console.log("Non Live matches this week : ", nonLiveMatches);
      setNonLiveMatchThisWeek(nonLiveMatches);
    } catch (error) {
      console.error("Error making GET match request:", error);
    }
  }
  // async function getThisWeekMatchData(url) {
  //   try {
  //     const response = await axios.get(url);
  //     console.log("GET This Week Match request successful");
  //     console.log(response.data);
  //     console.log("match this week : ", response.data.matches);
  //     const nonLiveMatches = response.data.matches.filter(
  //       (match) => !match.live
  //     );
  //     console.log("Non Live matches this week : ", nonLiveMatches);
  //     setNonLiveMatchThisWeek(nonLiveMatches);
  //     // setMatchData(response.data);
  //   } catch (error) {
  //     console.error("Error making GET This Week match request:", error);
  //     // Handle errors
  //     setError(
  //       "Error fetching GET This Week match request. Please try again later."
  //     );
  //   }
  // }

  //--------------------------------------------------------
  const handleNonLiveLineup = async (match) => {
    console.log(match);
    setNonLiveLineup(true);
    setNonLiveLineupMatch(match);
    setLiveMatchMessage(true);
    const team1Name = match?.team1;
    getNonLiveTeam1Data(
      `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team1Name}&limit=1000`,
      match
    );
    const team2Name = match?.team2;
    getNonLiveTeam2Data(
      `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team2Name}&limit=1000`,
      match
    );
  };

  async function getNonLiveTeam1Data(url, match) {
    try {
      const response = await axios.get(url);
      console.log("GET Non Live Team1 Players request successful");
      console.log(response.data.players);
      const updatedPlayerTeam1 = response.data.players;
      updatedPlayerTeam1.forEach((person) => {
        person.isInLineup = match.lineup1.includes(person._id);
      });
      setLineup1(match.lineup1);
      //   setNonLiveTeam1Data(response.data.players);
      setNonLiveTeam1Data(updatedPlayerTeam1);
    } catch (error) {
      console.error("Error making GET Non Live Team1 Players  request:", error);
      // Handle errors
      setError(
        "Error fetching  GET Non Live Team1 Players. Please try again later."
      );
    }
  }
  async function getNonLiveTeam2Data(url, match) {
    try {
      const response = await axios.get(url);
      console.log("GET Non Live Team2 Players request successful");
      console.log(response.data.players);
      const updatedPlayerTeam2 = response.data.players;
      updatedPlayerTeam2.forEach((person) => {
        person.isInLineup = match.lineup2.includes(person._id);
      });
      setLineup2(match.lineup2);
      //   setNonLiveTeam1Data(response.data.players);
      setNonLiveTeam2Data(updatedPlayerTeam2);
    } catch (error) {
      console.error("Error making GET Non Live Team2 Players  request:", error);
      // Handle errors
      setError(
        "Error fetching  GET Non Live Team2 Players. Please try again later."
      );
    }
  }

  const [lineup1, setLineup1] = useState([]);
  const [lineup2, setLineup2] = useState([]);
  const [lineupMadeMessage, setLineupMadeMessage] = useState(false);

  const detailsRefLineupTeam1 = useRef(null); // Ref to access the <details> element
  const detailsRefLineupTeam2 = useRef(null); // Ref to access the <details> element

  const [selectedPlayersTeam1, setSelectedPlayersTeam1] = useState([]);
  const [selectedPlayersTeam2, setSelectedPlayersTeam2] = useState([]);

  const handleCheckboxChange = (event, team) => {
    const playerId = event.target.value;
    const isChecked = event.target.checked;

    if (team === "team1") {
      if (isChecked) {
        setLineup1((prevLineup1) => prevLineup1.filter((id) => id !== ""));

        setLineup1([...lineup1, playerId]);
      } else {
        setLineup1(lineup1.filter((id) => id !== playerId));
      }
      const { value, checked } = event.target;
      if (checked) {
        setSelectedPlayersTeam1((prev) => [...prev, value]);
      } else {
        setSelectedPlayersTeam1((prev) => prev.filter((id) => id !== value));
      }
    } else if (team === "team2") {
      if (isChecked) {
        setLineup2((prevLineup2) => prevLineup2.filter((id) => id !== ""));
        setLineup2([...lineup2, playerId]);
      } else {
        setLineup2(lineup2.filter((id) => id !== playerId));
      }
      const { value, checked } = event.target;
      if (checked) {
        setSelectedPlayersTeam2((prev) => [...prev, value]);
      } else {
        setSelectedPlayersTeam2((prev) => prev.filter((id) => id !== value));
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      lineup1,
      lineup2,
    };
    // Do something with data, e.g., send it to an API
    console.log("Submitted data:", data);
    submitData(data);
  };

  const submitData = async (data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/match/lineup/${nonLiveLineupMatch?._id}`,
        data
      );
      console.log("Response:", response.data);
      //   setLiveMatch(response.data.match);
      setLineupMadeMessage(true);
      if (detailsRefLineupTeam1.current) {
        detailsRefLineupTeam1.current.open = false; // Toggle the 'open' attribute
      }
      if (detailsRefLineupTeam2.current) {
        detailsRefLineupTeam2.current.open = false; // Toggle the 'open' attribute
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  //   const match = [
  //     {
  //       _id: "6612cbe63a0bd4e3ba21f583",
  //       team1: "Charla Challengers",
  //       team2: "Mango Marvels",
  //       lineup1: [],
  //       lineup2: [],
  //       location: "Andheri Sports Complex",
  //       link: "lx2CNP7kwhI?si=ajnuSoZ2IkHhakY8",
  //       week: 1,
  //       __v: 0,
  //     },
  //     {
  //       _id: "6612cbe63a0bd4e3ba21f584",
  //       team1: "Carron Capitals",
  //       team2: "Sagar Seahawks",
  //       lineup1: [],
  //       lineup2: [],
  //       location: "Andheri Sports Complex",
  //       link: "lx2CNP7kwhI?si=ajnuSoZ2IkHhakY8",
  //       week: 1,
  //       __v: 0,
  //     },
  //     {
  //       _id: "6612cbe63a0bd4e3ba21f585",
  //       team1: "Vandana Warriors",
  //       team2: "Icon Eagles",
  //       lineup1: [],
  //       lineup2: [],
  //       location: "Andheri Sports Complex",
  //       link: "lx2CNP7kwhI?si=ajnuSoZ2IkHhakY8",
  //       week: 1,
  //       __v: 0,
  //     },
  //     {
  //       _id: "6612cbe63a0bd4e3ba21f586",
  //       team1: "Feonn Furies",
  //       team2: "Vagad Avengers",
  //       lineup1: [],
  //       lineup2: [],
  //       location: "Andheri Sports Complex",
  //       link: "lx2CNP7kwhI?si=ajnuSoZ2IkHhakY8",
  //       week: 1,
  //       __v: 0,
  //     },
  //   ];

  useEffect(() => {
    const slidesFound = Array.from(
      { length: NonLiveMatchThisWeek?.length },
      (_, index) => index + 1
    );
    setSlides(slidesFound);
    console.log(NonLiveMatchThisWeek);
  }, [NonLiveMatchThisWeek]);
  //   console.log(NonLiveMatchThisWeek);

  const previous = () => {
    setCurrentIndex(
      currentIndex === 1 ? NonLiveMatchThisWeek?.length : currentIndex - 1
    );
  };

  const forward = () => {
    setCurrentIndex(
      currentIndex === NonLiveMatchThisWeek?.length ? 1 : currentIndex + 1
    );
  };
  const [showDetails, setShowDetails] = useState(false);

  return (
    <li className="flex w-full py-5">
      <details className="group w-full">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
          <div
            className="flex justify-between w-full"
            onClick={() => setShowDetails(true)}
          >
            <div className="flex gap-5">
              <div className="min-w-0 flex-auto">
                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-stone-700">
                  Want to set lineup for non Live match?
                </p>
              </div>
            </div>
          </div>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>
        <main className="grid  w-full place-content-center text-center border-emerald-50  border-b-2">
          <div className="flex flex-col relative mx-auto max-w-2xl overflow-hidden rounded-md bg-gray-100 p-2 sm:p-4 h-[430px] sm:h-[460px] my-5">
            <div className="absolute right-5 top-5 z-10 rounded-full bg-gray-600 px-2 text-center text-sm text-white">
              <span>{currentIndex}</span>/
              <span>{NonLiveMatchThisWeek?.length}</span>
            </div>

            <button
              onClick={() => previous()}
              className="absolute left-5 top-1/2 z-10 flex h-11 w-11 -translate-y-1/2 items-center justify-center rounded-full bg-gray-100 shadow-md"
            >
              <ChevronDoubleLeftIcon className="text-2xl font-bold text-gray-500" />
            </button>

            <button
              onClick={forward}
              className="absolute right-5 top-1/2 z-10 flex h-11 w-11 -translate-y-1/2 items-center justify-center rounded-full bg-gray-100 shadow-md"
            >
              <ChevronDoubleRightIcon className="text-2xl font-bold text-gray-500" />
            </button>
            <div className="relative h-60">
              {NonLiveMatchThisWeek?.map((match, index) => (
                <div
                  // key={match._id}
                  key={index}
                  className={` top-0 h-auto flex-shrink-0 mx-6 relative overflow-hidden bg-indigo-500 rounded-lg shadow-lg pt-2 pb-5 px-2 my-4 ${
                    currentIndex === index + 1 ? "" : "hidden"
                  } transition transform duration-300`}
                  style={{
                    opacity: currentIndex === index + 1 ? "100" : "0",
                  }}
                  //   className="flex-shrink-0 m-6 relative overflow-hidden bg-blue-500 rounded-lg shadow-lg pt-2 pb-5 px-2 my-8 "
                >
                  <div>
                    {/* Match content */}
                    <div
                      align="center"
                      className="text-white font-extrabold text-2xl"
                    >
                      Vagad League
                    </div>

                    <div className="text-center">{match.location}</div>
                    <div className=" text-center text-white font-semibold text-xl">
                      Week {match.week}
                    </div>
                    <p className=" text-xl text-gray-700 font-bold">
                      {" "}
                      Not Live
                    </p>

                    <div className="w-full flex p-1 justify-around sm:justify-between ">
                      <div className="md:w-[15%] w-[20%] text-center">
                        <div className="flex flex-col items-center">
                          <img
                            src={match.team1logo}
                            width={70}
                            alt="logo"
                            className="rounded-full"
                          />
                          <div className="mt-2 text-md text-lime-500 font-semibold text-xl ">
                            {match.team1}
                          </div>
                        </div>
                      </div>

                      <div className="hidden md:block w-[32%] text-right text-blue-300 font-semiboldblock opacity-75 -mb-1">
                        {match.team1}
                      </div>

                      <div className="w-[16%] text-center font-bold text-xl text-purple-700">
                        {match?.stats?.team1?.totalGoals} :{" "}
                        {match?.stats?.team2?.totalGoals}
                      </div>

                      <div className="hidden md:flex w-[32%] text-left  text-teal-500 font-semibold">
                        {match.team2}
                      </div>
                      <div className="md:w-[15%] w-[20%] text-center">
                        <div className="flex flex-col items-center">
                          <img
                            src={match.team2logo}
                            width={70}
                            alt="logo"
                            className="rounded-full"
                          />
                          <div className="mt-2 text-lime-500 block font-semibold text-xl ">
                            {match.team2}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="justify-center bg-red-300 text-center">button</div> */}
                  {match.lineup1.length !== 0 || match.lineup2.length !== 0 ? (
                    <button
                      className={` relative hover:shadow-form w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  bg-fuchsia-900
                                               }`}
                      onClick={() => handleNonLiveLineup(match)}
                    >
                      Change Line up
                    </button>
                  ) : (
                    <>
                      {" "}
                      <button
                        className={` relative hover:shadow-form w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  bg-emerald-900
                               }`}
                        onClick={() => handleNonLiveLineup(match)}
                      >
                        Set Line up
                      </button>
                    </>
                  )}
                </div>
              ))}
              <div className="w-full flex items-center justify-center px-4">
                {slides?.map((slide) => (
                  <button
                    key={slide}
                    className={`flex-1 w-4 h-2 mt-4 mx-2 mb-0 rounded-full overflow-hidden transition-colors duration-200 ease-out hover:bg-teal-600 hover:shadow-lg ${
                      currentIndex === slide ? "bg-yellow-500" : "bg-cyan-600"
                    }`}
                    onClick={() => setCurrentIndex(slide)}
                  ></button>
                ))}
              </div>
            </div>
          </div>
          {nonLiveLineup ? (
            // <p className="text-lg sm:text-xl md:text-2xl font-semibold leading-6  text-[#117C13] m-5">
            //   This match is set to live now
            // </p>
            <div className="flex flex-col">
              <p className="mt-6 text-md leading-6 text-stone-800 font-bold text-center">
                {nonLiveLineupMatch.team1} vs {nonLiveLineupMatch.team2} , week{" "}
                {nonLiveLineupMatch.week}
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col justify-center gap-4"
              >
                <ul
                  role="list"
                  className="flex flex-col items-center sm:flex-row sm:gap-4 justify-center mt-10 w-[80%] sm:w-auto m-10 gap-4 "
                >
                  <li className="flex w-full ">
                    <details
                      className="group w-full"
                      ref={detailsRefLineupTeam1}
                    >
                      <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
                        <div
                          className="flex justify-between w-full"
                          onClick={() => setShowDetails(true)}
                        >
                          <div className="flex gap-5">
                            <div className="min-w-0 flex-auto">
                              <p
                                className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-stone-700"
                                onClick={() => console.log(lineup1)}
                              >
                                Team 1 Lineup
                              </p>
                            </div>
                          </div>
                        </div>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>
                      <div className="border-4 mt-5 sm:mt-0 border-emerald-400 flex justify-center flex-col bg-white ">
                        <p className="flex justify-center border-4 border-yellow-400 text-4xl font-semibold">
                          {nonLiveLineupMatch?.team1}
                        </p>
                        <ul>
                          {nonLiveteam1Data?.map((person, index) => (
                            <li
                              key={person._id}
                              className="flex justify-start gap-x-6 py-1 bg-white mx-2"
                            >
                              <div className="flex  gap-x-1 w-full">
                                <div className="relative w-full">
                                  <input
                                    type="checkbox"
                                    id={`custom-checkbox-team1-${person._id}`}
                                    name={`custom-checkbox-team1-${person._id}`}
                                    value={person._id}
                                    defaultChecked={person.isInLineup}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, "team1")
                                    }
                                    className="text-violet-500 focus:ring-violet-300 border-gray-300 peer rounded-lg w-6 h-6 absolute top-7 left-4"
                                  />
                                  <label
                                    htmlFor={`custom-checkbox-team1-${person._id}`}
                                    className="w-full  h-[80px] cursor-pointer flex flex-row justify-between items-center border rounded-lg p-4 active:bg-violet-700 peer-focus:outline-none peer-focus:ring peer-focus:ring-violet-400 peer-checked:border-violet-400 peer-checked:bg-violet-200 hover:bg-violet-200"
                                  >
                                    <div className="flex flex-row justify-between items-center w-full ml-10 mr-4">
                                      <p className="text-md text-white font-bold">
                                        {selectedPlayersTeam1.includes(
                                          person._id
                                        )
                                          ? selectedPlayersTeam1.indexOf(
                                              person._id
                                            ) + 1
                                          : ""}
                                      </p>
                                      <div>
                                        <h3 className="font-bold">
                                          {person.name}
                                        </h3>
                                        <p className="text-sm text-gray-400">
                                          {person.position}
                                        </p>
                                      </div>
                                      <img
                                        src={person.photo}
                                        alt="No image"
                                        className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                      />
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </details>
                  </li>
                  <li className="flex w-full ">
                    <details
                      className="group w-full"
                      ref={detailsRefLineupTeam2}
                    >
                      <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
                        <div
                          className="flex justify-between w-full"
                          onClick={() => setShowDetails(true)}
                        >
                          <div className="flex gap-5">
                            <div className="min-w-0 flex-auto">
                              <p
                                className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-stone-700 text-nowrap"
                                onClick={() => console.log(lineup2)}
                              >
                                Team 2 Lineup
                              </p>
                            </div>
                          </div>
                        </div>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>
                      <div className="border-4 mt-5 sm:mt-0 border-emerald-400 flex justify-center flex-col bg-white">
                        <p className="flex justify-center border-4 border-yellow-400 text-4xl font-semibold ">
                          {nonLiveLineupMatch?.team2}
                        </p>

                        <ul>
                          {nonLiveteam2Data?.map((person, index) => (
                            <li
                              key={person._id}
                              className="flex justify-start gap-x-6 py-1 bg-white mx-2"
                            >
                              <div className="flex  gap-x-1 w-full">
                                <div className="relative w-full">
                                  <input
                                    type="checkbox"
                                    id={`custom-checkbox-team1-${person._id}`}
                                    name={`custom-checkbox-team1-${person._id}`}
                                    value={person._id}
                                    defaultChecked={person.isInLineup}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, "team2")
                                    }
                                    className="text-violet-500 focus:ring-violet-300 border-gray-300 peer rounded-lg w-6 h-6 absolute top-7 left-4"
                                  />
                                  <label
                                    htmlFor={`custom-checkbox-team1-${person._id}`}
                                    className="w-full  h-[80px] cursor-pointer flex flex-row justify-between items-center border rounded-lg p-4 active:bg-violet-700 peer-focus:outline-none peer-focus:ring peer-focus:ring-violet-300 peer-checked:border-violet-300 peer-checked:bg-violet-100 hover:bg-violet-100"
                                  >
                                    <div className="flex flex-row justify-between items-center w-full ml-10 mr-4">
                                      <p className="text-md text-white font-bold">
                                        {selectedPlayersTeam2.includes(
                                          person._id
                                        )
                                          ? selectedPlayersTeam2.indexOf(
                                              person._id
                                            ) + 1
                                          : ""}
                                      </p>
                                      <div>
                                        <h3 className="font-bold">
                                          {person.name}
                                        </h3>
                                        <p className="text-sm text-gray-400">
                                          {person.position}
                                        </p>
                                      </div>
                                      <img
                                        src={person.photo}
                                        alt="No image"
                                        className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                      />
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </details>
                  </li>
                  {/* <button >Submit</button> */}
                </ul>
                <div className="flex justify-center w-full">
                  <button
                    type="submit"
                    className={` relative hover:shadow-form w-[80%] sm:w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  
                  ${lineupMadeMessage ? "bg-[#117C13]" : "bg-[#6A64F1]"}`}
                    // onClick={() => livematchFunc(match)} bg-indigo-700
                  >
                    {!lineupMadeMessage
                      ? "Set This Non Live Lineup"
                      : "Non Live Lineup set succesfully"}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <></>
          )}
        </main>
      </details>
    </li>
  );
}

export default LineupForAnyMatch;
