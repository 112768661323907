import React, { useState } from "react";
import NonLiveChanges from "../adminComp/NonLiveChanges";
import LiveChanges from "../adminComp/LiveChanges";
import { useApp } from "../../context/app-context";

function TabButton({ id, title, onClick, isActive }) {
  return (
    <button
      className={`px-4 py-2 text-white font-semibold border-b-4 border-blue-700 hover:bg-blue-700 focus:outline-none tab-button ${
        isActive ? "border-blue-700 bg-blue-700" : ""
      }`}
      onClick={() => onClick(id)}
    >
      {title}
    </button>
  );
}

function Tabs() {
  const { activeTab, setActiveTab } = useApp();

  // const [isFileUploaded, setIsFileUploaded] = useState(false);

  // const handleFileUpload = (file) => {
  //   // Update state to indicate that a file has been uploaded
  //   setIsFileUploaded(true);
  // };

  const tabComponents = {
    // tab1: <NonLiveChanges onFileUpload={handleFileUpload} />,
    tab1: <NonLiveChanges />,

    tab2: <LiveChanges />,
    // isFileUploaded == true ? (
    //   <LiveChanges />
    // ) : (
    //   <div>Please upload a file first.</div>
    // ),
  };

  const tabs = [
    { id: "tab1", title: "Non Live Changes" },
    { id: "tab2", title: "Live Changes " },
  ];

  const showTab = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className=" max-w-screen bg-white  py-24  shadow-lg">
      {" "}
      {/* Tab Buttons */}
      <div className="bg-blue-500 p-2">
        <div className="flex justify-center space-x-4">
          {tabs?.map((tab) => (
            <TabButton
              key={tab.id}
              id={tab.id}
              title={tab.title}
              onClick={showTab}
              isActive={activeTab === tab.id}
            />
          ))}
        </div>
      </div>
      {/* Tab Content */}
      {tabComponents[activeTab]}
    </div>
  );
}

export default Tabs;
