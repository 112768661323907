import React, { useEffect, useState } from "react";
import PlayerTeamChanges from "../nonLiveChangesComponenets/PlayerTeamChanges";
import LeagueMatchSchedule from "../nonLiveChangesComponenets/LeagueMatchSchedule";
function NonLiveRestrictedAccess() {
  // const superPassword = `${process.env.REACT_APP_SUPER_PASS}`;
  const superPassword = `uv@1230`;

  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [playerTeamAccess, SetPlayerTeamAccess] = useState(false);

  const togglePasswordVisibility = () => {
    console.log("toggle");
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    console.log(e.target.value);
  };

  const authenticate = () => {
    if (password === superPassword) {
      console.log(password);
      SetPlayerTeamAccess(true);
    }
  };

  return (
    <>
      {!playerTeamAccess ? (
        <div className="flex flex-col m-10 gap-y-5">
          <div className="">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <div className="relative">
              <input
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                className={`w-full rounded-lg border-gray-600  border-2 p-4 pe-12 text-sm shadow-sm`}
                placeholder="Enter password"
              />
              <span
                className="absolute inset-y-0 end-0 grid place-content-center px-4 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {isPasswordVisible ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13.875 18.825A10.012 10.012 0 0112 19c-4.478 0-8.268-2.943-9.542-7A10.013 10.013 0 0112 5c2.027 0 3.913.597 5.475 1.625M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M20.084 20.084a1.517 1.517 0 000-2.134L4.055 4.055a1.517 1.517 0 10-2.134 2.134l16.029 16.029a1.517 1.517 0 002.134 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                )}
              </span>
            </div>
          </div>
          <button
            className="  block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
            onClick={() => {
              authenticate();
            }}
          >
            Enter super key
          </button>
        </div>
      ) : (
        <>
          <PlayerTeamChanges />
          <LeagueMatchSchedule />
        </>
      )}
    </>
  );
}

export default NonLiveRestrictedAccess;
