import axios from "axios";
import React, { useEffect, useState } from "react";

import PlayoffMatchSchedule from "../nonLiveChangesComponenets/PlayoffMatchSchedule";
import NonLiveRestrictedAccess from "./NonLiveRestrictedAccess";
import ChangeMatchLink from "../nonLiveChangesComponenets/ChangeMatchLink";

function NonLiveChanges() {
  return (
    <>
      <div className="flex items-center justify-center">
        <div className=" mx-auto w-full  bg-white">
          <ul role="list" className=" divide-y divide-gray-100 md:p-5">
            <NonLiveRestrictedAccess />
            <PlayoffMatchSchedule />
            <ChangeMatchLink />
          </ul>
        </div>
      </div>
    </>
  );
}

export default NonLiveChanges;
