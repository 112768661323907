import React from "react";
import Topintro from "../components/Topintro";
import Mainintro from "../components/Mainintro";
import Centreintro from "../components/Centreintro";
import Footer from "../components/Footer";
import { useApp } from "../context/app-context";
import InstallPrompt from "../components/pwa/InstallPrompt";

function Home() {
  const { test, setTest } = useApp();
  // console.log(test);
  return (
    <>
      <Topintro />
      <Centreintro />
      <Mainintro />
    </>
  );
}

export default Home;
