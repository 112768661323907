import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useApp } from "../../context/app-context";

function LineUp() {
  //League Matches
  const { activeTab, setActiveTab, liveMatch, setLiveMatch, setLiveLineup } =
    useApp();
  const [error, setError] = useState(null);
  const [selectedPlayersTeam1, setSelectedPlayersTeam1] = useState([]);
  const [selectedPlayersTeam2, setSelectedPlayersTeam2] = useState([]);
  //Check if Admin uploaded : (Team and players)
  useEffect(() => {}, []);
  // ------------------------------------------------------Team

  // ------------------------------------------------------Player
  const [showDetails, setShowDetails] = useState(false);
  //   const [showDetailsTeam1, setShowDetailsTeam1] = useState(false);chow is only one showdetails sufficient
  const [liveteam1Data, setLiveTeam1Data] = useState();
  const [liveteam2Data, setLiveTeam2Data] = useState();
  const handleCheckData = () => {
    // Perform any necessary checks
    // If conditions are met, set active tab to a specific value
    setActiveTab("tab1"); // Assuming "tab1" is the ID of the tab you want to navigate to
  };

  useEffect(() => {
    const team1Name = liveMatch?.team1;
    getLiveTeam1Data(
      `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team1Name}&limit=1000`
    );
    const team2Name = liveMatch?.team2;
    getLiveTeam2Data(
      `${process.env.REACT_APP_SERVER_URL}/api/player?team=${team2Name}&limit=1000`
    );
  }, [liveMatch]);
  async function getLiveTeam1Data(url) {
    try {
      const response = await axios.get(url);
      console.log("GET Live Team1 Players request successful");
      console.log(response.data.players);
      console.log("1", liveMatch);
      const updatedPlayerTeam1 = response.data.players;
      updatedPlayerTeam1.forEach((person) => {
        person.isInLineup = liveMatch?.lineup1.includes(person._id);
      });
      console.log(liveMatch?.lineup1);
      console.log(updatedPlayerTeam1);
      setLineup1(liveMatch?.lineup1);
      setLiveTeam1Data(updatedPlayerTeam1);
    } catch (error) {
      console.error("Error making GET Live Team1 Players  request:", error);
      // Handle errors
      setError(
        "Error fetching  GET Live Team1 Players. Please try again later."
      );
    }
  }
  async function getLiveTeam2Data(url) {
    try {
      const response = await axios.get(url);
      console.log("GET Live Team2 Players request successful");
      console.log(response.data.players);
      console.log("2", liveMatch);
      const updatedPlayerTeam2 = response.data.players;
      updatedPlayerTeam2.forEach((person) => {
        person.isInLineup = liveMatch?.lineup2.includes(person._id);
      });
      console.log(liveMatch?.lineup2);
      console.log(updatedPlayerTeam2);
      setLineup2(liveMatch?.lineup2);
      setLiveTeam2Data(updatedPlayerTeam2);
    } catch (error) {
      console.error("Error making GET Live Team2 Players  request:", error);
      // Handle errors
      setError(
        "Error fetching GET Live Team2 Players. Please try again later."
      );
    }
  }
  //lineup selection
  const [lineup1, setLineup1] = useState([]);
  const [lineup2, setLineup2] = useState([]);
  const [lineupMadeMessage, setLineupMadeMessage] = useState(false);
  const detailsRefLiveLineupTeam1 = useRef(null); // Ref to access the <details> element
  const detailsRefLiveLineupTeam2 = useRef(null); // Ref to access the <details> element
  const targetRefTeamsLineup = useRef(null);

  const handleCheckboxChange = (event, team) => {
    const playerId = event.target.value;
    const isChecked = event.target.checked;

    if (team === "team1") {
      if (isChecked) {
        setLineup1((prevLineup1) => prevLineup1.filter((id) => id !== ""));

        setLineup1([...lineup1, playerId]);
      } else {
        setLineup1(lineup1.filter((id) => id !== playerId));
      }
      const { value, checked } = event.target;
      if (checked) {
        setSelectedPlayersTeam1((prev) => [...prev, value]);
      } else {
        setSelectedPlayersTeam1((prev) => prev.filter((id) => id !== value));
      }
    } else if (team === "team2") {
      if (isChecked) {
        setLineup2((prevLineup2) => prevLineup2.filter((id) => id !== ""));
        setLineup2([...lineup2, playerId]);
      } else {
        setLineup2(lineup2.filter((id) => id !== playerId));
      }
      const { value, checked } = event.target;
      if (checked) {
        setSelectedPlayersTeam2((prev) => [...prev, value]);
      } else {
        setSelectedPlayersTeam2((prev) => prev.filter((id) => id !== value));
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      lineup1,
      lineup2,
    };
    // Do something with data, e.g., send it to an API
    console.log("Submitted data:", data);
    submitData(data);
  };
  const submitData = async (data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/match/lineup/${liveMatch?._id}`,
        data
      );
      console.log("Response:", response.data);
      setLiveMatch(response.data.match);
      setLineupMadeMessage(true);
      setLiveLineup(true); // allows the update score drop down to be available
      // Handle response or do something with the data
      targetRefTeamsLineup.current.scrollIntoView({ behavior: "smooth" });
      if (detailsRefLiveLineupTeam1.current) {
        detailsRefLiveLineupTeam1.current.open = false; // Toggle the 'open' attribute
      }
      if (detailsRefLiveLineupTeam2.current) {
        detailsRefLiveLineupTeam2.current.open = false; // Toggle the 'open' attribute
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };
  return (
    <>
      <li className="flex w-full py-5" ref={targetRefTeamsLineup}>
        <details className="group w-full">
          <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
            <div
              className="flex justify-between w-full"
              onClick={() => setShowDetails(true)}
            >
              <div className="flex gap-5">
                <div className="min-w-0 flex-auto">
                  <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-teal-600">
                    Want to finalize Lineup?
                  </p>
                </div>
              </div>
            </div>
            <svg
              className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </summary>
          <div className=" mx-auto max-w-[850px] bg-white ">
            <div className="mb-5">
              {liveMatch ? (
                <p className="mt-6 text-xl leading-8 text-green-800 font-bold text-center">
                  Live match : {liveMatch.team1} vs {liveMatch.team2} , week{" "}
                  {liveMatch.week}
                </p>
              ) : (
                <p className="mt-6 text-xl leading-8 text-red-800 font-bold text-center">
                  go to upper section and select live match
                </p>
              )}
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center gap-4"
            >
              <ul
                role="list"
                className="flex flex-col items-center sm:flex-row sm:gap-4 justify-center mt-10 w-[80%] sm:w-auto m-10 gap-4 "
              >
                <li className="flex w-full ">
                  <details
                    className="group w-full"
                    ref={detailsRefLiveLineupTeam1}
                  >
                    <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
                      <div
                        className="flex justify-between w-full"
                        onClick={() => setShowDetails(true)}
                      >
                        <div className="flex gap-5">
                          <div className="min-w-0 flex-auto">
                            <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-teal-600">
                              Team 1 Lineup
                            </p>
                          </div>
                        </div>
                      </div>
                      <svg
                        className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        ></path>
                      </svg>
                    </summary>
                    <div className="border-4 mt-5 sm:mt-0 border-emerald-400 flex justify-center flex-col bg-white ">
                      <p className="flex justify-center border-4 border-yellow-400 text-4xl font-semibold">
                        {liveMatch?.team1}
                      </p>
                      <ul>
                        {liveteam1Data?.map((person, index) => (
                          <li
                            key={person._id}
                            className="flex justify-start gap-x-6 py-1 bg-white mx-2"
                          >
                            <div className="flex  gap-x-1 w-full">
                              <div className="relative w-full">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-team1-${person._id}`}
                                  name={`custom-checkbox-team1-${person._id}`}
                                  value={person._id}
                                  defaultChecked={person.isInLineup}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, "team1")
                                  }
                                  className="text-violet-500 focus:ring-violet-300 border-gray-300 peer rounded-lg w-6 h-6 absolute top-7 left-4"
                                />
                                <label
                                  htmlFor={`custom-checkbox-team1-${person._id}`}
                                  className="w-full  h-[80px] cursor-pointer flex flex-row justify-between items-center border rounded-lg p-4 active:bg-violet-700 peer-focus:outline-none peer-focus:ring peer-focus:ring-violet-300 peer-checked:border-violet-300 peer-checked:bg-violet-100 hover:bg-violet-100"
                                >
                                  <div className="flex flex-row justify-between items-center w-full ml-10 mr-4">
                                    <p className="text-md text-white font-bold">
                                      {selectedPlayersTeam1.includes(person._id)
                                        ? selectedPlayersTeam1.indexOf(
                                            person._id
                                          ) + 1
                                        : ""}
                                    </p>
                                    <div>
                                      <h3 className="font-bold">
                                        {person.name}
                                      </h3>
                                      <p className="text-sm text-gray-400">
                                        {person.position}
                                      </p>
                                    </div>
                                    <img
                                      src={person.photo}
                                      alt="No image"
                                      className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                    />
                                  </div>
                                </label>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </details>
                </li>
                <li className="flex w-full ">
                  <details
                    className="group w-full"
                    ref={detailsRefLiveLineupTeam2}
                  >
                    <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
                      <div
                        className="flex justify-between w-full"
                        onClick={() => setShowDetails(true)}
                      >
                        <div className="flex gap-5">
                          <div className="min-w-0 flex-auto">
                            <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-teal-600">
                              Team 2 Lineup
                            </p>
                          </div>
                        </div>
                      </div>
                      <svg
                        className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        ></path>
                      </svg>
                    </summary>
                    <div className="border-4 mt-5 sm:mt-0 border-emerald-400 flex justify-center flex-col bg-white">
                      <p className="flex justify-center border-4 border-yellow-400 text-4xl font-semibold">
                        {liveMatch?.team2}
                      </p>

                      <ul>
                        {liveteam2Data?.map((person) => (
                          <li
                            key={person._id}
                            className="flex justify-start gap-x-6 py-1 bg-white mx-2"
                          >
                            <div className="flex  gap-x-1 w-full">
                              <div className="relative w-full">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-team1-${person._id}`}
                                  name={`custom-checkbox-team1-${person._id}`}
                                  value={person._id}
                                  defaultChecked={person.isInLineup}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, "team2")
                                  }
                                  className="text-violet-500 focus:ring-violet-300 border-gray-300 peer rounded-lg w-6 h-6 absolute top-7 left-4"
                                />
                                <label
                                  htmlFor={`custom-checkbox-team1-${person._id}`}
                                  className="w-full  h-[80px] cursor-pointer flex flex-row justify-between items-center border rounded-lg p-4 active:bg-violet-700 peer-focus:outline-none peer-focus:ring peer-focus:ring-violet-300 peer-checked:border-violet-300 peer-checked:bg-violet-100 hover:bg-violet-100"
                                >
                                  <div className="flex flex-row justify-between items-center w-full ml-10 mr-4">
                                    <p className="text-md text-white font-bold">
                                      {selectedPlayersTeam2.includes(person._id)
                                        ? selectedPlayersTeam2.indexOf(
                                            person._id
                                          ) + 1
                                        : ""}
                                    </p>
                                    <div>
                                      <h3 className="font-bold">
                                        {person.name}
                                      </h3>
                                      <p className="text-sm text-gray-400">
                                        {person.position}
                                      </p>
                                    </div>
                                    <img
                                      src={person.photo}
                                      alt="No image"
                                      className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                    />
                                  </div>
                                </label>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </details>
                </li>
                {/* <button >Submit</button> */}
              </ul>
              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  className={` relative hover:shadow-form w-[80%] sm:w-full rounded-md mt-2 py-3 px-8 text-center text-base font-semibold text-white  
                  ${lineupMadeMessage ? "bg-[#117C13]" : "bg-[#6A64F1]"}`}
                  // onClick={() => livematchFunc(match)} bg-indigo-700
                >
                  {!lineupMadeMessage
                    ? "Set this Lineup"
                    : "Lineup set succesfully"}
                </button>
              </div>
            </form>
          </div>
        </details>
      </li>
    </>
  );
}

export default LineUp;
