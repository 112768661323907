import footballImg from "../assets/scorer.png";
import assistImg from "../assets/assist.png";
import cleenSheetImg from "../assets/goal-post.png";
import { useApp } from "../context/app-context";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

export default function Centreintro() {
  const { targetRef } = useApp();
  const [error, setError] = useState(null);
  const [highestScorer, setHighestScorer] = useState();
  const [highestAssist, setHighestAssist] = useState();
  const [highestCleanSheets, setHighestCleanSheets] = useState({
    name: "No player with clean sheets",
    cleanSheets: 0,
  });

  useEffect(() => {
    PlayerDataFunc(`${process.env.REACT_APP_SERVER_URL}/api/player?limit=1000`);
  }, []);

  async function PlayerDataFunc(url) {
    try {
      const response = await axios.get(url);
      //console.log("GET Player Data request successful");
      //console.log(response.data);
      filterFunc(response.data.players);
    } catch (error) {
      console.error("Error making GET Player request:", error);
      // Handle errors
      setError("Error fetching player GET  request. Please try again later.");
    }
  }

  const filterFunc = (players) => {
    const playersWithGoals = players.filter((player) => player.goal_scored > 0);
    //console.log(playersWithGoals);
    if (playersWithGoals?.length !== 0) {
      const playerWithHighestGoals = playersWithGoals.reduce(
        (prevPlayer, currentPlayer) => {
          if (prevPlayer.goal_scored > currentPlayer.goal_scored) {
            return prevPlayer;
          } else if (prevPlayer.goal_scored < currentPlayer.goal_scored) {
            return currentPlayer;
          } else {
            // If goals are the same, compare assists
            return prevPlayer.assists >= currentPlayer.assists
              ? prevPlayer
              : currentPlayer;
          }
        },
        playersWithGoals[0]
      );

      //console.log(playerWithHighestGoals);
      setHighestScorer(playerWithHighestGoals);
    } else {
      const temp = {
        name: "No player with goal scored",
        goal_scored: 0,
      };
      //console.log(temp);
      setHighestScorer(temp);
    }

    const playersWithAssists = players.filter((player) => player.assists > 0);
    //console.log(playersWithAssists);
    if (playersWithAssists?.length !== 0) {
      const playerWithHighestAssists = playersWithAssists.reduce(
        (prevPlayer, currentPlayer) => {
          if (prevPlayer.assists > currentPlayer.assists) {
            return prevPlayer;
          } else if (prevPlayer.assists < currentPlayer.assists) {
            return currentPlayer;
          } else {
            // If assists are the same, compare goals
            return prevPlayer.goal_scored >= currentPlayer.goal_scored
              ? prevPlayer
              : currentPlayer;
          }
        },
        playersWithAssists[0]
      );

      //console.log(playerWithHighestGoals);
      setHighestAssist(playerWithHighestAssists);
    } else {
      const temp = {
        name: "No player with assists",
        assists: 0,
      };
      //console.log(temp);
      setHighestAssist(temp);
    }
    // const playerWithMostAssists = players.reduce(
    //   (prevPlayer, currentPlayer) => {
    //     return prevPlayer.assists > currentPlayer.assists
    //       ? prevPlayer
    //       : currentPlayer;
    //   },
    //   players[0]
    // );
    // console.log(playerWithMostAssists);
    // // if (playerWithMostAssists?.assists === 0) {
    // //   setHighestAssist({
    // //     name: "No player with assists",
    // //     cleanSheets: 0,
    // //   });
    // // }
    // setHighestAssist(playerWithMostAssists);

    // const goalkeepers = players.filter((player) =>
    //   player.position.includes("Goalkeeper")
    // );
    // console.log(goalkeepers);

    // const goalkeepersWithCleanSheets = players?.filter((player) =>
    //   player.hasOwnProperty("cleanSheets")
    // );
    //console.log(goalkeepersWithCleanSheets);

    const cleanSheets = players.filter((player) => player.cleanSheets > 0);
    // console.log("cleanSheets:", cleanSheets);

    // Find the player with the highest number of clean sheets
    let playerWithMostCleanSheets = cleanSheets.reduce(
      (prevPlayer, currentPlayer) => {
        return prevPlayer.cleanSheets > currentPlayer.cleanSheets
          ? prevPlayer
          : currentPlayer;
      }
    );
    // const cleanSheets = goalkeepersWithCleanSheets.map(
    //   (player) => player.cleanSheets
    // );
    // console.log("cleanSheets:", cleanSheets);

    setHighestCleanSheets(playerWithMostCleanSheets);

    // console.log(
    //   "Player with the highest clean sheets:",
    //   playerWithMostCleanSheets
    // );
    // console.log("Player with the highest goal scored:", playerWithHighestGoals);
    // console.log("Player with the most assists:", playerWithMostAssists);
  };
  const features = [
    {
      id: `${highestScorer?._id}`,
      title: "Scoring Champ",
      name: `${highestScorer?.name}`,
      stat: `${highestScorer?.goal_scored} Goals`,
      team: `${highestScorer?.team}`,
      icon: footballImg,
    },
    {
      id: `${highestAssist?._id}`,
      title: "Assist Wizard",
      name: `${highestAssist?.name}`,
      stat: `${highestAssist?.assists} Assists`,
      team: `${highestAssist?.team}`,
      icon: assistImg,
    },
    {
      id: `${highestCleanSheets?._id}`,
      title: "Goalkeeping Maestro",
      name: `${highestCleanSheets?.name}`,
      stat: `${highestCleanSheets?.cleanSheets} clean sheets`,
      team: `${highestCleanSheets?.team}`,
      icon: cleenSheetImg,
    },
    // {
    //   title: "Growth",
    //   name: "Captivating hearts day by day, one moment at a time.",
    //   stat: ``,
    //   team: ``,
    //   icon: HeartIcon,
    // },
    // {
    //   title: "Global Reach",
    //   name: "Fastest growing football legaue in Asia.",
    //   stat: ``,
    //   team: ``,
    //   icon: GlobeAltIcon,
    // },
  ];
  // if (highestCleanSheets.cleanSheets === 0) {
  //   features.pop();
  // }

  return (
    <div
      className="bg-gradient-to-b from-white via-gray-200 to-gray-900 pt-12 pb-24 mix-blend-color"
      ref={targetRef}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything you need about our League
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-700">
            Let's Introduce you to our top players , dominating the league in
            goals , assist and clean sheets respectively
          </p>
        </div>
        <div className="mx-auto mt-10 sm:mt-20 lg:mt-24 ">
          <dl className="grid  grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none md:grid-cols-3 lg:gap-y-16">
            {features?.map((feature) => (
              <div
                key={feature.title}
                className="relative pl-16 flex flex-col justify-center md:mx-auto"
              >
                <dt className=" flextext-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    {/* <feature.icon
                      className="h-6 w-6 text-white "
                      aria-hidden="true"
                    /> */}
                    <img
                      src={feature.icon}
                      alt=""
                      className="h-6 w-6 text-white"
                      // className="h-6 w-6 text-white filter brightness-0 invert"
                      aria-hidden="true"
                    />
                  </div>
                </dt>
                <div className="flex flex-col">
                  <p className="text-xl font-bold ont-semibold block">
                    {" "}
                    {feature.title}
                  </p>

                  {feature.id !== "undefined" ? (
                    <NavLink to={`/teams/${feature.team}/${feature.id}`}>
                      <dd className="mt-2 text-lg font-bold leading-7 text-sky-700 cursor-pointer  hover:-translate-1 hover:scale-110 hover:text-indigo-600">
                        {feature.name}
                      </dd>
                    </NavLink>
                  ) : (
                    <>
                      <dd className="mt-2 text-lg font-bold leading-7 text-sky-700 cursor-pointer  hover:-translate-1 hover:scale-110 hover:text-indigo-600">
                        {feature.name}
                      </dd>
                    </>
                  )}
                  <dd className="mt-2 text-base font-bold leading-7 text-black">
                    {feature.stat}
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
      {/* its sole pupose is to take the blend intercept a little lower */}
      <div className="p-16"></div>
    </div>
  );
}
