import React, { useState } from "react";
import MatchScoreCard from "../MatchScoreCard";

function CheckMatchUpdates() {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <li className="flex w-full py-5">
      <details className="group w-full">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
          <div
            className="flex justify-between w-full"
            onClick={() => setShowDetails(true)}
          >
            <div className="flex gap-5">
              <div className="min-w-0 flex-auto">
                <p className="text-xl sm:text-2xl md:text-4xl font-semibold leading-6  text-green-700 uppercase">
                  see live updates
                </p>
              </div>
            </div>
          </div>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>
        <main className="m-5 place-content-center text-center border-emerald-50  border-b-2">
          <MatchScoreCard />
        </main>
      </details>
    </li>
  );
}

export default CheckMatchUpdates;
