import { NavLink } from "react-router-dom";
import ReactGA from "react-ga4";

const TeamLeaderBoard = ({ teamData }) => {
  // Example data

  // console.log(teamData, "leaderBoard");
  // Sort the data array based on points (pts)
  // data.sort((a, b) => b.wins * 3 + b.draws - a.wins * 3 - a.draws);

  // if (!teamData || !teamData.teams) {
  //   // Render a loading indicator or error message
  //   return <div>Loading...</div>;
  // }

  // // Your existing code for mapping over teamData.teams
  const open_teamName = (prop) => {
    ReactGA.event({
      category: "Team",
      action: "clicked_Team_LeaderBoard_new",
      label: prop?.name,
    });
  };
  // clicked_Team_LeaderBoard_new --- Date:28-6-24
  return (
    <div className="flex flex-col items-center  py-10  bg-transparent ">
      <h1 className="text-lg text-[#E1C564] font-bold uppercase">
        2024-25 Season
      </h1>
      <div className="flex flex-col mt-6 w-[90%] md:w-[95%] ">
        <div className="-my-2 -mx-2 overflow-x-auto md:mx-0  bg-[#0E2954] rounded-md">
          <div className=" align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full text-sm text-white bg-[#0E2954] sm:rounded-3xl ">
                <thead className="bg-[#0E2954] text-xs uppercase font-medium">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center tracking-wider"
                    >
                      No.
                    </th>
                    <th
                      scope="col"
                      className="px-12 pr-20 py-3 text-left tracking-wider"
                    >
                      Club
                    </th>
                    <th
                      scope="col"
                      className="px-6  py-3 text-left tracking-wider"
                    >
                      MP
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      W
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      D
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      L
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      GS
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      GA
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      GD
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Pts
                    </th>
                    {/* <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Last 5
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-[#0E2954]">
                  {teamData?.map((team, index) => (
                    <tr key={index} className="bg-[#0E2954] bg-opacity-20">
                      <td className="p-4 text-center">{index + 1}</td>
                      <NavLink
                        to={`/teams/${team?.name}/null`}
                        onClick={() => {
                          open_teamName(teamData);
                        }}
                      >
                        <td className="flex pr-2 py-4 whitespace-nowrap gap-4 items-center cursor-pointer hover:text-indigo-600 ">
                          <img
                            className="w-8 h-8 rounded-md"
                            src={team?.team_logo}
                            alt=""
                          />
                          <span className="font-medium mr-1">{team?.name}</span>
                        </td>
                      </NavLink>

                      <td className="px-6 py-4">{team?.matchesPlayed}</td>
                      <td className="px-6 py-4">{team?.wins}</td>
                      <td className="px-6 py-4">{team?.draws}</td>
                      <td className="px-6 py-4">{team?.losses}</td>
                      {/* <td className="px-6 py-4">{team.goalsFor}</td> */}
                      <td className="px-6 py-4">{team.goal_scored}</td>
                      <td className="px-6 py-4">{team.goal_conceded}</td>
                      <td className="px-6 py-4">
                        {team.goal_scored - team.goal_conceded}
                      </td>
                      <td className="px-6 py-4">
                        {team?.wins * 3 + team?.draws}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamLeaderBoard;
