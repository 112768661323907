import React, { useEffect, useState } from "react";

import vagad from "../assets/vagad.png";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useApp } from "../context/app-context";
import iosInstallation from "../assets/InstallationGuide.png";
import android_installation from "../assets/android_installation.jpg";
import ReactGA from "react-ga4";

function Navbar() {
  // const { backgroundColorTeam, setBackgroundColorTeam, login } = useApp();
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const { login, navigation, setNavigation } = useApp();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [check, setCheck] = useState(true);
  let navDummy = [
    { displayName: "Home", name: "home", path: "/" },
    { displayName: "Teams", name: "teams", path: "teams" },
    { displayName: "Matches", name: "matches", path: "matches" },
    { displayName: "Login", name: "login", path: "login" },
  ];
  const storedUser = JSON.parse(localStorage.getItem("loginCheck"));

  const adminCheck = JSON.parse(localStorage.getItem("adminCheck"));

  if (!adminCheck && storedUser) {
    navDummy.pop();

    navDummy.push({ displayName: "Fantasy", name: "fantasy", path: "fantasy" });
  }

  if (adminCheck) {
    navDummy.pop();
    navDummy.push({ displayName: "Admin", name: "admin", path: "admin" });
  }
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("loginCheck"));
    // console.log("Navnar mobile user", storedUser);
    const adminCheck = JSON.parse(localStorage.getItem("adminCheck"));
    //console.log("Navbar mobile amdmin", adminCheck);

    if (storedUser && adminCheck) {
      const updatedNavigation = [
        { displayName: "Home", name: "home", path: "/" },
        { displayName: "Teams", name: "teams", path: "teams" },
        { displayName: "Matches", name: "matches", path: "matches" },
        // remove the object of login navlink if you want login to dissapear when you login in
        {
          displayName: "Fantasy Leaderboard",
          name: "publicFantasy",
          path: "publicFantasy",
        },
        { displayName: "VFL Gallery", name: "community", path: "community" },
        { displayName: "Profile", name: "login", path: "login" },
        { displayName: "Admin", name: "admin", path: "admin" },
      ];
      // Set the state with the new array
      //console.log(updatedNavigation);
      setNavigation(updatedNavigation);
    } else if (storedUser && !adminCheck) {
      const updatedNavigation = [
        { displayName: "Home", name: "home", path: "/" },
        { displayName: "Teams", name: "teams", path: "teams" },
        { displayName: "Matches", name: "matches", path: "matches" },
        // remove the object of login navlink if you want login to dissapear when you login in

        { displayName: "Fantasy", name: "fantasy", path: "fantasy" },
        {
          displayName: "Fantasy Leaderboard",
          name: "publicFantasy",
          path: "publicFantasy",
        },
        { displayName: "VFL Gallery", name: "community", path: "community" },
        { displayName: "Profile", name: "login", path: "login" },
      ];
      // Set the state with the new array
      //console.log(updatedNavigation);
      setNavigation(updatedNavigation);
    }

    // const handleBeforeInstallPrompt = (e) => {
    //   e.preventDefault();
    //   setDeferredPrompt(e);
    //   // setShowPrompt(true);
    //   // }
    // };
  }, [check]);

  const handleInstallClick = () => {
    // e.preventDefault();
    // setDeferredPrompt(e);
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          //console.log("User accepted the install prompt");
        } else {
          //console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  const checkedIosInstall = () => {
    ReactGA.event({
      category: "install manual",
      action: "checked_ios",
      label: "opened", // Optional
    });
  };
  const checkedAndroidInstall = () => {
    ReactGA.event({
      category: "install manual",
      action: "checked_android",
      label: "opened", // Optional
    });
  };
  const checkedRuleBook = () => {
    ReactGA.event({
      category: "Check Fantasy Rules",
      action: "click on rules",
      label: "opened rules", // Optional
    });
  };

  return (
    <>
      {/* <header className="absolute inset-x-0 top-0 z-50 w-full "> */}
      {/* when i put absolute to fixed the error of extra space vanishes */}
      {/* sticky navbar */}
      <header className="fixed inset-x-0 top-0 z-50 w-full backdrop-blur-md bg-slate-900/80">
        {/* <header className="relative inset-x-0 top-0 z-50">  if you need a partition between component and nvbar*/}
        <nav
          className="flex items-center justify-between px-2 pt-4 pb-4 md:py-6 lg:px-4"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-8 w-auto rounded-full" src={vagad} alt="" />
            </a>
          </div>
          <div className=" gap-x-1 hidden sm:flex sm:gap-x-3 md:gap-x-4 lg:gap-x-6">
            {navigation?.map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                // className="text-lg font-semibold leading-6 text-gray-900"
                className={({ isActive }) =>
                  ` text-md md:text-md lg:text-xl font-bold ont-semibold block py-2 lg:pr-3 lg:pl-2 pr-2 pl-1 duration-200 ${
                    isActive ? "text-indigo-500" : "text-gray-400"
                  }   lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0`
                }
              >
                {item.displayName}
              </NavLink>
            ))}
          </div>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-10 w-auto rounded-full"
                    src={vagad}
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root ">
                <div className="-my-6 ">
                  <div className="space-y-1 pt-6 pb-3 border-b-2">
                    {navigation?.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item.path}
                        className={({ isActive }) =>
                          `text-xl font-bold  block py-2 pr-4 pl-3 duration-200 ${
                            isActive ? "text-indigo-700" : "text-gray-900"
                          }   hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0`
                        }
                        onClick={() => setMobileMenuOpen(false)} // Close the mobile menu on click
                      >
                        {item.displayName}
                      </NavLink>
                    ))}
                  </div>
                  <div className="flex gap-7 p-2  mt-1 mb-1">
                    <div className="min-w-0 flex-auto">
                      <p className=" font-bold text-xl sm:text-2xl md:text-4xl  leading-6 text-gray-900  ">
                        Installation
                      </p>
                    </div>
                  </div>
                  <details className="group w-full mt-1">
                    <summary
                      className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer"
                      onClick={checkedIosInstall}
                    >
                      <div
                        className="flex justify-between w-full"
                        onClick={() => setShowDetails(true)}
                      >
                        <div className="flex gap-5 ">
                          <div className="min-w-0 flex-auto">
                            <p className="pl-2 font-bold text-xl sm:text-2xl md:text-4xl  leading-6 text-gray-900  ">
                              IOS
                            </p>
                          </div>
                        </div>
                      </div>
                      <svg
                        className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        ></path>
                      </svg>
                    </summary>
                    <div className=" mx-auto max-w-[850px] bg-white ">
                      <form className="flex flex-col justify-center gap-4">
                        {/* <div className="flex flex-col items-center sm:flex-row sm:gap-4 justify-center mt-10 w-[80%] sm:w-auto m-10 gap-4 "> */}
                        <div className="flex flex-col sm:gap-4  mt-10  m-10 gap-4 ">
                          <img
                            src={iosInstallation}
                            alt=""
                            className="w-full h-100"
                          />
                        </div>
                      </form>
                    </div>
                  </details>
                  <details className="group w-full">
                    <summary
                      className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer"
                      onClick={checkedAndroidInstall}
                    >
                      <div
                        className="flex justify-between w-full"
                        onClick={() => setShowDetails(true)}
                      >
                        <div className="flex gap-5">
                          <div className="min-w-0 flex-auto">
                            <p className="pl-2  font-bold text-xl sm:text-2xl md:text-4xl  leading-6 text-gray-900  ">
                              Android
                            </p>
                          </div>
                        </div>
                      </div>
                      <svg
                        className="w-5 h-5 text-gray-500 transition group-open:rotate-90 self-center"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        ></path>
                      </svg>
                    </summary>
                    <div className=" mx-auto max-w-[850px] bg-white ">
                      <form className="flex flex-col justify-center gap-4">
                        {/* <div className="flex flex-col items-center sm:flex-row sm:gap-4 justify-center mt-10 w-[80%] sm:w-auto m-10 gap-4 "> */}
                        <div className="flex flex-col sm:gap-4  mt-10  m-10 gap-4 ">
                          <img
                            src={android_installation}
                            alt=""
                            className="w-full h-100"
                          />
                        </div>
                      </form>
                    </div>
                  </details>
                  <details className="group w-full mt-2 border-t-2 border-gray-200">
                    <summary
                      className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer"
                      onClick={checkedRuleBook}
                    >
                      <div
                        className="flex justify-between w-full"
                        onClick={() => setShowDetails(true)}
                      >
                        <div className="flex gap-5 mb-1">
                          <div className="min-w-0 flex-auto">
                            <p className=" font-bold text-xl sm:text-2xl md:text-4xl  leading-6 text-gray-900  ">
                              Rule Book
                            </p>
                          </div>
                        </div>
                      </div>
                    </summary>
                    <div className=" mx-auto max-w-[850px] bg-white border-t-2 ">
                      <form className="flex flex-col justify-center gap-4">
                        {/* <div className="flex flex-col items-center sm:flex-row sm:gap-4 justify-center mt-10 w-[80%] sm:w-auto m-10 gap-4 "> */}
                        <div className="flex flex-col  ">
                          <div className="flex justify-center items-center h-screen bg-gray-100">
                            <iframe
                              className="w-full h-full"
                              src="https://docs.google.com/document/d/1ANWWfz7Ohqf9XMbdPA3iDaQljqLeUTztv7XBBHMBkMY/edit?usp=sharingembedded=true"
                              title="Embedded Google Doc"
                            ></iframe>
                          </div>
                        </div>
                      </form>
                    </div>
                  </details>
                  {/* <div className="py-2">
                    <img
                      src={iosInstallation}
                      alt=""
                      className="w-full h-100"
                    />
                  </div> */}
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
          <div className="flex sm:hidden justify-around w-4/5">
            {navDummy?.map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                // className="text-lg font-semibold leading-6 text-gray-900"
                className={({ isActive }) =>
                  ` text-md lg:text-xl font-bold  block py-2 lg:pr-3 lg:pl-2 pr-2 pl-1 duration-200 ${
                    isActive ? "text-indigo-500" : "text-gray-400"
                  }   lg:hover:bg-transparent lg:border-0 hover:text-orange-700 lg:p-0`
                }
              >
                {item.displayName}
              </NavLink>
            ))}
          </div>
          <div className=" lg:flex lg:flex-1 lg:justify-end">
            {/* keeps the nav links in the center */}
          </div>

          <div className="flex sm:hidden ">
            <button
              type="button"
              className="-my-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-blue-900 font-bold bg-slate-900 bg-opacity-100"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
